import { connect } from 'react-redux';
import './ContributorApplications.css';
import ContributorApplications from './ContributorApplications';
import { getMyAccountRequest } from '../../../redux/actions/account';
import {
  getContributorApplicationsRequest,
  searchContributorApplicationsRequest,
  clearContributorApplicationsCache,
  approveContributorApplicationRequest,
  denyContributorApplicationRequest,
} from '../../../redux/actions/contributor';
import {
  searchUserRequest,
} from '../../../redux/actions/account';

const mapStateToProps = state => ({
  accountInfo: state.account.myAccount.info,
  contributorApplications: state.contributors.contributorApplications,
  application: state.contributors.application,
  deniedUser: state.account.user,
});

const mapDispatchToProps = {
  getMyAccountRequest,
  getContributorApplicationsRequest,
  searchContributorApplicationsRequest,
  clearContributorApplicationsCache,
  approveContributorApplicationRequest,
  denyContributorApplicationRequest,
  searchUserRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContributorApplications);
