import { connect } from 'react-redux';
import Sidebar from './Sidebar';
import './Sidebar.css';
import {
  getMyAccountRequest,
} from '../../redux/actions/account';
import { logout } from '../../redux/actions/login';

const mapStateToProps = state => ({
  authenticated: state.login.authenticated,
  isContributor: state.account.myAccount.info.isContributor,
  isAdmin: state.account.myAccount.info.isAdmin,
  account: state.account.myAccount.info,
});

const mapDispatchToProps = {
  logout,
  getMyAccountRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sidebar);
