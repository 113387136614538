import { connect } from "react-redux";
import News from "./News";
import "./News.css";
import {
  newsFeedListRequest,
  feedNewsItemsRequest,
  getNewsRequest,
  searchNewsRequest,
  getNewsFeedRequest,
  getCategoryNewsRequest,
  getSeeMoreNewsRequest,
  saveNewsRequest,
  getSavedNewsRequest,
  getSavedNewsSeeMoreRequest,
  getSavedNewsIdsRequest,
  unSavedNewIdRequest,
  getCategoryRequest,
  getCategoryListRequest,
  clearSpecificNewsArticle,
} from "../../redux/actions/news";
import { showLoginModal } from '../../redux/actions/login'

const mapStateToProps = state => ({
  newsFeed: state.news.newsFeed,
  publishers: state.news.publishers,
  trendingTopics: state.news.trendingTopics,
  savedNews: state.news.savedNews,
  savedNewsIds: state.news.savedNewsIds,
  specificArticle: state.news.specificArticle,
});

const mapDispatchToProps = {
  newsFeedListRequest,
  feedNewsItemsRequest,
  getNewsRequest,
  searchNewsRequest,
  getNewsFeedRequest,
  getCategoryNewsRequest,
  getSeeMoreNewsRequest,
  saveNewsRequest,
  getSavedNewsRequest,
  getSavedNewsSeeMoreRequest,
  getSavedNewsIdsRequest,
  unSavedNewIdRequest,
  getCategoryRequest,
  getCategoryListRequest,
  showLoginModal,
  clearSpecificNewsArticle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(News);
