export default function WebWorker(args) {
  // const endpoint = "http://VelocityChannel-env.cvpbndzpbb.us-east-1.elasticbeanstalk.com/api/account/accessToken"
  let endpoint = ''
  if (process.env.NODE_ENV == "development") {
      endpoint = `http://VelocityChannelDev-env.zchvmbakqf.us-east-2.elasticbeanstalk.com/api/account/accessToken`
  } else {
      endpoint = `https://www.velocitychannel.io/api/account/accessToken`
  }
  // endpoint = "https://Velocitychannel.io/api/"

  let onmessage = e => {
    /*
      Listens for messages sent to WebWorker.js.
      Messages are composed of 'orders' & 'payloads',
        though not all messages/orders will require payloads.
      Based on the order given, a certain action will be performed.
      If the order is 'refreshToken',
        wait amountOfTime,
        then perform an xmlHttpRequest with the payload (a refreshToken).
      If the order is 'stop',
        send back a message of 'terminate'.
        (this will terminate the current instance of webWorker)
        (this is typically called on logout)
    */
    let message = { order: '', payload: {} }
    let amountOfTime = 180000 // in milliseconds

    if (e.data.order === 'refreshToken') {
        setTimeout(() => xmlHttpRequest(e.data.payload), amountOfTime)
    } else if (e.data.order === 'stop') {
        message = { order: 'terminate', payload: {} }
        postMessage(message)
    }
  } // end of onmessage(e)

  let xmlHttpRequest = data => {
    /*
      The HttpRequest to receive a new accessToken.
      If the xhrStatus === 200, you have received a new accessToken.
        Parse the response,
        then create message w/ order: 'accessToken' & payload of accessToken,
        then send message.
        (The webWorker in login.js has an eventListener that will hear the
        message & perform a task)
    */
    let message = { order: '', payload: {} }
    var xhr = new XMLHttpRequest();

    xhr.onload = function() {
      var response = xhr.response;
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const accessToken = JSON.parse(response)
          message = { order: 'accessToken', payload: accessToken.data }
          postMessage(message);
        }
      }
    }
    xhr.open("POST", endpoint, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send(`refreshToken=${data.refreshToken}`);
  } // end of xmlHttpRequest(accessToken)
} // end of WebWorker
