export const POST_EVENT_REQUEST = 'POST_EVENT_REQUEST'
export const POST_EVENT_SUCCESS = 'POST_EVENT_SUCCESS'

export const POST_UPVOTE_REQUEST = 'POST_UPVOTE_REQUEST'
export const POST_UPVOTE_SUCCESS = 'POST_UPVOTE_SUCCESS'

export const REMOVE_COMMENT_REQUEST = 'REMOVE_COMMENT_REQUEST'
export const REMOVE_COMMENT_SUCCESS = 'REMOVE_COMMENT_SUCCESS'

export const GET_CONTRIBUTOR_STATS_REQUEST_ANALYTICS = 'GET_CONTRIBUTOR_STATS_REQUEST_ANALYTICS'
export const GET_CONTRIBUTOR_STATS_SUCCESS_ANALYTICS = 'GET_CONTRIBUTOR_STATS_SUCCESS_ANALYTICS'

export const GET_REPORT_STATS_REQUEST = 'GET_REPORT_STATS_REQUEST'
export const GET_REPORT_STATS_SUCCESS = 'GET_REPORT_STATS_SUCCESS'

export const REFRESH_REPORT_COMMENTS = 'REFRESH_REPORT_COMMENTS'

export const REMOVE_EVENT_REQUEST = 'REMOVE_EVENT_REQUEST'
export const REMOVE_EVENT_SUCCESS = 'REMOVE_EVENT_SUCCESS'

export const UNPUBLISH_ARTICLE_REQUEST = 'UNPUBLISH_ARTICLE_REQUEST'
export const UNPUBLISH_ARTICLE_SUCCESS = 'UNPUBLISH_ARTICLE_SUCCESS'

export const CHECK_VIEWED_STATUS = 'CHECK_VIEWED_STATUS'
