import React from 'react';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';

export default ({ currentUser }) => (
    <Container className="mt-5">
        <Jumbotron>
            <h1>Hello, {currentUser.name}!</h1>
            <p>
                This is a simple platform to manage your weekly work.
            </p>
        </Jumbotron>
    </Container>
);