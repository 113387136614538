import React, { Fragment, Component } from "react"
import { FaFacebookSquare, FaTwitterSquare, FaLinkedin } from "react-icons/fa"
import { Link, NavLink } from 'react-router-dom'
import {
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    Image,
    Button,
    Dropdown,
    NavDropdown,
} from 'react-bootstrap'

export default class Footer extends Component {
  render() {
    return (
      <Row noGutters className='footer'>
        <Col>
          <Row noGutters className='footer-row'>
            <div className='footer-icon-container'>
              <a href={'/'} target='_blank' className='footer-icon'>
                <FaFacebookSquare />
              </a>
              <a href={'/'} target='_blank' className='footer-icon'>
                <FaTwitterSquare />
              </a>
              <a href={'/'} target='_blank' className='footer-icon'>
                <FaLinkedin />
              </a>
            </div>
          </Row>
          <Row noGutters className='all-rights-reserved'>
            @ 2020 Fundamental Interactions. All rights reserved.
          </Row>
        </Col>
      </Row>
    )
  }
}

// <Row noGutters className='footer-row'>
//   <div className='footer-links-container'>
//     <div className='footer-links'>
//       <NavLink className='footer-navlink' to='/privacypolicy'>
//           Privacy Policy
//       </NavLink>
//       <NavLink className='footer-navlink' to="/terms">
//           Terms of Service
//       </NavLink>
//     </div>
//     <div className='footer-links'>
//       <NavLink className='footer-navlink' to="/contact">
//           Contact Us
//       </NavLink>
//       <NavLink className='footer-navlink' to="/about">
//           About Us
//       </NavLink>
//     </div>
//   </div>
// </Row>
//
