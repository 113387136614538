import * as CONSTANTS from '../constants/research';
import { CLEAR_USER_CACHE } from '../constants/account'

const initialState = {
  reports: {
    list: [],
    loading: false,
    error: '',
  },
  report: {
    item: [],
    loading: false,
    error: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case CLEAR_USER_CACHE:
    // state = initialState
    case CONSTANTS.GET_REPORTS_REQUEST:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
        },
      };
    case CONSTANTS.GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          list: action.reports,
          loading: false,
        },
      };
    case CONSTANTS.GET_REPORTS_ERROR:
      return {
        ...state,
        reports: {
          ...state.reports,
          error: action.error,
          loading: false,
        },
      };
    case CONSTANTS.GET_REPORT_REQUEST:
      return {
        ...state,
        report: {
          ...state.report,
          loading: true,
        },
      };
    case CONSTANTS.GET_REPORT_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          item: action.report,
          loading: false,
        },
      };
    case CONSTANTS.GET_REPORT_ERROR:
      return {
        ...state,
        report: {
          ...state.report,
          error: action.error,
          loading: false,
        },
      };
    case CONSTANTS.SET_RESET_REPORT:
      return {
        ...state,
        report: {
          ...state.report,
          item: [],
          loading: false,
          error: '',
        },
      };
    // case CONSTANTS.SAVE_DRAFT_REQUEST:
    //     return {
    //         ...state,
    //         draft: {
    //             ...state.draft,
    //
    //         }
    //     }
    default:
      return state;
  }
};
