import { connect } from 'react-redux';
import AccountSettings from './AccountSettings';
import './AccountSettings.css';
import {
  getMyAccountRequest,
  profileImageUploadRequest,
  editAccountRequest,
  sendInvitesRequest,
} from '../../redux/actions/account';

const mapStateToProps = state => ({
  accountInfo: state.account.myAccount.info,
  accountImage: state.account.myAccount.image,
});
const mapDispatchToProps = {
  getMyAccountRequest,
  profileImageUploadRequest,
  editAccountRequest,
  sendInvitesRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountSettings);
