import { connect } from 'react-redux';
import Terms from './Terms';
import './Terms.css';
const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Terms);