import React, { PureComponent } from "react";
import he from "he";
import {
    Container,
    Row,
    Col,
    Image,
    Button,
} from 'react-bootstrap'
import ReactHtmlParser from "react-html-parser";
import { MdThumbUp, MdThumbDown, } from 'react-icons/md'
import defaultAvatar from '../../../images/default-avatar-image.png'
import "./Preview.css";

class Preview extends PureComponent {
    constructor(props) {
        super(props)
    } // end of constructor(props)

    formatDate = dateInput => {
      let date = new Date(dateInput);
      const month = date.toLocaleString("en-us", { month: "short" });
      const day = date.getDate();
      // const year = date.getFullYear();
      return month + " " + day;
    } // end of formatDate(dateInput)

    renderTopics = topics => {
      if (topics) {
        return (
          <Row className='tags'>
            Topics: {this.renderTopic(topics)}
          </Row>
        )
      }
    } // end of renderTopics(topics)

    renderTopic = topics => {
      if (topics) {
        if (topics.length) {
          return topics.map( topic =>
            <div className='info-piece'>{topic}</div>
          )}
        }
      } // end of renderTags(topics)

    renderCategory = category => {
      if (category) {
        return (
          <Row className='category'>
            Category: <div className='info-piece'>{category}</div>
          </Row>
        )
      }
    } // end of renderCategory(category)

    renderTestArticle = () => {
      const { title, summary, body, accountInfo, image, tags, category, likes, dislikes } = this.props.currentDraft.data.docs[0]
      const { displayName } = this.props.accountInfo
      let datePublished = this.formatDate(new Date())
      return (
        <React.Fragment>
          <Row className='render-article-top-row'>
            <Col className='render-article-info-col'>
              <Row className='render-article-title-row'>
                <h1>{title || 'No Title'}</h1>
              </Row>
              <Row className='render-article-summary-row'>
                <h2>{summary || 'No Summary'}</h2>
              </Row>
              <Row className='render-article-user-row'>
                <Col md={0.5} className='render-article-user-image-col'>
                  <Image className='render-article-user-image' src={accountInfo.image || defaultAvatar} roundedCircle fluid />
                </Col>
                <Col className='render-article-user-info-col'>
                  <Row className='render-article-name-row'>
                    <p>{displayName}</p>
                    {/* <Button
                      className='follow-button'
                      onClick={() => this.handleFollow(this.props.user.userId, userId, 'contributor')}
                      >
                        {stats.isFollowing ? 'Unfollow' : 'Follow'}
                      </Button> */}
                  </Row>
                  <Row className='render-article-date-row' id='preview-body-row'>
                    {this.formatDate(datePublished)}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='render-article-body-row'>
            {body ? ReactHtmlParser(he.decode(body)) : ''}
          </Row>
        </React.Fragment>
      )
    } // end of renderArticle()

    renderTopics = topics => {
      if (topics) {
        return (
          <Row className='tags'>
            Topics: {this.renderTopic(topics)}
          </Row>
        )
      }
    } // end of renderTopics(topics)

    render() {
      console.log(this.props)
      return (
        <div>test</div>
      )
    }

} // end of class Preview

export default Preview
