import React, { Component } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Image,
  Nav,
  Form,
  FormControl,
  InputGroup,
  Jumbotron,
  Spinner
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ReactLoading from "react-loading";
import ReactHtmlParser from "react-html-parser";
import he from "he";
import { GoPencil } from "react-icons/go";
import { MdRemoveShoppingCart, MdArrowBack } from "react-icons/md";
import { history } from "../../../configureStore";
import "./FullReportPage.css";

class FullReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const { reportId } = this.props;
    this.props.readArticleRequest(reportId);
  }

  renderLoading = () => (
    <div className="loading-bar">
      <ReactLoading type={"spin"} color={"#5790c8"} height={100} width={100} />
    </div>
  );

  formatDate = dateInput => {
    let date = new Date(dateInput);
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    return month + " " + day + ", " + year;
  };

  onClickUnpublishReport = (e, id, userId) => {
    this.props.unpublishReportRequest(id, userId);
  };

  renderViewFullReport = () => {
    const {
      articles: { article, loading }
    } = this.props;

    if (loading) {
      this.renderLoading();
    } else {
      // console.log("article");
      // console.log(article);
      if (article && article.length > 0) {
        const {
          image,
          datePublished,
          summary,
          industry,
          id,
          published,
          body,
          category,
          title
        } = article[0].report;
        const { displayName, username, userId } = article[0].contributor;
        const contributorImage = article[0].contributor.image;
        return (
          <React.Fragment>
            <Row className="report-title-author-section">
              <Col sm={{ span: 9, offset: 0 }}>
                <Row className="report-title-section">
                  <h4>{title}</h4>
                  <p>{summary}</p>
                </Row>
                <Row>
                  <Image
                    src={image}
                    className="report-section-article-image "
                  />
                </Row>
                <Row>
                  <p className="report-section-article-body">
                    {ReactHtmlParser(he.decode(body))}
                  </p>
                </Row>
              </Col>
              <Col sm={{ span: 3, offset: 0 }}>
                <Row>
                  <Col
                    sm={{ span: 9, offset: 1 }}
                    className="report-author-section"
                  >
                    <p>{displayName}</p>
                    <p>{userId}</p>
                    <p>{this.formatDate(datePublished)}</p>
                  </Col>
                  <Col sm={{ span: 2, offset: 0 }}>
                    <Image
                      src={contributorImage}
                      className="report-author-section-image"
                    />
                  </Col>
                </Row>
                <Row className="research-report-unpublish">
                  <Col sm={{ span: 4, offset: 7 }}>
                    {published == true && (
                      <button
                        onClick={e => {
                          this.onClickUnpublishReport(e, id, userId);
                        }}
                      >
                        unpublish
                      </button>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col className="report-category-section">
                    <ul>
                      <li>{category}</li>
                      <li>{industry}</li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <Row style={{ margin: "53px" }}>no data</Row>
          </React.Fragment>
        );
      }
    }
  };

  render() {
    return <React.Fragment>{this.renderViewFullReport()}</React.Fragment>;
  }
}

export default FullReportPage;
