import * as CONSTANTS from '../constants/contributor';
import { CLEAR_USER_CACHE } from '../constants/account'

const initialState = {
  contributors: {
    list: [],
    error: ""
  },
  featuredContributors: {
    list: [],
    error: ""
  },
  followedContributors: {
    list: [],
    loading: false
  },
  application: {
    selectedApplication: {},
    found: false
  },
  contributorApplications: {
    list: [],
    error: "",
    loading: false,
    loaded: false,
  },
  contributor: {
    info: {},
    loaded: false
  },
  analytics: {
    data: {},
    loaded: false
  },
  reports: {
    list: [],
    loading: false,
    loaded: false,
    error: ""
  },
  contributorReports: {
    list: [],
    loaded: false,
  },
  contributorStats: {
    user: {},
    stats: {},
    loaded: false,
  },
  lineGraphData: {
    data: [],
    loaded: false,
  },
  pieChartData: {
    data: [],
    loaded: false,
  },
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case CLEAR_USER_CACHE:
    // state = initialState
    case CONSTANTS.GET_CONTRIBUTORS_SUCCESS:
      return {
        ...state,
        contributors: {
          ...state.contributors,
          list: action.list
        }
      };
    case CONSTANTS.GET_CONTRIBUTORS_ERROR:
      return {
        ...state,
        contributors: {
          ...state.contributors,
          error: action.error
        }
      };
    case CONSTANTS.GET_FEATURED_CONTRIBUTOR_SUCCESS:
      return {
        ...state,
        featuredContributors: {
          ...state.featuredContributors,
          list: action.list
        }
      };
    case CONSTANTS.GET_FEATURED_CONTRIBUTOR_ERROR:
      return {
        ...state,
        featuredContributors: {
          ...state.featuredContributors,
          error: action.error
        }
      };
    case CONSTANTS.GET_CONTRIBUTOR_REQUEST:
      return {
        ...state,
        contributor: {
          ...state.contributor,
          info: {},
          loaded: false
        }
      };
    case CONSTANTS.GET_CONTRIBUTOR_SUCCESS:
      return {
        ...state,
        contributor: {
          ...state.contributor,
          info: action.contributor,
          loaded: true
        }
      };
    case CONSTANTS.GET_CONTRIBUTOR_ERROR:
      return {
        ...state,
        error: action.error
      };
    case CONSTANTS.RESET_CONTRIBUTOR_FORM_ITEMS:
      return {
        ...state,
        contributor: {}
      };
    case CONSTANTS.GET_CONTRIBUTOR_APPLICATIONS_REQUEST:
      return {
        ...state,
        contributorApplications: {
          ...state.contributorApplications,
          error: "",
          loading: true,
          loaded: false,
        }
      };
    case CONSTANTS.GET_CONTRIBUTOR_APPLICATIONS_SUCCESS:
      return {
        ...state,
        contributorApplications: {
          list: action.contributorApplications.data,
          error: "",
          loading: false,
          loaded: true,
        }
      }
    case CONSTANTS.SEARCH_CONTRIBUTOR_APPLICATIONS_SUCCESS:
      return {
        ...state,
        application: {
          selectedApplication: action.application.data,
          found: true
        }
      };
    case CONSTANTS.CLEAR_CONTRIBUTOR_APPLICATIONS_CACHE:
      return {
        ...state,
        application: {
          selectedApplication: {},
          found: false
        }
      };
    case CONSTANTS.FOLLOW_CONTRIBUTOR_REQUEST:
      return {
        ...state,
        followedContributors: {
          ...state.followedContributors,
          loading: true
        }
      };
    case CONSTANTS.FOLLOW_CONTRIBUTOR_SUCCESS:
      return {
        ...state,
        followedContributors: {
          ...state.followedContributors,
          list: action.followedContributors
        }
      };
    case CONSTANTS.GET_CONTRIBUTOR_ANALYTICS_REQUEST:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          data: {},
          loaded: false
        }
      };
    case CONSTANTS.GET_CONTRIBUTOR_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          data: action.data,
          loaded: true
        }
      };
    case CONSTANTS.GET_CONTRIBUTORS_REPORT_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          list: action.list,
          loading: false,
          loaded: true
        }
      };
    case CONSTANTS.GET_CONTRIBUTORS_REPORT_ERROR:
      return {
        ...state,
        reports: {
          ...state.reports,
          error: action.error
        }
      }
    case CONSTANTS.GET_CONTRIBUTOR_REPORTS_REQUEST:
      return {
        ...state,
        contributorReports: {
          list: [],
          loaded: false,
        }
      }
    case CONSTANTS.GET_CONTRIBUTOR_REPORTS_SUCCESS:
      return {
        ...state,
        contributorReports: {
          list: action.reports,
          loaded: true,
        }
      }
    case CONSTANTS.GET_CONTRIBUTOR_STATS_REQUEST:
      return {
        ...state,
        contributorStats: {
          user: {},
          stats: {},
          loaded: false,
        }
      }
    case CONSTANTS.GET_CONTRIBUTOR_STATS_SUCCESS:
      return {
        ...state,
        contributorStats: {
          user: action.userData.user,
          stats: action.userData.stats,
          loaded: true,
        }
      }
    case CONSTANTS.GET_LINE_GRAPH_DATA_REQUEST: {
      return {
        ...state,
        lineGraphData: {
          ...state.lineGraphData,
          loaded: false,
        },
      }
    }
    case CONSTANTS.GET_LINE_GRAPH_DATA_SUCCESS: {
      return {
        ...state,
        lineGraphData: {
          data: action.data,
          loaded: true,
        },
      }
    }
    case CONSTANTS.GET_TOKEN_GRAPH_DATA_SUCCESS: {
      return {
        ...state,
        lineGraphData: {
          data: action.data,
          loaded: true,
        },
      }
    }
    case CONSTANTS.GET_CONTRIBUTOR_PIE_CHART_DATA_REQUEST: {
      return {
        ...state,
        pieChartData: {
          ...state.pieChartData,
          loaded: false,
        },
      }
    }
    case CONSTANTS.GET_CONTRIBUTOR_PIE_CHART_DATA_SUCCESS: {
      return {
        ...state,
        pieChartData: {
          data: action.data,
          loaded: true,
        },
      }
    }
    default:
      return state;
  }
};
