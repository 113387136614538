import * as CONSTANTS from '../constants/research';

export const researchFormRequest = data => ({
  type: CONSTANTS.RESEARCH_FORM_REQUEST,
  data,
});

export const researchFormSuccess = response => ({
  type: CONSTANTS.RESEARCH_FORM_SUCCESS,
  response,
});

export const researchFormError = error => ({
  type: CONSTANTS.RESEARCH_FORM_ERROR,
  error,
});

export const getReportsReqeust = () => ({
  type: CONSTANTS.GET_REPORTS_REQUEST,
});

export const getReportsSuccess = reports => ({
  type: CONSTANTS.GET_REPORTS_SUCCESS,
  reports,
});

export const getReportsError = error => ({
  type: CONSTANTS.GET_REPORTS_ERROR,
  error,
});

export const getReportRequest = id => ({
  type: CONSTANTS.GET_REPORT_REQUEST,
  id,
});

export const getReportSuccess = report => ({
  type: CONSTANTS.GET_REPORT_SUCCESS,
  report,
});

export const getReportError = error => ({
  type: CONSTANTS.GET_REPORT_ERROR,
  error,
});

export const deleteReportRequest = id => ({
  type: CONSTANTS.DELETE_REPORT_REQUEST,
  id,
});

export const setResetReport = () => ({
  type: CONSTANTS.SET_RESET_REPORT,
});

export const editReportRequest = data => ({
  type: CONSTANTS.EDIT_REPORT_REQUEST,
  data,
});
