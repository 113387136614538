import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from '../constants/resetpassword';

export const resetPasswordRequest = (code, email, password, cpassword) => ({
  type: RESET_PASSWORD_REQUEST,
  code,
  email,
  password,
  cpassword,
});

export const resetPasswordSuccess = responseData => ({
  type: RESET_PASSWORD_SUCCESS,
  responseData,
});

export const resetPasswordError = error => ({
  type: RESET_PASSWORD_ERROR,
  error,
});

export const resetPasswordHistory = () => ({
  type: 'RESET_PASSWORD_HISTORY',
});
