import * as CONSTANTS from "../constants/news";

export const newsFeedListRequest = () => ({
  type: CONSTANTS.NEWS_FEED_LIST_REQUEST
});

export const newsFeedListSuccess = feedList => ({
  type: CONSTANTS.NEWS_FEED_LIST_SUCCESS,
  feedList
});

export const newsFeedListError = error => ({
  type: CONSTANTS.NEWS_FEED_LIST_ERROR,
  error
});

export const feedNewsItemsRequest = feedItem => ({
  type: CONSTANTS.FEED_NEWS_ITEMS_REQUEST,
  feedItem
});

export const feedNewsItemSuccess = feedItems => ({
  type: CONSTANTS.FEED_NEWS_ITEMS_SUCCESS,
  feedItems
});

export const feedNewsItemsError = error => ({
  type: CONSTANTS.FEED_NEWS_ITEMS_ERROR,
  error
});

export const getNewsRequest = id => ({
  type: CONSTANTS.GET_NEWS_REQUEST,
  id
});

export const getNewsSuccess = news => ({
  type: CONSTANTS.GET_NEWS_SUCCESS,
  news
});

export const getNewsError = error => ({
  type: CONSTANTS.GET_NEWS_ERROR,
  error
});

export const searchNewsRequest = (
  searchTitle,
  publisher,
  toDate,
  fromDate,
  offset,
  compact
) => ({
  type: CONSTANTS.SEARCH_NEWS_REQUEST,
  searchTitle,
  offset,
  publisher,
  toDate,
  fromDate,
  compact
});

export const searchNewsSuccess = searchResult => ({
  type: CONSTANTS.SEARCH_NEWS_SUCCESS,
  searchResult
});

export const searchNewsError = error => ({
  type: CONSTANTS.SEARCH_NEWS_ERROR,
  error
});

export const getCategoryNewsRequest = (category, offset, compact) => ({
  type: CONSTANTS.GET_CATEGORY_REQUEST,
  category,
  offset,
  compact
});

export const getNewsFeedRequest = params => ({
  type: CONSTANTS.GET_NEWS_FEED_REQUEST,
  params
});

export const getNewsFeedSuccess = data => ({
  type: CONSTANTS.GET_NEWS_FEED_SUCCESS,
  data
});

export const getNewsFeedError = error => ({
  type: CONSTANTS.GET_NEWS_FEED_ERROR,
  error
});

export const getSeeMoreNewsRequest = (publisher, date) => ({
  type: CONSTANTS.GET_NEWS_SEE_MORE_REQUEST,
  publisher,
  date
});

export const getSeeMoreNewsSuccess = moreNews => ({
  type: CONSTANTS.GET_NEWS_SEE_MORE_SUCCESS,
  moreNews
});

export const getSeeMoreNewsError = error => ({
  type: CONSTANTS.GET_NEWS_SEE_MORE_ERROR,
  error
});

export const saveNewsRequest = (id, title, date, publisher) => ({
  type: CONSTANTS.SAVE_NEWS_REQUEST,
  id,
  title,
  date,
  publisher
});

export const saveNewsSuccess = savedNews => ({
  type: CONSTANTS.SAVE_NEWS_SUCCESS,
  savedNews
});

export const saveNewsError = error => ({
  type: CONSTANTS.SAVE_NEWS_ERROR,
  error
});

export const savedNewsIds = id => ({
  type: CONSTANTS.SAVED_NEWS_IDS,
  id
});

export const getSavedNewsRequest = () => ({
  type: CONSTANTS.GET_USERS_SAVED_NEWS_REQUEST
})

export const getSavedNewsSuccess = data => ({
  type: CONSTANTS.GET_USERS_SAVED_NEWS_SUCCESS,
  data
})

export const getSavedNewsError = error => ({
  type: CONSTANTS.GET_USERS_SAVED_NEWS_ERROR,
  error
})

export const getSavedNewsSeeMoreRequest = (dateFav, userId) => ({
  type: CONSTANTS.GET_USER_SAVED_NEWS_SEE_MORE_REQUEST,
  dateFav,
  userId
});

export const getSavedNewsSeeMoreSuccess = moreSavedNews => ({
  type: CONSTANTS.GET_USER_SAVED_NEWS_SEE_MORE_SUCCESS,
  moreSavedNews
});

export const getSavedNewsSeeMoreError = err => ({
  type: CONSTANTS.GET_USER_SAVED_NEWS_SEE_MORE_ERROR,
  err
});

export const getSavedNewsIdsRequest = () => ({
  type: CONSTANTS.GET_SAVED_NEWS_IDS_REQUEST
});

export const getSavedNewsIdsSuccess = savedNewsIds => ({
  type: CONSTANTS.GET_SAVED_NEWS_IDS_SUCCESS,
  savedNewsIds
});

export const getSavedNewsIdsError = error => ({
  type: CONSTANTS.GET_SAVED_NEWS_IDS_ERROR,
  error
});

export const unSavedNewIdRequest = id => ({
  type: CONSTANTS.UNSAVED_NEWS_ID_REQUEST,
  id
});

export const unSavedNewIdError = error => ({
  type: CONSTANTS.UNSAVED_NEWS_ID_ERROR,
  error
});

export const getCategoryRequest = (categoryType, offset, compact) => ({
  type: CONSTANTS.GET_CATEGORY_NEWS_REQUEST,
  categoryType,
  offset,
  compact
});

export const getCategorySuccess = newsfeeds => ({
  type: CONSTANTS.GET_CATEGORY_NEWS_SUCCESS,
  newsfeeds
});

export const getCategoryError = error => ({
  type: CONSTANTS.GET_CATEGORY_NEWS_ERROR,
  error
});

export const getCategoryListRequest = () => ({
  type: CONSTANTS.GET_NEWS_CATEGORY_LIST_REQUEST
});

export const getCategoryListSuccess = categoryList => ({
  type: CONSTANTS.GET_NEWS_CATEGORY_LIST_SUCCESS,
  categoryList
});

export const getCategoryListError = error => ({
  type: CONSTANTS.GET_NEWS_CATEGORY_LIST_ERROR,
  error
});

export const clearSpecificNewsArticle = () => ({
  type: CONSTANTS.CLEAR_SPECIFIC_NEWS_ARTICLE
})

export const saveArticleRequest = id => ({
  type: CONSTANTS.SAVE_ARTICLE_REQUEST,
  id
})

export const saveArticleSuccess = () => ({
  type: CONSTANTS.SAVE_ARTICLE_SUCCESS
})

export const saveArticleError = error => ({
  type: CONSTANTS.SAVE_ARTICLE_ERROR,
  error
})
