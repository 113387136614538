import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import ReactLoading from 'react-loading';
import { MdArrowBack, MdRemoveShoppingCart } from 'react-icons/md';
import ReactHtmlParser from 'react-html-parser';
import he from 'he';

class SuggestionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTokenPage: false,
      suggestion: null,
    };
  }

  componentDidMount() {
    this.props.getSuggestionListReqeust();
  }

  tokenDetailPage = suggestion => {
    this.setState({ showTokenPage: true, suggestion });
  };

  back = () => {
    this.setState({ showTokenPage: false });
  };

  removeSuggestion = (date, id) => {
    this.props.removeSuggestionReqeust(date, id);
  };

  renderSuggestion = suggestion => {
    const { id, date, token_name, issuance_stage, description } = suggestion;
    return (
      <Row key={id} className="suggestion-item">
        <Col sm={11}>
          <h5
            className="token-name-link"
            onClick={() => {
              this.tokenDetailPage(suggestion);
            }}
          >
            {token_name}
          </h5>
          <p>{ReactHtmlParser(he.decode(description))}</p>
          <div className="suggestion-sub-info">
            <span>{issuance_stage && issuance_stage}</span>
            <span>{issuance_stage && date && ' | '}</span>
            <span>{date && moment.unix(date / 1000).format('L')}</span>
          </div>
        </Col>
        <Col sm={1}>
          <MdRemoveShoppingCart
            onClick={() => {
              this.removeSuggestion(date, id);
            }}
            className="delete-suggestion"
          />
        </Col>
      </Row>
    );
  };

  renderSuggestionToken = () => {
    const { suggestion } = this.state;
    return (
      <Container>
        <Row>
          <Col sm={{ span: 8, offset: 2 }}>
            <h1 className="form-header">SUGGESTION DETAILS</h1>
            <div className="user-credential-info m-0 w-100">
              <div className="contact-form">
                <div className="one-paragraph paragraph">
                  <Col sm={3}>Name:</Col>
                  <Col sm={9}>
                    <p className="pt-3">{suggestion.token_name}</p>
                  </Col>
                </div>
                <div className="one-paragraph">
                  <Col sm={3}>Token Type:</Col>
                  <Col sm={9}>
                    <p className="pt-3">{suggestion.issuance_stage}</p>
                  </Col>
                </div>
                <div className="one-paragraph paragraph">
                  <Col sm={3}>Email:</Col>
                  <Col sm={9}>
                    <p className="pt-3">{suggestion.email}</p>
                  </Col>
                </div>
                <div className="one-paragraph">
                  <Col sm={3}>Description:</Col>
                  <Col sm={9}>
                    <p className="pt-3">
                      {ReactHtmlParser(he.decode(suggestion.description))}
                    </p>
                  </Col>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={2}>
            <Button
              className="back-news-items"
              onClick={() => {
                this.back();
              }}
            >
              <MdArrowBack />
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };

  renderSuggestionTokens = () => {
    const { suggestions } = this.props;

    if (!suggestions) {
      return (
        <React.Fragment>
          <div className="loading-bar">
            <ReactLoading
              type={'spin'}
              color={'#5790c8'}
              height={100}
              width={100}
            />
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className="admin-portal-content">
        <h1 className="form-header">SUGGESTION LIST</h1>
        <div className="form-content">
          {suggestions.map(suggestion => this.renderSuggestion(suggestion))}
        </div>
      </div>
    );
  };

  render() {
    const { showTokenPage } = this.state;
    return (
      <React.Fragment>
        {showTokenPage
          ? this.renderSuggestionToken()
          : this.renderSuggestionTokens()}
      </React.Fragment>
    );
  }
}

export default SuggestionList;
