import { connect } from 'react-redux'
import WebsiteConfig from './WebsiteConfig'
import './WebsiteConfig.css'
import {
  getWebsiteConfigRequest,
  updateWebsiteConfigRequest,
} from '../../../redux/actions/admin';

const mapStateToProps = state => ({
  websiteConfig: state.admin.websiteConfig
})

const mapDispatchToProps = {
  getWebsiteConfigRequest,
  updateWebsiteConfigRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebsiteConfig)
