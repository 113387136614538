export const NEWS_FEED_LIST_REQUEST = "NEWS_FEED_LIST_REQUEST";
export const NEWS_FEED_LIST_SUCCESS = "NEWS_FEED_LIST_SUCCESS";
export const NEWS_FEED_LIST_ERROR = "NEWS_FEED_LIST_ERROR";

export const FEED_NEWS_ITEMS_REQUEST = "FEED_NEWS_ITEMS_REQUEST";
export const FEED_NEWS_ITEMS_SUCCESS = "FEED_NEWS_ITEMS_SUCCESS";
export const FEED_NEWS_ITEMS_ERROR = "FEED_NEWS_ITEMS_ERROR";

export const GET_NEWS_REQUEST = "GET_NEWS_REQUEST";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const GET_NEWS_ERROR = "GET_NEWS_ERROR";

export const SEARCH_NEWS_REQUEST = "SEARCH_NEWS_REQUEST";
export const SEARCH_NEWS_SUCCESS = "SEARCH_NEWS_SUCCESS";
export const SEARCH_NEWS_ERROR = "SEARCH_NEWS_ERROR";

export const GET_NEWS_FEED_REQUEST = "GET_NEWS_FEED_REQUEST";
export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_NEWS_FEED_SUCCESS = "GET_NEWS_FEED_SUCCESS";
export const GET_NEWS_FEED_ERROR = "GET_NEWS_FEED_ERROR";

export const GET_NEWS_SEE_MORE_REQUEST = "GET_NEWS_SEE_MORE_REQUEST";
export const GET_NEWS_SEE_MORE_SUCCESS = "GET_NEWS_SEE_MORE_SUCCESS";
export const GET_NEWS_SEE_MORE_ERROR = "GET_NEWS_SEE_MORE_ERROR";

export const SAVE_NEWS_REQUEST = "SAVE_NEWS_REQUEST";
export const SAVE_NEWS_SUCCESS = "SAVE_NEWS_SUCCESS";
export const SAVE_NEWS_ERROR = "SAVE_NEWS_ERROR";

export const SAVED_NEWS_IDS = "SAVED_NEWS_IDS";

export const GET_USERS_SAVED_NEWS_REQUEST = "GET_USERS_SAVED_NEWS_REQUEST";
export const GET_USERS_SAVED_NEWS_SUCCESS = "GET_USERS_SAVED_NEWS_SUCCESS";
export const GET_USERS_SAVED_NEWS_ERROR = "GET_USERS_SAVED_NEWS_ERROR";

export const GET_USER_SAVED_NEWS_SEE_MORE_REQUEST =
  "GET_USER_SAVED_NEWS_SEE_MORE_REQUEST";
export const GET_USER_SAVED_NEWS_SEE_MORE_SUCCESS =
  "GET_USER_SAVED_NEWS_SEE_MORE_SUCCESS";
export const GET_USER_SAVED_NEWS_SEE_MORE_ERROR =
  "GET_USER_SAVED_NEWS_SEE_MORE_ERROR";

export const GET_SAVED_NEWS_IDS_REQUEST = "GET_SAVED_NEWS_IDS_REQUEST";
export const GET_SAVED_NEWS_IDS_SUCCESS = "GET_SAVED_NEWS_IDS_SUCCESS";
export const GET_SAVED_NEWS_IDS_ERROR = "GET_SAVED_NEWS_IDS_ERROR";

export const UNSAVED_NEWS_ID_REQUEST = "UNSAVED_NEWS_ID_REQUEST";
export const UNSAVED_NEWS_ID_SUCCESS = "UNSAVED_NEWS_ID_SUCCESS";
export const UNSAVED_NEWS_ID_ERROR = "UNSAVED_NEWS_ID_ERROR";

export const GET_CATEGORY_NEWS_REQUEST = "GET_CATEGORY_NEWS_REQUEST";
export const GET_CATEGORY_NEWS_SUCCESS = "GET_CATEGORY_NEWS_SUCCESS";
export const GET_CATEGORY_NEWS_ERROR = "GET_CATEGORY_NEWS_ERROR";

export const GET_NEWS_CATEGORY_LIST_REQUEST = "GET_NEWS_CATEGORY_LIST_REQUEST";
export const GET_NEWS_CATEGORY_LIST_SUCCESS = "GET_NEWS_CATEGORY_LIST_SUCCESS";
export const GET_NEWS_CATEGORY_LIST_ERROR = "GET_NEWS_CATEGORY_LIST_ERROR";

export const CLEAR_SPECIFIC_NEWS_ARTICLE = 'CLEAR_SPECIFIC_NEWS_ARTICLE'

export const SAVE_ARTICLE_REQUEST = 'SAVE_ARTICLE_REQUEST'
export const SAVE_ARTICLE_SUCCESS = 'SAVE_ARTICLE_SUCCESS'
export const SAVE_ARTICLE_ERROR = 'SAVE_ARTICLE_ERROR'
