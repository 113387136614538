import * as CONSTANTS from "../constants/news";
import { CLEAR_USER_CACHE } from '../constants/account'

const initialState = {
  publishers: {
    data: [],
    loading: false,
    loaded: false,
    error: ''
  },
  newsFeed: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  trendingTopics: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  savedNews: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  savedNewsIds: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  specificArticle: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case CLEAR_USER_CACHE:
    // state = initialState
    case CONSTANTS.NEWS_FEED_LIST_REQUEST:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          data: [],
          loading: true,
          loaded: false
        }
      }
    case CONSTANTS.NEWS_FEED_LIST_SUCCESS:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          data: action.feedList,
          loaded: true,
          loading: false
        }
      }
    case CONSTANTS.NEWS_FEED_LIST_ERROR:
      return {
        ...state,
        publishers: {
          ...state.publishers,
          data: [],
          loaded: false,
          loading: false,
          error: action.error
        }
      };
    case CONSTANTS.FEED_NEWS_ITEMS_REQUEST:
      return {
        ...state,
        feedItemList: {
          ...state.feedItemList,
          items: []
        }
      };
    case CONSTANTS.FEED_NEWS_ITEMS_SUCCESS:
      return {
        ...state,
        feedItemList: {
          ...state.feedItemList,
          items: action.feedItems
        }
      };
    case CONSTANTS.FEED_NEWS_ITEMS_ERROR:
      return {
        ...state,
        feedItemList: {
          ...state.feedItemList,
          error: action.error
        }
      };
    case CONSTANTS.GET_NEWS_REQUEST:
      return {
        ...state,
        specificArticle: {
          ...state.specificArticle,
          loading: true,
          loaded: false
        }
      };
    case CONSTANTS.GET_NEWS_SUCCESS:
      return {
        ...state,
        specificArticle: {
          ...state.specificArticle,
          data: action.news,
          loading: false,
          loaded: true
        }
      };
    case CONSTANTS.GET_NEWS_ERROR:
      return {
        ...state,
        specificArticle: {
          ...state.specificArticle,
          error: action.error,
          loading: false,
          loaded: false,
        }
      };
    case CONSTANTS.SEARCH_NEWS_SUCCESS:
      return {
        ...state,
        searchItemList: {
          ...state.searchItemList,
          searchList: action.searchResult
        }
      };
    case CONSTANTS.SEARCH_NEWS_ERROR:
      return {
        ...state,
        searchItemList: {
          ...state.searchItemList,
          error: action.error
        }
      };

    case CONSTANTS.GET_NEWS_FEED_REQUEST:
      return {
        ...state,
        newsFeed: {
          ...state.newsFeed,
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.GET_NEWS_FEED_SUCCESS:
      return {
        ...state,
        newsFeed: {
          ...state.newsFeed,
          loading: false,
          loaded: true,
          data: action.data
        }
      }

    case CONSTANTS.GET_NEWS_FEED_ERROR:
      return {
        ...state,
        newsFeed: {
          ...state.newsFeed,
          loading: false,
          loaded: false,
          error: action.error
        }
      }

    case CONSTANTS.GET_CATEGORY_NEWS_REQUEST:
      return {
        ...state,
        newsFeedItems: {
          ...state.newsFeedItems,
          newsFeed: [],
          loading: true
        }
      };
    case CONSTANTS.GET_CATEGORY_NEWS_SUCCESS:
      return {
        ...state,
        newsFeedItems: {
          ...state.newsFeedItems,
          newsFeed: action.newsfeeds,
          loading: false
        }
      };
    case CONSTANTS.GET_CATEGORY_NEWS_ERROR:
      return {
        ...state,
        newsFeedItems: {
          ...state.newsFeedItems,
          error: action.error
        }
      };
    case CONSTANTS.GET_NEWS_SEE_MORE_SUCCESS:
      return {
        ...state,
        feedItemList: {
          ...state.feedItemList,
          items: {
            ...action.moreNews,
            Items: state.feedItemList.items.Items.concat(action.moreNews.Items)
          }
        }
      };
    case CONSTANTS.GET_NEWS_SEE_MORE_ERROR:
      return {
        ...state,
        feedItemList: {
          ...state.feedItemList,
          error: action.error
        }
      };
    case CONSTANTS.SAVED_NEWS_IDS:
      return {
        ...state,
        savedNewsIds: {
          ...state.savedNewsIds,
          ids: state.savedNewsIds.idList.push(action.id)
        }
      };

    case CONSTANTS.GET_USERS_SAVED_NEWS_REQUEST:
      return {
        ...state,
        savedNews: {
          ...state.savedNews,
          loading: true,
          loaded: false
        }
      }
    case CONSTANTS.GET_USERS_SAVED_NEWS_SUCCESS:
      return {
        ...state,
        savedNews: {
          ...state.savedNews,
          loading: false,
          loaded: true,
          data: action.data
        }
      }
    case CONSTANTS.GET_USERS_SAVED_NEWS_ERROR:
      return {
        ...state,
        savedNews: {
          ...state.savedNews,
          loading: false,
          loaded: false,
          error: action.error
        }
      }
    case CONSTANTS.GET_USER_SAVED_NEWS_SEE_MORE_SUCCESS:
      return {
        ...state,
        savedNewsItems: {
          ...state.savedNewsItems,
          items: {
            ...action.moreSavedNews,
            Items: state.savedNewsItems.items.Items.concat(
              action.moreSavedNews.Items
            )
          }
        }
      };
    case CONSTANTS.GET_USER_SAVED_NEWS_SEE_MORE_ERROR:
      return {
        ...state,
        savedNewsItems: {
          ...state.savedNewsItems,
          error: action.err
        }
      };
    case CONSTANTS.GET_SAVED_NEWS_IDS_REQUEST:
      return {
        ...state,
        savedNewsIds: {
          ...state.savedNewsIds,
          loading: true,
          loaded: false,
        }
      }
    case CONSTANTS.GET_SAVED_NEWS_IDS_SUCCESS:
      return {
        ...state,
        savedNewsIds: {
          ...state.savedNewsIds,
          loaded: true,
          data: action.savedNewsIds
        }
      };
    case CONSTANTS.GET_SAVED_NEWS_IDS_ERROR:
      return {
        ...state,
        savedNewsIds: {
          ...state.savedNewsIds,
          loaded: false,
          loading: false,
          error: action.error,
        }
      }

    case CONSTANTS.GET_NEWS_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        trendingTopics: {
          ...state.trendingTopics,
          loading: true,
          loaded: false
        }
      }
    case CONSTANTS.GET_NEWS_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        trendingTopics: {
          ...state.trendingTopics,
          data: action.categoryList,
          loaded: true,
          loading: false
        }
      };
    case CONSTANTS.GET_NEWS_CATEGORY_LIST_ERROR:
      return {
        ...state,
        trendingTopics: {
          ...state.trendingTopics,
          error: action.error,
          loaded: false,
          loading: false
        }
      };

    case CONSTANTS.CLEAR_SPECIFIC_NEWS_ARTICLE:
      return {
        ...state,
        specificArticle: {
          data: {},
          loading: false,
          loaded: false,
          error: ''
        }
      }
    default:
      return state;
  }
};
