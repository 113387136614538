import React, { PureComponent } from 'react'
import {
  Row,
  Col,
  Button,
  Image,
  Container,
  Spinner,
  Pagination,
  Modal,
  NavLink,
} from 'react-bootstrap'
import ReactHtmlParser from "react-html-parser"
import he from 'he'
import MetaTags from 'react-meta-tags'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  RedditShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  RedditIcon,
} from 'react-share'
import defaultAvatar from '../../../images/default-avatar-image.png'


class Article extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      article: this.props.article,
    }
  }

  componentDidMount() {
    this.handleGetArticleIdFromURL()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        article: nextProps.article,
      })
    }
  }

  handleGetArticleIdFromURL = () => {
    const params = window.location.href
    const splitTitle = params.split('-')
    const articleId = splitTitle[splitTitle.length-1]

    let array = articleId.split('?')
    let parameters = {}
    for (let i = 1; i < array.length; i++) {
      let split = array[i].split('=')
      parameters[split[0].trim()] = decodeURI(split[1].trim())
    }

    const id = array[0]

    this.props.readArticleRequest(id)
    this.props.getRelatedArticlesRequest(id)
  }

  formatDate = dateInput => {
    let date = new Date(dateInput)
    const month = date.toLocaleString("en-us", { month: "short" })
    const day = date.getDate()
    const year = date.getFullYear()
    return month + " " + day + ", " + year
  } // end of formatDate(dateInput)

  /****************************************************************************/

  renderArticle = () => {
    const { data, loading, loaded } = this.state.article

    let content
    if (loaded) {
      const { title, image, body, categories, summary, published, datePublished, user_id } = data
      const date = this.formatDate(datePublished)

      content = (
        <div className='new-article-container'>
          <Row noGutters className='show-article-top-row'>
            <Col className='show-article-info-col'>
              <Row noGutters className='show-article-title-row'>
                <h1>{title}</h1>
              </Row>
              <Row noGutters className='show-article-summary-row'>
                <h2>{summary}</h2>
              </Row>
              <Row noGutters className='show-article-user-row'>
                <Image className='show-article-user-image'
                  src={user_id.image || defaultAvatar} roundedCircle fluid />
                <Col className='show-article-user-info-col'>
                  <Row noGutters className='show-article-name-row'>
                    {user_id._id}
                  </Row>
                  <Row noGutters className='show-article-date-row'>
                    {this.formatDate(datePublished)}
                  </Row>
                </Col>
              </Row>
              {this.renderShareButtons()}
            </Col>
          </Row>
          <Row noGutters className='show-article-body-row'>
            <Col>
              <Row noGutters className='show-article-image-row'>
                <Image src={image} className='show-article-image' />
              </Row>
              {body ? ReactHtmlParser(he.decode(body)) : ''}
            </Col>
          </Row>
        </div>
      )
    } else if (loading) {
      content = this.renderLoading()
    }

    return content
  }

  renderShareButtons = () => {
    const report = this.state.article.loaded ? this.state.article.data : ''
    const userId = this.props.account.data.userid
    const copyLink = `${window.location.href}?source=${userId}`
    const iconSize = 28

    return (
      <Row noGutters className='share-buttons-row'>
        <FacebookShareButton className='share-button' url={copyLink}>
          <FacebookIcon size={iconSize} />
        </FacebookShareButton>
        <TwitterShareButton className='share-button' url={copyLink}>
          <TwitterIcon size={iconSize} />
        </TwitterShareButton>
        <LinkedinShareButton className='share-button' url={copyLink}>
          <LinkedinIcon size={iconSize} />
        </LinkedinShareButton>
        <RedditShareButton className='share-button' url={copyLink}>
          <RedditIcon size={iconSize} />
        </RedditShareButton>
        <EmailShareButton className='share-button' url={copyLink}>
          <EmailIcon size={iconSize} />
        </EmailShareButton>
      </Row>
    )
  }

  renderLoading = () => (
    <Row noGutters>
      <Spinner className='loading-spinner' />
    </Row>
  )

  renderMetaTags = () => {
    const { data, loaded } = this.state.article

    if (loaded) {
      return (
        <MetaTags>
          <title>{data.title}</title>
          <meta name='description' content={data.summary || ''}/>
          <meta name='title' property='og:title' content={(data.title || '')}/>
          <meta property='og:description' content={data.summary || ''}/>
          <meta property='og:image' content={data.image || ''}/>
          <meta property='og:type' content='website'/>
          <meta property='og:url' content={window.location.href} />
          <meta property='twitter:title' content={(data.title || '')}/>
          <meta property='twitter:description' content={data.summary || ''}/>
          <meta property='twitter:image' content={data.image || ''}/>
          <meta property='twitter:site' content='Velocity Channel'/>
          <meta name='robots' content='nofollow' />
        </MetaTags>
      )
    }
  }

  render() {
    return (
      <Container fluid className='view-article-container'>
        {this.renderMetaTags()}
        {this.renderArticle()}
      </Container>
    )
  }
}

export default Article
