import * as CONSTANTS from '../constants/index'
import { CLEAR_USER_CACHE } from '../constants/account'

const initialState = {
  version: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    // case CLEAR_USER_CACHE:
    // state = initialState
    case CONSTANTS.GET_VERSION_REQUEST:
      return {
        ...state,
        version: '',
      }
    case CONSTANTS.GET_VERSION_SUCCESS:
      return {
        ...state,
        version: action.data.version,
      }
    default:
      return state
  }
}
