export const RESET_COLLECTION_INITIAL_STATE = 'RESET_COLLECTION_INITIAL_STATE'

export const GET_DISCOVER_COLLECTIONS_REQUEST = 'GET_DISCOVER_COLLECTIONS_REQUEST'
export const GET_DISCOVER_COLLECTIONS_SUCCESS = 'GET_DISCOVER_COLLECTIONS_SUCCESS'
export const GET_DISCOVER_COLLECTIONS_ERROR = 'GET_DISCOVER_COLLECTIONS_ERROR'

export const GET_PUBLIC_USER_COLLECTIONS_REQUEST = 'GET_PUBLIC_USER_COLLECTIONS_REQUEST'
export const GET_PUBLIC_USER_COLLECTIONS_SUCCESS = 'GET_PUBLIC_USER_COLLECTIONS_SUCCESS'
export const GET_PUBLIC_USER_COLLECTIONS_ERROR = 'GET_PUBLIC_USER_COLLECTIONS_ERROR'

export const GET_PRIVATE_USER_COLLECTIONS_REQUEST = 'GET_PRIVATE_USER_COLLECTIONS_REQUEST'
export const GET_PRIVATE_USER_COLLECTIONS_SUCCESS = 'GET_PRIVATE_USER_COLLECTIONS_SUCCESS'
export const GET_PRIVATE_USER_COLLECTIONS_ERROR = 'GET_PRIVATE_USER_COLLECTIONS_ERROR'

export const GET_SPECIFIC_COLLECTION_REQUEST = 'GET_SPECIFIC_COLLECTION_REQUEST'
export const GET_SPECIFIC_COLLECTION_SUCCESS = 'GET_SPECIFIC_COLLECTION_SUCCESS'
export const GET_SPECIFIC_COLLECTION_ERROR = 'GET_SPECIFIC_COLLECTION_ERROR'

export const CREATE_NEW_PRIVATE_COLLECTION_REQUEST = 'CREATE_NEW_PRIVATE_COLLECTION_REQUEST'
export const CREATE_NEW_PRIVATE_COLLECTION_SUCCESS = 'CREATE_NEW_PRIVATE_COLLECTION_SUCCESS'
export const CREATE_NEW_PRIVATE_COLLECTION_ERROR = 'CREATE_NEW_PRIVATE_COLLECTION_ERROR'
export const CREATE_NEW_PRIVATE_COLLECTION_FINISH ='CREATE_NEW_PRIVATE_COLLECTION_FINISH'
export const RESET_CREATE_NEW_PRIVATE_COLLECTION = 'RESET_CREATE_NEW_PRIVATE_COLLECTION'

export const SEARCH_COLLECTIONS_REQUEST = 'SEARCH_COLLECTIONS_REQUEST'
export const SEARCH_COLLECTIONS_SUCCESS = 'SEARCH_COLLECTIONS_SUCCESS'
export const SEARCH_COLLECTIONS_ERROR = 'SEARCH_COLLECTIONS_ERROR'

export const ADD_ARTICLE_TO_COLLECTION_REQUEST = 'ADD_ARTICLE_TO_COLLECTION_REQUEST'
export const ADD_ARTICLE_TO_COLLECTION_SUCCESS = 'ADD_ARTICLE_TO_COLLECTION_SUCCESS'
export const ADD_ARTICLE_TO_COLLECTION_ERROR = 'ADD_ARTICLE_TO_COLLECTION_ERROR'

export const EDIT_COLLECTION_REQUEST = 'EDIT_COLLECTION_REQUEST'
export const EDIT_COLLECTION_SUCCESS = 'EDIT_COLLECTION_SUCCESS'
export const EDIT_COLLECTION_ERROR = 'EDIT_COLLECTION_ERROR'

export const EDIT_COLLECTION_IMAGE_REQUEST = 'EDIT_COLLECTION_IMAGE_REQUEST'
export const EDIT_COLLECTION_IMAGE_SUCCESS = 'EDIT_COLLECTION_IMAGE_SUCCESS'
export const EDIT_COLLECTION_IMAGE_ERROR = 'EDIT_COLLECTION_IMAGE_ERROR'
export const HANDLE_CLEAR_COLLECTION_IMAGE = 'HANDLE_CLEAR_COLLECTION_IMAGE'

export const REMOVE_REPORT_FROM_COLLECTION_REQUEST = 'REMOVE_REPORT_FROM_COLLECTION_REQUEST'
export const REMOVE_REPORT_FROM_COLLECTION_SUCCESS = 'REMOVE_REPORT_FROM_COLLECTION_SUCCESS'
export const REMOVE_REPORT_FROM_COLLECTION_ERROR = 'REMOVE_REPORT_FROM_COLLECTION_ERROR'

export const TOGGLE_PUBLIC_PRIVATE_COLLECTION_REQUEST = 'TOGGLE_PUBLIC_PRIVATE_COLLECTION_REQUEST'
export const TOGGLE_PUBLIC_PRIVATE_COLLECTION_SUCCESS = 'TOGGLE_PUBLIC_PRIVATE_COLLECTION_SUCCESS'
export const TOGGLE_PUBLIC_PRIVATE_COLLECTION_ERROR = 'TOGGLE_PUBLIC_PRIVATE_COLLECTION_ERROR'

export const DELETE_COLLECTION_REQUEST = 'DELETE_COLLECTION_REQUEST'
export const DELETE_COLLECTION_SUCCESS = 'DELETE_COLLECTION_SUCCESS'
export const DELETE_COLLECTION_ERROR = 'DELETE_COLLECTION_ERROR'
