import { CONTACT_ERROR, CONTACT_SUCCESS, RESET_CONTACT } from '../constants/contact';

const initialState = {
  contactRequest: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_SUCCESS:
      return {
        ...state,
        contactRequest: true,
      };
    case CONTACT_ERROR:
      return {
        ...state,
        contactRequest: false,
      };
    case RESET_CONTACT:
      return {
        ...state,
        contactRequest: false,
      }
    default:
      return state;
  }
};
