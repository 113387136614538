import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactLoading from "react-loading";
import { history } from "../../../configureStore";

class FeaturedContributors extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getFeaturedContributorsReqeust();
  }

  renderLoading = () => {
    return (
      <React.Fragment>
        <div className="loading-bar">
          <ReactLoading
            type={"spin"}
            color={"#5790c8"}
            height={100}
            width={100}
          />
        </div>
      </React.Fragment>
    );
  };
  onClickRemoveFeatured = (e, userId) => {
    this.props.removeFeaturedContributorsReqeust(userId);
    this.props.getFeaturedContributorsReqeust();
  };
  renderContributor = hit => {
    const { displayName, username, userId } = hit;
    return (
      <React.Fragment>
        <Row key={userId} className="suggestion-item pt-2">
          <Col sm={{ span: 3, offset: 0 }}>{displayName}</Col>
          <Col sm={{ span: 3, offset: 0 }}>{username}</Col>
          <Col sm={{ span: 3, offset: 0 }}>{userId}</Col>
          <Col sm={{ span: 3, offset: 0 }}>
            <button onClick={e => this.onClickRemoveFeatured(e, userId)}>
              Remove
            </button>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  renderContributors = () => {
    const {
      featuredContributors : { list }
    } = this.props;
    if (list) {
      const { hits } = list;
      return (
        <React.Fragment>
          {hits && hits.map(hit => this.renderContributor(hit))}
        </React.Fragment>
      );
    } else {
      return <React.Fragment>no featured contributors</React.Fragment>;
    }
  };

  render() {
    const { featuredContributors } = this.props;

    return (
      <Container>
        <h1 className="text-center pt-3">FEATURED CONTRIBUTOR LIST</h1>
        {featuredContributors && Object.keys(featuredContributors).length
          ? this.renderContributors()
          : this.renderLoading()}
      </Container>
    );
  }
}

export default FeaturedContributors;
