import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SuggestionForm from './SuggestionForm';
import './SuggestionForm.css';
import { postSuggestionRequest } from '../../../redux/actions/suggestion';
import 'react-quill/dist/quill.snow.css';

postSuggestionRequest.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

postSuggestionRequest.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

/*
 * PropType validation
 */
postSuggestionRequest.propTypes = {
  placeholder: PropTypes.string,
};

const mapStateToProps = state => ({});
const mapDispatchToProps = {
  postSuggestionRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SuggestionForm);
