import { connect } from 'react-redux';
import NotFound from './NotFound';
import './NotFound.css';


const mapStateToProps = state => ({
  account: state.login.accountData
})

export default connect(
    mapStateToProps,
)(NotFound)
