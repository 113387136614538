import { takeLatest, call, put, delay } from 'redux-saga/effects';
import jwt from 'jsonwebtoken'
import request from '../../../utils/apiRequest';
import * as CONSTANTS from '../../constants/analytics'
import * as ACTIONS from '../../actions/analytics'
import * as RESEARCH_ACTIONS from '../../actions/researches'
import * as LOGIN_ACTIONS from '../../actions/login'
import * as CONTRIBUTOR_ACTIONS from '../../actions/contributor'

function* postEvent(action) {
  console.log(action)
  if (action.data.type !== 'view') {
    if (localStorage.accessToken && localStorage.refreshToken) {
      try {
        const decodedToken = jwt.decode(localStorage.accessToken)
        if (decodedToken.userId) {
          yield call(
            request,
            'analytics/event',
            'POST',
            action.data,
            true,
          )
          if (action.data.type === 'comment') {
            yield delay(1000)
            yield put(RESEARCH_ACTIONS.getCommentsRequest(action.data.resourceId))
          } else if (action.data.type === 'follow') {
            yield delay(1000)
            yield put(RESEARCH_ACTIONS.readArticleRequest(action.data.articleId))
          } else if (action.data.type === 'view') {
            // yield put(ACTIONS.checkViewedStatus())
          } else {
            yield delay(1000)
            yield put(RESEARCH_ACTIONS.readArticleRequest(action.data.resourceId))
          }
        }
      } catch (err) {
        yield put(LOGIN_ACTIONS.showLoginModal(window.location.pathname))
      }
    } else {

      try {
        yield put(LOGIN_ACTIONS.showLoginModal(window.location.pathname))
      } catch (err) {
        yield put(LOGIN_ACTIONS.showLoginModal(window.location.pathname))
      }
    }
  } else {
    //console.log('test')
    try {
      const decodedToken = jwt.decode(localStorage.accessToken)
      yield call(
        request,
        'analytics/event',
        'POST',
        action.data,
        true,
      )
    } catch (err) {
    // do nothing to prevent showLoginModal from triggering
    }
    // if (localStorage.accessToken && localStorage.refreshToken) {
    //   try {
    //     const decodedToken = jwt.decode(localStorage.accessToken)
    //     if (decodedToken.userId) {
    //       yield call(
    //         request,
    //         'analytics/event',
    //         'POST',
    //         action.data,
    //         true,
    //       )
    //     }
    //   } catch (err) {
    //   // do nothing to prevent showLoginModal from triggering
    //   }
    // }
  }
}

function* refreshReportComments(action) {
  try {
    // setTimeout(function() {
    //   yield put()
    // })
  } catch(err) {}
}

function* removeComment(action) {
  try {
    const responseData = yield call(
      request,
      'analytics/removeComment',
      'POST',
      action.data,
      true,
    )
  } catch (err) {}
}

function* unpublishArticle(action) {
  try {
    const reportObject = {id: action.data}
    const responseData = yield call(
      request,
      'research/contributor/unpublish',
      'POST',
      reportObject,
      true,
    )
    yield delay(1000)
    yield put(ACTIONS.unpublishArticleSuccess())
  } catch (err) {}
}

function* getContributorStats(action) {
  try {
    let from = action.from ? `from=${action.from}` : ''
    let to = action.to ? `to=${action.to}` : ''
    let string = ''
    if (from && to) {
      string = `${from}&${to}`
    } else if (!from && to) {
      string = `${to}`
    } else if (from && !to) {
      string = `${from}`
    }
    let responseData
    if (action.reportId) {
      responseData = yield call(
        request,
        `research/contributor/reportStats?id=${action.reportId}&${string}`,
        'GET',
        null,
        true,
      )
    } else {
      responseData = yield call(
        request,
        `analytics/contributorStats?${string}`,
        'GET',
        null,
        true,
      )
    }
    yield put(ACTIONS.getContributorStatsSuccess(responseData))
  } catch (err) {}
}

function* getReportStats(action) {
  try {
    const responseData = yield call(
      request,
      `analytics/reportStats?id=${action.data}`,
      'GET',
      null,
      true,
    )
  } catch (err) {}
}

function* removeEvent(action) {
  try {
    const responseData = yield call(
      request,
      'analytics/removeEvent',
      'POST',
      action.data,
      true,
    )
    // yield delay(1000)
    // if (action.data.type === 'follow') {
    //   yield put(RESEARCH_ACTIONS.readArticleRequest(action.data.articleId))
    // } else {
    //   yield put(RESEARCH_ACTIONS.readArticleRequest(action.data.resourceId))
    // }
  } catch (err) {}
}

export default function* analyticsSaga() {
    yield takeLatest(CONSTANTS.POST_EVENT_REQUEST, postEvent)
    yield takeLatest(CONSTANTS.REMOVE_COMMENT_REQUEST, removeComment)
    yield takeLatest(CONSTANTS.GET_CONTRIBUTOR_STATS_REQUEST_ANALYTICS, getContributorStats)
    yield takeLatest(CONSTANTS.GET_REPORT_STATS_REQUEST, getReportStats)
    yield takeLatest(CONSTANTS.REMOVE_EVENT_REQUEST, removeEvent)
    yield takeLatest(CONSTANTS.UNPUBLISH_ARTICLE_REQUEST, unpublishArticle)
}
