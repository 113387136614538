import React, { PureComponent } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Jumbotron,
  Tabs,
  Tab,
  Spinner,
  Image,
  Modal,
  FormControl,
} from 'react-bootstrap';
import ImageUploader from 'react-images-upload';
import { MdLocationOn, MdEdit } from "react-icons/md";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FaFacebookSquare, FaTwitterSquare, FaLinkedin, FaRegShareSquare } from 'react-icons/fa'
import { FiClipboard } from 'react-icons/fi'
import { IoIosArrowRoundForward } from 'react-icons/io'
import defaultAvatar from '../../images/default-avatar-image.png'
import MetaTags from 'react-meta-tags'
import LogoImg from '../../images/logoImage.js'
import tempNewsImage from '../../images/blank-image.png'
import { NavLink } from 'react-router-dom'
import he from 'he'
import { toast } from 'react-toastify'
import ReactHtmlParser from "react-html-parser";
import defaultBannerImage from '../../images/topbar-background-image.png'
import defaultAvatarImage from '../../images/default-avatar-image.png'

class ContributorProfile extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      offset: 0,
      contributorStats: {
        user: {},
        stats: {},
        loaded: false,
      },
      contributorReports: {
        list: [],
        loaded: false,
      },
      editUserParams: {
        bannerImage: {
          image: '',
          loading: false,
          loaded: false,
          error: ''
        },
        avatarImage: {
          image: '',
          loading: false,
          loaded: false,
          error: ''
        },
        userImage: {
          image: '',
          loading: false,
          loaded: false,
          error: ''
        }
      },
      editAvatarImage: false,
      editBannerImage: false,
      editingUserImage: false,
      showShareProfileModal: false,
      shareProfileLink: '',
    }
  }

  componentDidMount() {
    const { params } = this.props.match
    this.props.getContributorReportsRequest(params.username)
    this.props.getContributorStatsRequest(params.username)
    this.setState({
      shareProfileLink: this.props.user.userId ? `https://velocitychannel.io/contributor/${params.username}?source=${this.props.user.userId}` : '',
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        contributorStats: nextProps.contributorStats,
        contributorReports: nextProps.contributorReports,
      })

      if (nextProps.uploadBannerImage !== this.props.uploadBannerImage) {
        this.setState({
          editBannerImage: false,
          contributorStats: {
            ...this.state.contributorStats,
            user: {
              ...this.state.contributorStats.user,
              coverImage: nextProps.uploadBannerImage.image,
            }
          }
        })
      }

      if (nextProps.uploadAvatarImage !== this.props.uploadAvatarImage) {
        this.setState({
          editAvatarImage: false,
          contributorStats: {
            ...this.state.contributorStats,
            user: {
              ...this.state.contributorStats.user,
              image: nextProps.uploadAvatarImage.image,
            }
          }
        })
      }
    }
  }

  formatDate = dateInput => {
    let date = new Date(dateInput);
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    return month + " " + day + ", " + year;
  }

  handleNavigationTitle = (title, id) => {
    if (title && id) {
      return title.toLowerCase().replace(/%/g, '').replace(/\s+/g, '-').replace(/\?/g,'') + '-' + id
    }
  } // end of handleNavigationTitle(title)

  handleFollow = (userId, resourceId, resourceType) => {
    const data = {type: 'follow', userId, resourceId, resourceType}
    this.props.postEventRequest(data)
    this.setState({
      contributorStats: {
        ...this.state.contributorStats,
        stats: {
          ...this.state.contributorStats.stats,
          isFollowing: true,
        }
      }
    })
  } // end of handleFollow(contributorId)

  handleUnfollow = (userId, resourceId, resourceType) => {
    const data = {type: 'follow', userId, resourceId, resourceType}
    this.props.removeEventRequest(data)
    this.setState({
      contributorStats: {
        ...this.state.contributorStats,
        stats: {
          ...this.state.contributorStats.stats,
          isFollowing: false,
        }
      }
    })
  }

  handleToggleEditBannerImage = () => {
    this.setState({
      editBannerImage: !this.state.editBannerImage
    })
  }

  handleToggleEditAvatarImage = () => {
    this.setState({
      editAvatarImage: !this.state.editAvatarImage
    })
  }

  handleUploadAvatarImage = image => {
    this.props.uploadAvatarImageRequest(image[0])
  }

  handleUploadBannerImage = image => {
    this.props.uploadBannerImageRequest(image[0])
  }

  handlePessimisticRendering = (updatedArticle, data) => {
    if (localStorage.accessToken) {
      this.setState({
        currentArticle: {
          ...this.state.currentArticle,
          article: updatedArticle,
        }})
      }
    this.props.postEventRequest(data)
  }

  /************************** END OF HANDLERS *********************************/



  /******************************* RENDERS ************************************/

  renderFollowButton = () => {
    const { user, stats } = this.state.contributorStats
    const userId = this.props.user.userId
    const contributorId = user.userId

    if (stats.isFollowing) {
      return <Button className='cp-user-follow-button' onClick={() => this.handleUnfollow(userId, contributorId, 'contributor')}>UNFOLLOW</Button>
    } else {
      return <Button className='cp-user-follow-button' onClick={() => this.handleFollow(userId, contributorId, 'contributor')}>FOLLOW</Button>
    }
  } // end of renderFollowButton(followed)

  renderLoading = () => (
    <Jumbotron className='loading-spinner-container'>
      <Spinner className='loading-spinner' animation="border" role="status" variant='primary'>
        <span className="sr-only">Loading...</span>
      </Spinner>
    </Jumbotron>
  ) // end of renderLoading()

  renderUserBanner = () => {
    const { user } = this.state.contributorStats
    const { editBannerImage, editUserParams } = this.state
    const viewingUser = this.props.user
    const editButton = viewingUser.userId === user.userId ? <MdEdit className='cp-change-banner-image-edit-icon' onClick={this.handleToggleEditBannerImage} /> : null
    let bannerImage = editBannerImage ? this.renderBannerImageUploader() : <Image src={user.coverImage || defaultBannerImage} fluid className='cp-user-banner-image'/>
    return (
      <Jumbotron fluid className='cp-user-banner'>
        {editButton}
        {bannerImage}
      </Jumbotron>
    )
  }

  renderBannerImageUploader = () => {
    const { editUserParams } = this.state
    if (editUserParams.bannerImage.loading) {
      return (
        <div className='cp-banner-image-loading'>
          {this.renderLoading()}
        </div>
      )
    } else {
      return (
        <ImageUploader
          className='cp-user-banner-image-uploader'
          withIcon={false}
          buttonText='CHOOSE BANNER IMAGE'
          label=''
          singleImage
          onChange={this.handleUploadBannerImage}
        />
      )
    }
  }

  renderAvatarImageUploader = () => {
    const { editUserParams } = this.state
    if (editUserParams.avatarImage.loading) {
      return (
        <div className='cp-avatar-image-loading'>
          {this.renderLoading()}
        </div>
      )
    } else {
      return (
        <ImageUploader
          className='cp-user-avatar-image-uploader'
          withIcon={false}
          buttonText='CHOOSE AVATAR'
          label=''
          singleImage
          onChange={this.handleUploadAvatarImage}
        />
      )
    }
  }

  renderAvatarImage = () => {
    const { user } = this.state.contributorStats
    const { editAvatarImage, editUserParams } = this.state
    const viewingUser = this.props.user
    const editButton = viewingUser.userId === user.userId ? <MdEdit className='cp-change-banner-image-edit-icon' onClick={this.handleToggleEditAvatarImage} /> : null
    let avatarImage = editAvatarImage ? this.renderAvatarImageUploader() : <Image src={user.image || defaultAvatarImage} fluid className='cp-user-content-user-image'/>

    return (
      <React.Fragment>
        {editButton}
        {avatarImage}
      </React.Fragment>
    )
  }


  renderContentSection = () => {
    const { user, stats } = this.state.contributorStats
    const { list } = this.state.contributorReports
    return (
      <Row className='no-padding cp-user-content-section'>
        <Col className='no-padding cp-user-content-about-column' md={3}>
          {this.renderAvatarImage()}
          <Row className='no-padding cp-user-content-follow-button-row'>
            {this.renderFollowButton()}
          </Row>
          <Row className='no-padding cp-user-content-about-section'>
            <Col className='no-padding'>
              <h5>ABOUT</h5>
              <Row className='no-padding cp-user-content-about-text-row'>
                <p>{user.about}</p>
              </Row>
            </Col>
          </Row>
          <Row className='no-padding cp-user-content-stats-section'>
            <Col className='no-padding'>
              <Row className='no-padding cp-user-stat'>
                <Col className='no-padding'>
                  Followers
                </Col>
                <Col className='no-padding right-justified-column' md={1}>
                  {stats.follows}
                </Col>
              </Row>
              <Row className='no-padding cp-user-stat'>
                <Col className='no-padding'>
                  Views
                </Col>
                <Col className='no-padding right-justified-column' md={1}>
                  {stats.views}
                </Col>
              </Row>
              <Row className='no-padding cp-user-stat'>
                <Col className='no-padding'>
                  Reports Published
                </Col>
                <Col className='no-padding right-justified-column' md={1}>
                  {stats.reportsPublished}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className='no-padding cp-user-content-research-column'>
          <Row className='cp-user-banner-info-row'>
            <Col className='no-padding cp-user-banner-info-column'>
              <Row className='no-padding cp-user-banner-info-name-row'>
                <Col className='no-padding'>
                  <Row className='no-padding cp-user-banner-display-name-row'>
                    {user.displayName || user.username}
                  </Row>
                  <Row className='no-padding cp-user-banner-coverage-row'>
                    Covering {user.industryFocus} in {user.geographicFocus}
                  </Row>
                </Col>
                <Col className='no-padding cp-user-banner-share-column'>
                  <Button className='cp-user-banner-share-button' onClick={() => this.setState({showShareProfileModal: true})}>
                    <FaRegShareSquare className='cp-user-banner-share-icon'/> SHARE PROFILE
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='no-padding'>
            {/* <Col md={1} /> */}
            <Col className='no-padding cp-published-research-column'>
              <h3>Published Research</h3>
              {list.length > 0 ? list.map(article => this.renderIndividualPublishedResearch(article)) : <div className='cp-no-articles-published'>This user has not published any articles</div>}
            </Col>
            <Col md={1} />
          </Row>
        </Col>
        {/* <Col md={1} /> */}
      </Row>
    )
  }

  renderIndividualPublishedResearch = article => {
    const { user } = this.state.contributorStats
    const displayName = user.displayName || ''
    const body = article.body || ''
    const datePublished = this.formatDate(article.datePublished)
    const id = article.id || ''
    const summary = article.summary
    const title = article.title.length > 140 ? article.title.slice(0, 140) + '...' : article.title
    const userId = article.userId || ''
    const navTitle = this.handleNavigationTitle(article.title, article.id)

    let renderImage
    if (article.image) {
      renderImage = <Image src={article.image} className='cp-individual-article-image' />
    } else {
      renderImage = <div className='cp-individual-article-image-no-image' />
    }

    return (
      <Row className='no-padding cp-individual-article' key={id}>
        <Col className='no-padding'>
          <Row className='no-padding cp-individual-article-title'>
            <NavLink to={`/research/${navTitle}`} target='_blank'>
              {title}
            </NavLink>
          </Row>
          <Row className='no-padding cp-individual-article-summary'>
            {summary}
          </Row>
          <Row className='no-padding cp-individual-article-date-published'>
            {datePublished}
          </Row>
        </Col>
        <Col md={3} className='no-padding'>
          <NavLink to={`/research/${navTitle}`} target='_blank'>
            {renderImage}
          </NavLink>
        </Col>
      </Row>
    )

  }

  renderMetaTags = () => {
    // const { selectedUser } = this.props.currentUser
    const { user } = this.state.contributorStats
    return (
      <MetaTags>
        <meta name='description' content={user.about || ''}/>
        <meta name='title' property='og:title' content={(user.displayName || '') + ' ' + '- Velocity Channel Insight Provider'}/>
        <meta property='og:description' content={user.about || ''}/>
        <meta property='og:image' content={user.image || ''}/>
        <meta property='og:type' content='website'/>
        <meta property='og:url' content={`https://velocitychannel.io${this.props.history.location.pathname}`} />
        <meta property='twitter:title' content={(user.displayName || '') + ' ' + '- Velocity Channel Insight Provider'}/>
        <meta property='twitter:description' content={user.about || ''}/>
        <meta property='twitter:image' content={user.image || ''}/>
        <meta property='twitter:site' content='Velocity Channel'/>
        <meta name='robots' content='nofollow' />
      </MetaTags>
    )
  }

  renderShareProfileModal = () => {
    const { showShareProfileModal, shareProfileLink } = this.state
    return (
      <Modal
        className='cp-share-profile-modal'
        centered
        show={showShareProfileModal}
        onHide={() => this.setState({showShareProfileModal: false})}
      >
        <Row className='no-padding cp-modal-header-text'>
          Copy and share this unique profile link
        </Row>
        <Row className='no-padding copy-link-control-row'>
          <Col md={11} className='no-padding'>
            <FormControl className='cp-copy-link-form-control' value={shareProfileLink} />
          </Col>
          <Col md={1} className='no-padding clipboard-col'>
            <CopyToClipboard text={shareProfileLink} onCopy={() => toast.info('Copied link to clipboard')}>
              <FiClipboard className='cp-copy-clipboard-icon' />
            </CopyToClipboard>
          </Col>
        </Row>
      </Modal>
    )
  }

  render() {
    const statsAndReportsLoaded = this.state.contributorStats.loaded && this.state.contributorReports.loaded
    if (this.state.contributorStats.loaded) {
      return (
        <Container className='contributor-profile-container'>
          {this.renderMetaTags()}
          {this.renderShareProfileModal()}
          <Row>
            <Col md={2} />
            <Col className='no-padding cp-profile-container-column'>
              {this.renderUserBanner()}
              {this.renderContentSection()}
            </Col>
            <Col md={2} />
          </Row>
        </Container>
      )
    } else {
      return (
        <Container className='contributor-profile-container'>
          {this.renderLoading()}
        </Container>
      )
    }
  }
} // end of ContributorProfile class

export default ContributorProfile
