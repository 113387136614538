import { connect } from 'react-redux'
import './ResearchManagementPortal.css'
import ResearchManagementPortal from './ResearchManagementPortal'
import { logout } from '../../../redux/actions/login'

const mapStateToProps = state => ({
  account: state.login.accountData,
})

const mapDispatchToProps = {
  logout
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResearchManagementPortal)
