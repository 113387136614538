import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Image,
  Tabs,
  Tab,
} from 'react-bootstrap';
import queryString from 'query-string';
import ReactLoading from 'react-loading';
import { FiThumbsUp, FiEdit3 } from 'react-icons/fi';
import { MdArrowBack } from 'react-icons/md';
import ReactQuill from 'react-quill';
import ReactHtmlParser from 'react-html-parser';
import he from 'he';
import defaultAvatar from '../../../images/default-avatar-image.png';

class TokenDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 'asset',
      theme: 'snow',
      tokenId: '',
      newComment: '',
      comment: '',
      commentReply: '',
      news_comment: '',
      showArticle: false,
    };
  }

  componentDidMount() {
    const { token_name } = this.props;

    this.props.getTokenDetailRequest(token_name);
    this.props.getTokenNewsCommentRequest(token_name);

    const { search } = window.location;
    if (search) {
      const idObj = queryString.parse(search);
      this.setState({ tokenId: idObj.id });
      this.props.getCommentsRequest(idObj.id, 'token_comment');
    }
  }

  formatDate = dateInput => {
    let date = new Date(dateInput);
    const month = date.toLocaleString('en-us', { month: 'long' });
    const day = date.getDate();
    return month + ' ' + day;
  };

  render() {
      const { detail, newsItem } = this.props;
      const { Items } = detail;
      const { showArticle } = this.state;

      if (!Items || Items.length === 0) {
          return (
              <React.Fragment>
                  <div className="loading-bar">
                      <ReactLoading
                          type={'spin'}
                          color={'#5790c8'}
                          height={100}
                          width={100}
                      />
                  </div>
              </React.Fragment>
          );
      }

      const {
          id,
          name,
          brief,
          token_ticker,
          description,
          issuance_type,
          image,
          start_date,
          end_date,
          industry,
          location,
          token_price,
          minimum_investment,
          employees,
          team,
          website_link,
          twitter_link,
          facebook_link,
          medium_link,
          document_links,
      } = Items[0];

      return (
          <Container>
              <React.Fragment>
                  <Row className="detail-page-section">
                      <Col sm={7}>
                          <Row>
                              <Col sm={3}>
                                  <Image src={image} className="company-logo" />
                              </Col>
                              <Col sm={9} className="company-header">
                                  <h3>{name}</h3>
                                  <h5 className="description-header">Issuance Type: </h5> {issuance_type} <br/>
                                  <h5 className="description-header">Industry: </h5> {industry} <br/>
                                  <h5 className="description-header">Location: </h5> {location} <br/>
                                  <h5 className="description-header">Start Date: </h5> {this.formatDate(start_date)} <h5 className="description-header">- End Date: </h5> {this.formatDate(end_date)}
                              </Col>
                          </Row>
                          <Row>
                              <div className="description-container">
                                  <h5 className="description-header">Description</h5>
                                  <div className="description-box">
                                      {ReactHtmlParser(he.decode(description))}
                                  </div>
                              </div>
                          </Row>
                      </Col>
                      <Col sm={5} className="company-description">
                          <div className="financials">
                              <h5 className="description-header">Token Price: </h5> $ {token_price} <br />
                              <h5 className="description-header">Minimum Investment: </h5> $ {minimum_investment}
                          </div>
                          <div className="teams">
                              <h5 className="description-header">Team(s)</h5>
                              {team && team.length > 0 ? (
                                  team.map(team => (
                                      <Col sm={6} key={team.name}>
                                          <div className="single-team">
                                              <h5 className="description-header">Team Name: </h5> {team.name}
                                              <h5 className="description-header" id="link">Team Link: </h5> {team.link}
                                          </div>
                                      </Col>
                                  ))
                              ) : (
                                  <div className="ml-4">No Teams</div>
                              )}
                              <h5 className="description-header">Employees: </h5> {employees}
                          </div>
                          <div className="relevant-links">
                              <h5 className="description-header"><a href={website_link}>Website Link</a></h5>  <br />
                              <h5 className="description-header"><a href={twitter_link}>Twitter Link</a> </h5>  <br />
                              <h5 className="description-header"><a href={facebook_link}>Facebook Link</a> </h5>  <br />
                              <h5 className="description-header"><a href={medium_link}>Medium Link</a> </h5>  <br />
                              <h5 className="description-header"><a href={document_links}>Document Links</a> </h5>  <br />
                          </div>
                      </Col>
                  </Row>
              </React.Fragment>
          </Container>
    );
  }
}

export default TokenDetailPage;
