import { connect } from 'react-redux'
import AdminPage from './AdminPage'
import './AdminPage.css'
import {
  searchTokensRequest
} from '../../../redux/actions/tokenlist'
import {
  logout
} from '../../../redux/actions/login'

const mapStateToProps = state => ({
  searchTokens: state.tokens.searchTokens.tokens,
  error: state.tokens.searchTokens.error,
})

const mapDispatchToProps = {
  searchTokensRequest,
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage)
