import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Jumbotron,
  Form,
  FormControl,
  InputGroup,
  Tabs,
  Tab,
  Modal,
  Spinner,
  Image,
  Pagination,
} from 'react-bootstrap';
import DatePicker from "react-date-picker";
import { NavLink } from 'react-router-dom'
import { MdRemoveRedEye, MdSearch, MdFilterNone, MdThumbUp, MdThumbDown, MdTrendingUp, MdBookmark, MdExplore, MdClear, MdCollectionsBookmark } from "react-icons/md";
import { FaUserCircle } from 'react-icons/fa'
import tempNewsImage from '../../../images/blank-image.png'
import { history } from "../../../configureStore";
import MetaTags from 'react-meta-tags'
import LogoImg from '../../../images/logoImage.js'
import { initGA, logPageView } from "../../../utils/analytics";
import jwt from 'jsonwebtoken'

class ResearchHomepage extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      query: '',
      currentCategory: 'Discover',
      feeds: {},
      pages: [],
      articlesPerPage: 10,
      active: 1,
      commentsOffset: 0,
      currentTimeFrame: '',
      searchParameters: {
        query: '',
        category: '',
        industry: '',
        from: '',
        to: '',
      },
      isTopicSelected: false,
      selectedTopic: '',
    }
  } // end of constructor(props)

  componentDidMount() {
    initGA();
    logPageView();
    if (this.props.location.search) {
      // this triggers if the user, for example, pastes a url with search parameters
      this.handleParseUrlAndGetArticles()
    } else {
      this.handleInPageNavigation(this.state.currentCategory)
      this.handleParseUrlAndGetArticles()
    }
    this.handleGetTrendingTopics('week')
  } // end of componentDidMount()

  componentWillReceiveProps(nextProps) {
    if (nextProps != this.props) {
      if (nextProps.feeds != this.props.feeds) {
        this.setState({feeds: nextProps.feeds})
        this.handleCreatePages(nextProps.feeds)
      }
    }
  }

  componentWillUpdate(prevState) {
    if (this.props.history.location !== this.props.location) {
      this.handleParseUrlAndGetArticles()
    }
  }

  handleParseUrlAndGetArticles = () => {
    let array = this.props.history.location.search.split('?')
    let obj = {}
    for (let i = 1; i < array.length; i++) {
      let split = array[i].split('=')
      obj[split[0].trim()] = decodeURI(split[1].trim())
    }
    this.setState({
      active: obj.page || 1,
      currentCategory: obj.section || '',
      searchParameters: {
        query: obj.query,
        category: obj.category,
        industry: obj.industry,
        from: obj.from,
        to: obj.to,
      }
    }, () => this.props.getArticlesByCategoryRequest(this.state.currentCategory, this.state.offset))

    if (obj.section !== 'Discover' && obj.section !== 'Trending Reports' && obj.section !== 'My Channel' && obj.section !== 'My Bookmarks' && obj.section !== 'My Upvotes' && obj.section !== 'My Downvotes') {
      this.setState({
        isTopicSelected: true,
        selectedTopic: obj.section,
      })
      // this checks to see if a section other than one of the main categories is selected.
      // if not, then it must be a trending topic, and therefore set isTopicSelected to true.
    }
  }

  handleFormChange = e => {
    const { name, value } = e.target
    this.setState({
      searchParameters: {
        ...this.state.searchParameters,
        [name]:  value
    }}, () => this.handleInPageNavigation(this.state.currentCategory, 'query'))
  } // end of handleFormChange(e)

  handleClearFilters = () => {
    this.setState({
      searchParameters: {
        query: '',
        category: '',
        industry: '',
        from: '',
        to: '',
    }}, () => this.handleInPageNavigation(this.state.currentCategory))
  }

  handleChangeDate = (e, category) => {
    this.setState({
      searchParameters: {
        ...this.state.searchParameters,
        [category]: e
    }}, () => this.handleInPageNavigation(this.state.currentCategory, 'query'))
  }

  handleFollowContributor = itemId => {
    const { userId } = this.props.user
    const data = { userId, resourceId: itemId, resourceType: 'contributor' }
    this.props.followContributorRequest(data)
  } // end of handleFollowContributor(itemId)

  handleTruncateSummary = (summary, length) => {
    if (summary.length > length) {
      return summary.slice(0, length).concat("...")
    } else {
      return summary
    }
  } // end of handleTruncateSummary(input, length)

  handleTruncateTitle = (title, length) => {
    if (title.length > length) {
      return title.slice(0, length).concat("...")
    } else {
      return title
    }
  } // end of handleTruncateTitle(title, length)

  formatDate = dateInput => {
    let date = new Date(dateInput);
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    return month + " " + day + ", " + year;
  }

  onKeyPress = e => {
    if (e.key === "Enter" && e.target.value.length > 0) {
    }
  } // end of onKeyPress(e)

  handleTruncateText = (input, length) => {
    if (input.length > length) {
      return input.slice(0, length).concat("...")
    } else {
      return input
    }
  } // end of handleTruncateText(input, length)

  handleNavigationTitle = (title, id) => {
    if (title && id) {
      return title.toLowerCase().replace(/%/g, '').replace(/\s+/g, '-').replace(/\?/g,'') + '-' + id
    }
  } // end of handleNavigationTitle(title)

  handleInPageNavigation = (section, query, topics, page) => {
    /*
      SECTION corresponds to the section the user is looking through (Discover, Trending, etc).
      QUERY corresponds to the search parameters
      TOPICS corresponds to specific topics the user is clicking through
      PAGE corresponds to the page in the URL
    */
    let url = topics ? `/research/feed?section=${section}&Topic=${topics}` : `/research/feed?section=${section}`
    if (this.state.searchParameters) {
      const { searchParameters } = this.state
      for (var key in searchParameters) {
        if (searchParameters[key]) {
          url += `?${key}=${searchParameters[key]}`
        }
      }
      if (page) {
        url += `?page=${page}`
      } else {
        url += `?page=${this.state.active}`
      }
    }
    this.props.history.push(url)
  }

  handleGetArticlesByCategory = category => {
    this.setState({
      ...this.state,
      currentCategory: category,
      offset: 0,
      active: 1,
      searchParameters: {
        query: '',
        category: '',
        industry: '',
        from: '',
        to: '',
      },
      isTopicSelected: false,
    }, () => this.handleInPageNavigation(category))
  }

  handleGetArticlesByTopic = topic => {
    this.setState({
      ...this.state,
      currentCategory: topic,
      offset: 0,
      active: 1,
      searchParameters: {
        query: '',
        category: '',
        industry: '',
        from: '',
        to: '',
      },
      isTopicSelected: true,
      selectedTopic: topic,
    }, () => this.handleInPageNavigation(topic))
  }

  handleGetTrendingTopics = timeFrame => {
    this.setState({currentTimeFrame: timeFrame})
    this.props.getTrendingTopicsRequest(timeFrame)
  }

  handleCreatePages = feeds => {
    if (feeds.loaded === true) {
      const { articlesPerPage } = this.state
      const { total } = feeds.list
      let pages = []
      const numberOfPages = total === 0 ? 0 : Math.ceil(total / articlesPerPage)
      for (let number = 1; number <= numberOfPages; number++) {
        pages.push(
          <Pagination.Item
            onClick={e => this.handlePagination(e)}
            key={number}
            id={number}
            active={number == this.state.active}
          >
            {number}
          </Pagination.Item>
        )
      }
      this.setState({pages: pages})
    }
  }

  handlePagination = e => {
    const { articlesPerPage } = this.state
    const offset = (e.target.id - 1) * articlesPerPage
    this.setState({active: e.target.id, commentsOffset: e.target.id * articlesPerPage},
    () => this.handleInPageNavigation(this.state.currentCategory))
  }

  handlePaginationArrow = direction => {
    const { articlesPerPage, currentCategory } = this.state
    if (direction === 'prev') {
      const offset = (this.state.active - 1) * articlesPerPage
      this.setState({active: this.state.active - 1, commentsOffset: offset})
    } else if (direction === 'next') {
      const offset = (this.state.active + 1) * articlesPerPage
      this.setState({active: this.state.active + 1, commentsOffset: offset})
    }
  }

  renderOptionsForLoggedInUsers = () => {
    const decodedToken = jwt.decode(localStorage.accessToken)
    if (decodedToken) {
      if (decodedToken.userId) {
        return (
          <React.Fragment>
            <Row
              className='research-search-part'
              id={this.state.currentCategory === 'My Channel' ? 'current-category' : ''}
              onClick={() => this.handleGetArticlesByCategory('My Channel')}
              >
            <FaUserCircle
              className='research-icon'
            />
              My Feed
            </Row>
            <Row className='research-search-part'>
              <NavLink to={'/research/collections'} className='research-search-part'>
                <MdCollectionsBookmark className='research-icon' />
                My Collections
              </NavLink>
            </Row>
          </React.Fragment>
        )
      }
    }
  }

  renderSearchSection = () => {
    const { query, industry, category, from, to } = this.state.searchParameters
    return (
      <Col md={2} xs={2} className='research-left-col'>
        <Row className='research-topics'>
          <Col className='research-search-col'>
            <Row
              className='research-search-part'
              id={this.state.currentCategory === 'Discover' ? 'current-category' : ''}
              onClick={() => this.handleGetArticlesByCategory('Discover')}
            >
              <MdExplore className='research-icon' />
              Latest
            </Row>
            <Row
              className='research-search-part'
              id={this.state.currentCategory === 'Trending Reports' ? 'current-category' : ''}
              onClick={() => this.handleGetArticlesByCategory('Trending Reports')}
              >
              <MdTrendingUp className='research-icon' />
              Trending Research
            </Row>
            {this.renderOptionsForLoggedInUsers()}
          </Col>
        </Row>
        <Row className='research-topics'>
          <Row className='research-topics-header-row'>
            <h4>Search & Filter</h4>
            <MdClear className='search-filter-clear-button' onClick={this.handleClearFilters} />
          </Row>
          {/* <h6>Narrow your search for insights</h6> */}
          <Form.Group as={Row} className='research-search-bar'>
            <Form.Control
              size='sm'
              className='search-bar-form-control'
              type="text"
              name='query'
              value={query}
              placeholder="Search articles..."
              onChange={this.handleFormChange}
            />
            <Button className='research-search-button'><MdSearch /></Button>
          </Form.Group>
          <Form.Group as={Row} className='research-search-dropdown-row'>
              {/* <Form.Label className='research-label'>Filter by category</Form.Label> */}
              <Form.Control
                className='research-search-dropdown'
                name='category'
                as='select'
                onChange={this.handleFormChange}
                value={category}
                size='sm'
              >
                <option value=''>Category...</option>
                <option>STO Analysis</option>
                <option>Macro Trends</option>
                <option>Technology and Protocol Analysis</option>
                <option>Trading + TA</option>
                <option>Legal + Regulation</option>
                <option>Strategy + Advisory</option>
              </Form.Control>
          </Form.Group>
          <Form.Group as={Row} className='research-search-dropdown-row'>
              {/* <Form.Label className='research-label'>Select an industry</Form.Label> */}
              <Form.Control
                className='research-search-dropdown'
                name='industry'
                as='select'
                onChange={this.handleFormChange}
                value={industry}
                size='sm'
              >
                <option value="">Industry...</option>
                <option>Art</option>
                <option>Banking</option>
                <option>Blockchain</option>
                <option>Cryptocurrency</option>
                <option>Custody</option>
                <option>Energy</option>
                <option>Entertainment</option>
                <option>Financial Tech</option>
                <option>Gaming</option>
                <option>Healthcare</option>
                <option>Investment Fund</option>
                <option>Legal</option>
                <option>Manufacturing</option>
                <option>Real Estate</option>
                <option>Retail</option>
                <option>Software/Technology</option>
                <option>Sports</option>
                <option>Supply Chain</option>
                <option>Travel</option>
              </Form.Control>
          </Form.Group>
          <Form.Group as={Row} className='research-datepicker-row'>
            <Col md={2} xs={2} className='research-datepicker-label'>From{" "}</Col>
            <Col md={10} xs={10} className='research-datepicker-col'>
              <DatePicker
                className='research-datepicker'
                onChange={e => this.handleChangeDate(e, 'from')}
                value={from}
                calendarType="US"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='research-datepicker-row'>
            <Col md={2} xs={2} className='research-datepicker-label'>To{" "}</Col>
            <Col md={10} xs={10} className='research-datepicker-col'>
              <DatePicker
                className='research-datepicker'
                onChange={e => this.handleChangeDate(e, 'to')}
                value={to}
                calendarType="US"
              />
            </Col>
          </Form.Group>
        </Row>
      </Col>
    )
  } // end of renderSearchSection()

  renderArticleSection = () => {
    return (
      <Col md={5} xs={5} id='article-section' className='research-homepage-col'>
        <Row className='no-padding'>
          {this.renderLatestArticlesSection()}
        </Row>
        <Pagination className='render-article-section-pagination'>
          {this.state.pages.length > 1 ? this.state.pages : null}
        </Pagination>
      </Col>
    )
  } // end of renderArticleSection()

  handleFollowTopic = () => {
    const { isFollowing } = this.state.feeds.list
    const { selectedTopic } = this.state
    const { userId } = this.props.user
    this.setState({
      feeds: {
        ...this.state.feeds,
        list: {
          ...this.state.feeds.list,
          isFollowing: isFollowing ? false : true
        }
      }
    })

    const data = {
      type: 'follow',
      userId: this.props.user.userId,
      resourceId: this.state.selectedTopic,
      resourceType: 'topic',
    }

    if (isFollowing) {
      this.props.removeEventRequest(data)
    } else {
      this.props.postEventRequest(data)
    }
  }

  renderLatestArticlesSection = () => {
    if (this.state.feeds.loaded) {
      const { isTopicSelected, searchParameters } = this.state
      const { reports, total, isFollowing } = this.state.feeds.list

      let followTopicButton
      if (isTopicSelected) {
        if (isFollowing) {
          followTopicButton = (
            <Button className='rdisc-follow-topic-button' onClick={this.handleFollowTopic}>
              Unfollow Topic
            </Button>
          )
        } else {
          followTopicButton = (
            <Button className='rdisc-follow-topic-button' onClick={this.handleFollowTopic}>
              Follow Topic
            </Button>
          )
        }
      }

      let noResultsContent = 'No results'
      noResultsContent = searchParameters.query ? noResultsContent + ` for '${searchParameters.query}'` : noResultsContent

      return (
        <Col className='research-articles-section'>
          <Row noGutters className='rdisc-current-category-header-text'>
            <h4>{this.state.currentCategory}</h4>
            {followTopicButton}
          </Row>
          {total > 0 ? reports.map(report => this.renderArticle(report)) : <Row className='no-reports'>{noResultsContent}</Row>}
          {/* <Row className='research-see-more-articles'><MdFilterNone className='research-icon' />See more latest articles</Row> */}
        </Col>
      )
    } else {
      return (
        <Col className='research-articles-section'>
          {/* <h4>Latest Articles</h4> */}
          {this.renderLoading()}
        </Col>
      )
    }
  } // end of renderLatestArticlesSection()

  renderArticle = article => {
    const { contributor, report } = article
    const contributorId = contributor.userId ? contributor.userId : ''
    const contributorImage = contributor.image ? contributor.image : tempNewsImage
    const {
      id,
      datePublished,
    } = report
    let summary
    if (report.summary) {
      summary = report.summary.length > 280 ? report.summary.slice(0, 280) + '...' : report.summary
    }
    const title = report.title.length > 140 ? report.title.slice(0, 140) + '...' : report.title
    const displayName = contributor.displayName ? contributor.displayName : 'Missing displayName'
    const reportImage = report.image ? report.image : null
    const truncatedTitle = title ? this.handleTruncateTitle(title, 100) : "Missing Title"
    const truncatedSummary = summary ? this.handleTruncateSummary(summary, 200) : "Missing Summary"
    const formattedDate = datePublished ? this.formatDate(datePublished) : ''
    let newTitle = this.handleNavigationTitle(title, id)
    return (
      <Row noGutters className='render-insight-container' key={id}>
        <Col md={3} className='insight-contributor-information-container'>
          <div className='insight-contributor-image-container'>
            <NavLink to={`/contributor/${contributor.username}`}>
            {contributorImage ? <Image className='contributor-image' responsive roundedCircle src={contributorImage} /> : null}
            </NavLink>
          </div>
          <div className='insight-contributor-name-date-container'>
            <div className='display-name'>
              <NavLink to={`/contributor/${contributor.username}`}>
                {displayName}
              </NavLink>
            </div>
            <div className='date-published'>
              {formattedDate}
            </div>
          </div>
        </Col>
        <Col className='insight-article-information-container'>
          <div className='insight-article-title-summary-container'>
            <div className='article-title'>
              <NavLink to={`/research/${newTitle}`}>
                {title}
              </NavLink>
            </div>
            <div className='article-summary'>
              {summary}
            </div>
          </div>
          <div className='insight-article-image-container'>
            <NavLink to={`/research/${newTitle}`}>
              <Image className='article-image' src={reportImage} />
            </NavLink>
          </div>
        </Col>
      </Row>
    )
  } // end of renderInsightsCard(hit)

  renderTopicsSection = () => {
    return (
      <Col md={3} xs={3} className='research-right-col'>
        <h4>Trending Topics</h4>
        <Row className='research-trending-section'>
          {this.renderTrendingTopicsSection()}
        </Row>
      </Col>
    )
  } // end of renderTopicsSection()

  renderTrendingTopicsSection = () => {
    let content
    if (this.props.trendingTopics.loaded) {
      const { hits } = this.props.trendingTopics.list

      let data
      if (hits.length > 0) {
        data = hits.map(hit => this.renderTrendingTopic(hit))
      } else {
        data = <div className='no-trending-topics'>No trending topics</div>
      }
      content = (
        <Tabs
          className='payout-tabs'
          defaultActiveKey='week'
          onSelect={k => this.handleGetTrendingTopics(k)}>
          <Tab className='trending-topic-tab' eventKey='week' title='This Week'>
            {data}
          </Tab>
          <Tab className='trending-topic-tab' eventKey='month' title='This Month'>
            {data}
          </Tab>
          <Tab className='trending-topic-tab' eventKey='year' title='This Year'>
            {data}
          </Tab>
          <Tab className='trending-topic-tab' eventKey='all' title='All Time'>
            {data}
          </Tab>
        </Tabs>
      )
    } else {
      content = (
        <Container>
          {this.renderLoading()}
        </Container>
      )
    }

    return content
  } // end of renderTrendingTopicsSection()

  renderTrendingTopic = topic => {
    const {
      name,
      count,
    } = topic._source
    let fixedName = name.length > 25 ? name.slice(0, 25) + '...' : name
    return (
      <div
        className='trending-topic'
        key={name}
        onClick={() => this.handleGetArticlesByTopic(name)}
        id={this.state.currentCategory === name ? 'current-category' : ''}>
        {fixedName}
      </div>
    )
  } // end of renderTrendingTopic(topic)

  renderLoading = () => (
    <Row noGutters className='discover-loading-spinner-container'>
      <Spinner className='loading-spinner' animation="border" />
    </Row>
  ) // end of renderLoading()

  renderMetaTags = () => {
    return (
      <MetaTags>
        <meta name='description' content='Velocity Channel provides up to date and comprehensive research on the technologies, organizations, and regulation driving the digital asset revolution.'/>
        <meta name='title' property='og:title' content='Velocity Channel STO and Digital Asset Research Portal'/>
        <meta property='og:description' content='Velocity Channel provides up to date and comprehensive research on the technologies, organizations, and regulation driving the digital asset revolution.'/>
        <meta property='og:image' content={LogoImg}/>
        <meta property='og:type' content='website'/>
        <meta property='og:url' content='https://velocitychannel.io/research'/>
        <meta property='twitter:title' content='Velocity Channel STO and Digital Asset Research Portal'/>
        <meta property='twitter:description' content='Velocity Channel provides up to date and comprehensive research on the technologies, organizations, and regulation driving the digital asset revolution.'/>
        <meta property='twitter:image' content={LogoImg}/>
        <meta property='twitter:site' content='Velocity Channel'/>
        <meta name='robots' content='index,follow'/>
      </MetaTags>
    )
  }


  render() {
    return (
      <Container fluid className='research-homepage-jumbotron'>
        {this.renderMetaTags()}
        <Row noGutters className='research-homepage-top-row'>
          {/* <Col md={1} xs={1} className='separation-column' /> */}
          {this.renderSearchSection()}
          {this.renderArticleSection()}
          {this.renderTopicsSection()}
          {/* <Col md={1} xs={1} className='separation-column' /> */}
        </Row>
      </Container>
    )
  }
} // end of class ResearchHomepage

export default ResearchHomepage
