import { connect } from 'react-redux';
import './SpamReports.css'
import SpamReports from './SpamReports'
import { getReportListRequest } from '../../../redux/actions/researches.js'

const mapStateToProps = state => ({
  reports: state.research.reports,
})

const mapDispatchToProps = {
  getReportListRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(SpamReports)
