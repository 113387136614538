import { put, takeLatest, call } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { history } from '../../../configureStore'
import request from '../../../utils/apiRequest'
import * as CONSTANTS from '../../constants/admin'
import * as ACTIONS from '../../actions/admin'
import * as TOKENFORM_ACTIONS from '../../actions/tokenform'

function* getTradedSymbols(action) {
  try {
    let url = '/admin/profile/symbols?'
    if (action.params.q) url += `q=${action.params.q}&`

    const responseData = yield call(
      request,
      url,
      'GET'
    )

    yield put(ACTIONS.getTradedSymbolsSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.getTradedSymbolsError(err))
  }
}

function* createTradedSymbol(action) {
  try {
    const responseData = yield call(
      request,
      '/admin/profile/create',
      'POST',
      action.data
    )

    yield put(TOKENFORM_ACTIONS.selectAssetProfileRequest(responseData.profile._id))
    yield put(ACTIONS.createTradedSymbolSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.createTradedSymbolError(err))
  }
}

function* searchUsers(action) {
  try {
    let url = '/admin/users/search?'
    if (action.params) {
      for (let [key, value] of Object.entries(action.params)) {
        url = url + `${key}=${value}&`
      }
    }

    const responseData = yield call(
      request,
      url,
      'GET'
    )

    yield put(ACTIONS.searchUsersSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.searchUsersError(err))
  }
}

function* searchReports(action) {
  try {
    let url = `/research/contributor/search?`
    if (action.params.userId) url += `user_id=${action.params.userId}&`
    if (action.params.title) url += `q=${action.params.title}&`
    if (action.params.status) url += `published=${action.params.status}`
    if (action.params.offset) url += `offset=${action.params.offset}`

    const responseData = yield call(
      request,
      url,
      'GET'
    )

    yield put(ACTIONS.searchReportsSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.searchReportsError(err))
  }
}

function* createOffering(action) {
  try {
    const body = {
      profile_id: action.profileId
    }

    const responseData = yield call(
      request,
      '/admin/profile/createOffer',
      'POST',
      body
    )

    yield put(ACTIONS.createOfferingSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.createOfferingError(err))
  }
}

function* getOffer(action) {
  try {
    const responseData = yield call(
      request,
      `/admin/profile/getOffer?offer_id=${action.offerId}`,
      'POST',
    )

    yield put(ACTIONS.getOfferSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.getOfferError(err))
  }
}

function* updateOffer(action) {
  try {
    const responseData = yield call(
      request,
      `/admin/profile/updateOffer`,
      'POST',
      action.params
    )

    yield put(ACTIONS.updateOfferSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.updateOfferError(err))
  }
}

function* getWebsiteConfig(action) {
  try {
    const responseData = yield call(
      request,
      '/website',
      'GET'
    )

    yield put(ACTIONS.getWebsiteConfigSuccess(responseData.data))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.getWebsiteConfigError(err))
  }
}

function* updateWebsiteConfig(action) {
  try {
    const responseData = yield call(
      request,
      '/admin/website/config',
      'POST',
      action.data
    )

    yield put(ACTIONS.updateWebsiteConfigSuccess())
    yield put(ACTIONS.getWebsiteConfigRequest())
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.updateWebsiteConfigError(err))
  }
}

export default function* adminSaga() {
  yield takeLatest(CONSTANTS.ADMIN_GET_TRADED_SYMBOLS_REQUEST, getTradedSymbols)
  yield takeLatest(CONSTANTS.ADMIN_CREATE_TRADED_SYMBOL_REQUEST, createTradedSymbol)
  yield takeLatest(CONSTANTS.ADMIN_SEARCH_USERS_REQUEST, searchUsers)
  yield takeLatest(CONSTANTS.ADMIN_SEARCH_REPORTS_REQUEST, searchReports)
  yield takeLatest(CONSTANTS.ADMIN_CREATE_OFFERING_REQUEST, createOffering)
  yield takeLatest(CONSTANTS.ADMIN_GET_OFFER_REQUEST, getOffer)
  yield takeLatest(CONSTANTS.ADMIN_UPDATE_OFFER_REQUEST, updateOffer)
  yield takeLatest(CONSTANTS.ADMIN_GET_WEBSITE_CONFIG_REQUEST, getWebsiteConfig)
  yield takeLatest(CONSTANTS.ADMIN_UPDATE_WEBSITE_CONFIG_REQUEST, updateWebsiteConfig)
}
