
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getData } from '../../redux/actions';

import App from './App';

App.propTypes = {
    getData: PropTypes.func.isRequired,
    currentUser: PropTypes.objectOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
    currentUser: state.login.userData,
});

const mapDispatchToProps = {
    getData,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);