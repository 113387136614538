import { connect } from 'react-redux';
import ContributorProfile from './ContributorProfile'
import './ContributorProfile.css';
import {
  getContributorRequest,
  searchContributorApplicationsRequest,
  getContributorAnalyticsRequest,
  getContributorStatsRequest,
  getContributorReportsRequest,
} from '../../redux/actions/contributor'
import { postEventRequest, removeEventRequest } from '../../redux/actions/analytics'
import {
  uploadAvatarImageRequest,
  uploadBannerImageRequest,
} from '../../redux/actions/account'

const mapStateToProps = state => ({
  currentContributor: state.contributors,
  currentUser: state.account.user,
  contributorStats: state.contributors.contributorStats,
  contributorReports: state.contributors.contributorReports,
  user: state.account.myAccount.info,
  uploadAvatarImage: state.account.uploadAvatarImage,
  uploadBannerImage: state.account.uploadBannerImage,
  test: state.account,
})

const mapDispatchToProps = {
  getContributorRequest,
  searchContributorApplicationsRequest,
  getContributorAnalyticsRequest,
  getContributorStatsRequest,
  getContributorReportsRequest,
  postEventRequest,
  uploadAvatarImageRequest,
  uploadBannerImageRequest,
  removeEventRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContributorProfile)
