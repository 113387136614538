import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Form,
  Modal,
  Jumbotron,
  Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { MdArrowBack } from 'react-icons/md';

class ContributorApplications extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      query: '',
      applicationSelected: false,
      selectedApplication: {},
      show: false,
      setShow: false,
      reason: '',
      contributorApplications: {
        list: [],
        error: "",
        loading: false,
        loaded: false,
      },
    }
  } // end of constructor(props)

  componentDidMount() {
    this.props.clearContributorApplicationsCache()
    this.props.getContributorApplicationsRequest()
  } // end of componentDidMount()

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        contributorApplications: nextProps.contributorApplications
      })
    }
  }

  handleCloseModal = () => {
    this.setState({ setShow: false })
  } // end of handleCloseModal()

  handleShowModal = () => {
    this.setState({ setShow: true })
  } // end of handleShowModal()

  handleFormChange = e => {
      const { name, value } = e.target
      this.setState({ [name]:  value })
  } // end of handleFormChange(e)

  formatDate = dateInput => {
    let date = new Date(dateInput);
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    return month + " " + day + ", " + year;
  } // end of formatDate(dateInput)

  onKeyPress = e => {
    const { query } = this.state;
    if (e.key === "Enter" && e.target.value.length > 0) {
        e.preventDefault()
        this.props.searchContributorApplicationsRequest(query)
    } else if (e.key === "Enter" && e.target.value.length === 0) {
        e.preventDefault()
        this.props.clearContributorApplicationsCache()
    }
  } // end of onKeyPress(e)

  handleSearchApplication = query => {
    if (query) {
        this.props.searchContributorApplicationsRequest(query)
    } else {
        this.props.getContributorApplicationsListRequest()
    }
  } // end of handleSearchApplication(Query)

  handleBackToUserList = () => {
      this.props.clearContributorApplicationsCache()
  } // end of handleBackToUserList()

  handleApprove = (userId, date, username) => {
    const info = { userId, date, username }
    this.props.approveContributorApplicationRequest(info)
    this.handleBackToUserList()
  } // end of handleApprove(userId, date, username)

  handleDeny = e => {
    e.preventDefault()
    const { userId, date, username } = this.props.application.selectedApplication
    const { reason } = this.state
    if (reason.length === 0) {
      toast.error('Admins must provide a reason for rejecting a contributor application')
    } else {
      const info = { userId, date, username, reason }
      this.props.denyContributorApplicationRequest(info)
      this.handleCloseModal()
      this.handleBackToUserList()
    }
  } // end of handleDeny(userId, username, date, reason, email)

  renderContributorApplicationLookup = () => {
      const { query } = this.state
      return (
        <Row noGutters className='user-lookup-form-row'>
            <label className='user-lookup-label'>
              Contributor Application Lookup:
            </label>
            <FormControl
              className="user-lookup-formcontrol"
              placeholder="Enter a username..."
              name='query'
              value={query}
              onChange={this.handleFormChange}
              onKeyPress={e => this.onKeyPress(e)} />
        </Row>
      )
  } // end of renderContributorApplicationLookup()

  renderContributorApplicationList = () => {
    const { contributorApplications } = this.state
    if (contributorApplications.loaded) {
      const hits = contributorApplications.list
      return (
        <Col className='user-list-users-container'>
          {hits.map(hit => this.renderContributorApplication(hit))}
        </Col>
      )
    } else {
      return this.renderLoading()
    }
  } // end of renderContributorApplicationList()

  renderContributorApplication = application => {
      const {
        username,
        userId,
        contributor_type,
        date,
      } = application
      const formattedDate = this.formatDate(date)

      return (
        <Row key={userId} className='user-list-user-row'>
            <Col>
                <div
                  className='user-list-username'
                  onClick={() => this.handleSearchApplication(username)}>
                    {username}
                </div>
            </Col>
            <Col className='user-list-user-col'>
                <div className='user-list-status'>Type: {contributor_type}</div>
            </Col>
            <Col>
                Date Submitted: {formattedDate}
            </Col>
        </Row>
      )
  } // end of renderContributorApplication(contributorApplication)

  renderApplicationDetails = () => {
    const { selectedApplication } = this.props.application

    if (selectedApplication.contributor_type === 'Individual') {
      return this.renderIndividualApplication(selectedApplication)
    } else if (selectedApplication.contributor_type === 'Organization') {
      return this.renderOrganizationApplication(selectedApplication)
    }
  } // end of renderApplicationDetails()

  renderOrganizationApplication = application => {
    const {
      name,
      contributor_type,
      date,
      description,
      regions,
      industry,
      status,
      username,
      website,
    } = application
    const formattedDate = this.formatDate(date)

    return (
      <Col className='user-list-users-container'>
          <Row noGutters>
              <div
                  className='back-to-user-list-button'
                  onClick={this.handleBackToUserList}
              >
                  <MdArrowBack />
                  Back to the Contributor List
              </div>
          </Row>
          <Row noGutters>
              <Col className='user-list-selected-user-info-col'>
                  <Row className='user-list-selected-user-info-row'>
                      <Col><label>Username</label></Col>
                      <Col><p>{username}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                      <Col><label>Website</label></Col>
                      <Col><p>{website}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                      <Col><label>Type</label></Col>
                      <Col><p>{contributor_type}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                      <Col><label>Date Submitted</label></Col>
                      <Col><p>{formattedDate}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                      <Col><label>Name</label></Col>
                      <Col><p>{name}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                      <Col><label>Description</label></Col>
                      <Col><p>{description}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                      <Col><label>Regions</label></Col>
                      <Col><p>{regions}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                      <Col><label>Industry</label></Col>
                      <Col><p>{industry}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                      <Col><label>Status</label></Col>
                      <Col>
                        <Row className='user-list-button-row'>
                            {status}
                            {this.renderStatusButtons(application)}
                        </Row>
                      </Col>
                  </Row>
              </Col>
          </Row>
      </Col>
    )
  } // end of renderOrganizationApplication(application)

  renderStatusButtons = application => {
    const {
      status,
      userId,
      date,
      username
    } = application
    if (status === 'pending') {
      return (
        <React.Fragment>
          <Button
              id='application-approval'
              className='user-list-toggle-button'
              onClick={() => this.handleApprove(userId, date, username)}>
                Approve
          </Button>
          <Button
              id='application-deny'
              className='user-list-toggle-button'
              onClick={() => this.handleShowModal(application)}>
                Deny
          </Button>
        </React.Fragment>
      )
    }
  } // end of renderStatusButtons(application)

  renderReasonModal = () => {
    const {
      setShow,
      reason,
    } = this.state
    return (
      <Modal centered show={setShow} onHide={this.handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Please provide a reason:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { /* this will be a text input */ }
            <FormControl
                required
                as='textarea'
                name='reason'
                value={reason}
                onChange={this.handleFormChange}
            />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => this.handleDeny(e)}>Submit</Button>
        </Modal.Footer>
      </Modal>
    )
  } // end of renderReasonModal()

  renderIndividualApplication = application => {
    const {
      years_experience,
      description,
      linkedin,
      regions,
      industry,
      resume,
      sample,
      conflicts,
      username,
      date,
      contributor_type,
      status,
      name,
    } = application
    const formattedDate = this.formatDate(date)

    return (
      <React.Fragment>
          <Col className='user-list-users-container'>
              <Row noGutters>
                  <div
                      className='back-to-user-list-button'
                      onClick={this.handleBackToUserList}
                  >
                      <MdArrowBack />
                      Back to the User List
                  </div>
              </Row>
              <Row noGutters>
                  <Col className='user-list-selected-user-info-col'>
                      <Row className='user-list-selected-user-info-row'>
                          <Col><label>Username</label></Col>
                          <Col><p>{username}</p></Col>
                      </Row>
                      <Row className='user-list-selected-user-info-row'>
                          <Col><label>Name</label></Col>
                          <Col><p>{name}</p></Col>
                      </Row>
                      <Row className='user-list-selected-user-info-row'>
                          <Col><label>Type</label></Col>
                          <Col><p>{contributor_type}</p></Col>
                      </Row>
                      <Row className='user-list-selected-user-info-row'>
                          <Col><label>Date Submitted</label></Col>
                          <Col><p>{formattedDate}</p></Col>
                      </Row>
                      <Row className='user-list-selected-user-info-row'>
                          <Col><label>Years of Experience</label></Col>
                          <Col><p>{years_experience}</p></Col>
                      </Row>
                      <Row className='user-list-selected-user-info-row'>
                          <Col><label>LinkedIn</label></Col>
                          <Col><a href={linkedin} target='_blank'>{linkedin}</a></Col>
                      </Row>
                      <Row className='user-list-selected-user-info-row'>
                        <Col><label>Regions</label></Col>
                        <Col><p>{regions}</p></Col>
                      </Row>
                      <Row className='user-list-selected-user-info-row'>
                        <Col><label>Industry</label></Col>
                        <Col><p>{industry}</p></Col>
                      </Row>
                      <Row className='user-list-selected-user-info-row'>
                        <Col><label>Conflicts</label></Col>
                        <Col><p>{conflicts}</p></Col>
                      </Row>
                      <Row className='user-list-selected-user-info-row'>
                          <Col><label>Description</label></Col>
                          <Col><p>{description}</p></Col>
                      </Row>
                      <Row className='user-list-selected-user-info-row'>
                          <Col><label>Resume</label></Col>
                          <Col><a href={resume}>Click here to download</a></Col>
                      </Row>
                      <Row className='user-list-selected-user-info-row'>
                          <Col><label>Writing Sample</label></Col>
                          <Col><a href={sample}>Click here to download</a></Col>
                      </Row>
                      <Row className='user-list-selected-user-info-row'>
                          <Col><label>Status</label></Col>
                          <Col>
                            <Row className='user-list-button-row'>
                                {status}
                                {this.renderStatusButtons(application)}
                            </Row>
                          </Col>
                      </Row>
                  </Col>
              </Row>
          </Col>
      </React.Fragment>
    )
  } // end of renderIndividualApplication(application)

  renderLoading = () => (
    <Jumbotron className='loading-spinner-container'>
      <Spinner className='loading-spinner' animation="border" role="status" variant='primary'>
        <span className="sr-only">Loading...</span>
      </Spinner>
    </Jumbotron>
  ) // end of renderLoading()

  render() {
    const { application: { found } } = this.props
    return (
      <Container fluid className='user-lookup-container'>
        {this.renderReasonModal()}
        <Row noGutters className='user-list-top-row'>
          <Col>
            <h1>Contributor Applications List</h1>
            {this.renderContributorApplicationLookup()}
          </Col>
        </Row>
        <Row className='user-list-section'>
          {found ? this.renderApplicationDetails() : this.renderContributorApplicationList()}
        </Row>
      </Container>
    )
  } // end of render()

} // end of ContributorApplications class

export default ContributorApplications
