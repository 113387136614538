import React, { Component } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import ReactLoading from 'react-loading'

class BarChart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: this.props.data,
    }
  } // end of constructor(props)

  renderTooltip = e => {
    const assetType = e.data['Asset Type']
    const count = e.data.count

    return (
      <div className='chart-tooltip'>
        {assetType} - {count}
      </div>
    )
  }

  render() {
    const { data } = this.state
    const chartData = data.item

    const theme = {
      // background: "rgba(0, 0, 0, 0.3)",
      axis: {
        fontSize: "14px",
        tickColor: "#eee",
        ticks: {
          line: {
            stroke: "white"
          },
          text: {
            fill: "#ffffff"
          }
        },
        legend: {
          text: {
            fill: "white"
          }
        }
      },
      grid: {
        line: {
          stroke: "white"
        }
      }
    };

    const axisBottom = {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "Asset Type",
      legendPosition: "middle",
      legendOffset: 32
    };

    const axisLeft = {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "Count",
      legendPosition: "middle",
      legendOffset: -40
    };

    return (
      <React.Fragment>
        <ResponsiveBar
            data={data}
            keys={[ 'count' ]}
            indexBy="Asset Type"
            colors={{ scheme: 'category10' }}
            margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
            padding={0.5}
            axisTop={null}
            axisRight={null}
            axisLeft={axisLeft}
            axisBottom={axisBottom}
            labelTextColor='white'
            labelTextColor='white'
            tooltip={this.renderTooltip}
            theme={theme}
            animate={true}
        />
    </React.Fragment>
    )
  }
} // end of BarChart extends Component

export default BarChart
