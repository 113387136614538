import * as CONSTANTS from '../constants/tokenlist';

export const getTokenListRequest = () => ({
  type: CONSTANTS.GET_TOKEN_LIST_REQUEST,
});

export const getTokenListSuccess = tokenList => ({
  type: CONSTANTS.GET_TOKEN_LIST_SUCCESS,
  tokenList,
});

export const getTokenListError = error => ({
  type: CONSTANTS.GET_TOKEN_LIST_ERROR,
  error,
});

export const deleteTokenRequest = id => ({
  type: CONSTANTS.DELETE_TOKEN_REQUEST,
  id,
});

export const deleteTokenSuccess = result => ({
  type: CONSTANTS.DELETE_TOKEN_SUCCESS,
  result,
});

export const deleteTokenError = error => ({
  type: CONSTANTS.DELETE_TOKEN_ERROR,
  error,
});

export const getTokenDetailRequest = token_name => ({
  type: CONSTANTS.GET_TOKEN_DETAIL_REQUEST,
  token_name,
});

export const getTokenDetailSuccess = detail => ({
  type: CONSTANTS.GET_TOKEN_DETAIL_SUCCESS,
  detail,
});

export const getTokenDetailError = error => ({
  type: CONSTANTS.GET_TOKEN_DETAIL_ERROR,
  error,
});

export const getCommentsRequest = (id, commentType) => ({
  type: CONSTANTS.GET_COMMENTS_REQUEST,
  id,
  commentType,
});

export const getReplyCommentsSuccess = (comments, commentType) => ({
  type: CONSTANTS.GET_REPLY_COMMENTS_SUCCESS,
  comments,
  commentType,
});

export const getTokenCommentsSuccess = (comments, commentType) => ({
  type: CONSTANTS.GET_TOKEN_COMMENTS_SUCCESS,
  comments,
  commentType,
});

export const getCommentsError = error => ({
  type: CONSTANTS.GET_COMMENTS_ERROR,
  error,
});

export const postCommentsRequest = (
  resourceId,
  comment,
  tags,
  commentType,
) => ({
  type: CONSTANTS.POST_COMMENTS_REQUEST,
  resourceId,
  comment,
  tags,
  commentType,
});

export const postCommentsSuccess = comment => ({
  type: CONSTANTS.POST_COMMENTS_SUCCESS,
  comment,
});

export const postCommentError = err => ({
  type: CONSTANTS.POST_COMMENTS_ERROR,
  err,
});

export const getTokenNewsCommentRequest = token_name => ({
  type: CONSTANTS.GET_TOKEN_NEWS_REQUEST,
  token_name,
});

export const getTokenNewsCommentSuccess = comments => ({
  type: CONSTANTS.GET_TOKEN_NEWS_SUCCESS,
  comments,
});

export const getNewsCommentsSuccess = (comments, commentType) => ({
  type: CONSTANTS.GET_NEWS_COMMENTS_SUCCESS,
  comments,
  commentType,
});

export const getReportCommentsSuccess = (comments, commentType) => ({
  type: CONSTANTS.GET_REPORT_COMMENTS_SUCCESS,
  comments,
  commentType,
});

export const getReportReplyCommentSuccess = (comments, commentType) => ({
  type: CONSTANTS.GET_REPORT_REPLY_COMMENT_SUCCESS,
  comments,
  commentType,
});

export const getTokenNewsCommentError = error => ({
  type: CONSTANTS.GET_TOKEN_NEWS_ERROR,
  error,
});

export const searchTokensRequest = searchKey => ({
  type: CONSTANTS.SEARCH_TOKEN_REQUEST,
  searchKey,
});

export const searchTokenSuccess = tokenList => ({
  type: CONSTANTS.SEARCH_TOKEN_SUCCESS,
  tokenList,
});

export const searchTokenError = error => ({
  type: CONSTANTS.SEARCH_TOKEN_ERROR,
  error,
});

export const getSeeMoreCommentsRequest = (id, commentType, date) => ({
  type: CONSTANTS.GET_SEE_MORE_COMMENTS_REQUEST,
  id,
  commentType,
  date,
});

export const getSeeMoreCommentSuccess = (comments, commentType) => ({
  type: CONSTANTS.GET_SEE_MORE_COMMENTS_SUCCESS,
  comments,
  commentType,
});

export const getSeeMoreCommentError = error => ({
  type: CONSTANTS.GET_SEE_MORE_COMMENTS_ERROR,
  error,
});

export const getTokenDetailsRequest = tokenType => ({
  type: CONSTANTS.GET_TOKEN_DETAILS_REQUEST,
  tokenType,
});

export const getTokenDetailsSuccess = (tokenList, tokenType) => ({
  type: CONSTANTS.GET_TOKEN_DETAILS_SUCCESS,
  tokenList,
  tokenType,
});

export const getTokenDetailsError = error => ({
  type: CONSTANTS.GET_TOKEN_DETAILS_ERROR,
  error,
});

export const getMarketStatsRequest = () => ({
  type: CONSTANTS.GET_MARKET_STATS_REQUEST,
});

export const getMarketStatsSuccess = stats => ({
  type: CONSTANTS.GET_MARKET_STATS_SUCCESS,
  stats,
});

export const getMarketStatsError = () => ({
  type: CONSTANTS.GET_MARKET_STATS_ERROR,
});

export const getTokensByDateRequest = (date) => ({
  type: CONSTANTS.GET_TOKENS_BY_DATE_REQUEST,
  date,
});

export const getTokensByDateSuccess = tokens => ({
  type: CONSTANTS.GET_TOKENS_BY_DATE_SUCCESS,
  tokens,
});

export const getPressReleasesRequest = data => ({
    type: CONSTANTS.GET_PRESS_RELEASES_REQUEST,
    data,
})

export const getPressReleasesSuccess = pressReleases => ({
    type: CONSTANTS.GET_PRESS_RELEASES_SUCCESS,
    pressReleases,
})

/***************************** CHOROPLETH ACTIONS *****************************/
export const getChoroplethDataRequest = data => ({
    type: CONSTANTS.GET_CHOROPLETH_DATA_REQUEST,
    data,
})

export const getChoroplethDataSuccess = data => ({
    type: CONSTANTS.GET_CHOROPLETH_DATA_SUCCESS,
    data,
})

export const getChoroplethDataError = error => ({
    type: CONSTANTS.GET_CHOROPLETH_DATA_ERROR,
    error,
})
/************************* END OF CHOROPLETH ACTIONS **************************/


/***************************** PIE_CHART ACTIONS *****************************/
export const getPieChartDataRequest = () => ({
    type: CONSTANTS.GET_PIE_CHART_DATA_REQUEST,
})

export const getPieChartDataSuccess = data => ({
    type: CONSTANTS.GET_PIE_CHART_DATA_SUCCESS,
    data,
})

export const getPieChartDataError = error => ({
    type: CONSTANTS.GET_PIE_CHART_DATA_ERROR,
    error,
})
/**************************** END OF PIE_CHART ACTIONS ************************/


export const getTokenSpecificNewsRequest = tokenName => ({
    type: CONSTANTS.GET_TOKEN_SPECIFIC_NEWS_REQUEST,
    tokenName,
})

export const getTokenSpecificNewsSuccess = data => ({
    type: CONSTANTS.GET_TOKEN_SPECIFIC_NEWS_SUCCESS,
    data,
})

export const getTokenSpecificNewsError = error => ({
    type: CONSTANTS.GET_TOKEN_SPECIFIC_NEWS_ERROR,
    error,
})

export const getProfileFiltersRequest = assetType => ({
  type: CONSTANTS.GET_PROFILE_FILTERS_REQUEST,
  assetType
})

export const getProfileFiltersSuccess = data => ({
  type: CONSTANTS.GET_PROFILE_FILTERS_SUCCESS,
  data
})

export const getProfileFiltersError = error => ({
  type: CONSTANTS.GET_PROFILE_FILTERS_ERROR,
  error
})

export const getListOfAssetTypesRequest = () => ({
  type: CONSTANTS.GET_LIST_OF_ASSET_TYPES_REQUEST,
})

export const getListOfAssetTypesSuccess = data => ({
  type: CONSTANTS.GET_LIST_OF_ASSET_TYPES_SUCCESS,
  data,
})

export const getListOfAssetTypesError = error => ({
  type: CONSTANTS.GET_LIST_OF_ASSET_TYPES_ERROR,
  error,
})

export const getListOfAssetsRequest = params => ({
  type: CONSTANTS.GET_LIST_OF_ASSETS_REQUEST,
  params,
})

export const getListOfAssetsSuccess = data => ({
  type: CONSTANTS.GET_LIST_OF_ASSETS_SUCCESS,
  data,
})

export const getListOfAssetsError = error => ({
  type: CONSTANTS.GET_LIST_OF_ASSETS_ERROR,
  error,
})

export const getListOfFiltersForAssetTypeRequest = params => ({
  type: CONSTANTS.GET_LIST_OF_FILTERS_FOR_ASSET_TYPE_REQUEST,
  params
})

export const getListOfFiltersForAssetTypeSuccess = data => ({
  type: CONSTANTS.GET_LIST_OF_FILTERS_FOR_ASSET_TYPE_SUCCESS,
  data
})

export const getListOfFiltersForAssetTypeError = error => ({
  type: CONSTANTS.GET_LIST_OF_FILTERS_FOR_ASSET_TYPE_ERROR,
  error
})

export const resetListOfFiltersForAssetType = () => ({
  type: CONSTANTS.RESET_LIST_OF_FILTERS_FOR_ASSET_TYPE
})

export const getAssetsWithParamsRequest = params => ({
  type: CONSTANTS.GET_ASSETS_WITH_PARAMS_REQUEST,
  params
})

export const getAssetsWithParamsSuccess = data => ({
  type: CONSTANTS.GET_ASSETS_WITH_PARAMS_SUCCESS,
  data
})

export const getAssetsWithParamsError = error => ({
  type: CONSTANTS.GET_ASSETS_WITH_PARAMS_ERROR,
  error
})
