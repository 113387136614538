import { connect } from 'react-redux';
import ResearchApplication from './ResearchApplication';
import './ResearchApplication.css';
import captchaKeys from '../../../captchaKeys.js'
import {
  uploadDocumentRequest,
} from '../../../redux/actions/tokenform';
import { signupRequest } from '../../../redux/actions/signup';
import { loginRequest } from '../../../redux/actions/login';
import {
  contributorApplicationRequest,
  contributorApplicationDocumentUploadRequest,
} from '../../../redux/actions/account';

const mapStateToProps = state => ({
  accountInfo: state.account.myAccount.info,
  resumeUrl: state.token.resumeUrl.docURL,
  sampleUrl: state.token.sampleUrl.docURL,
  resume: state.account.contributorResume,
  sample: state.account.contributorSample,
  siteKey: captchaKeys.siteKey,
  secretKey: captchaKeys.secretKey,
});
const mapDispatchToProps = {
  uploadDocumentRequest,
  contributorApplicationRequest,
  contributorApplicationDocumentUploadRequest,
  signupRequest,
  loginRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResearchApplication);
