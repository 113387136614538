import * as CONSTANTS from '../../constants/discover';
import * as ACTIONS from '../../actions/discover';
import { put, takeLatest, call, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { history } from "../../../configureStore";
import request from "../../../utils/apiRequest";

function* getResearchDiscover(action) {
  try {
    let url = '/research/search?'
    if (action.params.q) url += `q=${action.params.q}&`

    const responseData = yield call(
      request,
      url,
      'GET',
    )

    yield put(ACTIONS.getResearchDiscoverSuccess(responseData))
  } catch (err) {
    yield put(ACTIONS.getResearchDiscoverError(err))
  }
}

export default function* researchsSaga() {
  yield takeLatest(CONSTANTS.GET_RESEARCH_DISCOVER_REQUEST, getResearchDiscover)
}
