import React, { Component } from 'react';
import { Button, Form, Row, Col, Jumbotron } from 'react-bootstrap';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

class AccountReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      code: '',
      email: '',
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { search } = location;
    const qs = queryString.parse(search);
    this.setState({
      code: qs.code,
      email: qs.email,
    });
  }

  componentWillUnmount() {
    this.props.resetPasswordHistory();
  }

  handleFormChange = fieldName => e => {
    this.setState({
      [fieldName]: e.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { code, email, password, confirmPassword } = this.state;
    const { resetPasswordRequest } = this.props;

    if (!code || !email) {
      toast.error('Please check the deeplink from your mail');
    } else if (password !== confirmPassword) {
      toast.error('Password does not match');
    } else {
      resetPasswordRequest(code, email, password, confirmPassword);
    }
  };

  renderResetPasswordForm = () => (
    <React.Fragment>
      <Form onSubmit={this.handleSubmit}>
        <Form.Group controlId="formResetPassword">
          <Form.Control
            className='pw-reset-form-control'
            type="password"
            placeholder="Password"
            onChange={this.handleFormChange('password')}
            required
          />
        </Form.Group>
        <Form.Group controlId="formResetConfirmPassword">
          <Form.Control
            className='pw-reset-form-control'
            type="password"
            placeholder="Confirm Password"
            onChange={this.handleFormChange('confirmPassword')}
            required
          />
        </Form.Group>
        <Row className='pw-button-row'>
          <Button variant="primary" type="submit" className='pw-confirm-button'>
            Update
          </Button>
        </Row>
      </Form>
    </React.Fragment>
  );

  renderSuccessResetPasswordForm = () => (
    <Row className='pw-reset-success'>
      <h5>
        Your password has been reset. Try <Link to="/login">logging in.</Link>
      </h5>
    </Row>
  );

  render() {
    const { resetPasswordSuccess } = this.props;

    return (
      <Row className="mt-5 pt-4 body-section-bottom pw-reset-row">
        <Col sm={4} />
        <Col sm={4} className='pw-reset-col'>
          <div className='pw-reset-box'>
            {resetPasswordSuccess
              ? this.renderSuccessResetPasswordForm()
              : this.renderResetPasswordForm()}
          </div>
        </Col>
        <Col sm={4} />
      </Row>
    );
  }
}

export default AccountReset;
