import { connect } from "react-redux"
import Reset from './Reset'
// import { resetRequest, resetReset } from '../../redux/actions/reset'
import {
  resetPasswordRequest,
  resetPasswordReset,
} from '../../redux/actions/forgotpassword'

const mapStateToProps = state => ({
  passwordSuccessfullyReset: state.forgot.passwordSuccessfullyReset,
})

const mapDispatchToProps = {
  resetPasswordRequest,
  resetPasswordReset,
}

export default connect(mapStateToProps, mapDispatchToProps)(Reset)
