import { connect } from 'react-redux'
import Research from './Research.js'
import {
  searchUsersRequest,
  searchUsersClear,
  searchReportsRequest,
  searchReportsClear,
} from '../../../redux/actions/admin'
import {
  deleteDraftRequest,
  unpublishReportRequest
} from '../../../redux/actions/researches'

const mapStateToProps = state => ({
  accountData: state.login.accountData,
  users: state.admin.users,
  reports: state.admin.reports,
})

const mapDispatchToProps = {
  searchUsersRequest,
  searchUsersClear,
  searchReportsRequest,
  searchReportsClear,
  deleteDraftRequest,
  unpublishReportRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Research)
