import React, { PureComponent } from 'react'
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  Spinner,
  Image,
  ListGroup,
  NavDropdown,
  Accordion,
  Dropdown,
} from 'react-bootstrap'
import { NavLink } from "react-router-dom"
import {
  AiOutlineFileSearch,
  AiOutlineFundView,
  AiOutlineTeam,
  AiOutlineWarning,
  AiOutlineReconciliation,
  AiOutlineSolution,
  AiOutlineUser,
  AiOutlineSearch,
  AiOutlineLogout,
  AiOutlineSetting,
  AiOutlineUnorderedList,
  AiOutlineGlobal
} from 'react-icons/ai'
import { FiSearch, FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import * as COMPONENT from './'
import { Switch, Route } from "react-router-dom"
import LogoImg from '../../images/HomePage/FI_LOGO.png'

class Admin extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      collapseSidebar: false,
      currentSection: 'dashboard',
      account: this.props.account,
      navbarLinks: this.props.adminTopbarLinks,
    }
  }

  componentDidMount() {
    this.handleFixPath()
    this.props.getLinksForAdminTopbarRequest()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        account: nextProps.account,
        navbarLinks: nextProps.adminTopbarLinks,
      })
    }
  }

  handleFixPath = () => {
    if (this.props.location.pathname == '/') {
      this.props.history.push('/admin')
    }
  }

  handleGetSectionFromUrl = () => {
    const { pathname } = this.props.history.location

    const section = pathname.split('/admin/')

    if (section[1]) {
      // if a section is in the url, select that section
      this.handleSelectSection(section[1])
    } else {
      // otherwise, set the current section to dashboard
      this.setState({ currentSection: 'dashboard' })
    }
  }

  handleSelectSection = section => {
    this.setState({ currentSection: section })
  }


  handleLogout = e => {
    e.preventDefault()
    this.props.logout()
  }

  handleToggleCollapseSidebar = () => {
    this.setState({
      collapseSidebar: !this.state.collapseSidebar
    })
  }

  /***************************************************************************/

  renderResearchAccordion = () => {
    return (
      <Dropdown className='admin-list-accordion'>
        <Dropdown.Toggle>
          <Row noGutters className='admin-list-accordion-title'>
            <AiOutlineFileSearch className='admin-list-item-icon'/>
            Research
          </Row>
        </Dropdown.Toggle>

        <Dropdown.Menu className='admin-list-accordion-collapse classify-onboarding'>
          <ul>
            <NavLink to='/admin/view-reports' className='admin-list-item-title'>
              <li>View Reports</li>
            </NavLink>

            <NavLink to='/admin/create-a-report' className='admin-list-item-title'>
              <li>Create a Report</li>
            </NavLink>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  renderAssetProfilesAccordion = () => {
    return (
      <Dropdown className='admin-list-accordion'>
        <Dropdown.Toggle>
          <Row noGutters className='admin-list-accordion-title'>
            <AiOutlineFundView className='admin-list-item-icon'/>
            Asset Profiles
          </Row>
        </Dropdown.Toggle>

        <Dropdown.Menu className='admin-list-accordion-collapse classify-onboarding'>
          <ul>
            <NavLink to='/admin' className='admin-list-item-title'>
              <li>Create an Asset Profile</li>
            </NavLink>

            <NavLink to='/admin/market-symbols' className='admin-list-item-title'>
              <li>Market Symbols</li>
            </NavLink>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  renderSidebar = () => {
    const { collapseSidebar, currentSection } = this.state

    let sidebarToggle
    if (collapseSidebar) {
      sidebarToggle = 'collapsed-sidebar'
    }

    return (
      <div className='admin-sidebar' id={sidebarToggle}>
        <Col>
          <Row noGutters className='admin-logo-row'>
            <NavLink to='/'>
              <Image className='admin-logo' src={LogoImg} />
            </NavLink>
          </Row>

          <Row noGutters className='admin-system-row'>
            <div className='system-header'>SYSTEM</div>
          </Row>

          <ListGroup variant='flush' className='admin-list-group'>
            <ListGroup.Item
              className='admin-list-item'
              active={currentSection === 'users'}>
              {this.renderAssetProfilesAccordion()}
            </ListGroup.Item>

            <ListGroup.Item
              className='admin-list-item'
              active={currentSection === 'asset-templates'}>
              <NavLink to='/admin/asset-templates' className='admin-list-item-title'>
                <Row noGutters>
                  <AiOutlineReconciliation className='admin-list-item-icon'/>
                  Asset Templates
                </Row>
              </NavLink>
            </ListGroup.Item>

            <ListGroup.Item
              className='admin-list-item'
              active={currentSection === 'user-management'}>
              <NavLink to='/admin/user-lookup' className='admin-list-item-title'>
                <Row noGutters>
                  <AiOutlineTeam className='admin-list-item-icon'/>
                  User Management
                </Row>
              </NavLink>
            </ListGroup.Item>

            <ListGroup.Item
              className='admin-list-item'
              active={currentSection === 'contributor-applications'}>
              <NavLink to={'/admin/website-config'} className='admin-list-item-title'>
                <Row noGutters>
                  <AiOutlineGlobal className='admin-list-item-icon'/>
                  Website Configuration
                </Row>
              </NavLink>
            </ListGroup.Item>

            <ListGroup.Item
              className='admin-list-item'
              active={currentSection === 'spam-reports'}>
              <NavLink to={window.location.pathname} className='admin-list-item-title'>
                <Row noGutters>
                  <AiOutlineWarning className='admin-list-item-icon'/>
                  Spam Reports
                </Row>
              </NavLink>
            </ListGroup.Item>
          </ListGroup>

          {this.renderSidebarToggleButton()}
        </Col>
      </div>
    )
  }

  renderTopbar = () => {
    const { account, navbarLinks } = this.state

    let displayName
    if (account.loaded) {
      displayName = account.data.first_name + ' ' + account.data.last_name
    }

    let displayNavbarLinks
    if (navbarLinks.loaded) {
      displayNavbarLinks = (
        <NavDropdown className='admin-topbar-navdropdown' title='Asset Builder'>
          {navbarLinks.data.map(link => this.renderNavbarLink(link))}
        </NavDropdown>
      )
    }

    const title = (
      <React.Fragment>
        {displayName}
        <AiOutlineUser />
      </React.Fragment>
    )

    return (
      <div className='admin-topbar'>
        <Row noGutters className='admin-topbar-row'>
          <div className='admin-search-container'>
            {displayNavbarLinks}
          </div>

          <NavDropdown className='admin-topbar-nav' title={title}>
            <NavDropdown.Item
              href='/'
              className='admin-topbar-login'>
              <AiOutlineUnorderedList />
              Portal
            </NavDropdown.Item>
            <NavDropdown.Item
              href='/admin'
              className='admin-topbar-login'>
              <AiOutlineSetting />
              Administration
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              className='admin-topbar-login'
              onClick={() => this.handleLogout()}>
              <AiOutlineLogout />
              Log out
            </NavDropdown.Item>
          </NavDropdown>
        </Row>
      </div>
    )
  }

  renderNavbarLink = link => {
    return (
      <NavDropdown.Item href={link.url} className='admin-topbar-navdropitem'>
        {link.name}
      </NavDropdown.Item>
    )
  }

  renderSidebarToggleButton = () => {
    const { collapseSidebar } = this.state

    let icon
    if (collapseSidebar) {
      icon = <FiChevronRight className='admin-sidebar-collapse-toggle-button' />
    } else {
      icon = <FiChevronLeft className='admin-sidebar-collapse-toggle-button' />
    }

    return (
      <Row noGutters className='admin-sidebar-collapse-toggle-row'>
        <div
          onClick={this.handleToggleCollapseSidebar}
          className='admin-sidebar-collapse-toggle-aura'>
          {icon}
        </div>
      </Row>
    )
  }

  render() {
    return (
      <Container fluid className='admin-container'>
        <Row noGutters>

          {this.renderSidebar()}

          <Col>
            {this.renderTopbar()}

            <div className='admin-content-pane'>
              <Route exact path='/admin' component={COMPONENT.AssetProfiles} />
              <Route exact path='/admin/user-lookup' component={COMPONENT.UserLookUp} />
              <Route exact path='/admin/asset-templates' component={COMPONENT.TokenTemplates} />
              <Route exact path='/admin/create-an-asset-profile' component={COMPONENT.AssetProfiles} />
              <Route exact path='/admin/market-symbols' component={COMPONENT.TradedSymbols} />
              <Route exact path='/admin/view-reports' component={COMPONENT.Research} />
              <Route exact path='/admin/create-a-report' component={COMPONENT.CreateReport} />
              <Route exact path='/admin/website-config' component={COMPONENT.WebsiteConfig} />
            </div>

          </Col>

        </Row>
      </Container>
    )
  }
}

export default Admin
