import { connect } from 'react-redux';
import UserLookUp from './UserLookUp';
import './UserLookUp.css';
import {
  searchUserRequest,
  resetSearchUser,
  changeAdminStatusRequest,
  changeContributorStatusRequest,
  getUserListRequest,
  clearUserListCache,
  adminSearchUsersRequest,
} from '../../../redux/actions/account';
const mapStateToProps = state => ({
  user: state.account.user,
  userList: state.account.userList,
  userSearch: state.account.userSearch,
});

const mapDispatchToProps = {
  searchUserRequest,
  resetSearchUser,
  changeAdminStatusRequest,
  changeContributorStatusRequest,
  getUserListRequest,
  clearUserListCache,
  adminSearchUsersRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserLookUp);
