import { put, takeLatest, call, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  GET_TOKEN_LIST_REQUEST,
  DELETE_TOKEN_REQUEST,
  GET_TOKEN_DETAIL_REQUEST,
  GET_COMMENTS_REQUEST,
  POST_COMMENTS_REQUEST,
  GET_TOKEN_NEWS_REQUEST,
  SEARCH_TOKEN_REQUEST,
  GET_SEE_MORE_COMMENTS_REQUEST,
  GET_TOKEN_DETAILS_REQUEST,
  GET_MARKET_STATS_REQUEST,
  GET_TOKENS_BY_DATE_REQUEST,
  GET_PRESS_RELEASES_REQUEST,
  GET_TOKEN_SPECIFIC_NEWS_REQUEST,
} from '../../constants/tokenlist';
import * as CONSTANTS from '../../constants/tokenlist'
import {
  getTokenListRequest,
  getTokenListSuccess,
  getTokenListError,
  deleteTokenError,
  getTokenDetailSuccess,
  getTokenDetailError,
  getCommentsRequest,
  getTokenCommentsSuccess,
  getReplyCommentsSuccess,
  getReportCommentsSuccess,
  getReportReplyCommentSuccess,
  getCommentsError,
  postCommentError,
  getTokenNewsCommentSuccess,
  getTokenNewsCommentError,
  getNewsCommentsSuccess,
  searchTokenSuccess,
  searchTokenError,
  getSeeMoreCommentError,
  getSeeMoreCommentSuccess,
  getTokenDetailsError,
  getTokenDetailsSuccess,
  getMarketStatsSuccess,
  getTokensByDateSuccess,
  getPressReleasesRequest,
  getPressReleasesSuccess,
  getTokenSpecificNewsRequest,
  getTokenSpecificNewsSuccess,
  getTokenSpecificNewsError,
} from '../../actions/tokenlist';
import * as ACTIONS from '../../actions/tokenlist'
import {
    GET_CHOROPLETH_DATA_REQUEST,
    GET_PIE_CHART_DATA_REQUEST,
} from '../../constants/charts';
import {
    getChoroplethDataRequest,
    getChoroplethDataSuccess,
    getChoroplethDataError,
    getPieChartDataRequest,
    getPieChartDataSuccess,
    getPieChartDataError,
} from '../../actions/charts';
import request from '../../../utils/apiRequest';

function* getTokenList(action) {
  try {
    const responseData = yield call(
        request,
        'tokens/list',
        'GET',
        null,
        true
    );
    yield put(getTokenListSuccess(responseData));
  } catch (err) {
    yield put(getTokenListError(err));
  }
}

function* deleteToken(action) {
  try {
    const { id } = action;
    const requestData = { id };

    yield call(request, 'admin/deleteToken', 'POST', requestData, true);
    yield put(getTokenListRequest());
  } catch (err) {
    // toast.error(err.reason);
    yield put(deleteTokenError(err));
  }
}

function* getTokenDetail(action) {
  try {
    const { token_name } = action;

    const responseData = yield call(
      request,
      `profiles/single?profileName=${token_name}`,
      'GET',
      null,
      true,
    );
    yield put(getTokenDetailSuccess(responseData));
  } catch (err) {

    // toast.error(err.reason);
    yield put(getTokenDetailError(err));
  }
}

function* postComments(action) {
  try {
    const { resourceId, comment, tags, commentType } = action;
    const requestData = { resourceId, comment, tags };
    yield call(request, `tokens/newComment`, 'POST', requestData, true);
    // toast.success('Success');
    yield put(getCommentsRequest(resourceId, commentType));
  } catch (err) {
    // toast.error(err.reason);
    yield put(postCommentError(err));
  }
}

function* getComments(action) {
  try {
    const { id, commentType, date } = action;
    const requestData = { resourceId: id };
    if (date && commentType !== 'news_comment') {
      requestData.date = date;
    }

    const responseData = yield call(
      request,
      `comments`,
      'GET',
      requestData,
      true,
    );

    if (commentType === 'reply_comment') {
      yield put(getReplyCommentsSuccess(responseData, commentType));
    } else if (commentType === 'token_comment') {
      yield put(getTokenCommentsSuccess(responseData, commentType));
    } else if (commentType === 'news_comment') {
      yield put(getNewsCommentsSuccess(responseData, commentType));
    } else if (commentType === 'report_comment') {
      yield put(getReportCommentsSuccess(responseData, commentType));
    } else if (commentType === 'report_reply_comment') {
      yield put(getReportReplyCommentSuccess(responseData, commentType));
    }
  } catch (err) {
    yield put(getCommentsError(err));
  }
}

function* getTokenComments(action) {
  try {
    const { token_name } = action;
    const requestData = { q: token_name };

    const responseData = yield call(
      request,
      'news/search',
      'GET',
      requestData,
      true,
    );
    yield put(getTokenNewsCommentSuccess(responseData));
  } catch (err) {
    yield put(getTokenNewsCommentError(err));
  }
}

function* getSearchTokenList(action) {
    const {
        industry,
        location,
        issuanceType,
        dealSize,
        q,
    } = action.searchKey.searchParams
    const offset = action.searchKey.offset

    try {
        const requestData = {
            industry: industry,
            location: location,
            issuanceType: issuanceType,
            dealSize: dealSize,
            q: q,
            offset: offset,
        }

        const responseData = yield call(
            request,
            `profiles/featured?`,
            'GET',
            requestData,
            true,
        )
        yield put(searchTokenSuccess(responseData))
    } catch (err) {
        yield put(searchTokenError(err))
    }
}

function* getSeeMoreComments(action) {
  try {
    const { id, commentType, date } = action;
    const requestData = { resourceId: id, date };

    const responseData = yield call(
      request,
      'comments',
      'GET',
      requestData,
      true,
    );
    yield put(getSeeMoreCommentSuccess(responseData, commentType));
  } catch (error) {
    yield put(getSeeMoreCommentError(error));
  }
}

function* getTokenDetails(action) {
  try {
    const { tokenType } = action;
    const requestData = { type: tokenType };
    const responseData = yield call(
      request,
      'tokens/category/type',
      'GET',
      requestData,
      true,
    );
    yield put(getTokenDetailsSuccess(responseData, tokenType));
  } catch (error) {
    yield put(getTokenDetailsError(error));
  }
}

function* getMarketStats(action) {
  try {
    const responseData = yield call(
      request,
      'marketdata/stats',
      'GET',
      null,
      true,
    );
    yield put(getMarketStatsSuccess(responseData));
  } catch (err) {
    // toast.error(err.reason);
  }
}

function* getTokensByDate(action) {
    const { date } = action
    try {
        const requestData = {
            date: date.selectedDate,
            offset: date.offset,
         }

        const responseData = yield call(
            request,
            `tokens/calendar?`,
            'GET',
            requestData,
            true,
        )
        yield put(getTokensByDateSuccess(responseData))
    } catch (err) {
        // toast.error(err.reason)
    }
}

function* getPressReleases(action) {
    const { data } = action
    try {
        const body = {
            offset: data || 0
        }
        const responseData = yield call(
            request,
            `tokens/press?`,
            'GET',
            body,
            true,
        )
        yield put(getPressReleasesSuccess(responseData))
    } catch (err) {
        // toast.error(err.reason)
    }
}

/**************************** CHOROPLETH WATCHERS *****************************/
function* getChoroplethData(action) {
    try {
        const responseData = yield call(
            request,
            'tokens/stats',
            'GET',
            null,
            true,
        )
        yield put(getChoroplethDataSuccess(responseData))
    } catch (err) {
        // toast.error(err.reason)
        yield put(getChoroplethDataError(err))
    }
} // end of getChoroplethData(action)
/************************ END OF CHOROPLETH WATCHERS **************************/


/**************************** PIE_CHART WATCHERS *****************************/
function* getPieChartData(action) {
    try {
        const responseData = yield call(
            request,
            'tokens/stats',
            'GET',
            null,
            true,
        )
        yield put(getPieChartDataSuccess(responseData))
    } catch (err) {
        // toast.error(err.reason)
        yield put(getPieChartDataError(err))
    }
} // end of getPieChartData(action)
/************************ END OF PIE_CHART WATCHERS ***************************/

function* getTokenSpecificNews(action) {
  try {
    const responseData = yield call(
      request,
      `/news/profileSearch?q=${action.tokenName}`,
      'GET',
    )

    yield put(getTokenSpecificNewsSuccess(responseData))
  } catch (err) {
    yield put(getTokenSpecificNewsError(err))
  }
}

function* getListOfAssetTypes(action) {
  try {
    const responseData = yield call(
      request,
      '/profiles/assetTypes',
      'GET',
    )

    yield put(ACTIONS.getListOfAssetTypesSuccess(responseData))
  } catch (err) {
    yield put(ACTIONS.getListOfAssetTypesError(err))
  }
}

function* getListOfAssets(action) {
  try {
    const { params } = action
    let queries = '/profiles/search?'
    if (params) {
      for (let [key, value] of Object.entries(params)) {
        queries = queries + `${key}=${value}&`
      }
    }

    const responseData = yield call(
      request,
      queries,
      'GET',
    )

    yield put(ACTIONS.getListOfAssetsSuccess(responseData))
  } catch (err) {
    yield put(ACTIONS.getListOfAssetsError(err))
  }
}

function* getListOfFiltersForAssetType(action) {
  try {
    let url = '/profiles/profileFilters?'
    if (action.params) {
      for (let [key, value] of Object.entries(action.params)) {
        url = url + `${key}=${value}&`
      }
    }

    const responseData = yield call(
      request,
      url,
      'GET',
    )

    console.log(responseData)

    yield put(ACTIONS.getListOfFiltersForAssetTypeSuccess(responseData))
  } catch (err) {
    yield put(ACTIONS.getListOfFiltersForAssetTypeError(err))
  }
}

function* getAssetsWithParams(action) {
  try {
    let url = '/profiles/search?'
    if (action.params) {
      for (let [key, value] of Object.entries(action.params)) {
        url = value ? url + `${key}=${value}&` : url
      }
    }

    const responseData = yield call(
      request,
      url,
      'GET',
    )

    yield put(ACTIONS.getAssetsWithParamsSuccess(responseData))
  } catch (err) {
    yield put(ACTIONS.getAssetsWithParamsError(err))
  }
}

export default function* tokenListSaga() {
  yield takeLatest(GET_TOKEN_LIST_REQUEST, getTokenList);
  yield takeLatest(DELETE_TOKEN_REQUEST, deleteToken);
  yield takeLatest(GET_TOKEN_DETAIL_REQUEST, getTokenDetail);
  yield takeLatest(GET_TOKEN_DETAILS_REQUEST, getTokenDetails);
  yield takeLatest(POST_COMMENTS_REQUEST, postComments);
  yield takeLatest(GET_COMMENTS_REQUEST, getComments);
  yield takeLatest(GET_TOKEN_NEWS_REQUEST, getTokenComments);
  yield takeLatest(SEARCH_TOKEN_REQUEST, getSearchTokenList);
  yield takeLatest(GET_SEE_MORE_COMMENTS_REQUEST, getSeeMoreComments);
  yield takeLatest(GET_MARKET_STATS_REQUEST, getMarketStats);
  yield takeLatest(GET_TOKENS_BY_DATE_REQUEST, getTokensByDate);
  yield takeLatest(GET_PRESS_RELEASES_REQUEST, getPressReleases);
  yield takeLatest(GET_CHOROPLETH_DATA_REQUEST, getChoroplethData)
  yield takeLatest(GET_PIE_CHART_DATA_REQUEST, getPieChartData)
  yield takeLatest(GET_TOKEN_SPECIFIC_NEWS_REQUEST, getTokenSpecificNews)
  yield takeLatest(CONSTANTS.GET_LIST_OF_ASSET_TYPES_REQUEST, getListOfAssetTypes)
  yield takeLatest(CONSTANTS.GET_LIST_OF_ASSETS_REQUEST, getListOfAssets)
  yield takeLatest(CONSTANTS.GET_LIST_OF_FILTERS_FOR_ASSET_TYPE_REQUEST, getListOfFiltersForAssetType)
  yield takeLatest(CONSTANTS.GET_ASSETS_WITH_PARAMS_REQUEST, getAssetsWithParams)
}
