import * as CONSTANTS from '../constants/suggestion';

export const postSuggestionRequest = (
  tokenName,
  issuanceStage,
  tokenDescription,
  email,
) => ({
  type: CONSTANTS.POST_SUGGESTION_REQUEST,
  token_name: tokenName,
  description: tokenDescription,
  issuance_stage: issuanceStage,
  email,
});

export const postSuggestionSuccess = response => ({
  type: CONSTANTS.POST_SUGGESTION_SUCCESS,
  response,
});

export const postSuggestionError = error => ({
  type: CONSTANTS.POST_SUGGESTION_ERROR,
  error,
});

export const getSuggestionListReqeust = () => ({
  type: CONSTANTS.GET_SUGGESTION_LIST_REQUEST,
});

export const getSuggestionListSuccess = list => ({
  type: CONSTANTS.GET_SUGGESTION_LIST_SUCCESS,
  list,
});

export const getSuggestionListError = error => ({
  type: CONSTANTS.GET_SUGGESTION_LIST_ERROR,
  error,
});

export const removeSuggestionReqeust = (date, id) => ({
  type: CONSTANTS.REMOVE_SUGGESTION_REQUEST,
  date,
  id,
});

export const removeSuggestionError = error => ({
  type: CONSTANTS.REMOVE_SUGGESTION_ERROR,
  error,
});
