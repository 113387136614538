import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Container, Button, Row, Col, Image, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/* Images sourced from Velocity Channel PSD renders */
import FeaturesImg1 from '../../images/HomePage/features-section-image-1.png'
import FeaturesImg2 from '../../images/HomePage/features-section-image-2.png'
import InfoImg1 from '../../images/HomePage/info-section-image-1.png'
import InfoImg2 from '../../images/HomePage/info-section-image-2.png'
import InfoImg3 from '../../images/HomePage/info-section-image-3.png'
import InfoImg4 from '../../images/HomePage/info-section-image-4.png'
import OpportunityImg1 from '../../images/HomePage/opportunity-section-image-1.png'
import OpportunityImg2 from '../../images/HomePage/opportunity-section-image-2.png'
import TimelineCopy1 from '../../images/HomePage/timeline-copy-1.png'
import TimelineCopy2 from '../../images/HomePage/timeline-copy-2.png'
import TimelineCopy3 from '../../images/HomePage/timeline-copy-3.png'
import TimelineCopy4 from '../../images/HomePage/timeline-copy-4.png'
import TimelineCopy5 from '../../images/HomePage/timeline-copy-5.png'
import PartnersLogo1 from '../../images/HomePage/partners-section-logo-1.png'
import { initGA, logPageView } from '../../utils/analytics'

/* Sidenav anchors */
let featuresAnchor = document.getElementsByClassName("features-anchor")
let infoAnchor = document.getElementsByClassName("info-anchor")
let opportunityAnchor = document.getElementsByClassName("opportunity-anchor")
let timelineAnchor = document.getElementsByClassName("timeline-anchor")
let partnersAnchor = document.getElementsByClassName("partners-anchor")

const applyNowForm = '/research/apply'


class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            cpassword: '',
            first: '',
            last: '',
            username: '',
        };
    }

    componentDidMount(){
        initGA();
        logPageView();
    }

    handleFormChange = fieldName => e => {
        this.setState({
            [fieldName]: e.target.value,
        });
    };

    handleSubmit = e => {
        e.preventDefault();

        const {
            email,
            password,
            cpassword,
            username,
            first,
            last,
        } = this.state;

        const { signupRequest } = this.props;

        if (password !== cpassword) {
            toast.error('password is not matched');
        } else {
            signupRequest(email, password, cpassword, username, first, last);
        }
    };

    renderHomeContainer = () => (
        <Container>
            <Row>
                <Col md={{ span: 7 }} className="header-section">
                    <div className="header-sub-title">
                        <h1>
                            Stay up to Date on the Latest in Tokenized
                            Securities
                        </h1>
                        <p>
                            Velocity Channel is an information portal providing comprehensive news and research on digital assets and blockchain, created to help investors and operators navigate the rapidly evolving digital asset ecosystem.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    );

    renderFeaturesContainer = () => (
        <Container>
            <div className="features-section">
                <div className="features-anchor"  />
                <h1>
                    Decentralized Research Community
                </h1>
                <Row className="images-row">
                    <ul>
                        <li>
                            <Image src={FeaturesImg1} className="feature-image" />
                        </li>
                        <li>
                            <Image src={FeaturesImg2} className="feature-image" />
                        </li>
                    </ul>
                </Row>
                <Row>
                    <Col md={{ span: 6 }} className="features-column">
                        <div className="features-contents">
                            <ul>
                                <li>
                                    Gain access to a decentralized community of
                                    researchers covering ICOs, STOs and digital
                                    assets across a wide variety of industries.
                                </li>
                                <li>
                                    Participate in a feedback based rewards system
                                    incentivizing high quality research.
                                </li>
                                <li>
                                    Robust vetting system on all contributors to
                                    ensure honest content and prevent conflicts of
                                    interest.
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={{ span: 6 }} className="features-column">
                        <div className="features-contents">
                            <ul>
                                <li>
                                    Join the community as a researcher and get paid
                                    for your work.
                                </li>
                                <li>
                                    Creative freedom to cover ICOs, issuance,
                                    trading, industry developments of choice.
                                </li>
                                <li>
                                    Feedback based payout scheme that rewards
                                    popular and high quality research.
                                </li>
                                <li>
                                    You retain full ownership of your content.
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <div className="center-aligned-button">
                  <Link to={applyNowForm}>
                    <Button type="submit" className="apply-now">
                      APPLY NOW
                    </Button>
                  </Link>
                </div>
            </div>
        </Container>
    )

    renderInfoContainer = () => (
        <Container>
            <div className="info-section">
                <div className="info-anchor"  />
                <h1>
                    24/7 News and Market Data
                </h1>
                <Row className="images-row">
                    <Col md={{ span: 3 }}>
                        <div className="image-block">
                            <Image src={InfoImg1} className="info-image" />
                        </div>
                    </Col>
                    <Col md={{ span: 3 }}>
                        <div className="image-block">
                            <Image src={InfoImg2} className="info-image" />
                        </div>
                    </Col>
                    <Col md={{ span: 3 }}>
                        <div className="image-block">
                            <Image src={InfoImg3} className="info-image" />
                        </div>
                    </Col>
                    <Col md={{ span: 3 }}>
                        <div className="image-block">
                            <Image src={InfoImg4} className="info-image" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 3 }} className="column">
                        <p>
                            Real time news feed
                        </p>
                    </Col>
                    <Col md={{ span: 3 }} className="column">
                        <p>
                            Curated news sources on digital assets
                        </p>
                    </Col>
                    <Col md={{ span: 3 }} className="column">
                        <p>
                            Advanced search and save capabilities
                        </p>
                    </Col>
                    <Col md={{ span: 3 }} className="column">
                        <p>
                            Lastest price data on digital assets with
                            secondary trading
                        </p>
                    </Col>
                </Row>
            </div>
        </Container>
    )

    renderOpportunityContainer = () => (
        <Container>
            <div className="opportunity-section">
                <div className="opportunity-anchor"  />
                <h1>
                    Investor Opportunity Network
                </h1>
                <Row className="images-row">
                    <Col md={{ span: 6 }}>
                        <div className="image-block">
                            <Image src={OpportunityImg1} className="opportunity-image" />
                        </div>
                    </Col>
                    <Col md={{ span: 6 }}>
                        <div className="image-block">
                            <Image src={OpportunityImg2} className="opportunity-image" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 6 }}>
                        <div className="opportunity-contents">
                            <h2>
                                Investors can:
                            </h2>
                            <ul>
                                <li>
                                    See lists of vetted investment opportunities
                                    based on their personalized investor profile.
                                </li>
                                <li>
                                    Communicate with token issuers.
                                </li>
                                <li>
                                    Get informed on where and how to participate in
                                    a wide selection of STOs.
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={{ span: 6 }} className="header-section-three">
                        <div className="opportunity-contents">
                            <h2>
                                Issuing teams can:
                            </h2>
                            <ul>
                                <li>
                                    Invite particular groups of investors to
                                    participate.
                                </li>
                                <li>
                                    Gain exposure for their product/digital asset.
                                </li>
                                <li>
                                    Disseminate information to investors directly
                                    on Velocity Channel.
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    )

    renderTimelineContainer = () => (
        <Container>
            <div className="timeline-section">
                <div className="timeline-anchor" />
                <h1>
                    Timeline
                </h1>
                <Row className="images-row">
                    <Col>
                        <div className="image-block">
                            <Image src={TimelineCopy1} className="timeline-image" />
                        </div>
                    </Col>
                    <Col>
                        <div className="image-block">
                            <Image src={TimelineCopy2} className="timeline-image" />
                        </div>
                    </Col>
                    <Col>
                        <div className="image-block">
                            <Image src={TimelineCopy3} className="timeline-image" />
                        </div>
                    </Col>
                    <Col>
                        <div className="image-block">
                            <Image src={TimelineCopy4} className="timeline-image" />
                        </div>
                    </Col>
                    <Col>
                        <div className="image-block">
                            <Image src={TimelineCopy5} className="timeline-image" />
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    )

    renderPartnersContainer = () => (
        <Container>
            <div className="partners-section">
                <div className="partners-anchor"  />
                <h1>
                    Our Partners
                </h1>
                <Row className="images-row">
                    <Col>
                        <div className="image-block">
                            <Image src={PartnersLogo1} className="partners-image" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 12 }} className="partners-content">
                        <p>
                            Velocity Ledger Technology
                        </p>
                    </Col>
                </Row>
            </div>
        </Container>
    )

    renderSidenav = () => (
        /*
            Sidenav allows users to navigate the HomePage by clicking on a
            specific section and immediately smooth-scrolling to that section.
            The top of the container should land exactly beneath the Navbar.

            The styling for the anchors is very important, and is detailed
            further in HomePage.css under any of the anchor profiles.
        */
        <ul className="sidenav-section">
            <div className="navigation-button" onClick={() => featuresAnchor[0].scrollIntoView(true)}>
                <a>
                    <span className="sidenav-text">RESEARCH COMMUNITY</span>
                    <span className="sidenav-icon" />
                </a>
            </div>
            <div className="navigation-button" onClick={() => infoAnchor[0].scrollIntoView(true)}>
                <a>
                    <span className="sidenav-text">NEWS & MARKET DATA</span>
                    <span className="sidenav-icon" />
                </a>
            </div>
            <div className="navigation-button" onClick={() => opportunityAnchor[0].scrollIntoView(true)}>
                <a>
                    <span className="sidenav-text">INVESTOR OPPORTUNITY</span>
                    <span className="sidenav-icon" />
                </a>
            </div>
            <div className="navigation-button" onClick={() => timelineAnchor[0].scrollIntoView(true)}>
                <a>
                    <span className="sidenav-text">TIMELINE</span>
                    <span className="sidenav-icon" />
                </a>
            </div>
            <div className="navigation-button" onClick={() => partnersAnchor[0].scrollIntoView(true)}>
                <a>
                    <span className="sidenav-text">PARTNERS</span>
                    <span className="sidenav-icon" />
                </a>
            </div>
        </ul>
    )

    render() {
        return (
            <React.Fragment>
                <div className="sidenav-container-homepage">
                    {this.renderSidenav()}
                </div>
                <div className="home-container">
                    <div className="landing-container">
                        {this.renderHomeContainer()}
                    </div>
                    <div className="features-container">
                        {this.renderFeaturesContainer()}
                    </div>
                </div>
                <div className="info-container">
                    {this.renderInfoContainer()}
                </div>
                <div className="opportunity-container">
                    {this.renderOpportunityContainer()}
                </div>
                <div className="timeline-container">
                    {this.renderTimelineContainer()}
                </div>
                <div className="partners-container">
                    {this.renderPartnersContainer()}
                </div>
            </React.Fragment>
        );
    }
} // end of class

export default HomePage;
