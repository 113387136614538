import { connect } from "react-redux";
import Contributors from "./Contributors";
import {
  getContributorsReqeust,
  featureContributorReqeust,
  getContributorsReportRequest,
  contributorSearchRequest
} from "../../../redux/actions/contributor";
const mapStateToProps = state => ({
  contributors: state.contributors.contributors.list,
  reports: state.contributors.reports
});
const mapDispatchToProps = {
  getContributorsReqeust,
  featureContributorReqeust,
  getContributorsReportRequest,
  contributorSearchRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contributors);
