import * as CONSTANTS from "../constants/discover";

const initialState = {
  researchDiscover: {
    data: [],
    loaded: false,
    loading: false,
    error: '',
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
  case CONSTANTS.GET_RESEARCH_DISCOVER_REQUEST: {
    return {
      ...state,
      researchDiscover: {
        ...state.researchDiscover,
        loaded: false,
        loading: true,
      }
    }
  }
  case CONSTANTS.GET_RESEARCH_DISCOVER_SUCCESS: {
    return {
      ...state,
      researchDiscover: {
        ...state.researchDiscover,
        data: action.data,
        loaded: true,
        loading: false,
      }
    }
  }
  case CONSTANTS.GET_RESEARCH_DISCOVER_ERROR: {
    return {
      ...state,
      researchDiscover: {
        ...state.researchDiscover,
        loaded: false,
        loading: false,
        error: action.error,
      }
    }
  }
  default:
    return state;
  }
}
