import { put, takeLatest, call } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import * as CONSTANTS from '../../constants/forgotpassword'
import * as ACTIONS from '../../actions/forgotpassword'
import request from '../../../utils/apiRequest'

function* forgotPassword(action) {
  try {
    const body = {
      email: action.params
    }
    const responseData = yield call(
      request,
      '/account/forgot',
      'POST',
      body,
    )

    yield put(ACTIONS.forgotPasswordSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.forgotPasswordError(err))
  }
}

function* resetPassword(action) {
  try {
    const responseData = yield call(
      request,
      '/account/reset',
      'POST',
      action.params
    )

    yield put(ACTIONS.resetPasswordSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.resetPasswordError(err))
  }
}

export default function* forgotPasswordSaga() {
  yield takeLatest(CONSTANTS.FORGOT_PASSWORD_REQUEST, forgotPassword)
  yield takeLatest(CONSTANTS.RESET_PASSWORD_REQUEST, resetPassword)
}
