import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import './index.css';

class Paginations extends Component {
  get pageCount() {
    const { total, perPage } = this.props;
    return Math.ceil(total / perPage);
  }

  render() {
    const { pageCount } = this;

    return (
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={5}
        pageRangeDisplayed={5}
        onPageChange={this.props.handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    );
  }
}

export default Paginations;
