import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Form, Row, Col, Jumbotron } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Contact.css";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      email: "",
      issue: "",
      contactRequest: false,
      submittedRequest: false,
    };
  }

  componentDidMount() {
    this.props.resetContact()
  }

  handleFormChange = fieldName => e => {
    this.setState({
      [fieldName]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { email, userName, issue } = this.state;
    const { contactRequest } = this.props;

    contactRequest(email, userName, issue);
    this.setState({ submittedRequest: true })
  };

  handleCancel = e => {
    this.setState({
      userName: "",
      email: "",
      issue: ""
    });
  };

  renderContactForm = () => (
    <Col className='contact-us-col'>
      <Row className='no-padding'>
        <Col className='research-application-form'>
          <Form.Group controlId="formBasicUsername" as={Row}>
            <Form.Control
              className="research-application-form-control"
              placeholder="Name"
              onChange={this.handleFormChange("userName")}
              value={this.state.userName}
            />
            <Form.Text className="text-muted" />
          </Form.Group>
          <Form.Group controlId="formBasicEmail" as={Row}>
            <Form.Control
              className="research-application-form-control"
              type="email"
              placeholder="Email"
              onChange={this.handleFormChange("email")}
              value={this.state.email}
            />
            <Form.Text className="text-muted" />
          </Form.Group>
        </Col>
        <Col md={1} />
        <Col className='no-padding contact-us-form research-application-form'>
          <Form.Group controlId="formBasicIssue">
            <Form.Control
              className="research-application-form-control"
              as="textarea"
              placeholder="Please describe your issue..."
              onChange={this.handleFormChange("issue")}
              value={this.state.issue}
              // required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className='no-padding contact-us-submit-row'>
        <Button
          onClick={this.handleSubmit}
          className='research-application-submit'
        >
          Submit
        </Button>
      </Row>
    </Col>
  )

  renderFormReceived = () => (
    <Col className='contact-us-col'>
      <Row className='no-padding contact-us-received-row'>
        Thank you for getting in touch with us! A Velocity Channel team member will reach out to you soon.
      </Row>
    </Col>
  )

  handleSwitch = () => {
    const { submittedRequest } = this.state
    if (!submittedRequest) {
      return this.renderContactForm()
    } else {
      return this.renderFormReceived()
    }
  }

  render() {
    return (
      <Jumbotron className="contact-us-jumbotron">
        <Row className='no-padding header-text-row'>
          <Col md={3} />
          <Col className='no-padding'>
            <h3 className="header-text">CONTACT US</h3>
          </Col>
          <Col md={3} />
        </Row>
        <Row className='no-padding'>
          <Col md={3} />
          {this.handleSwitch()}
          <Col md={3} />
        </Row>
      </Jumbotron>
    );
  }
}

export default Contact;
