import React, { PureComponent } from 'react'
import {
    Container,
    Row,
    Col,
    FormControl,
    Form,
    Jumbotron,
    Button,
    Image,
    Modal,
    Spinner,
} from 'react-bootstrap'
import './Research.css'
import ReactQuill, { Quill } from 'react-quill'
import Avatar from "react-avatar-edit"
import Files from "react-butterfiles"
import TagsInput from 'react-tagsinput'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'
import Preview from './Preview/Preview.js'
import { IoIosAdd } from "react-icons/io"
import { FaFileWord } from 'react-icons/fa'
import { GoPlus } from 'react-icons/go'
import { MdAddCircleOutline, MdDelete, MdModeEdit, MdSave, MdCancel, MdFileUpload,  } from 'react-icons/md'
import { ImageDrop } from 'quill-image-drop-module'
import Autosuggest from 'react-autosuggest';
// import { ImageResize } from 'quill-image-resize'

/******************************* QUILL CONSTANTS ******************************/

/*************************** END OF QUILL CONSTANTS ***************************/
let timeout

class Research extends PureComponent {
    constructor(props) {
        super(props)

        Quill.register('modules/imageDrop', ImageDrop)
        var bold = Quill.import('formats/bold')
        bold.tagName = 'b'
        Quill.register(bold, true)

        // Quill.register('modules/ImageResize', ImageResize)

        this.state = {
            userId: '',
            id: '',
            comments: '',
            title: '',
            titleHTML: '',
            subtitle: '',
            subtitleHTML: '',
            summary: '',
            summaryHTML: '',
            body: '',
            category: '',
            industry: '',
            tags: { values: [] },
            image: '',
            showPreview: false,
            currentlyDrafting: true,
            saving: false,
            unsavedImage: '',
            savedImage: '',
            src: '',
            doc: '',
            publishingArticle: false,
            drafts: [],
        }

        this.myImageInput = React.createRef()
        this.myDocumentInput = React.createRef()

        this.modules = {
          toolbar: [
            // [{ 'font': [] }],
            // [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline'],
            // ['blockquote', 'code-block'], // not working
            // [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            ['link', 'image'],
            // [{ 'header': [1, 2, 3, false] }],
            // [{ 'align': [] }],
            // [{ 'color': [] }, { 'background': [] }],
            ['clean'],
          ],
          imageDrop: {},
          clipboard: {
            matchVisual: true,
          },
          // imageResize: {
          //     modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
          // },
        }

        this.formats = [
            'font',
            'size',
            'bold', 'italic', 'underline',
            'blockquote', 'code-block',
            'indent',
            'link', 'image',
            // 'indent': '-1', 'indent': '+1',
            'list', 'bullet',
            'align',
            'color', 'background'
        ]

        this.handleRteChange = this.handleRteChange.bind(this)
    } // end of constructor(props)

    componentDidMount() {
      const { accountInfo: { userId } } = this.props
      this.setState({ userId })
      this.props.getUsersDraftsRequest(userId)
      // this auto-selects the title input
      // const title = document.getElementsByClassName('rte-content-title')
      // if (this.props.drafts.length > 0) {
      //   title[0].focus()
      //   title[0].select()
      // }
      this.props.clearArticleImage()
      this.props.clearDocumentData()
      this.props.getTopicsListRequest()
      this.props.saveDraftReset()
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps !== this.props) {
        if (nextProps.publishingArticle !== this.props.publishingArticle) {
          this.setState({publishingArticle: nextProps.publishingArticle})
        }
        // if (nextProps.drafts.length > 0) {
        //   let newestDraft = nextProps.drafts[0]
        //   this.handleSelectDraft(newestDraft)
        // }
        if (nextProps.articleImage.loaded && nextProps.articleImage.image !== this.state.image) {
          this.setState({image: nextProps.articleImage.image}, () => this.handleAutoSave())
        }
        if (nextProps.doc.loaded && nextProps.doc.data !== this.state.body) {
          this.setState({body: nextProps.doc.data}, () => this.handleAutoSave())
        }
        this.setState({saving: nextProps.saving})
      }
    }

/*********************** HANDLERS *********************************************/
    handleImageSelect = e => {
      if (e.target.files[0].size > 1000000) {
        alert('Image is too large')
        e.target.value = ''
      } else {
        this.props.articleImageUploadRequest(e.target.files[0])
      }
    } // end of handleImageSelect(e)

    handleDocumentSelect = e => {
      // if (e.target.files[0].size > 1000000) {
      //   alert('Document is too large')
      //   e.target.value = ''
      // } if (e.target.files[0].type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      //   alert('Please upload a Word (.docx) document')
      //   e.target.value = ''
      // } else {
      // }
      this.props.uploadDocumentRequest(e.target.files[0])
    } // end of handleDocumentSelect(e)

    handleClearImage = () => {
      this.props.clearArticleImage()
      this.setState({image: null})
    } // end of handleClearImage()

    handleClearDoc = () => {
      this.props.clearDocumentData()
      this.setState({doc: null})
    } // end of handleClearDoc()

    handleSelectNewestDraft = () => {
      const { drafts } = this.props
      let newestDraft, newestDraftId
      if (this.props.newestDraft) {
        if (this.props.newestDraft.data.id && this.props.drafts.length > 0) {
          newestDraftId = this.props.newestDraft.data.id
          let newestDraft = drafts.find(function(draft) { return draft.id === newestDraftId })
          this.handleSelectDraft(newestDraft)
        }
      }
    } // end of handleSelectNewestDraft()

    handleFindDraft = () => {
      const { drafts } = this.props
      let test = drafts.find(draft => this.handleTest(draft))
      this.handleSelectDraft(test)
    } // end of handleFindDraft()

    handleTest = draft => {
      return draft.id === this.props.newestDraft.data
    }

    handleRteChange = (content, delta, source, editor) => {
        this.setState({
            body: editor.getHTML()
        })
    } // end of handleRteChange()

    handleTitleChange = (content, delta, source, editor) => {
        this.setState({
            body: editor.getHTML()
        })
    } // end of handleRteChange()

    handleFormChange = e => {
        const { name, value } = e.target
        this.setState({ [name]:  value },
        () => this.handleAutoSave())
    } // end of handleFormChange(e)

    handleShowPreview = () => {
        this.setState(prevState => ({
            showPreview: !prevState.showPreview
        }))
    } // end of handleShowPreview()

    handleChangeTags = (tags) => {
        this.setState({ tags: { values: tags } },
          () => this.handleAutoSave())
    } // end of handleChangeTags()

    handleCreateNewReport = () => {
        this.props.createDraftRequest()
        this.setState(prevState => ({
            currentlyDrafting: true
        }), () => this.handleSelectNewestDraft())
    }

    handleSaveDraft = () => {
      this.props.clearArticleImage()
      this.props.clearDocumentData()
      this.props.saveDraftRequest(this.state)
    } // end of handleSaveDraft

    handleSelectDraft = (draft) => {
      if (draft) {
        this.setState({
          id: draft.id ? draft.id : '',
          title: draft.title ? draft.title : '',
          summary: draft.summary ? draft.summary : '',
          body: draft.body ? draft.body : '',
          category: draft.category ? draft.category : '',
          industry: draft.industry ? draft.industry : '',
          image: draft.image ? draft.image : null,
          tags: draft.tags ? { values: draft.tags } :  { values: [] },
        })
      }
    } // end of handleSelectDraft(draft)

    handleDeleteDraft = (id, userId) => {
        this.props.deleteDraftRequest(id, userId)
    } // end of handleDeleteDraft(id, userId)

    formatDate = dateInput => {
      let date = new Date(dateInput)
      const month = date.toLocaleString("en-us", { month: "short" })
      const day = date.getDate()
      return month + " " + day
    } // end of formatDate(dateInput)

    handleAutoSave = timeout => {
      clearTimeout(timeout)
      timeout = setTimeout(this.handleSaveDraft, 1500)
    } // end of handleAutoSave()

    handlePublish = () => {
      this.props.publishArticleRequest(this.state)
    } // end of handlePublish()

    truncateTitle = string => {
      const length = 23
      const ending = "..."
      if (string && string !== null && string.length > length) {
        return string.substring(0, length - ending.length) + ending
      } else {
        return string
      }
    } // end of truncateTitle

    handleAutoSuggestRenderInput = ({addTag, ...props}) => {
      if (this.props.topics) {
        if (this.props.topics.loaded) {
          const { list } = this.props.topics
          const handleOnChange = (e, {newValue, method}) => {
            if (method === 'enter') {
              e.preventDefault()
            } else {
              props.onChange(e)
            }
          }

          const inputValue = (props.value && props.value.trim().toLowerCase()) || ''
          const inputLength = inputValue.length

          let suggestions = list.filter((topic) => {
            return topic.topic.toLowerCase().slice(0, inputLength) === inputValue
          })

          return (
            <Autosuggest
              ref={props.ref}
              suggestions={suggestions}
              shouldRenderSuggestions={(value) => value && value.trim().length > 0}
              getSuggestionValue={(suggestion) => suggestion.topic}
              renderSuggestion={(suggestion) => <span>{suggestion.topic}</span>}
              inputProps={{...props, onChange: handleOnChange}}
              onSuggestionSelected={(e, {suggestion}) => {addTag(suggestion.topic)}}
              onSuggestionsClearRequested={() => {}}
              onSuggestionsFetchRequested={() => {}}
            />
          )
        }
      }
    } // end of handleAutoSuggestRenderInput

/*********************** END OF HANDLERS **************************************/


/*********************** RENDERS **********************************************/
    renderResearchFormTopBar = () => {
        return (
            <Row className='research-form-top-bar-row'>
              <Col className='research-form-saving-col'>
                {this.renderSavingMessage()}
              </Col>
              <Col className='research-form-buttons-col'>
                {this.renderSaveOrPublish()}
                {this.renderTopBarButtons()}
              </Col>
            </Row>
        )
    } // end of renderResearchFormTopBar()

    renderSavingMessage = () => {
      if (this.state.saving) {
        return <div className='saving-message'>Saving...</div>
      }
    } // end of renderSavingMessage()

    renderTopBarButtons = () => {
        const {
            showPreview,
            currentlyDrafting,
        } = this.state

        return (
          <Button className='research-top-bar-button' id={showPreview ? 'preview-button' : null} onClick={this.handleShowPreview}>
              {showPreview ? 'Continue' : 'Preview'}
          </Button>
        )
    } // end of renderTopBarButtons()

    renderSaveOrPublish = () => {
      const {
        showPreview,
        currentlyDrafting,
      } = this.state

      const saveButton = (
        <React.Fragment>
          <MdSave className='research-save-icon' />
          Save
        </React.Fragment>
      )

      return (
        <Button className='research-top-bar-button' id={showPreview ? 'preview-button' : null} onClick={showPreview ? this.handlePublish : this.handleSaveDraft}>
            {showPreview ? 'Publish' : saveButton}
        </Button>
      )
    } // end of renderSaveOrPublish()

    renderDraftsCol = () => {
      const { showPreview } = this.state
      if (showPreview) {
        return <Col md={{ span: 2 }} />
      } else {
        return (
          <Col md={{ span: 2 }} className='research-form-drafts-section'>
              {this.renderUserDraftsSection()}
          </Col>
        )
      }
    } //end of renderDraftsCol()

    renderCriteriaCol = () => {
      const { showPreview } = this.state
      if (showPreview) {
        return <Col md={{ span: 2 }} />
      } else if (!this.state.id) {
        return (
          <Col md={{ span: 2 }} className='research-form-criteria-section'>

          </Col>
        )
      } else {
        return (
          <Col md={{ span: 2 }} className='research-form-criteria-section'>
              {this.renderCriteriaSection()}
          </Col>
        )
      }
    } //end of renderCriteriaCol()

    renderResearchForm = () => {
      const { drafts } = this.props
      if (!this.state.id) {
        return (
          <Row className='research-form-top-row'>
            {this.renderDraftsCol()}
            <Col md={{ span: 8 }} className='research-form-rte-section'>
              <Row className='no-padding rte-no-drafts'>
                <Button className='rte-no-drafts-button' onClick={this.handleCreateNewReport}>
                  <IoIosAdd className='rte-no-drafts-add-icon' />
                  Click here to create a draft
                </Button>
              </Row>
            </Col>
            {this.renderCriteriaCol()}
          </Row>
        )
      } else {
        return (
          <Row className='research-form-top-row'>
            {this.renderDraftsCol()}
            <Col md={{ span: 8 }} className='research-form-rte-section'>
              {this.renderResearchFormTopBar()}
              {this.renderFormOrPreview()}
            </Col>
            {this.renderCriteriaCol()}
          </Row>
        )
      }
    } // end of renderResearchForm()

    renderUserDraftsSection = () => {
        const { drafts } = this.props
        return (
            <React.Fragment>
                <Row className='user-drafts-section-top-row'>
                  <Col sm={2} />
                  <Col><h4>Your Drafts</h4></Col>
                  <Col sm={2}>
                    <GoPlus className='drafts-create-icon' size='2em' onClick={this.handleCreateNewReport} />
                  </Col>
                </Row>
                <Row className='user-drafts-section-bottom-row'>
                    <Col className='user-drafts-col'>
                        {drafts.length > 0 ? drafts.map(draft => this.renderDrafts(draft)) : 'No Drafts'}
                    </Col>
                </Row>
            </React.Fragment>
        )
    } // end of renderUserDraftsSection()

    renderDrafts = (draft) => {
        const {
            title,
            id,
        } = draft
        const {
            userId
        } = this.props.accountInfo
        const truncatedTitle = this.truncateTitle(title)

        if (this.state.id === id) {
          return (
            <Row key={id} className='single-draft-selected' onClick={() => this.handleSelectDraft(draft)}>
                <Col className='draft-col' md={{ span: 9 }} xs={{ span: 9 }}>
                    <div className='single-draft-title' >
                        {truncatedTitle ? truncatedTitle : 'Untitled Draft'}
                    </div>
                </Col>
                <Col className='buttons-col' md={{ span: 3 }} xs={{ span: 3 }}>
                    <Row>
                        <MdModeEdit className='single-draft-button' onClick={() => this.handleSelectDraft(draft)} />
                        <MdDelete className='single-draft-button' onClick={() => this.handleDeleteDraft(id, userId)} />
                    </Row>
                </Col>
            </Row>
          )
        } else {
          return (
            <Row key={id} className='single-draft' onClick={() => this.handleSelectDraft(draft)}>
              <Col className='draft-col' md={{ span: 9 }} xs={{ span: 9 }}>
                <div className='single-draft-title'>
                  {truncatedTitle ? truncatedTitle : 'Untitled Draft'}
                </div>
              </Col>
              <Col className='buttons-col' md={{ span: 3 }} xs={{ span: 3 }}>
                <Row>
                  <MdModeEdit className='single-draft-button' onClick={() => this.handleSelectDraft(draft)} />
                  <MdDelete className='single-draft-button' onClick={() => this.handleDeleteDraft(id, userId)} />
                </Row>
              </Col>
            </Row>
          )
        }
    } // end of renderSingleDraft()

    renderRteSection = () => {
        const {
            title,
            summary,
            body,
        } = this.state
        const {
          image,
          first,
          last,
        } = this.props.accountInfo

        const date = new Date()
        const formattedDate = this.formatDate(date)

        return (
          <Row className='rte-container'>
            <Form>
              <Row className='rte-title-row'>
                <h1>
                  <FormControl
                    className='rte-content-title'
                    name='title'
                    placeholder='Title...'
                    onChange={this.handleFormChange}
                    value={title}
                    autoComplete='off'
                    onKeyUp={this.handleAutoSave}
                  />
                </h1>
              </Row>
              <Row className='rte-summary-row'>
                <h2>
                  <FormControl
                    className='rte-content-summary'
                    name='summary'
                    placeholder='Summary...'
                    onChange={this.handleFormChange}
                    value={summary}
                    autoComplete='off'
                    onKeyUp={this.handleAutoSave}
                  />
                </h2>
              </Row>
              <Row className='rte-quill-row'>
                {this.renderRichTextEditor()}
              </Row>
            </Form>
          </Row>
        )
    } // end of renderRteForm()

    renderCriteriaSection = () => {
        const {
            category,
            industry,
            tags,
        } = this.state
        const { articleImage: {image, loaded} } = this.props

        return (
            <Form className='research-form-criteria'>
                <Form.Group as={Col} className='research-form-tags-column'>
                    <Form.Label className='research-label'>Add/change topics (up to 5)</Form.Label>
                    <div className='research-form-tags-container'>
                        {this.renderTagsInput()}
                    </div>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label className='research-label'>Categorize your content</Form.Label>
                    <Form.Control
                        name='category'
                        as='select'
                        onChange={this.handleFormChange}
                        value={category}
                    >
                        <option value=''>Category</option>
                        <option>STO Analysis</option>
                        <option>Macro Trends</option>
                        <option>Technology and Protocol Analysis</option>
                        <option>Trading + TA</option>
                        <option>Legal + Regulation</option>
                        <option>Strategy + Advisory</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label className='research-label'>Select an industry</Form.Label>
                    <Form.Control
                        name='industry'
                        as='select'
                        onChange={this.handleFormChange}
                        value={industry}
                    >
                        <option value="">Industry</option>
                        <option>Art</option>
                        <option>Banking</option>
                        <option>Blockchain</option>
                        <option>Cryptocurrency</option>
                        <option>Custody</option>
                        <option>Energy</option>
                        <option>Entertainment</option>
                        <option>Financial Tech</option>
                        <option>Gaming</option>
                        <option>Healthcare</option>
                        <option>Investment Fund</option>
                        <option>Legal</option>
                        <option>Manufacturing</option>
                        <option>Real Estate</option>
                        <option>Retail</option>
                        <option>Software/Technology</option>
                        <option>Sports</option>
                        <option>Supply Chain</option>
                        <option>Travel</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='research-label'>Upload a cover image</Form.Label>
                  <div className='uploaded-image-div'>
                    {this.renderImageUploader()}
                  </div>
                  <input ref={(ref) => this.myImageInput = ref} style={{ display: 'none' }} type='file' onChange={this.handleImageSelect} />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='research-label'>Import text from .doc or .docx file</Form.Label>
                  <Form.Label className='research-label-italic'>(this will replace the body of your report)</Form.Label>
                  <div className='uploaded-image-div'>
                    {this.renderDocumentUploader()}
                  </div>
                  <input ref={(ref) => this.myDocumentInput = ref} style={{ display: 'none' }} type='file' onChange={this.handleDocumentSelect} />
                </Form.Group>
            </Form>
        )
    } // end of renderCriteriaSection()

    renderImageUploader = () => {
      const { image } = this.state
      // const { loaded, image } = this.props.articleImage
      if (image) {
        return (
          <div className='uploaded-image-container'>
            <Image className='uploaded-report-image' src={image} />
            <MdCancel className='md-cancel' onClick={this.handleClearImage}/>
          </div>
        )
      } else {
        return <div onClick={(e) => this.myImageInput.click()} className='no-article-image'>Select an image</div>
      }
    } // end of renderImageUploader()

    renderTagsInput = () => {
      const { tags: { values } } = this.state
      const value = values && Array.isArray(values) ? values : [values]
      return (
        <TagsInput
          value={value}
          onChange={this.handleChangeTags}
          onlyUnique={true}
          maxTags={5}
          renderInput={this.handleAutoSuggestRenderInput}
          inputProps={{
            className: 'react-tagsinput-input',
            placeholder: 'Add a topic'
          }}
        />
      )
    } // end of renderTagsInput

    renderDocumentUploader = () => {
      return <div onClick={(e) => this.myDocumentInput.click()} className='no-article-image'>Browse files</div>
    } // end of renderDocumentUploader()

    renderRichTextEditor = () => {
      const { body } = this.state

      return (
        <ReactQuill
          className='rte-quill'
          onChange={this.handleRteChange}
          modules={this.modules}
          formats={this.formats}
          value={body || ''}
          placeholder='Start writing your report...'
          onKeyUp={() => this.handleAutoSave(timeout)}
        />
      )
    } // end of renderRichTextEditor()

    renderPublishingArticleModal = () => {
      return (
        <Modal className='publishing-article-modal' show={this.state.publishingArticle}>
          <Modal.Body>
            Please wait while we publish your article
            {this.renderLoading()}
          </Modal.Body>
        </Modal>
      )
    }

    renderFormOrPreview = () => {
        const {
            showPreview,
            title,
            summary,
            body,
            image,
            tags,
            category
        } = this.state
        const { accountInfo } = this.props

        if (showPreview) {
          return (
            <Preview
              title={title}
              summary={summary}
              body={body}
              accountInfo={accountInfo}
              image={image}
              tags={tags}
              category={category}
            />
          )
        } else {
          return this.renderRteSection()
        }
    } // end of renderFormOrPreview()

    renderLoading = () => (
      <Jumbotron className='loading-spinner-container'>
        <Spinner className='loading-spinner' animation="border" role="status" variant='primary'>
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Jumbotron>
    ) // end of renderLoading()

    render() {
      const {
          showPreview,
          title,
          summary,
          body,
          image,
          tags,
          category
      } = this.state
      const {
          accountInfo
      } = this.props

      if (!showPreview) {
        return (
          <Container fluid className='content-pane-container'>
            {this.renderResearchForm()}
          </Container>
        )
      } else {
        return (
          <Container fluid className='content-pane-container'>
            {this.renderPublishingArticleModal()}
            <Row className='research-form-top-bar-buttons-row' id={this.state.showPreview ? 'showing-preview' : null}>
              <Col sm={2} />
              <Col className='research-form-top-bar-buttons-col'>
                {this.renderResearchFormTopBar()}
              </Col>
              <Col sm={2} />
            </Row>
            <Row className='preview-article-row'>
              <Preview
                  title={title}
                  summary={summary}
                  body={body}
                  accountInfo={accountInfo}
                  image={image}
                  tags={tags}
                  category={category}
                  accountInfo={accountInfo}
              />
            </Row>
          </Container>
        )
      }
    } // end of render()
/*********************** END OF RENDERS ***************************************/
} // end of Class Research

export default Research
