import React, { PureComponent } from "react";
import Avatar from "react-avatar-edit";
import Modal from "react-bootstrap/Modal";
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  Button,
  Jumbotron,
  Form,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { IoIosAddCircle, IoMdRemoveCircle } from "react-icons/io";
import ReactQuill from "react-quill";
import DatePicker from "react-date-picker";
import { v1, v4 } from "uuid";
import defaultAvatarImage from '../../images/logo-hi-res.png'

class AccountForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
        id: this.props.accountInfo.id,
        first: this.props.accountInfo.first,
        last: this.props.accountInfo.last,
        email: this.props.accountInfo.email,
        username: this.props.accountInfo.username,
        address: this.props.accountInfo.address,
        image: this.props.accountInfo.image,
        savedImage: true,
        showButton: false,
        file: {},
        preview: null,
        savedImage: false,
        src: "",
        showModal: false,
        editedField: "",
        selectedField: "",
        changeImage: false,
    };
  }

  componentDidMount() {
    // console.log(this.props)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      // console.log(nextProps.accountInfo.image, this.props.accountInfo.image)

    }
  }

/******************************* HELPERS **************************************/
  onBeforeFileLoad(elem) {
    if(elem.target.files[0].size > 1000000){
      alert("File is too big!");
      elem.target.value = "";
    };
  }

  onClose = () => {
    this.setState({
      preview: null,
      savedImage: false,
      showButton: false,
      file: {},
    });
  };

  handleFormChange = e => {
      const { name, value } = e.target
      this.setState({
          [name]:  value,
      })
  } // end of handleFormChange(e)

  onCrop = preview => {
    this.setState({ showButton: true });
  };

  onFileLoad = file => {
    this.setState({ file, preview: file });
  };

  onSaveImage = () => {
    const { file } = this.state;
    if (file.name) {
      this.setState({
        savedImage: true,
        showButton: false,
        changeImage: false,
      })
      this.props.profileImageUploadRequest(file);
    } else {
      this.setState({
        showButton: false,
        changeImage: false,
      })
    }
  }

  saveEditedAccount = (info) => {
    this.props.editAccountRequest(info)
    this.props.getMyAccountRequest();
    this.props.handleEditForm()
  };

  handlePreviewImage = (previewImg) => {
     return previewImg ? this.state.preview : this.state.image
  }

  handleClickChangeImage = () => {
      this.setState(prevState => ({
          changeImage: !prevState.changeImage
      }))
  } // end of handleClickChangeImage()

  handleOnImageError = e => {
      e.target.src = defaultAvatarImage
  } // end of handleOnImageError()
/******************************* END OF HELPERS *******************************/


/******************************* RENDERS **************************************/
  renderImageUploader = () => {
    const { savedImage, changeImage } = this.state
    const previewImage = this.handlePreviewImage(this.state.preview)

    if (changeImage) {
        return (
            <React.Fragment>
                <Row className='image-uploader-row'>
                    <div className='avatar-container'>
                        <Avatar
                            className='avatar'
                            width={300}
                            height={300}
                            imageWidth={300}
                            onCrop={this.onCrop}
                            onClose={this.onClose}
                            src={this.state.src}
                            onFileLoad={this.onFileLoad}
                            onBeforeFileLoad={this.onBeforeFileLoad}
                        />
                    </div>
                </Row>
                <Row className='save-image-button-row'>
                    <Button className="save-image" onClick={() => this.onSaveImage()}>
                        Confirm
                    </Button>
                </Row>
            </React.Fragment>
        )
    } else if (!changeImage) {
        return (
            <React.Fragment>
                <Row className='image-uploader-row'>
                    <div className='image-uploader-container'>
                        <img
                            className="uploaded-image"
                            src={previewImage}
                            alt="Preview"
                            onError={this.handleOnImageError}
                        />
                        <div
                            className="image-overlay"
                            onClick={() => {
                                this.handleClickChangeImage();
                            }}
                        >
                            <div className='image-overlay-text'>
                                Change Image?
                            </div>
                        </div>
                    </div>
                </Row>
            </React.Fragment>
        )
    }
  } // end of renderImageUploader()

  render() {
    const { first, last, email, username, address, image } = this.state;
    return (
        <Form>
            <Row className='account-info'>
                <Col md={{ span: 4 }} className='image-uploader'>
                    {this.renderImageUploader()}
                </Col>
                <Col sm={{ span: 8 }} className='my-account-info-column'>
                    <Row className="my-account-info-top">
                        <Col sm={3}>Name</Col>
                        <Col sm={4} className='my-account-edit-form-control-col'>
                            <Form.Control
                                name="first"
                                onChange={this.handleFormChange}
                                value={first}
                                placeholder='First Name...'
                            />
                        </Col>
                        <Col sm={5}>
                            <Form.Control
                                name="last"
                                onChange={this.handleFormChange}
                                value={last}
                                placeholder='Last Name...'
                            />
                        </Col>
                    </Row>
                    <Row className="my-account-info-inner">
                        <Col sm={3}>User Name</Col>
                        <Col sm={9}>
                            <Form.Control
                                name="username"
                                onChange={this.handleFormChange}
                                value={username}
                            />
                        </Col>
                    </Row>
                    <Row className="my-account-info-inner">
                        <Col sm={3}>Email</Col>
                        <Col sm={9}>
                            <Form.Control
                                name="email"
                                onChange={this.handleFormChange}
                                value={email}
                            />
                        </Col>
                    </Row>
                    <Row className='account-form-button-row'>
                        <Button className="save-image" onClick={() => this.saveEditedAccount(this.state)}>
                            Save
                        </Button>{" "}
                    </Row>
                </Col>
            </Row>
        </Form>
    );
  }
/******************************* RENDERS **************************************/
}

export default AccountForm;
