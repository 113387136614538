import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Jumbotron,
  Spinner,
} from 'react-bootstrap'
import MetaTags from 'react-meta-tags'
import LogoImg from '../../../images/logoImage.js'
import { Link, NavLink } from 'react-router-dom';
import splashImage from '../../../images/ResearchApplication/splash-image.png'
const brochure = 'https://velocitychannel-tokens-dev.s3.us-east-2.amazonaws.com/documents-dev/The-Velocity-Channel-Contributor-Network.pdf'

class ResearchApplicationInformation extends PureComponent {
  renderMetaTags = () => {
    return (
      <MetaTags>
        <meta name='description' content='Create a profile on VelocityChannel and start publishing financial insights to our highly engaged audience of analysts, investors and operators.'/>
        <meta name='title' property='og:title' content='Publish Research to VelocityChannel and Start Earning Tokens'/>
        <meta property='og:description' content='Create a profile on VelocityChannel and start publishing financial insights to our highly engaged audience of analysts, investors and operators.'/>
        <meta property='og:image' content={LogoImg}/>
        <meta property='og:type' content='website'/>
        <meta property='og:url' content={`https://velocitychannel.io/become-a-contributor`}/>
        <meta property='twitter:title' content='Publish Research to VelocityChannel and Start Earning Tokens'/>
        <meta property='twitter:description' content='Create a profile on VelocityChannel and start publishing financial insights to our highly engaged audience of analysts, investors and operators.'/>
        <meta property='twitter:image' content={LogoImg}/>
        <meta property='twitter:site' content='Velocity Channel'/>
        {/* <meta name='robots' content='nofollow' /> */}
      </MetaTags>
    )
  }

  render() {
    return (
      <Container className='token-listed-container'>
        {this.renderMetaTags()}
        <Row className='no-padding token-listed-header-row'>
          <Col md={1} />
          <Col md={3} className='header-text no-padding'>
            <Row className='no-padding token-listed-top-row-text'>
              <Col className='no-padding'>
                <h3>Share your insights.</h3>
                <h3>Build an audience.</h3>
                <h3 className='last-item-in-this-list'>Generate revenue.</h3>
                <h4>Velocity Channel is a research and</h4>
                <h4>information hub built for the analyst.</h4>
                <h4>Learn more below.</h4>
              </Col>
            </Row>
            <Row className='no-padding'>
              <Col className='no-padding'>
                {/* <h3>Join the Contributor Network</h3> */}
                <p>Globally, interest in digital assets and blockchain has grown exponentially among retail and institutional investment communities.</p>
                <p>Now more than ever, a massive growth opportunity exists for independent research providers with the knowledge and experience to cover this fast moving industry.</p>
                <p>Velocity Channel is a research platform built to provide you, the analyst, with the network and tools you need to turn your insights into revenue. Download our brochure to learn more.</p>
                <Row className='no-padding'>
                  <a href={brochure} target='_blank' className='token-listed-link'>
                    <Button className='research-app-info-button'>
                      READ THE BROCHURE
                    </Button>
                  </a>
                  <Link to={'/research/apply'} className='token-listed-link'>
                    <Button className='research-app-info-button'>
                      APPLY TODAY
                    </Button>
                  </Link>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={8} className='no-padding splash-image-col' />
        </Row>
      </Container>
    )
  }
}

export default ResearchApplicationInformation
