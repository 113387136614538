import * as CONSTANTS from '../constants/forgotpassword'
import { CLEAR_USER_CACHE } from '../constants/account'

const initialState = {
  requestSent: false,
  passwordSuccessfullyReset: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        requestSent: false
      }
    case CONSTANTS.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        requestSent: true
      }
    case CONSTANTS.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        requestSent: false,
        error: action.error
      }
    case CONSTANTS.FORGOT_PASSWORD_RESET:
      return {
        ...state,
        requestSent: false
      }

    case CONSTANTS.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        passwordSuccessfullyReset: false
      }
    case CONSTANTS.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordSuccessfullyReset: true
      }
    case CONSTANTS.RESET_PASSWORD_ERROR:
      return {
        ...state,
        passwordSuccessfullyReset: false,
        error: action.error
      }
    case CONSTANTS.RESET_PASSWORD_RESET:
      return {
        ...state,
        passwordSuccessfullyReset: false
      }
    default:
      return state;
  }
}
