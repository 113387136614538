import {
    CONTACT_REQUEST,
    CONTACT_SUCCESS,
    CONTACT_ERROR,
    RESET_CONTACT,
  } from '../constants/contact';

  export const contactRequest = (
    email,
    username,
    issue,
  ) => {
    return {
      type: CONTACT_REQUEST,
      email,
      username,
      issue,
    };
  };

  export const contactSuccess = responseData => ({
    type: CONTACT_SUCCESS,
    responseData,
  }

  );

  export const contactError = err => ({
    type: CONTACT_ERROR,
    payload: err,
  });

  export const resetContact = () => ({
    type: RESET_CONTACT,
  })
