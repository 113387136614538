import React, { PureComponent } from 'react'
import { ResponsivePie } from '@nivo/pie'
import {
    Row,
    Col,
} from 'react-bootstrap'
import ReactLoading from 'react-loading'

class PieChart extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: this.props.data,
        }
    } // end of constructor(props)

    renderTooltip = event => {
        const {
            id,
            value,
            label,
            color,
        } = event

        return (
            <div className='chart-tooltip' id={id}>
              {label + ' - ' + value}
            </div>
        )
    } // end renderTooltip(event)

    render() {
        const {
            data,
        } = this.state
        const chartData = data.item

        return (
            <React.Fragment>
                <ResponsivePie
                    className='pie-chart'
                    data={data}
                    innerRadius={0.5}
                    colors={{ scheme: 'category10' }}
                    enableLabel={false}
                    enableSlicesLabels={false}
                    slicesLabelsTextColor="#ffffff"
                    sliceLabel='id'
                    enableRadialLabels={false}
                    radialLabelsTextColor="white"
                    radialLabelsLinkColor={{ from: 'color', modifiers: [] }}
                    tooltip={(event) => this.renderTooltip(event)}
                />
            </React.Fragment>
        )
    } // end of render()
} // end of PieChart extends PureComponent

export default PieChart
