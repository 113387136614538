import { connect } from 'react-redux';
import AccountReset from './AccountReset';
import './AccountReset.css';
import {
  resetPasswordRequest,
  resetPasswordHistory,
} from '../../redux/actions/resetpassword';

const mapStateToProps = state => ({
  resetPasswordSuccess: state.reset.resetPassword.success,
});

const mapDispatchToProps = {
  resetPasswordRequest,
  resetPasswordHistory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountReset);
