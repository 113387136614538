import * as CONSTANTS from '../../constants/collections';
import * as COLLECTIONS_ACTIONS from '../../actions/collections';
import { put, takeLatest, call, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { history } from "../../../configureStore";
import request from "../../../utils/apiRequest";

function* getDiscoverCollections(action) {
  try {
    const responseData = yield call(
      request,
      'research/collections/getUserCollections',
      'GET',
      null,
      true,
    )
    yield put(COLLECTIONS_ACTIONS.getDiscoverCollectionsSuccess(responseData))
  } catch (err) {
    yield put(COLLECTIONS_ACTIONS.getDiscoverCollectionsError(err))
  }
}

function* getPublicUserCollections(action) {
  try {
    const responseData = yield call(
      request,
      `research/collections/getUserCollections?filter=public`,
      'GET',
      null,
      true,
    )
    yield put(COLLECTIONS_ACTIONS.getPublicUserCollectionsSuccess(responseData))
  } catch (err) {
    yield put(COLLECTIONS_ACTIONS.getPublicUserCollectionsError(err))
  }
}

function* getPrivateUserCollections(action) {
  try {
    const responseData = yield call(
      request,
      `research/collections/getUserCollections?filter=private`,
      'GET',
      null,
      true,
    )
    yield put(COLLECTIONS_ACTIONS.getPrivateUserCollectionsSuccess(responseData))
  } catch (err) {
    yield put(COLLECTIONS_ACTIONS.getPrivateUserCollectionsError(err))
  }
}

function* getSpecificCollection(action) {
  try {
    const { id } = action.data
    const collectionInfo = yield call(
      request,
      `research/collections/getCollection?collectionId=${id}`,
      'GET',
      null,
      true,
    )
    const reports = yield call(
      request,
      `research/collections/getReports?collectionId=${id}`,
      'GET',
      null,
      true,
    )
    yield delay(200)
    yield put(COLLECTIONS_ACTIONS.getSpecificCollectionSuccess(collectionInfo, reports))
  } catch (err) {
    yield put(COLLECTIONS_ACTIONS.getSpecificCollectionError(err))
  }
}

function* createNewPrivateCollection(action) {
  try {
    const responseData = yield call(
      request,
      'research/collections/createCollection',
      'POST',
      null,
      true,
    )
    yield put(COLLECTIONS_ACTIONS.createNewPrivateCollectionSuccess(responseData))
    yield delay(1000)
    yield put(COLLECTIONS_ACTIONS.createNewPrivateCollectionFinish())
    yield put(COLLECTIONS_ACTIONS.resetCreateNewPrivateCollection())
  } catch (err) {
    yield put(COLLECTIONS_ACTIONS.createNewPrivateCollectionError(err))
  }
}

function* searchCollections(action) {
  try {
    const { query, reportId } = action
    const queryString = query ? `q=${query}` : null
    const responseData = yield call(
      request,
      `research/collections/search?${queryString}&reportId=${reportId}`,
      'GET',
      null,
      true,
    )
    yield put(COLLECTIONS_ACTIONS.searchCollectionsSuccess(responseData))
  } catch (err) {
    yield put(COLLECTIONS_ACTIONS.searchCollectionsError(err))
  }
}

function* addArticleToCollection(action) {
  try {
    const body = {
      collectionId: action.collectionId,
      reportId: action.reportId,
    }
    const responseData = yield call(
      request,
      `research/collections/addReport`,
      'POST',
      body,
      true,
    )
    yield put(COLLECTIONS_ACTIONS.addArticleToCollectionSuccess(responseData))
  } catch (err) {
    yield put(COLLECTIONS_ACTIONS.addArticleToCollectionError(err))
  }
}

function* editCollection(action) {
  try {
    const body = {
      collectionId: action.collectionId,
      name: action.name,
      description: action.description,
      image: action.image,
      isPublic: action.isPublic,
    }
    const responseData = yield call(
      request,
      'research/collections/editCollection',
      'POST',
      body,
      true,
    )
    yield put(COLLECTIONS_ACTIONS.editCollectionSuccess(responseData))
    toast.info('Your collection has been changed')
  } catch (err) {
    yield put(COLLECTIONS_ACTIONS.editCollectionError(err))
  }
}

function* editCollectionImage(action) {
  try {
    const { image } = action
    let imageData = new FormData()
    imageData.append("file", image)
    const responseData = yield call(
      request,
      'file/tokenImage',
      'POST',
      imageData,
      true,
      true,
    )
    yield put(COLLECTIONS_ACTIONS.editCollectionImageSuccess(responseData))
  } catch (err) {
    yield put(COLLECTIONS_ACTIONS.editCollectionImageError(err))
  }
}

function* removeReportFromCollection(action) {
  try {

    const body = {
      collectionId: action.collectionId,
      reportId: action.reportId,
    }

    const responseData = yield call(
      request,
      'research/collections/removeReport',
      'POST',
      body,
      true
    )

    const collectionData = {
      id: action.collectionId
    }

    yield delay(2000)
    yield put(COLLECTIONS_ACTIONS.removeReportFromCollectionSuccess())
  } catch (err) {
    yield put(COLLECTIONS_ACTIONS.removeReportFromCollectionError(err))
  }
}

function* togglePublicPrivateCollection(action) {
  try {

    const body = {
      collectionId: action.collectionId
    }

    const responseData = yield call(
      request,
      'research/collections/togglePublic',
      'POST',
      body,
      true
    )

    yield delay(1000)
    yield put(COLLECTIONS_ACTIONS.togglePublicPrivateCollectionSuccess())
  } catch (err) {
    yield put(COLLECTIONS_ACTIONS.togglePublicPrivateCollectionError(err))
  }
}

function* deleteCollection(action) {
  try {

    const body = {
      collectionId: action.collectionId
    }

    const responseData = yield call(
      request,
      'research/collections/deleteCollection',
      'POST',
      body,
      true,
    )

    yield delay(1000)
    yield put(COLLECTIONS_ACTIONS.deleteCollectionSuccess())
  } catch (err) {
    yield put(COLLECTIONS_ACTIONS.deleteCollectionError(err))
  }
}

export default function* researchsSaga() {
  yield takeLatest(CONSTANTS.GET_DISCOVER_COLLECTIONS_REQUEST, getDiscoverCollections)
  yield takeLatest(CONSTANTS.GET_PUBLIC_USER_COLLECTIONS_REQUEST, getPublicUserCollections)
  yield takeLatest(CONSTANTS.GET_PRIVATE_USER_COLLECTIONS_REQUEST, getPrivateUserCollections)
  yield takeLatest(CONSTANTS.GET_SPECIFIC_COLLECTION_REQUEST, getSpecificCollection)
  yield takeLatest(CONSTANTS.CREATE_NEW_PRIVATE_COLLECTION_REQUEST, createNewPrivateCollection)
  yield takeLatest(CONSTANTS.SEARCH_COLLECTIONS_REQUEST, searchCollections)
  yield takeLatest(CONSTANTS.ADD_ARTICLE_TO_COLLECTION_REQUEST, addArticleToCollection)
  yield takeLatest(CONSTANTS.EDIT_COLLECTION_REQUEST, editCollection)
  yield takeLatest(CONSTANTS.EDIT_COLLECTION_IMAGE_REQUEST, editCollectionImage)
  yield takeLatest(CONSTANTS.REMOVE_REPORT_FROM_COLLECTION_REQUEST, removeReportFromCollection)
  yield takeLatest(CONSTANTS.TOGGLE_PUBLIC_PRIVATE_COLLECTION_REQUEST, togglePublicPrivateCollection)
  yield takeLatest(CONSTANTS.DELETE_COLLECTION_REQUEST, deleteCollection)
}
