import { connect } from 'react-redux';
import captchaKeys from '../../captchaKeys.js'

import Register from './Register';
import { signupRequest } from '../../redux/actions/signup';
const mapStateToProps = state => ({
  siteKey: captchaKeys.siteKey,
  secretKey: captchaKeys.secretKey,
});

const mapDispatchToProps = {
    signupRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Register);
