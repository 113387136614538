export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const LOGIN_WITH_TOKEN = 'LOGIN_WITH_TOKEN'
export const LOGIN_NEED_TWO_FACTOR_AUTH = 'LOGIN_NEED_TWO_FACTOR_AUTH'
export const CLEAR_LOGIN_PARAMS = 'CLEAR_LOGIN_PARAMS'

export const SET_USER_DATA = 'SET_USER_DATA';

export const ENABLE_WEB_WORKER = 'ENABLE_WEB_WORKER'
export const DISABLE_WEB_WORKER = 'DISABLE_WEB_WORKER'

export const TOGGLE_LOGIN_MODAL = 'TOGGLE_LOGIN_MODAL'
export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL'
export const HIDE_LOGIN_MODAL = 'HIDE_LOGIN_MODAL'
export const HIDE_LOGIN_MODAL_AND_WIPE_LOCAL_STORAGE = 'HIDE_LOGIN_MODAL_AND_WIPE_LOCAL_STORAGE'

export const SET_USER_AUTHENTICATED_STATUS= 'SET_USER_AUTHENTICATED_STATUS'

export const LOGIN_FROM_MODAL_REQUEST = 'LOGIN_FROM_MODAL_REQUEST';
export const LOGIN_FROM_MODAL_SUCCESS = 'LOGIN_FROM_MODAL_SUCCESS';
export const LOGIN_FROM_MODAL_ERROR = 'LOGIN_FROM_MODAL_ERROR';

export const AUTO_LOGIN_REQUEST = 'AUTO_LOGIN_REQUEST'
export const AUTO_LOGIN_SUCCESS = 'AUTO_LOGIN_SUCCESS'
export const AUTO_LOGIN_ERROR = 'AUTO_LOGIN_ERROR'
