import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  FormControl,
  Button,
  Form,
  Table,
  Spinner,
  Modal,
} from 'react-bootstrap'
import {
  AiOutlineUserDelete,
  AiOutlineReload,
} from 'react-icons/ai'
import { MdArrowBack } from 'react-icons/md'

class UserLookUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {
        q: '',
        isAdmin: '',
        isContributor: '',
      },
      userSearch: {
        data: {},
        loading: false,
        loaded: false,
        error: '',
      }
    }
  }

  componentDidMount() {
    this.handleSearchUsers()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        userSearch: nextProps.userSearch
      })
    }
  }

  handleSearchUsers = () => {
    this.props.adminSearchUsersRequest(this.state.params)
  }

  handleResetSearchUsers = () => {
    this.setState({
      params: {
        q: '',
        isAdmin: '',
        isContributor: '',
      }
    }, () => this.handleSearchUsers())
  }

  handleChangeParams = e => {
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]:  e.target.value
      }
    }, () => this.handleSearchUsers())
  }

  handleBackToUserList = () => {
    this.props.clearUserListCache()
  }

  renderSearchbar = () => {
    const { email, isAdmin, isContributor } = this.state.params
    return (
      <Row noGutters className='submissions-search-bar'>
        <Col>
          <label className='submission-form-control-label'>
            Email:
          </label>
          <FormControl
            size='sm'
            name='email'
            value={email}
            onChange={this.handleChangeParams}
            placeholder='Search by email...'
            className='submission-form-control' />
        </Col>
        <Col>
          <label className='submission-form-control-label'>
            Filter Admins:
          </label>
          <FormControl
            size='sm'
            name='isAdmin'
            as='select'
            value={isAdmin}
            onChange={this.handleChangeParams}
            className='submission-form-control'>
            <option value=''>Select an option...</option>
            <option value='false'>False</option>
            <option value='true'>True</option>
          </FormControl>
        </Col>
        <Col>
          <label className='submission-form-control-label'>
            Filter Contributors:
          </label>
          <FormControl
            size='sm'
            as='select'
            name='isContributor'
            value={isContributor}
            onChange={this.handleChangeParams}
            className='submission-form-control'>
            <option value=''>Select an option...</option>
            <option value='false'>False</option>
            <option value='true'>True</option>
          </FormControl>
        </Col>
      </Row>
    )
  }

  renderTable = () => {
    const { data, loading, loaded } = this.state.userSearch
    let content

    if (loading) {
      content = this.renderTableLoading()
    } else if (loaded) {
      if (data.total > 0) {
        content = data.docs.map(admin => this.renderIndividualAdmin(admin))
      } else {
        content = (
          <tr>
            <td colSpan='3'>
              <Row noGutters className='table-loading-row'>
                <Spinner className='asset-profile-loading-spinner' animation='border' />
              </Row>
            </td>
          </tr>
        )
      }
    }

    return (
      <Table bordered className='admin-table'>
        <thead>
          <tr>
            <th>User ID</th>
            <th>Admin</th>
            <th>Contributor</th>
          </tr>
        </thead>

        <tbody>
          {content}
        </tbody>
      </Table>
    )
  }

  renderIndividualAdmin = admin => {
    const { _id, email, isAdmin, isContributor } = admin

    const adminStatus = isAdmin
      ? <div id='mentionedIn-true'>True</div>
      : <div id='mentionedIn-false'>False</div>
    const contributorStatus = isContributor
      ? <div id='mentionedIn-true'>True</div>
      : <div id='mentionedIn-false'>False</div>

    return (
      <tr key={_id}>
        <td>{_id}</td>
        <td>{adminStatus}</td>
        <td>
          <Row noGutters className='td-row'>
            {contributorStatus}
            <AiOutlineUserDelete
              onClick={() => this.handleShowConfirmDeleteModal(admin)}
              className='individual-form-icon' />
          </Row>
        </td>
      </tr>
    )
  }

  renderResetQueriesButton = () => (
    <AiOutlineReload
      onClick={this.handleResetSearchUsers}
      className='submissions-reset-queries-button' />
  )

  renderTableLoading = () => (
    <tr>
      <td colSpan='3'>
        <Row noGutters className='table-loading-row'>
          <Spinner className='asset-profile-loading-spinner' animation='border' />
        </Row>
      </td>
    </tr>
  )

  handleShowConfirmDeleteModal = admin => {
    this.setState({
      adminToDelete: admin,
      currentModal: 'confirmDeleteModal'
    })
  }

  handleHideConfirmDeleteModal = () => {
    this.setState({
      adminToDelete: {},
      currentModal: ''
    })
  }

  renderConfirmDeleteModal = () => {
    const show = this.state.currentModal == 'confirmDeleteModal' ? true : false
    const profileId = show ? this.state.adminToDelete._id : ''

    return (
      <Modal
        centered
        className='admin-modal'
        show={show}
        onHide={this.handleHideConfirmDeleteModal}>

        <Modal.Header closeButton>
          User Data
        </Modal.Header>

        <Modal.Body>
          <Row noGutters className='confirm-delete-modal-row'>
            Are you sure you want to delete this user?
          </Row>

          <Row noGutters className='confirm-delete-modal-row email-row'>
            <b>{profileId}</b>
          </Row>

          <Row noGutters className='confirm-delete-modal-row'>
            <Button
              className='delete-button' size='sm'>
              Delete
            </Button>

            <Button
              onClick={this.handleHideConfirmDeleteModal}
              className='teal-button' size='sm'>
              Cancel
            </Button>
          </Row>

        </Modal.Body>
      </Modal>
    )
  }

  render() {
    return (
      <Container fluid className='admin-content-container'>
        <Row noGutters className='forms-header'>
          <h4>User Management</h4>
        </Row>

        <div className='forms-table-box'>
          {this.renderResetQueriesButton()}
          {this.renderSearchbar()}
          {this.renderTable()}
          {this.renderConfirmDeleteModal()}
        </div>
      </Container>
    )
  }
}

export default UserLookUp
