import React, { Component } from "react"
import EquityTokenForm from "../EquityTokenForm"
import DebtSecurityForm from "../DebtSecurityForm"
import RealEstateTokenForm from "../RealEstateTokenForm"
import FundSecurityForm from "../FundSecurityForm"
import SuggestionForm from "../SuggestionForm"
import ResearchForm from "../ResearchForm"
import Research from "../Research"
import TokenList from "../TokenList"
import SuggestionList from "../SuggestionList"
import TokenDetailPage from "../TokenDetailPage"
import Contributor from "../Contributor"
import ContributorApplications from "../ContributorApplications"
import Contributors from "../Contributors"
import FeaturedContributors from "../FeaturedContributors"
import UserLookUp from "../UserLookUp"
import TokenForm from "../TokenForm"
import ListingRequests from '../ListingRequests'
import SpamReports from '../SpamReports'
import TokenTemplates from '../TokenTemplates'
import AssetProfiles from '../AssetProfiles'
import { LinkContainer } from "react-router-bootstrap"
import { history } from "../../../configureStore"
import {
  Row,
  Col,
  Jumbotron,
  Button,
  InputGroup,
  FormControl,
  Nav,
  Image,
  Dropdown,
  Alert,
  Container,
  NavDropdown
} from "react-bootstrap"
import { MdClear } from "react-icons/md"
import {
  AiOutlineUser,
  AiOutlineSearch,
  AiOutlineLogout
} from 'react-icons/ai'
import { Switch, Route, withRouter } from "react-router-dom"

class AdminPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentSection: '',
      topbarQuery: ''
    }
  }

  componentDidMount() {
    this.handleParseUrl()
  }

  handleOnChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleParseUrl = () => {
    const pathname = history.location.pathname.split('/admin/')
    this.setState({
      currentSection: pathname[1]
    })
  }

  renderTopbar = () => {
    const title = (
      <React.Fragment>
        <AiOutlineUser />
        My Account
      </React.Fragment>
    )

    return (
      <div className='admin-topbar'>
        <Row noGutters className='admin-topbar-row'>
          <div className='admin-search-container'>
            <FormControl
              name='topbarQuery'
              value={this.state.topbarQuery}
              onChange={this.handleOnChange}
              className='common-form-control'
              placeholder='Search for...' size='sm'/>
            <Button className='common-button' size='sm'>
              <AiOutlineSearch />
            </Button>
          </div>

          <div className='admin-topbar-nav'>
            <NavDropdown title={title}>
              <NavDropdown.Item
                onClick={this.props.logout}>
                <AiOutlineLogout />
                Log out
              </NavDropdown.Item>
            </NavDropdown>
          </div>

        </Row>
      </div>
    )
  }

  render() {
    return (
      <Container fluid className='admin-content-pane-container'>
        {this.renderTopbar()}
        <Route exact path='/admin/user-lookup' component={UserLookUp} />
        <Route exact path='/admin/contributor-applications' component={ContributorApplications} />
        <Route exact path='/admin/reports' component={Research} />
        <Route exact path='/admin/contributor-list' component={Contributors} />
        <Route exact path='/admin/featured-contributors' component={FeaturedContributors} />
        <Route exact path='/admin/spam-reports' component={SpamReports} />
        <Route exact path='/admin/asset-templates' component={TokenTemplates} />
        <Route exact path='/admin/create-an-asset-profile' component={AssetProfiles} />
        <Route exact path='/admin/' component={UserLookUp} />
      </Container>
    )
  }
}

export default AdminPage
