export const GET_TOKEN_LIST_REQUEST = 'GET_TOKEN_LIST_REQUEST';
export const GET_TOKEN_LIST_SUCCESS = 'GET_TOKEN_LIST_SUCCESS';
export const GET_TOKEN_LIST_ERROR = 'GET_TOKEN_LIST_ERROR';

export const DELETE_TOKEN_REQUEST = 'DELETE_TOKEN_REQUEST';
export const DELETE_TOKEN_SUCCESS = 'DELETE_TOKEN_SUCCESS';
export const DELETE_TOKEN_ERROR = 'DELETE_TOKEN_ERROR';

export const GET_TOKEN_DETAIL_REQUEST = 'GET_TOKEN_DETAIL_REQUEST';
export const GET_TOKEN_DETAIL_SUCCESS = 'GET_TOKEN_DETAIL_SUCCESS';
export const GET_TOKEN_DETAIL_ERROR = 'GET_TOKEN_DETAIL_ERROR';

export const GET_COMMENTS_REQUEST = 'GET_COMMENTS_REQUEST';
export const GET_REPLY_COMMENTS_SUCCESS = 'GET_REPLY_COMMENTS_SUCCESS';
export const GET_TOKEN_COMMENTS_SUCCESS = 'GET_TOKEN_COMMENTS_SUCCESS';
export const GET_NEWS_COMMENTS_SUCCESS = 'GET_NEWS_COMMENTS_SUCCESS';

export const GET_REPORT_REPLY_COMMENT_SUCCESS =
  'GET_REPORT_REPLY_COMMENT_SUCCESS';
export const GET_REPORT_COMMENTS_SUCCESS = 'GET_REPORT_COMMENTS_SUCCESS';

export const GET_COMMENTS_ERROR = 'GET_COMMENTS_ERROR';
export const GET_COMMENTS_RESET = 'GET_COMMENTS_RESET';
export const POST_COMMENTS_REQUEST = 'POST_COMMENTS_REQUEST';
export const POST_COMMENTS_SUCCESS = 'POST_COMMENTS_SUCCESS';
export const POST_COMMENTS_ERROR = 'POST_COMMENTS_ERROR';

export const GET_TOKEN_NEWS_REQUEST = 'GET_TOKEN_NEWS_REQUEST';
export const GET_TOKEN_NEWS_SUCCESS = 'GET_TOKEN_NEWS_SUCCESS';
export const GET_TOKEN_NEWS_ERROR = 'GET_TOKEN_NEWS_ERROR';

export const SEARCH_TOKEN_REQUEST = 'SEARCH_TOKEN_REQUEST';
export const SEARCH_TOKEN_SUCCESS = 'SEARCH_TOKEN_SUCCESS';
export const SEARCH_TOKEN_ERROR = 'SEARCH_TOKEN_ERROR';

export const GET_SEE_MORE_COMMENTS_REQUEST = 'GET_SEE_MORE_COMMENTS_REQUEST';
export const GET_SEE_MORE_COMMENTS_SUCCESS = 'GET_SEE_MORE_COMMENTS_SUCCESS';
export const GET_SEE_MORE_COMMENTS_ERROR = 'GET_SEE_MORE_COMMENTS_ERROR';

export const GET_TOKEN_DETAILS_REQUEST = 'GET_TOKEN_DETAILS_REQUEST';
export const GET_TOKEN_DETAILS_SUCCESS = 'GET_TOKEN_DETAILS_SUCCESS';
export const GET_TOKEN_DETAILS_ERROR = 'GET_TOKEN_DETAILS_ERROR';

export const GET_MARKET_STATS_REQUEST = 'GET_MARKET_STATS_REQUEST';
export const GET_MARKET_STATS_SUCCESS = 'GET_MARKET_STATS_SUCCESS';
export const GET_MARKET_STATS_ERROR = 'GET_MARKET_STATS_ERROR';

export const GET_THIS_MONTH_TOKENS_REQUEST = 'GET_THIS_MONTH_TOKENS_REQUEST';
export const GET_THIS_MONTH_TOKENS_SUCCESS = 'GET_THIS_MONTH_TOKENS_SUCCESS';
export const GET_THIS_MONTH_TOKENS_ERROR = 'GET_THIS_MONTH_TOKENS_ERROR';

export const GET_TOKENS_BY_DATE_REQUEST = 'GET_TOKENS_BY_DATE_REQUEST'
export const GET_TOKENS_BY_DATE_SUCCESS = 'GET_TOKENS_BY_DATE_SUCCESS'
export const GET_TOKENS_BY_DATE_ERROR = 'GET_TOKENS_BY_DATE_ERROR'

export const GET_PRESS_RELEASES_REQUEST = 'GET_PRESS_RELEASES_REQUEST'
export const GET_PRESS_RELEASES_SUCCESS = 'GET_PRESS_RELEASES_SUCCESS'
export const GET_PRESS_RELEASES_ERROR = 'GET_PRESS_RELEASES_ERROR'

export const GET_CHOROPLETH_DATA_REQUEST = 'GET_CHOROPLETH_DATA_REQUEST'
export const GET_CHOROPLETH_DATA_SUCCESS = 'GET_CHOROPLETH_DATA_SUCCESS'
export const GET_CHOROPLETH_DATA_ERROR = 'GET_CHOROPLETH_DATA_ERROR'

export const GET_PIE_CHART_DATA_REQUEST = 'GET_PIE_CHART_DATA_REQUEST'
export const GET_PIE_CHART_DATA_SUCCESS = 'GET_PIE_CHART_DATA_SUCCESS'
export const GET_PIE_CHART_DATA_ERROR = 'GET_PIE_CHART_DATA_ERROR'

export const GET_TOKEN_SPECIFIC_NEWS_REQUEST = 'GET_TOKEN_SPECIFIC_NEWS_REQUEST'
export const GET_TOKEN_SPECIFIC_NEWS_SUCCESS = 'GET_TOKEN_SPECIFIC_NEWS_SUCCESS'
export const GET_TOKEN_SPECIFIC_NEWS_ERROR = 'GET_TOKEN_SPECIFIC_NEWS_ERROR'

export const GET_LIST_OF_ASSET_TYPES_REQUEST = 'GET_LIST_OF_ASSET_TYPES_REQUEST'
export const GET_LIST_OF_ASSET_TYPES_SUCCESS = 'GET_LIST_OF_ASSET_TYPES_SUCCESS'
export const GET_LIST_OF_ASSET_TYPES_ERROR = 'GET_LIST_OF_ASSET_TYPES_ERROR'

export const GET_LIST_OF_ASSETS_REQUEST = 'GET_LIST_OF_ASSETS_REQUEST'
export const GET_LIST_OF_ASSETS_SUCCESS = 'GET_LIST_OF_ASSETS_SUCCESS'
export const GET_LIST_OF_ASSETS_ERROR = 'GET_LIST_OF_ASSETS_ERROR'

export const GET_PROFILE_FILTERS_REQUEST = 'GET_PROFILE_FILTERS_REQUEST'
export const GET_PROFILE_FILTERS_SUCCESS = 'GET_PROFILE_FILTERS_SUCCESS'
export const GET_PROFILE_FILTERS_ERROR = 'GET_PROFILE_FILTERS_ERROR'

export const GET_LIST_OF_FILTERS_FOR_ASSET_TYPE_REQUEST = 'GET_LIST_OF_FILTERS_FOR_ASSET_TYPE_REQUEST'
export const GET_LIST_OF_FILTERS_FOR_ASSET_TYPE_SUCCESS = 'GET_LIST_OF_FILTERS_FOR_ASSET_TYPE_SUCCESS'
export const GET_LIST_OF_FILTERS_FOR_ASSET_TYPE_ERROR = 'GET_LIST_OF_FILTERS_FOR_ASSET_TYPE_ERROR'

export const RESET_LIST_OF_FILTERS_FOR_ASSET_TYPE = 'RESET_LIST_OF_FILTERS_FOR_ASSET_TYPE'

export const GET_ASSETS_WITH_PARAMS_REQUEST = 'GET_ASSETS_WITH_PARAMS_REQUEST'
export const GET_ASSETS_WITH_PARAMS_SUCCESS = 'GET_ASSETS_WITH_PARAMS_SUCCESS'
export const GET_ASSETS_WITH_PARAMS_ERROR = 'GET_ASSETS_WITH_PARAMS_ERROR'
