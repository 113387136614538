import { connect } from 'react-redux'
import TradedSymbols from './TradedSymbols'
import './TradedSymbols.css'
import {
  getTradedSymbolsRequest,
  createTradedSymbolRequest,
} from '../../../redux/actions/admin';
import {
  getPublishedTemplatesRequest,
  createAssetProfileRequest,
  selectAssetProfileRequest,
} from '../../../redux/actions/tokenform'

const mapStateToProps = state => ({
  tradedSymbols: state.admin.tradedSymbols,
  assetTypes: state.token.publishedTemplates,
})

const mapDispatchToProps = {
  getTradedSymbolsRequest,
  createTradedSymbolRequest,
  getPublishedTemplatesRequest,
  createAssetProfileRequest,
  selectAssetProfileRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TradedSymbols)
