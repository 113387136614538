export const GET_RESEARCH_FEED_REQUEST = 'GET_RESEARCH_FEED_REQUEST';
export const GET_RESEARCH_FEED_SUCCESS = 'GET_RESEARCH_FEED_SUCCESS';
export const GET_RESEARCH_FEED_ERROR = 'GET_RESEARCH_FEED_ERROR';

export const GET_FILTER_RESEARCH_REQEUST = 'GET_FILTER_RESEARCH_REQEUST';
export const GET_FILTER_RESEARCH_SUCCESS = 'GET_FILTER_RESEARCH_SUCCESS';
export const GET_FILTER_RESEARCH_ERROR = 'GET_FILTER_RESEARCH_ERROR';

export const SET_FILTER_KEYS = 'SET_FILTER_KEYS';

export const GET_DOWN_VOTES_REQUEST = 'GET_DOWN_VOTES_REQUEST';
export const GET_DOWN_VOTES_SUCCESS = 'GET_DOWN_VOTES_SUCCESS';
export const GET_DOWN_VOTES_ERROR = 'GET_DOWN_VOTES_ERROR';

export const GET_UP_VOTES_REQUEST = 'GET_UP_VOTES_REQUEST';
export const GET_UP_VOTES_SUCCESS = 'GET_UP_VOTES_SUCCESS';
export const GET_UP_VOTES_ERROR = 'GET_UP_VOTES_ERROR';

export const POST_UP_VOTE_REQUEST = 'POST_UP_VOTE_REQUEST';
export const POST_DOWN_VOTE_REQUEST = 'POST_DOWN_VOTE_REQUEST';

export const REMOVE_UP_VOTE_REQUEST = 'REMOVE_UP_VOTE_REQUEST';
export const REMOVE_DOWN_VOTE_REQUEST = 'REMOVE_DOWN_VOTE_REQUEST';

export const GET_REPORT_VOTES_USER_REQUEST = 'GET_REPORT_VOTES_USER_REQUEST';
export const GET_REPORT_VOTES_USER_SUCCESS = 'GET_REPORT_VOTES_USER_SUCCESS';
export const GET_REPORT_VOTES_USER_ERROR = 'GET_REPORT_VOTES_USER_ERROR';

export const GET_FEED_REPORT_REQUEST = 'GET_FEED_REPORT_REQUEST';
export const GET_FEED_REPORT_SUCCESS = 'GET_FEED_REPORT_SUCCESS';
export const GET_FEED_REPORT_ERROR = 'GET_FEED_REPORT_ERROR';

export const UPDATE_UPVOTE_COUNT = 'UPDATE_UPVOTE_COUNT';
export const UPDATE_DOWNVOTE_COUNT = 'UPDATE_DOWNVOTE_COUNT';

export const RESET_SEARCH_FEED = 'RESET_SEARCH_FEED';

export const CREATE_DRAFT_REQUEST = 'CREATE_DRAFT_REQUEST'
export const CREATE_DRAFT_SUCCESS = 'CREATE_DRAFT_SUCCESS'
export const CREATE_DRAFT_ERROR = 'CREATE_DRAFT_ERROR'
export const CREATE_DRAFT_CLEAR = 'CREATE_DRAFT_CLEAR'

export const SAVE_DRAFT_REQUEST = 'SAVE_DRAFT_REQUEST'
export const SAVE_DRAFT_SUCCESS = 'SAVE_DRAFT_SUCCESS'
export const SAVE_DRAFT_ERROR = 'SAVE_DRAFT_ERROR'
export const SAVE_DRAFT_RESET = 'SAVE_DRAFT_RESET'

export const DELETE_DRAFT_REQUEST = 'DELETE_DRAFT_REQUEST'
export const DELETE_DRAFT_SUCCESS = 'DELETE_DRAFT_SUCCESS'
export const DELETE_DRAFT_ERROR = 'DELETE_DRAFT_ERROR'

export const GET_USERS_DRAFTS_REQUEST = 'GET_USERS_DRAFTS_REQUEST'
export const GET_USERS_DRAFTS_SUCCESS = 'GET_USERS_DRAFTS_SUCCESS'
export const GET_USERS_DRAFTS_ERROR = 'GET_USERS_DRAFTS_ERROR'

export const PUBLISH_ARTICLE_REQUEST = 'PUBLISH_ARTICLE_REQUEST'
export const PUBLISH_ARTICLE_SUCCESS = 'PUBLISH_ARTICLE_SUCCESS'

export const GET_BOOKMARKS_REQUEST = 'GET_BOOKMARKS_REQUEST'
export const GET_BOOKMARKS_SUCCESS = 'GET_BOOKMARKS_SUCCESS'

export const GET_TRENDING_ARTICLES_REQUEST = 'GET_TRENDING_ARTICLES_REQUEST'
export const GET_TRENDING_ARTICLES_SUCCESS = 'GET_TRENDING_ARTICLES_SUCCESS'

export const GET_TRENDING_TOPICS_REQUEST = 'GET_TRENDING_TOPICS_REQUEST'
export const GET_TRENDING_TOPICS_SUCCESS = 'GET_TRENDING_TOPICS_SUCCESS'

export const GET_RESEARCH_USER_FEED_REQUEST = 'GET_RESEARCH_USER_FEED_REQUEST'
export const GET_RESEARCH_USER_FEED_SUCCESS = 'GET_RESEARCH_USER_FEED_SUCCESS'

export const READ_ARTICLE_REQUEST = 'READ_ARTICLE_REQUEST'
export const READ_ARTICLE_SUCCESS = 'READ_ARTICLE_SUCCESS'
export const READ_ARTICLE_ERROR = 'READ_ARTICLE_ERROR'

export const GET_RELATED_ARTICLES_REQUEST = 'GET_RELATED_ARTICLES_REQUEST'
export const GET_RELATED_ARTICLES_SUCCESS = 'GET_RELATED_ARTICLES_SUCCESS'

export const POST_COMMENT_REQUEST = 'POST_COMMENT_REQUEST'

export const GET_ARTICLE_COMMENTS_REQUEST = 'GET_ARTICLE_COMMENTS_REQUEST'
export const GET_ARTICLE_COMMENTS_SUCCESS = 'GET_ARTICLE_COMMENTS_SUCCESS'

export const ARTICLE_IMAGE_UPLOAD_REQUEST = 'ARTICLE_IMAGE_UPLOAD_REQUEST'
export const ARTICLE_IMAGE_UPLOAD_SUCCESS = 'ARTICLE_IMAGE_UPLOAD_SUCCESS'
export const ARTICLE_IMAGE_UPLOAD_ERROR = 'ARTICLE_IMAGE_UPLOAD_ERROR'

export const CLEAR_ARTICLE_IMAGE = 'CLEAR_ARTICLE_IMAGE'

export const ARTICLE_UPLOAD_DOCUMENT_REQUEST = 'ARTICLE_UPLOAD_DOCUMENT_REQUEST'
export const ARTICLE_UPLOAD_DOCUMENT_SUCCESS = 'ARTICLE_UPLOAD_DOCUMENT_SUCCESS'
export const ARTICLE_UPLOAD_DOCUMENT_ERROR = 'ARTICLE_UPLOAD_DOCUMENT_ERROR'

export const CLEAR_DOCUMENT_DATA = 'CLEAR_DOCUMENT_DATA'

export const GET_TOPICS_LIST_REQUEST = 'GET_TOPICS_LIST_REQUEST'
export const GET_TOPICS_LIST_SUCCESS = 'GET_TOPICS_LIST_SUCCESS'

export const GET_ARTICLES_BY_CATEGORY_REQUEST = 'GET_ARTICLES_BY_CATEGORY_REQUEST'
export const GET_ARTICLES_BY_CATEGORY_SUCCESS = 'GET_ARTICLES_BY_CATEGORY_SUCCESS'

export const SEARCH_ARTICLES_REQUEST = 'SEARCH_ARTICLES_REQUEST'
export const SEARCH_ARTICLES_SUCCESS = 'SEARCH_ARTICLES_SUCCESS'

export const UNPUBLISH_REPORT_REQUEST = 'UNPUBLISH_REPORT_REQUEST'
export const UNPUBLISH_REPORT_SUCCESS = 'UNPUBLISH_REPORT_SUCCESS'
export const UNPUBLISH_REPORT_ERROR = 'UNPUBLISH_REPORT_ERROR'

export const REPORT_SEARCH_REQUEST = 'REPORT_SEARCH_REQUEST';
export const REPORT_SEARCH_SUCCESS = 'REPORT_SEARCH_SUCCESS';
export const REPORT_SEARCH_ERROR = 'REPORT_SEARCH_ERROR';

export const SUBMIT_REPORT_REQUEST = 'SUBMIT_REPORT_REQUEST'
export const SUBMIT_REPORT_SUCCESS = 'SUBMIT_REPORT_SUCCESS'

export const GET_REPORT_LIST_REQUEST = 'GET_REPORT_LIST_REQUEST'
export const GET_REPORT_LIST_SUCCESS = 'GET_REPORT_LIST_SUCCESS'

export const REPORT_NOT_FOUND = 'REPORT_NOT_FOUND'

export const GET_ASSET_PROFILES_REQUEST = 'GET_ASSET_PROFILES_REQUEST'
export const GET_ASSET_PROFILES_SUCCESS = 'GET_ASSET_PROFILES_SUCCESS'
export const GET_ASSET_PROFILES_ERROR = 'GET_ASSET_PROFILES_ERROR'

export const GET_DRAFT_REQUEST = 'GET_DRAFT_REQUEST'
export const GET_DRAFT_SUCCESS = 'GET_DRAFT_SUCCESS'
export const GET_DRAFT_ERROR = 'GET_DRAFT_ERROR'

export const GET_ASSET_RESEARCH_REQUEST = 'GET_ASSET_RESEARCH_REQUEST'
export const GET_ASSET_RESEARCH_SUCCESS = 'GET_ASSET_RESEARCH_SUCCESS'
export const GET_ASSET_RESEARCH_ERROR = 'GET_ASSET_RESEARCH_ERROR'

export const GET_ASSET_PRICING_DATA_REQUEST = 'GET_ASSET_PRICING_DATA_REQUEST'
export const GET_ASSET_PRICING_DATA_SUCCESS = 'GET_ASSET_PRICING_DATA_SUCCESS'
export const GET_ASSET_PRICING_DATA_ERROR = 'GET_ASSET_PRICING_DATA_ERROR'
