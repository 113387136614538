import React, { Component } from 'react'
import {
  Row,
  Col,
  Button,
  Image,
  Container,
  Card,
  Jumbotron,
  Modal,
  Spinner,
  OverlayTrigger,
  Tooltip,
  NavLink,
  Table,
} from 'react-bootstrap'
import Paginations from "../../Pagination"
import ReactHtmlParser from "react-html-parser"
import Iframe from "react-iframe";
import YouTube from 'react-youtube'
import he from 'he'
import { MdLocationOn } from 'react-icons/md'
import { IoIosHeartEmpty, IoIosHeart, IoMdPeople } from "react-icons/io"
import MetaTags from 'react-meta-tags'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaMediumM, FaGlobe, FaIndustry, FaMapMarkerAlt, FaTelegram, FaFileSignature, FaCalendarAlt, FaCheckCircle } from "react-icons/fa"
import { AiOutlineUser, AiOutlineFile } from 'react-icons/ai'
import LogoImg from '../../../images/logoImage.js'
import Scrollspy from 'react-scrollspy'
import LightWeightChart from '../../LightWeightChart'
import StickyBox from "react-sticky-box/dist/esnext"

let lastScrollY = 0;
let ticking = false;

class UserTokenDetailPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      asset: this.props.asset,
      news: this.props.news,
      research: this.props.research,
      pricing: this.props.pricing,
      currentPosition: 'Profile Information',
      showTileModal: {
        value: '',
        contentType: ''
      }
    }
  }

  componentDidMount() {
    this.handleGetAssetAndInfo()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        asset: nextProps.asset,
        news: nextProps.news,
        research: nextProps.research,
        pricing: nextProps.pricing,
      })
    }
  }

  handleGetAssetAndInfo = () => {
    const assetName = this.props.match.params.asset_name
    this.props.userViewAssetRequest(assetName)
  }

  handleChangeCurrentPosition = position => {
    this.setState({ currentPosition: position })
  }

  formatDate = dateInput => {
    let date = new Date(dateInput)
    const month = date.toLocaleString("en-us", { month: "short" })
    const day = date.getDate()
    const year = date.getFullYear()
    return month + " " + day + ", " + year
  }

  handleShowTileModal = (value, contentType) => {
    this.setState({
      showTileModal: {
        value: value,
        contentType: contentType
      }
    })
  }

  handleHideTileModal = () => {
    this.setState({
      showTileModal: {
        value: '',
        contentType: ''
      }
    })
  }

  handleOpenImageInNewTab = imageUrl => {
    window.open(imageUrl)
  }

  /*****************************************************************************/

  renderLoading = () => {
    return (
      <Row noGutters>
        <Spinner animation='border' variant='light' />
      </Row>
    )
  }

  renderHeaderSection = () => {
    const {
      _id,
      name,
      image,
      location,
      industry,
      description,
      tradeLink,
      offering
    } = this.state.asset.data

    const briefContent = description

    let test
    if (this.state.asset.data.offering) {
      test = this.state.asset.data.offering
    } else {
      test = {}
    }
    const { pending_nda, nda_upload, pending_subscriber, subscriber_upload, completed_order, order_upload } = test

    let linkToTrade
    if (tradeLink) {
      linkToTrade = (
        <NavLink className='button-navlink' to={tradeLink}>
          <Button className='teal-button' size='sm'>
            Trade
          </Button>
        </NavLink>
      )
    }

    const renderLocation = location ? (
      <React.Fragment>
        <FaMapMarkerAlt className='utdp-header-info-icon' />
        {location}
      </React.Fragment>
    ) : ''

    const renderIndustry = industry ? (
      <React.Fragment>
        <FaIndustry className='utdp-header-info-icon' />
        {industry}
      </React.Fragment>
    ) : ''

    return (
      <Row noGutters className='utdp-header-section'>
        <Col>
          <Row noGutters className='utdp-header-name'>
            {name}
            {linkToTrade}
          </Row>
          <Row noGutters className='utdp-header-desc'>
            {description}
          </Row>
          <Row noGutters className='utdp-header-info-row'>
            <Col className='utdp-header-info'>
              <Row noGutters>
                {renderLocation}
              </Row>
            </Col>
            <Col className='utdp-header-info'>
              <Row noGutters>
                {renderIndustry}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col sm={3} className='utdp-token-image-container'>
          <Image src={image} className='utdp-token-image' />
        </Col>
      </Row>
    )
  } // end of renderHeaderSection()

  renderNavigationBar = () => {
    const { currentPosition } = this.state

    let pricingData
    if (this.state.asset.data.tradedSymbol) {
      pricingData = (
        <Col
          className='utdp-nav-item'
          name='Pricing Data'
          onClick={() => this.handleChangeCurrentPosition('Pricing Data')}
          id={currentPosition === 'Pricing Data' ? 'current-position' : ''}>
          <a href='#pricing-data'>Pricing Data</a>
        </Col>
      )
    }
    return (
      <Row noGutters className='utdp-nav-bar'>
        <Col
          className='utdp-nav-item'
          name='Profile Information'
          onClick={() => this.handleChangeCurrentPosition('Profile Information')}
          id={currentPosition === 'Profile Information' ? 'current-position' : ''}>
          <a href='#profile-information'>Profile Information</a>
        </Col>
        <Col
          className='utdp-nav-item'
          name='News'
          onClick={() => this.handleChangeCurrentPosition('News')}
          id={currentPosition === 'News' ? 'current-position' : ''}>
          <a href='#news'>News</a>
        </Col>
        {pricingData}
      </Row>
    )
  }

  // <Col
  //   className='utdp-nav-item'
  //   name='Research'
  //   onClick={() => this.handleChangeCurrentPosition('Research')}
  //   id={currentPosition === 'Research' ? 'current-position' : ''}>
  //   <a href='#research'>Research</a>
  // </Col>

  renderProfileInformation = () => {
    const { sections } = this.state.asset.data
    if (!sections) return

    return (
      <Col noGutters className='utdp-profile-info'>
        <Row noGutters>
          <Col sm={3} md={2} lg={2} xl={2} className='utdp-scrollspy-col'>
            {this.renderScrollspy()}
          </Col>
          <Col>
            {sections.map(section => this.renderIndividualSection(section))}
          </Col>
        </Row>
      </Col>
    )
  }

  renderScrollspy = () => {
    const { sections } = this.state.asset.data

    const scrollspySections = sections.map(section => this.renderIndividualSectionForList(section))
    let scrollspyItems = []
    for (let i = 0; i < sections.length; i++) {
      scrollspyItems.push(sections[i].name)
    }

    return (
      <StickyBox offsetTop={20} offsetBottom={20}>
        <Scrollspy
          items={scrollspyItems}
          currentClassName='is-current-profile-section'
          className='profile-scrollspy'>
          {scrollspySections}
        </Scrollspy>
      </StickyBox>

    )
  }

  renderIndividualSectionForList = section => {
    const { name } = section
    return (
      <a className='individual-section-for-scrollspy'
        id={name + ' section-for-list'} href={`#${name}`}>
        <Button className='teal-button' size='sm'>
          {name}
        </Button>
      </a>
    )
  }

  renderIndividualSection = section => {
    const { name, fields, display } = section
    // console.log(fields)

    if (display == 'List') {
      return (
        <Row noGutters className='utdp-individual-section' key={name}>
          <div className='utdp-anchor' id={name} />
          <Col>
            <h3>{name}</h3>
            <Row noGutters className='utdp-fields-row'>
              {fields.map(field => this.renderIndividualField(field))}
            </Row>
          </Col>
        </Row>
      )
    } else if (display == 'Tile') {
      return (
        <Row noGutters className='utdp-individual-section' key={name}>
          <div className='utdp-anchor' id={name} />
          <Col>
            <h3>{name}</h3>
            <Row noGutters className='utdp-fields-row'>
              {fields.map(field => this.renderIndividualTileField(field))}
            </Row>
          </Col>
        </Row>
      )
    }
  }

  renderIndividualField = field => {
    const { fieldName, fieldType, value, platform, fields, subfield, subfields, displayText } = field
    const key = Math.random()

    // console.log(field)

    let fieldRender
    if (fieldType === 'date') {
      fieldRender = <p className='date-p'>{this.formatDate(value)}</p>
    } else if (fieldType === 'image') {
      fieldRender = this.renderFieldImage(value)
    } else if (fieldType == 'video') {
      fieldRender = this.renderVideo(value)
    } else if (fieldType == 'document') {
      fieldRender = this.renderDocument(value)
    } else if (fieldType == 'social') {
      fieldRender = this.renderSocial(value, platform)
    } else if (fieldType == 'link') {
      fieldRender = this.renderLink(value, displayText)
    } else if (fieldType == 'website') {
      fieldRender = <a target='_blank' href={value}>Link</a>
    } else if (fieldType == 'object') {
      fieldRender = this.renderObject(subfields)
    } else if (fieldType == 'multi') {
      fieldRender = this.renderMultiFields(subfield, subfields)
    } else {
      fieldRender = <div className='utdp-individual-field-text-content'>{value}</div>
    }

    let hidden = ''
    if (!fieldName || !value && fieldType !== 'multi') hidden = 'hidden'

    return (
      <Row noGutters className='utdp-individual-field' key={Math.random()} id={hidden}>
        <label className='utdp-individual-field-label'>
          {fieldName}
        </label>
        <Col>
          {fieldRender}
        </Col>
      </Row>
    )
  }

  renderIndividualTileField = (field, position) => {
    const { fieldName, fieldType, value, displayText, platform, fields, subfield, subfields } = field
    const key = fieldName + ' ' + value

    // if none of the subfields have values, skip rendering them
    if (subfields && fieldType == 'multi') {
      let count = 0
      if (subfields[0].fieldType) {
        for (let i = 0; i < subfields[0].subfields.length; i++) {
          if (!subfields[0].subfields[i].value) count++
        }
        if (count == subfields[0].subfields.length) return
      } else {
        for (let i = 0; i < subfields.length; i++) {
          if (!subfields[i].value) count++
        }
        if (count == subfields.length) return
      }
    }

    if (subfields && fieldType == 'object') {
      let count = 0
      for (let i = 0; i < subfields.length; i++) {
        if (!subfields[i].value) count++
      }
      if (count == subfields.length) return
    }

    let fieldRender
    let configureButtons
    if (fieldType === 'date') {
      fieldRender = <p className='date-p'>{this.formatDate(value)}</p>
    } else if (fieldType === 'image') {
      fieldRender = this.renderTileImage(value)
    } else if (fieldType == 'video') {
      fieldRender = this.renderVideo(value)
    } else if (fieldType == 'document') {
      fieldRender = this.renderTileDocument(value)
    } else if (fieldType == 'social') {
      fieldRender = this.renderSocial(value, platform)
    } else if (fieldType == 'link') {
      fieldRender = this.renderLink(value, displayText)
    } else if (fieldType == 'website') {
      fieldRender = <a target='_blank' href={value}>Link</a>
    } else if (fieldType == 'object') {
      fieldRender = (
        <div className='utdp-tile-object-container'>
          {subfields.map(subfield => this.renderTileSubfield(subfield))}
        </div>
      )
    } else if (fieldType == 'paragraph') {
      fieldRender = this.renderTileParagraph(value)
    } else if (fieldType == 'multi') {
      // console.log(field)
      fieldRender = this.renderTileMultifields(subfield, subfields)
    } else {
      fieldRender = <p className='utdp-string-field'>{value}</p>
    }

    let hidden = ''
    if (!fieldName || !value && fieldType !== 'multi') hidden = 'hidden'

    let className
    if (position == 'inner') {
      className = 'utdp-individual-tile-field-inner'
    } else {
      className = 'utdp-individual-tile-field'
    }

    return (
      <div className={className} key={Math.random()} id={hidden}>
        <label>{fieldName}</label>
        {fieldRender}
      </div>
    )
  }

  renderTileMultifields = (subfield, subfields) => {
    if (!subfield) return

    let content
    if (subfield.fieldType == 'dropdown') {
      content = subfields.map(innerSubfield => this.renderMultifieldDropdown(innerSubfield))
    } else if (subfield.fieldType == 'object') {
      content = subfields[0].subfields.map(innerSubfield => this.renderIndividualTileField(innerSubfield, 'inner'))
    }

    return content
  }

  renderTileMultifieldDropdown = subfield => {
    return (
      <Row noGutters key={Math.random()} className='utdp-tile-test'>
        <div className='fieldName'>
          {subfield.fieldName}
        </div>
        <div className='value'>
          {subfield.value}
        </div>
      </Row>
    )
  }

  renderTileImage = value => {
    return (
      <Row noGutters className='utdp-tile-test'>
        <Image
          onClick={() => this.handleShowTileModal(value, 'image')}
          src={value} className='utdp-tile-image' />
      </Row>
    )
  }

  renderTileParagraph = value => {
    let renderValue
    if (value.length > 120) {
      renderValue = value.slice(0, 120) + '...'
    } else {
      renderValue = value
    }
    return (
      <Row noGutters className='utdp-tile-paragraph'>
        <div onClick={() => this.handleShowTileModal(value, 'paragraph')}
          className='utdp-text-container'>
          {renderValue}
        </div>
      </Row>
    )
  }

  renderTileDocument = value => {
    return (
      <Row noGutters className='utdp-tile-test'>
        <a href={value} target='_blank' className='utdp-social-icon-link tile-icon'>
          <AiOutlineFile />
        </a>
      </Row>
    )
  }

  renderTileSubfield = subfield => {
    const { fieldName, fieldType, value } = subfield

    let content
    const key = fieldName + ', ' + value
    if (fieldType == 'image') {
      content = (
        <Row noGutters className='utdp-tile-object-image-row'>
          <img className='utdp-tile-image' src={value} key={key} />
        </Row>
      )
    } else {
      content = (
        <div className='utdp-tile-subfield' key={key}>
          {value}
        </div>
      )
    }

    return content
  }

  renderTileModal = () => {
    const { value, contentType } = this.state.showTileModal
    let content
    if (contentType == 'paragraph') {
      content = value
    } else if (contentType == 'image') {
      content = (
        <Row noGutters className='utdp-tile-image-row'>
          <img src={value} className='utdp-tile-modal-image'/>
        </Row>
      )
    }

    return (
      <Modal
        centered
        className='admin-modal'
        onHide={this.handleHideTileModal}
        show={this.state.showTileModal.value ? true : false}>
        <Modal.Body>
          {content}
        </Modal.Body>
      </Modal>
    )
  }

  renderLink = (value, displayText) => {
    return (
      <Row noGutters className='utdp-form-link-row'>
        <a href={value} target='_blank'>
          {displayText}
        </a>
      </Row>
    )
  }

  renderSocial = (value, platform) => {
    if (!value) return
    let icon, newValue
    if (platform == 'Website') icon = <FaGlobe />
    else if (platform == 'Twitter') icon = <FaTwitter />
    else if (platform == 'LinkedIn') icon = <FaLinkedinIn />
    else if (platform == 'Facebook') icon = <FaFacebookF />
    else if (platform == 'Telegram') icon = <FaTelegram />
    else if (platform == 'Medium') icon = <FaMediumM />

    if (!value.includes('http://')) {
      newValue = `http://${value}`
    } else {
      newValue = value
    }
    return (
      <Row noGutters className='utdp-social-icon-row'>
        <a href={newValue} target='_blank' className='utdp-social-icon-link'>
          {icon}
        </a>
      </Row>
    )
  }

  renderDocument = doc => {
    return (
      <Row noGutters className='utdp-doc-row'>
        <a target='_blank' href={doc}>View Document</a>
      </Row>
    )
  }

  renderVideo = video => {
    if (video) {
      let videoId, videoLink, array
      if (video.includes('watch?v=')) {
        array = video.split('watch?v=')
      } else {
        array = video.split('/')
      }

      videoId = array[array.length - 1]
      videoLink = `https://youtube.com/embed/${videoId}`

      return (
        <Row noGutters className='utdp-image-row'>
          <Iframe
            url={videoLink}
            className='utdp-video' />
        </Row>
      )
    }
  }

  renderFieldImage = image => {
    return (
      <Row noGutters className='utdp-image-row'>
        <Image
          onClick={() => this.handleOpenImageInNewTab(image)}
          src={image} className='utdp-image' />
      </Row>
    )
  }

  renderObject = subfields => {
    return (
      <Row noGutters className='utdp-subfields-row'>
        <Col>
          {subfields.map(subfield => this.renderObjectSubfield(subfield))}
        </Col>
      </Row>
    )
  }

  renderObjectSubfield = subfield => {
    const { fieldType, fieldName, value, displayText, platform } = subfield
    let fieldRender
    if (fieldType === 'date') {
      fieldRender = <p className='date-p'>{this.formatDate(value)}</p>
    } else if (fieldType === 'image') {
      fieldRender = this.renderFieldImage(value)
    } else if (fieldType == 'video') {
      fieldRender = this.renderVideo(value)
    } else if (fieldType == 'document') {
      fieldRender = this.renderDocument(value)
    } else if (fieldType == 'social') {
      fieldRender = this.renderSocial(value, platform)
    } else if (fieldType == 'link') {
      fieldRender = this.renderLink(value, displayText)
    } else if (fieldType == 'website') {
      fieldRender = <a target='_blank' href={value}>Link</a>
    } else {
      fieldRender = <p className='utdp-string-field'>{value}</p>
    }

    return (
      <Row noGutters className='utdp-subfield-row'>
        {fieldRender}
      </Row>
    )
  }

  renderMultiFields = (subfield, subfields) => {
    let content
    if (subfield.fieldType == 'dropdown') {
      content = subfields.map(innerSubfield => this.renderMultifieldDropdown(innerSubfield))
    } else if (subfield.fieldType == 'object') {
      content = subfields[0].subfields.map(innerSubfield => this.renderIndividualField(innerSubfield))
    }

    return content
  }

  renderMultifieldDropdown = subfield => {
    return (
      <div key={Math.random()} className='multifield-subfield-dropdown-container'>
        <div className='fieldName'>
          {subfield.fieldName}
        </div>
        <div className='value'>
          {subfield.value}
        </div>
      </div>
    )
  }

  renderNews = () => {
    const { searchList, loaded, loading } = this.state.news

    let content
    if (loading) {
      content = this.renderLoading()
    } else if (loaded) {
      if (searchList.total > 0) {
        content = (
          <Row noGutters className='utdp-news-row'>
            <div className='utdp-news-container'>
              {searchList.docs.map(article => this.renderIndividualArticle(article))}
            </div>
          </Row>
        )
      }
    }

    return (
      <Row noGutters className='utdp-profile-info'>
        <Col>
          {content}
          <Row noGutters className='utdp-see-more-row'>
            <NavLink className='see-more-content' href={`/news?name=${this.state.asset.data.name}`}>
              <Button className='teal-button'>
                Explore Our Full News Library
              </Button>
            </NavLink>
          </Row>
        </Col>
      </Row>
    )
  }

  renderIndividualArticle = article => {
    const {
      date,
      author,
      link,
      id,
      title,
      content
    } = article

    const returnDate = this.formatDate(date)

    let newContent = ReactHtmlParser(content)
    if (newContent.length > 200) {
      newContent = newContent.splice(0, 200) + '...'
    }

    return (
      <Row noGutters className='utdp-individual-article'>
        <Col>
          <a href={link} target='_blank'>
            <h4 className='utdp-article-title'>{title}</h4>
          </a>
          <div className='utdp-article-info'>
            {returnDate} | {author}
          </div>
          <a href={link} target='_blank' className='utdp-article-link'>
            <Button className='teal-button' size='sm'>
              Read More
            </Button>
          </a>
        </Col>
      </Row>
    )
  }

  renderResearch = () => {
    const { data, loading, loaded } = this.state.research

    let content
    if (loaded) {
      if (data.total > 0) {
        content = (
          <Row noGutters className='utdp-news-row'>
            <div className='utdp-news-container'>
              {data.docs.map(research => this.renderIndividualResearch(research))}
            </div>
          </Row>

        )
      }
    } else if (loading) {
      content = this.renderLoading()
    }

    return (
      <Row noGutters className='utdp-profile-info'>
        <Col>
          {content}
          <Row noGutters className='utdp-see-more-row'>
            <NavLink href={`/research?name=${this.state.asset.data.name}`}>
              <Button className='teal-button'>
                Explore Our Full Research Library
              </Button>
            </NavLink>
          </Row>
        </Col>
      </Row>
    )
  }

  renderIndividualResearch = research => {
    const { _id, image, title, user_id, datePublished, summary } = research

    let displaySummary
    if (summary && summary.length > 220) {
      displaySummary = summary.slice(0, 220) + '...'
    } else {
      displaySummary = summary
    }

    let userImage
    if (user_id.image) {
      userImage = <Image src={user_id.image} className='utdp-user-image' />
    } else {
      userImage = <AiOutlineUser className='utdp-user-image-icon' />
    }

    const userName = user_id.name ? user_id.name : user_id._id

    const navName = title.replace(/\s+/g, '-').toLowerCase() + '-' + _id
    const link = `/research/${navName}`

    const returnDate = this.formatDate(datePublished)

    return (
      <Row noGutters className='utdp-individual-article' key={_id}>
        <Col>
          <a href={link} target='_blank'>
            <h4 className='utdp-article-title'>{title}</h4>
          </a>
          <div className='utdp-article-info'>
            {userImage} {userName} | {returnDate}
          </div>
          <div className='utdp-article-summary'>
            {displaySummary}
          </div>
          <a href={link} target='_blank' className='utdp-article-link'>
            <Button className='teal-button' size='sm'>
              Read More
            </Button>
          </a>
        </Col>
        <div className='utdp-article-image-container'>
          <a href={link} target='_blank'>
            <Image src={image} className='discover-article-image' />
          </a>
        </div>
      </Row>
    )
  }

  renderPricingData = () => {
    const { data, loaded, loading } = this.state.pricing
    console.log(this.state.pricing)
    let content
    if (loaded) {
      const { price, volume, high, low } = data
      content = (
        <Row noGutters>
          <Col>
            <Row noGutters className='light-chart-info-row'>
              <div className='light-chart-info-label'>
                Price: {price}
              </div>
              <div className='light-chart-info-label'>
                Volume: {volume}
              </div>
              <div className='light-chart-info-label'>
                High: {high}
              </div>
              <div className='light-chart-info-label'>
                Low: {low}
              </div>
            </Row>
            <Row noGutters className='light-chart-row'>
              <div className='light-chart-container'>
                <LightWeightChart data={data} />
              </div>
            </Row>
          </Col>
        </Row>
      )
    } else if (loading) {
      content = this.renderLoading()
    }

    return content
  }

  renderComingSoon = () => {
    return (
      <Row noGutters className='utdp-coming-soon'>
        Coming soon!
      </Row>
    )
  }

  renderOffering = () => {
    const { data, loaded, loading } = this.state.asset

    let content
    if (loaded) {
      const { days_left, individual_confirmed, individual_ioi, individual_orders,institutional_confirmed, institutional_ioi, institutional_orders, percent_filled, total_amount, total_confirmed, total_ioi, total_offered, total_orders } = data.offering
      const profileName = data.name
      content = (
        <Row noGutters>
          <Col>
            <Row noGutters className='utdp-offering-row'>
              <Col>
              <div className="utdp-offering-wrapper">
                <Row noGutters className='utdp-offering-header-row'>
                  Offering Status
                </Row>
                <Row noGutters className='utdp-offering-table-row'>
                  <div className='utdp-table-title utdp-table-profile-name'>
                    {profileName}
                  </div>
                  <Col>
                    <Table className='utdp-offering-table'>
                      <thead>
                        <tr>
                          <th>Total Offering</th>
                          <th>Percent of Capital Filled</th>
                          <th>Days Left</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>$ {total_offered}</td>
                          <td>{percent_filled} %</td>
                          <td>{days_left}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>

                </Row>
                </div>
              </Col>
            </Row>
            <Row noGutters className='utdp-offering-row'>
              <Col>
              <div className="utdp-offering-wrapper">
                <Row noGutters className='utdp-offering-header-row'>
                  Offering Breakdown
                </Row>
                <Row noGutters className='utdp-offering-table-row'>
                  <Table bordered className='utdp-offering-table'>
                    <thead>
                      <tr>
                        <th>Total Amount</th>
                        <th>Total IOI</th>
                        <th>Total Confirmed</th>
                        <th>Total Orders</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>$ {total_amount}</td>
                        <td>$ {total_ioi}</td>
                        <td>$ {total_confirmed}</td>
                        <td>{total_orders}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
                <Row noGutters className='utdp-offering-table-row'>
                  <div className='utdp-table-title'>
                    Institutional IOI's
                  </div>
                  <Col>
                    <Table bordered className='utdp-offering-table'>
                      <thead>
                        <tr>
                          <th>Institutional IOI</th>
                          <th>Institutional Confirmed</th>
                          <th>Institutional Orders</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>$ {institutional_ioi}</td>
                          <td>$ {institutional_confirmed}</td>
                          <td>{institutional_orders}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row noGutters className='utdp-offering-table-row'>
                  <div className='utdp-table-title'>
                    Individual IOI's
                  </div>
                  <Col>
                    <Table bordered className='utdp-offering-table'>
                      <thead>
                        <tr>
                          <th>Individual IOI</th>
                          <th>Individual Confirmed</th>
                          <th>Individual Orders</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>$ {individual_ioi}</td>
                          <td>$ {individual_confirmed}</td>
                          <td>{individual_orders}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )
    } else if (loading) {
      content = this.renderLoading()
    }

    return content
  }

  renderMetaTags = () => {
    const { data, loaded } = this.state.asset
    return (
      <div>
        <MetaTags>
          <title>{data.name || ''}</title>
          <meta name='description' content={data.description || ''}/>
          <meta name='title' property='og:title' content={(data.name || '') + ' ' + '- News, Information, Pricing, Research'}/>
          <meta property='og:description' content={data.description}/>
          <meta property='og:image' content={data.image || ''}/>
          <meta property='og:type' content='website'/>
          <meta property='og:url' content={window.location.href} />
          <meta property='twitter:title' content={(data.name || '') + ' ' + '- News, Information, Pricing, Research'}/>
          <meta property='twitter:description' content={data.description || ''}/>
          <meta property='twitter:image' content={data.image || ''}/>
          <meta property='twitter:site' content='Velocity Channel'/>
          <meta name='robots' content='nofollow' />
        </MetaTags>
      </div>
    )
  }

  render() {
    const { data, loaded, loading } = this.state.asset
    const { currentPosition } = this.state

    let innerContent
    if (currentPosition === 'Profile Information') innerContent = this.renderProfileInformation()
    else if (currentPosition === 'News') innerContent = this.renderNews()
    else if (currentPosition === 'Research') innerContent = this.renderResearch()
    else if (currentPosition === 'Pricing Data') innerContent = this.renderPricingData()
    else if (currentPosition === 'Offering') innerContent = this.renderOffering()

    let content
    if (loaded) {
      content = (
        <Container fluid className='asset-profile-container'>
          {this.renderTileModal()}
          {this.renderHeaderSection()}
          {this.renderNavigationBar()}
          <div className='utdp-inner-content-div'>
            {innerContent}
          </div>
        </Container>
      )
    } else if (loading) {
      content = this.renderLoading()
    }

    return (
      <Container fluid className='user-content-container'>
        {content}
      </Container>
    )
  }
/************************* END OF RENDER FUNCTIONS ****************************/
}  // end of UserTokenDetailPage

export default UserTokenDetailPage
