import { connect } from 'react-redux';
import FullReportPage from './FullReportPage.js';

import {
  getResearchFeedRequest,
  readArticleRequest,
  unpublishReportRequest
} from '../../../redux/actions/researches';

const mapStateToProps = state => ({
  reports: state.research.feeds,
  report: state.reports.report,
  articles : state.research.currentArticle
});

const mapDispatchToProps = {
  getResearchFeedRequest,
  readArticleRequest,
  unpublishReportRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FullReportPage);
