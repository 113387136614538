import { connect } from 'react-redux';
import ResearchApplicationInformation from './ResearchApplicationInformation'
import './ResearchApplicationInformation.css'

const mapStateToProps = state => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ResearchApplicationInformation)
