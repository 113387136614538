import * as CONSTANTS from '../constants/resetpassword';
import { CLEAR_USER_CACHE } from '../constants/account'

const initialState = {
  resetPassword: {
    success: false,
    error: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case CLEAR_USER_CACHE:
    // state = initialState
    case CONSTANTS.RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        resetPassword: {
          success: false,
        },
      };
    }
    case 'RESET_PASSWORD_HISTORY': {
      return {
        ...state,
        resetPassword: {
          success: false,
        },
      };
    }
    case CONSTANTS.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          success: true,
        },
      };
    case CONSTANTS.RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPassword: {
          error: action.err,
        },
      };
    default:
      return state;
  }
};
