import React from 'react';
import PrivacyPolicy from './PrivacyPolicy';
import { Container } from 'react-bootstrap';

export default () => (
  <div className='terms-of-service'>

    <Container>
            <div className="privacyb">
                <h1>
                    PRIVACY POLICY
                </h1>
                <p>
                        Velocity Channel provides up to date and
                        comprehensive research and news on the technologies,
                        organizations, and assets driving the digital
                        asset revolution.<br/>

                </p>
                <div  className="mainbody">
                    <div className="leftsite">
                        <h4>Personal information regarding website activity</h4>

                    </div>
                    <div className="rightsite">

                        <p> When you access The Velocity Channel, we automatically collect certain
                            information about your device, including information about your web browser,
                            IP address, time zone, and some of the cookies that are installed on your
                            device. Moreover, as you browse The Velocity Channel, we collect information
                            about the specific web pages that you access, what external websites or search
                            terms referred you to The Velocity Channel, and how you interact with The Velocity
                            Channel.

                        </p>
                    </div>
                </div>

                <div  className="mainbody">
                <hr/>
                    <div className="leftsite">
                        <h4>Data collection technologies and methods</h4>

                    </div>
                    <div className="rightsite">
                        <h6>Cookies:</h6>
                        <p>Cookies are small encrypted text files that are usually placed in a visitor’s
                            browser directories and are accessed when the user’s device or computer
                            browser loads The Velocity Channel. <br/> <br/>
                            We use cookies to track advertisements and make sure that you do not see the same ad multiple times.<br/> <br/>
                            We also use cookies to show ads that are the most relevant to you, based on your location
                            and language preferences, while utilising them in facilitating the enhancement of website
                            features and ads
                        </p>

                        <h6>Log files:</h6>
                        <p>Track the activity that occurs on The Velocity Channel site, which collects data including IP address,
                        browser type, internet service provider, referring/exit pages and data/time stamps.</p> <br/>
                        <h6>Web beacons/action tags/pixels:</h6>
                        <p>These tags are electronic files that record information about how you browse the site and
                        are used to measure the effectiveness of the advertisement.</p>


                    </div>

                </div>
                <div  className="mainbody">
                    <hr/>
                    <div className="leftsite">
                        <h4>How we use your personal information</h4>

                    </div>
                    <div className="rightsite">

                        <p> The Velocity Channel uses and tracks the personal information as it is stated
                            in this Privacy Policy and uses it for the following purposes: To improve the site
                            features and enhance user experience on The Velocity Channel by providing more
                            personalised content and advertising. Furthermore, we use it to ensure the technical
                            functionality of the website.


                        </p>
                    </div>
                </div>
                <div  className="mainbody">
                    <hr/>
                    <div className="leftsite">
                        <h4>Sharing of your personal information</h4>

                    </div>
                    <div className="rightsite">

                        <p> The Velocity Channel does not sell or disclose your personal information to
                            other people or non-affiliated companies, except to provide you with product
                            and services, when we have your permission and during the situations listed
                            below: <br/> <br/>
                            We share your personal information to comply with applicable laws and regulations,
                           to respond to subpoena, search warrant or other lawful requests for information we receive.<br/> <br/>

                           As described above, we may use your personal information to provide you with personalized advertising
                           or marketing communications that may be of relevance to you. Consequently, we may share the information
                           that we collect to other companies such as direct marketing firms and other third parties whom we have
                           marketing collaborations with. <br/><br/>
                           We may also share your personal information with other third parties that support the enhancement of The
                           Velocity Channel by providing tools that help us understand how to use your personal information. An
                           example could be Google Analytics, which provides insights on how you navigate through the site and how
                           you accessed The Velocity Channel. This benefits our internal performance analysis and evaluation regarding
                           our ambition to provide you with the best and most relevant content.<br/><br/>
                           Furthermore, we may share with third parties certain aggregated, non-personal information
                           such as total amount of users that clicked on a piece of content, or how many times the
                           website was accessed through a particular channel.




                        </p>
                    </div>
                </div>
                <div  className="mainbody">
                <hr/>
                    <div className="leftsite">
                        <h4>Your rights</h4>

                    </div>
                    <div className="rightsite">

                        <p> If you are a European resident, you have the right to access the personal information we
                            hold about you and to ask that your personal information be corrected, updated or deleted.
                            If you would like to exercise this right, please contact us using the contact information below.<br/> <br/>
                            The Velocity Channel is GDPR compliant.<br/> <br/>
                            We process your information to pursue our legitimate business goals as listed above. Consequently,
                            your personal information may be transferred to other parts of the world, such as the United States and Canada.


                        </p>
                    </div>
                </div>
                 <div className="pfooter">

                 <p> For more information about our privacy practices, or if you have any questions or complaints,
                     please contact us by email:</p>
                     <a href="info@velocityledger.com">info@velocityledger.com</a>

                 </div>


            </div>
    </Container>

  </div>
);
