import * as CONSTANTS from '../constants/charts';


/***************************** CHOROPLETH ACTIONS *****************************/
export const getChoroplethDataRequest = data => ({
    type: CONSTANTS.GET_CHOROPLETH_DATA_REQUEST,
    data,
})

export const getChoroplethDataSuccess = data => ({
    type: CONSTANTS.GET_CHOROPLETH_DATA_SUCCESS,
    data,
})

export const getChoroplethDataError = error => ({
    type: CONSTANTS.GET_CHOROPLETH_DATA_ERROR,
    error,
})
/************************* END OF CHOROPLETH ACTIONS **************************/


/***************************** PIE_CHART ACTIONS *****************************/
export const getPieChartDataRequest = () => ({
    type: CONSTANTS.GET_PIE_CHART_DATA_REQUEST,
})

export const getPieChartDataSuccess = data => ({
    type: CONSTANTS.GET_PIE_CHART_DATA_SUCCESS,
    data,
})

export const getPieChartDataError = error => ({
    type: CONSTANTS.GET_PIE_CHART_DATA_ERROR,
    error,
})
/**************************** END OF PIE_CHART ACTIONS ************************/
