import { connect } from 'react-redux';
import BarChart from './BarChart';
import './BarChart.css';
// import {
//     getBarChartDataRequest,
// } from '../../redux/actions/charts';

const mapStateToProps = state => ({
    // choroplethData: state.charts.choroplethData,
})

export default connect(
    mapStateToProps,
)(BarChart)
