import React, { PureComponent } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Spinner,
  Jumbotron,
} from 'react-bootstrap'
import { MdArrowBack } from 'react-icons/md';


class ListingRequests extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      selectedRequest: {},
      viewingRequestDetails: false,
      offset: 0,
      currentPage: 1,
    }
  }

  componentDidMount() {
    this.props.getListingRequestsRequest()
  }

  formatDate = dateInput => {
    let date = new Date(dateInput);
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    return month + " " + day + ", " + year;
  }

  handleSelectRequest = request => {
    this.setState({selectedRequest: request, viewingRequestDetails: true})
  }

  handleBack = () => {
    this.setState({selectedRequest: {}, viewingRequestDetails: false})
  }

  renderRequestDetails = request => {
    const date = request.date ? this.formatDate(request.date) : null
    const description = request.description || null
    const email = request.email || null
    const link = request.link || null
    const location = request.location || null
    const name = request.name || null
    const stoDate = request.stoDate ? this.formatDate(request.stoDate) : null
    return (
      <React.Fragment>
          <Col className='user-list-selected-user-col'>
              <Row>
                  <div
                      className='back-to-user-list-button'
                      onClick={this.handleBack}
                  >
                      <MdArrowBack />
                      Back to the User List
                  </div>
              </Row>
              <Row>
                <Col className='user-list-selected-user-info-col'>
                  <Row className='user-list-selected-user-info-row'>
                    <Col><label>Name</label></Col>
                    <Col><p>{name}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                    <Col><label>Email</label></Col>
                    <Col><p>{email}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                    <Col><label>Description</label></Col>
                    <Col><p>{description}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                    <Col><label>Location</label></Col>
                    <Col><p>{location}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                    <Col><label>Date Submitted</label></Col>
                    <Col><p>{date}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                    <Col><label>STO Date</label></Col>
                    <Col><p>{stoDate}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                    <Col>
                        <label>Status</label>
                    </Col>
                    <Col>
                      <Row className='user-list-button-row'>
                        <Button
                          id='application-approval'
                          className='user-list-toggle-button'>
                          {/* // onClick={() => this.handleApprove(userId, date, username)}> */}
                          Approve
                        </Button>
                        <Button
                          id='application-deny'
                          className='user-list-toggle-button'>
                          {/* // onClick={() => this.handleShowModal(application)}> */}
                          Deny
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
          </Col>
      </React.Fragment>
    )
  }

  renderRequest = request => {
    const date = request.date ? this.formatDate(request.date) : null
    const description = request.description || null
    const email = request.email || null
    const link = request.link || null
    const location = request.location || null
    const name = request.name || null
    const stoDate = request.stoDate ? this.formatDate(request.stoDate) : null
      return (
        <Row className='user-list-user-row'>
            <Col>
                <div
                  className='user-list-username'
                  onClick={() => this.handleSelectRequest(request)}
                >
                    {name}
                </div>
            </Col>
            <Col className='user-list-user-col'>
                <div className='user-list-status'>
                  {email}
                </div>
            </Col>
            <Col className='user-list-user-col'>
                <div className='user-list-status'>
                  Date Submitted: {date}
                </div>
            </Col>
        </Row>
      )
  }

  renderListingRequestsList = () => {
    const { listingRequests } = this.props
    return (
      <React.Fragment>
        <Row className='user-list-top-row'>
          <Row>
            <h1>Listing Requests</h1>
          </Row>
        </Row>
        <Row className='user-list-items-row'>
          <Col className='user-list-items-col'>
            <Row className='user-list-users-container'>
              <Col>
                {listingRequests.loaded ? listingRequests.requests.map(request => this.renderRequest(request)) : null}
                {/* {hits.map(hit => this.renderContributorApplication(hit))} */}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='user-list-pagination-row'>
          <Col className='pagination-section'>
            {listingRequests.total > 10 ? this.renderPagination() : null}
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  renderPagination = () => {
    const { total } = this.props.listingRequests
    const { currentPage } = this.state
    const pageCount = Math.ceil(total / 10) || 1
    let items = []
    for (let number = 1; number <= pageCount; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage}>
          {number}
        </Pagination.Item>
      )
    }

    return (
      <Pagination onClick={e => this.handlePageClick(e)}>{items}</Pagination>
    )
  }

  handlePageClick = e => {
    const pageNumber = parseInt(e.target.innerText) || 1
    this.setState({currentPage: pageNumber, offset: (pageNumber - 1) * 10})
    this.props.getListingRequestsRequest((pageNumber - 1) * 10)
  }

  renderLoading = () => (
    <Jumbotron className='loading-spinner-container'>
      <Spinner className='loading-spinner' animation="border" role="status" variant='primary'>
        <span className="sr-only">Loading...</span>
      </Spinner>
    </Jumbotron>
  ) // end of renderLoading()

  render() {
    const { selectedRequest, viewingRequestDetails } = this.state
    if (this.props.listingRequests.loaded) {
      return (
        <Container className='user-lookup-container'>
          <Row className='user-lookup-section'>
            {/* {this.renderContributorApplicationLookup()} */}
          </Row>
          <Row className='user-list-section'>
            {viewingRequestDetails ? this.renderRequestDetails(selectedRequest) : this.renderListingRequestsList() }
          </Row>
        </Container>
      )
    } else {
      return (
        <Container className='user-lookup-container'>
          {this.renderLoading()}
        </Container>
      )
    }
  }
}

export default ListingRequests
