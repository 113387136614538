import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { history } from '../../../configureStore';
import * as CONSTANTS from '../../constants/account'
import request from '../../../utils/apiRequest';
import * as ACTIONS from '../../actions/account'
import { showLoginModal, setUserAuthenticatedStatus } from '../../actions/login'

function* checkUserStatus(action) {
  // occurs on load site (Routes.js)
  // check if localStorage.refreshToken is set
  if (localStorage.refreshToken) {
    try {
    // if SET
      //   make call to accessToken w/ refreshToken in body
      const responseData = yield call(
        request,
        'account/accessToken',
        'POST',
        { refreshToken: localStorage.refreshToken },
        true,
      )
      //   if SUCCESS
      //     store accessToken in localStorage
      localStorage.setItem('accessToken', responseData.data.access_token)
      //     treat user as authenticated (set login props authenticated to true)
      yield put(setUserAuthenticatedStatus(true))
    } catch (err) {
      if (err.reason === 'Bad token' || err.reason === 'Invalid token') {
        //   if FAIL
        //     prompt user to log in
        localStorage.removeItem('accessToken')
        yield put(showLoginModal())
        //     if USER LOGS IN
        //       follow log in flow
        //       treat user as authenticated (set login props authenticated to true)
        //     if USER CLICKS OUT OF LOGIN MODAL
        //       remove refreshToken + accessToken (wipe localStorage)
        //       treat user as unauthenticated (set login props authenticated to false)
        //       THIS SHOULD BE A SEPARATE FUNCTION
      }
    }
  } else {
    // if NOT SET
    //   check if localStorage.accessToken is set
    if (localStorage.accessToken) {
      //   if SET
      //     load app as normal
      //     treat user as unauthenticated (set login props authenticated to false)
      try {
        yield put(setUserAuthenticatedStatus(false))
      } catch (err) {}
    } else {
      //   if NOT SET (user has never visited site)
      //     make call to accessToken w/ empty body
      try {
        const responseData = yield call(
          request,
          'account/accessToken',
          'POST',
          null,
          true,
        )
        //     store response.access_token in localStorage.accessToken
        localStorage.setItem('accessToken', responseData.access_token)
      } catch (err) {
        //console.log("ERR");
      }
      //     treat user as unauthenticated
      yield put(setUserAuthenticatedStatus(false))
    }
  }
}

function* getMyAccount(action) {
  try {
    const responseData = yield call(
      request,
      'account',
      'GET',
    )
    yield put(ACTIONS.getMyAccountSuccess(responseData.data));
  } catch (err) {
    yield put(ACTIONS.getMyAccountError(err));
  }
}

function* profileImageUpload(action) {
  try {
    const { file } = action;
    let formData = new FormData();
    formData.append('file', file);
    const responseData = yield call(
      request,
      'file/profileImage',
      'POST',
      formData,
      true,
      true,
    )
    yield put(ACTIONS.profileImageUploadSuccess(responseData));
    yield put(getMyAccount())
  } catch (err) {
    // toast.error(err)
  }
}

function* contributorApplicationDocumentUpload(action) {
  try {
    const { doc } = action
    let formData = new FormData()
    formData.append('file', doc)
    const responseData = yield call(
      request,
      'file/document',
      'POST',
      formData,
      true,
      true,
    )
    yield put(ACTIONS.contributorApplicationDocumentUploadSuccess(responseData.s3Location, action.fileType))
  } catch (err) {}
}

function* getUserList(action) {
    try {
        const responseData =  yield call(
          request, 'admin/userList', 'GET', null, true
        )
        yield put(ACTIONS.getUserListSuccess(responseData))
    } catch (err) {
        // toast.error(err.reason)
    }
}

function* searchUser(action) {
  try {
    const { username } = action
    const user = yield call(
      request,
      `admin/userLookup?username=${username}`,
      'GET',
      null,
      true,
    )
    yield put(ACTIONS.searchUserSuccess(user));
  } catch (err) {
    // toast.error(err.reason)
  }
}

function* editAccountInfo(action) {
    try {
      const {
        first,
        last,
        email,
        username,
        image,
      } = action.id

      const requestData = {}

      if (first) {
        requestData.first = first
      }
      if (last) {
        requestData.last = last
      }
      if (email) {
        requestData.email = email
      }
      if (username) {
        requestData.username = username
      }
      if (image) {
        requestData.image = image
      }
      const responseData = yield call(
          request,
          'account/edit',
          'POST',
          requestData,
          true,
      )
      // toast.success('Saved Changes')
      yield put(ACTIONS.editAccountSuccess(responseData))
    } catch (err) {
        yield put(ACTIONS.editAccountError(err))
    }
} // end of editAccountInfo(action)

function* changeAdminStatus(action) {
  try {
    const {
        userId,
        username,
    } = action
    const requestData = {
        userId,
    }

    yield call(request, 'admin/makeAdmin', 'POST', requestData, true);
    toast.success('User Admin status changed to TRUE');

    const user = yield call(
        request,
        `admin/userLookup?username=${username}`,
        'GET',
        true,
    )
    yield put(ACTIONS.searchUserSuccess(user));

    const responseData =  yield call(
        request,
        'admin/userList',
        'GET',
        true,
    )
    yield put(ACTIONS.getUserListSuccess(responseData))
    } catch (err) {
    // toast.error(err.reason);
    }
}

function* changeContributorStatus(action) {
  try {
    const {
      userId,
      isContributor,
      username,
    } = action
    const requestData = {
      userId,
    }
    if (isContributor) {
        yield call(request, 'admin/removeContributor', 'POST', requestData, true);
        toast.success('User Contributor status changed to FALSE');
    } else {
        yield call(request, 'admin/makeContributor', 'POST', requestData, true);
        toast.success('User Contributor status changed to TRUE');
    }

    const user = yield call(
        request,
        `admin/userLookup?username=${username}`,
        'GET',
        true,
    )
    yield put(ACTIONS.searchUserSuccess(user));

    const responseData =  yield call(
        request,
        'admin/userList',
        'GET',
        true,
    )
    yield put(ACTIONS.getUserListSuccess(responseData))
  } catch (err) {
    // toast.error(err.reason);
  }
}

// function* clearAccountData(action) {
//   // this will clear the accountData on logout
//
// }

function* submitContributorApplication(action) {
  try {
    const {
      category,
      conflicts,
      contributor_type,
      description,
      industry,
      linkedin,
      name,
      regions,
      resume,
      sample,
      website,
      years_experience,
      userId,
      username,
    } = action.data
    let requestData = {}

    if (contributor_type === 'Individual') {
      requestData = {
        category,
        conflicts,
        contributor_type,
        description,
        industry,
        linkedin,
        regions,
        resume,
        sample,
        years_experience,
      }
    } else if (contributor_type === 'Organization') {
      requestData = {
        contributor_type,
        name,
        description,
        regions,
        industry,
        category,
      }
    }
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('source')) {
      // on research/apply component - check for a source query string parameter
      // if set - log the event with a source field
      const source = urlParams.get('source')
      requestData.source = source
    }

    const responseData = yield call(
      request,
      'research/contributorApp',
      'POST',
      requestData,
      true,
    )
    window.location.reload()
    toast.success('Your application has been submitted!')

    let eventRequestData = {
      type: 'contributorApp',
      userId: userId,
      username: username,
    }
    if (urlParams.has('source')) {
      // on research/apply component - check for a source query string parameter
      // if set - log the event with a source field
      const source = urlParams.get('source')
      eventRequestData.source = source
    }
    const event = yield call(
      request,
      'analytics/event',
      'POST',
      eventRequestData,
      true,
    )

    // history.push('/account/settings')
  } catch (err) {
    // toast.error(err)
  }
} // end of submitContributorApplication(action)

function* editContributorProfile(action) {
  const { userInfo } = action
  try {
    const responseData = yield call(
      request,
      'research/contributor/editProfile',
      'POST',
      userInfo,
      true,
    )
    yield put(ACTIONS.getMyAccountRequest())
  } catch (err) {
    // toast.error(err)
  }
}

function* editContributorAccount(action) {
  try {
    const responseData = yield call(
        request,
        'account/edit',
        'POST',
        action.userInfo,
        true,
    )
    toast.info('Saved changes to your account')
    yield put(ACTIONS.editAccountSuccess(responseData))
    yield put(ACTIONS.getMyAccountRequest())
  } catch (err) {
    yield put(ACTIONS.editAccountError())
    toast.error(err.reason)
  }
}

function* getDrafts(action) {
  try {
    const { userId } = action
    const responseData = yield call(
      request,
      `research/contributor/drafts?userId=${userId}`,
      'GET',
      null,
      true,
    )
    yield put(ACTIONS.getDraftsSuccess(responseData))
  } catch(err) {
    // toast.error(err)
  }
}

function* getPublishedReports(action) {
  try {
    const { userId } = action
    const responseData = yield call(
      request,
      `research/userPublished?userId=${userId}`,
      'GET',
      null,
      true,
    )
    yield put(ACTIONS.getPublishedReportsSuccess(responseData))
  } catch(err) {
    // toast.error(err)
  }
}

function* profileImage(action) {
  try {
    const { file } = action;
    let formData = new FormData();
    formData.append('file', file);
    const responseData = yield call(
      request,
      'file/profileImage',
      'POST',
      formData,
      true,
      // true,
    );
    yield put(ACTIONS.profileImageUploadSuccess(responseData));
  } catch (err) {
    // toast.error(err)
  }
}

function* sendInvites(action) {
  try {
    const { array } = action
    const data = { emails: JSON.stringify(array) }
    const responseData = yield call(
      request,
      'account/invite',
      'POST',
      data,
      true,
    )
    toast.info('Invitations Sent')
  } catch (err) {}
}

function* uploadAvatarImage(action) {
  try {
    const { file } = action
    let formData = new FormData()
    formData.append('file', file)
    const responseData = yield call(
      request,
      'file/profileImage',
      'POST',
      formData,
      true,
      true,
    )
    yield put(ACTIONS.uploadAvatarImageSuccess(responseData.data.Attributes.image))
    toast.info('Your avatar has been updated')
  } catch (err) {
    yield put(ACTIONS.uploadAvatarImageError(err))
  }
}

function* uploadBannerImage(action) {
  try {
    const { file } = action
    let formData = new FormData()
    formData.append('file', file)
    const responseData = yield call(
      request,
      'file/coverImage',
      'POST',
      formData,
      true,
      true,
    )
    yield put(ACTIONS.uploadBannerImageSuccess(responseData.data.Attributes.coverImage))
    toast.info('Your banner image has been updated')
  } catch (err) {
    yield put(ACTIONS.uploadBannerImageError(err))
  }
}

function* landingPageUserSearch(action) {
  try {
    const { query } = action
    const responseData = yield call(
      request,
      `account/search?q=${query}`,
      'GET',
      null,
      true,
    )
    console.log(responseData)
  } catch (err) {
    yield put(ACTIONS.landingPageUserSearchError(err))
  }
}

function* adminSearchUsers(action) {
  try {
    let url = '/admin/users/search?'
    if (action.params.q) url += `q=${action.params.q}&`
    if (action.params.isAdmin) url += `isAdmin=${action.params.isAdmin}&`
    if (action.params.isContributor) url += `isContributor=${action.params.isContributor}`

    const responseData = yield call(
      request,
      url,
      'GET'
    )

    yield put(ACTIONS.adminSearchUsersSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.adminSearchUsersError(err))
  }
}

function* adminToggleContributor(action) {
  try {
    const body = { userid: action.id }
    const responseData = yield call(
      request,
      'admin/users/toggleContributor',
      'POST',
      body
    )
    console.log(responseData)
    yield put(ACTIONS.adminToggleContributorSuccess())
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.adminToggleContributorError(err))
  }
}

export default function* myAccountSaga() {
  yield takeLatest(CONSTANTS.MY_ACCOUNT_REQUEST, getMyAccount);
  yield takeLatest(CONSTANTS.PROFILE_IMAGE_UPLOAD_REQUEST, profileImageUpload);
  yield takeLatest(CONSTANTS.SEARCH_USER_REQUEST, searchUser);
  yield takeLatest(CONSTANTS.CHANGE_ADMIN_STATUS_REQUEST, changeAdminStatus);
  yield takeLatest(CONSTANTS.CHANGE_CONTRIBUTOR_STATUS_REQUEST, changeContributorStatus);
  yield takeLatest(CONSTANTS.EDIT_ACCOUNT_REQUEST, editAccountInfo)
  yield takeLatest(CONSTANTS.GET_USER_LIST_REQUEST, getUserList)
  yield takeLatest(CONSTANTS.CONTRIBUTOR_APPLICATION_REQUEST, submitContributorApplication)
  yield takeLatest(CONSTANTS.EDIT_CONTRIBUTOR_PROFILE_REQUEST, editContributorProfile)
  yield takeLatest(CONSTANTS.EDIT_CONTRIBUTOR_ACCOUNT_REQUEST, editContributorAccount)
  yield takeLatest(CONSTANTS.GET_PUBLISHED_REPORTS_REQUEST, getPublishedReports)
  yield takeLatest(CONSTANTS.GET_DRAFTS_REQUEST, getDrafts)
  yield takeLatest(CONSTANTS.PROFILE_IMAGE_REQUEST, profileImage)
  yield takeLatest(CONSTANTS.CONTRIBUTOR_APPLICATION_DOCUMENT_UPLOAD_REQUEST, contributorApplicationDocumentUpload)
  yield takeLatest(CONSTANTS.CHECK_USER_STATUS, checkUserStatus)
  yield takeLatest(CONSTANTS.SEND_INVITES_REQUEST, sendInvites)
  yield takeLatest(CONSTANTS.UPLOAD_AVATAR_IMAGE_REQUEST, uploadAvatarImage)
  yield takeLatest(CONSTANTS.UPLOAD_BANNER_IMAGE_REQUEST, uploadBannerImage)
  yield takeLatest(CONSTANTS.LANDING_PAGE_USER_SEARCH_REQUEST, landingPageUserSearch)
  yield takeLatest(CONSTANTS.ADMIN_SEARCH_USERS_REQUEST, adminSearchUsers)
}
