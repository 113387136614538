import { connect } from 'react-redux';
import TokenList from './TokenList';
import './TokenList.css';
import {
  getTokenListRequest,
  deleteTokenRequest,
} from '../../../redux/actions/tokenlist';

const mapStateToProps = state => ({
  tokenList: state.tokens.tokens.tokenList,
});

const mapDispatchToProps = {
  getTokenListRequest,
  deleteTokenRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TokenList);
