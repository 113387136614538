import { connect } from 'react-redux';
import Research from './Research';
import {
  setFilterKey,
  getUpvotesRequest,
  getDownvotesRequest,
  resetSearchFeed,
  createDraftRequest,
  saveDraftRequest,
  getUsersDraftsRequest,
  deleteDraftRequest,
  publishArticleRequest,
  articleImageUploadRequest,
  clearArticleImage,
  uploadDocumentRequest,
  clearDocumentData,
  getTopicsListRequest,
  saveDraftReset,
} from '../../redux/actions/researches';

const mapStateToProps = state => ({
  accountInfo: state.account.myAccount.info,
  drafts: state.research.drafts.list,
  newestDraft: state.research.newestDraft,
  saving: state.research.saving,
  articleImage: state.research.articleImage,
  doc: state.research.document,
  topics: state.research.topics,
  publishingArticle: state.research.publishingArticle,
});

const mapDispatchToProps = {
  setFilterKey,
  getUpvotesRequest,
  getDownvotesRequest,
  resetSearchFeed,
  createDraftRequest,
  saveDraftRequest,
  getUsersDraftsRequest,
  deleteDraftRequest,
  publishArticleRequest,
  articleImageUploadRequest,
  clearArticleImage,
  uploadDocumentRequest,
  clearDocumentData,
  getTopicsListRequest,
  saveDraftReset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Research);
