import * as CONSTANTS from '../constants/tokenform';
import { CLEAR_USER_CACHE } from '../constants/account'

const initialState = {
  token: {
    item: [],
    error: '',
  },
  publicURL: {
    url: '',
    docURL: '',
    fieldType: '',
    error: '',
  },
  resumeUrl: {
    url: '',
    docUrl: '',
    error: '',
  },
  sampleUrl: {
    url: '',
    docUrl: '',
    error: '',
  },
  legalForm: {
    url: '',
    docUrl: '',
    fieldType: '',
    error: '',
    fileName: '',
  },
  formDocuments: {
    docs: []
  },
  listingRequests: {
    requests: [],
    total: 0,
    loaded: false,
  },
  tokenTemplates: {
    templates: {},
    loaded: false,
    loading: false,
    error: ''
  },
  tokenTemplateToEdit: {
    template: {},
    loading: false,
    loaded: false,
    error: '',
  },
  savingTemplate: {
    loading: false,
    error: ''
  },
  publishedTemplates: {
    templates: {},
    loading: false,
    loaded: false,
    error: ''
  },
  tokenForm: {
    form: {},
    loading: false,
    loaded: false,
    error: '',
    validity: true,
    validated: false,
  },
  tokenFormImage: {
    image: '',
    fieldIndex: '',
    sectionIndex: '',
    loading: false,
    loaded: false,
    error: ''
  },
  tokenFormDocument: {
    file: '',
    fieldIndex: '',
    sectionIndex: '',
    loading: false,
    loaded: false,
    error: ''
  },
  savingForm: {
    loading: false,
    error: ''
  },
  tokenProfilesList: {
    profiles: {},
    loading: false,
    loaded: false,
    error: ''
  },
  savingAssetProfile: {
    loading: false,
    error: ''
  },
  unpublishAssetProfile: {
    id: '',
    complete: false,
    performing: false,
    error: ''
  },
  unpublishTokenTemplate: {
    id: '',
    complete: false,
    performing: false,
    error: ''
  },
  assetProfileEditors: {
    editors: [],
    loading: false,
    loaded: false,
    error: ''
  },
  searchEditors: {
    editors: [],
    loading: false,
    loaded: false,
    error: ''
  },
  userAssetToView: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  adminTopbarLinks: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.GET_TOKEN_SUCCESS:
      return {
        ...state,
        token: {
          ...state.token,
          item: action.token,
        },
      };
    case CONSTANTS.GET_TOKEN_ERROR:
      return {
        ...state,
        token: {
          ...state,
          error: action.error,
        },
      };
    case CONSTANTS.RESET_TOKEN_FORM_ITEMS:
      return {
        ...state,
        token: {},
      };
    case CONSTANTS.UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        publicURL: {
          ...state.publicURL,
          url: null,
        },
      };
    case CONSTANTS.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        publicURL: {
          ...state.publicURL,
          url: action.imageInfo.s3Location,
        },
      };
    case CONSTANTS.UPLOAD_IMAGE_ERROR:
      return {
        ...state,
        publicURL: {
          ...state,
          error: action.error,
        },
      };
    case CONSTANTS.UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        publicURL: {
          ...state.publicURL,
          docURL: null,
        },
      };
    case CONSTANTS.UPLOAD_DOCUMENT_SUCCESS:
      const { fieldType } = action
      if (fieldType === 'resume') {
        return {
          ...state,
          resumeUrl: {
            ...state.resumeUrl,
            docURL: action.documentInfo.s3Location,
          },
        }
      } else if (fieldType === 'sample') {
        return {
          ...state,
          sampleUrl: {
            ...state.sampleUrl,
            docURL: action.documentInfo.s3Location,
          },
        }
      } else {
        return {
          ...state,
          publicURL: {
            ...state.publicURL,
            docURL: action.documentInfo.s3Location,
            fieldType: action.fieldType,
          },
          formDocuments: [
            ...state.formDocuments,
            {
              fileName: action.fileName,
              documentInfo: action.documentInfo,
            }
          ],
        }
      };
    case CONSTANTS.UPLOAD_DOCUMENT_ERROR:
      return {
        ...state,
        publicURL: {
          ...state,
          error: action.error,
        },
      };
    case CONSTANTS.RESET_DOCUMENT_LINK:
      return {
        ...state,
        publicURL: {
          ...state.publicURL,
          docURL: null,
          fieldType: null,
        },
      };
    case CONSTANTS.GET_LISTING_REQUESTS_REQUEST:
      return {
        ...state,
        listingRequests: {
          requests: '',
          total: 0,
          loaded: false,
        },
      }
    case CONSTANTS.GET_LISTING_REQUESTS_SUCCESS:
      return {
        ...state,
        listingRequests: {
          requests: action.listings.requests,
          total: action.listings.total,
          loaded: true,
        },
      }
    case CONSTANTS.GET_TOKEN_TEMPLATES_REQUEST:
      return {
        ...state,
        tokenTemplates: {
          ...state.tokenTemplates,
          loading: true,
          loaded: false,
          error: ''
        }
      }
    case CONSTANTS.GET_TOKEN_TEMPLATES_SUCCESS:
      return {
        ...state,
        tokenTemplates: {
          ...state.tokenTemplates,
          templates: action.data,
          loading: false,
          loaded: true,
        }
      }
    case CONSTANTS.GET_TOKEN_TEMPLATES_ERROR:
      return {
        ...state,
        tokenTemplates: {
          ...state.tokenTemplates,
          loading: false,
          loaded: false,
          error: action.error,
        }
      }
    case CONSTANTS.GET_TOKEN_TEMPLATE_TO_EDIT_REQUEST:
      return {
        ...state,
        tokenTemplateToEdit: {
          ...state.tokenTemplateToEdit,
          loading: true,
          loaded: false,
          error: ''
        }
      }
    case CONSTANTS.GET_TOKEN_TEMPLATE_TO_EDIT_SUCCESS:
      return {
        ...state,
        tokenTemplateToEdit: {
          ...state.tokenTemplateToEdit,
          template: action.template,
          loading: false,
          loaded: true,
        }
      }
    case CONSTANTS.GET_TOKEN_TEMPLATE_TO_EDIT_ERROR:
      return {
        ...state,
        tokenTemplateToEdit: {
          ...state.tokenTemplateToEdit,
          loading: false,
          loaded: false,
          error: action.error,
        }
      }
    case CONSTANTS.HANDLE_CLEAR_TOKEN_TEMPLATE_TO_EDIT:
      return {
        ...state,
        tokenTemplateToEdit: {
          ...state.tokenTemplateToEdit,
          template: {},
          loading: false,
          loaded: false,
          error: '',
        },
      }
    case CONSTANTS.CREATE_TOKEN_TEMPLATE_SUCCESS:
      const newData = state.tokenTemplates.templates.data
      newData.push(action.template.template)
      return {
        ...state,
        tokenTemplates: {
          ...state.tokenTemplates,
          templates: {
            ...state.tokenTemplates.templates,
            data: newData
          }
        }
      }
    case CONSTANTS.EDIT_TOKEN_TEMPLATE_REQUEST:
      return {
        ...state,
        savingTemplate: {
          ...state.savingTemplate,
          loading: true
        }
      }
    case CONSTANTS.EDIT_TOKEN_TEMPLATE_SUCCESS:
      return {
        ...state,
        savingTemplate: {
          ...state.savingTemplate,
          loading: false
        }
      }
    case CONSTANTS.EDIT_TOKEN_TEMPLATE_ERROR:
      return {
        ...state,
        savingTemplate: {
          ...state.savingTemplate,
          loading: false,
          error: action.error
        }
      }
    case CONSTANTS.GET_PUBLISHED_TEMPLATES_REQUEST:
      return {
        ...state,
        publishedTemplates: {
          ...state.publishedTemplates,
          loading: true,
          loaded: false
        }
      }
    case CONSTANTS.GET_PUBLISHED_TEMPLATES_SUCCESS:
      return {
        ...state,
        publishedTemplates: {
          ...state.publishedTemplates,
          templates: action.data,
          loading: false,
          loaded: true
        }
      }
    case CONSTANTS.GET_PUBLISHED_TEMPLATES_ERROR:
      return {
        ...state,
        publishedTemplates: {
          ...state.publishedTemplates,
          loading: false,
          loaded: false,
          error: action.error
        }
      }
    case CONSTANTS.CREATE_TOKEN_FORM_FROM_TEMPLATE_SUCCESS:
      const newPublishedTemplatesData = state.tokenProfilesList.profiles
      const newProfileObject = { _source: action.tokenForm.profile }
      newPublishedTemplatesData.push(newProfileObject)
      return {
        ...state,
        tokenProfilesList: {
          ...state.tokenProfilesList,
          profiles: newPublishedTemplatesData
        }
      }
    case CONSTANTS.RESET_TOKEN_FORM_PARAMS:
      return {
        ...state,
        tokenForm: {
          form: {},
          loading: false,
          loaded: false,
          error: ''
        },
      }
    case CONSTANTS.UPLOAD_TOKEN_FORM_IMAGE_REQUEST:
      return {
        ...state,
        tokenFormImage: {
          ...state.tokenFormImage,
          // image: '',
          fieldIndex: action.fieldIndex,
          sectionIndex: action.sectionIndex,
          loading: true,
          loaded: false,
          error: ''
        },
      }
    case CONSTANTS.UPLOAD_TOKEN_FORM_IMAGE_SUCCESS:
      return {
        ...state,
        tokenFormImage: {
          ...state.tokenFormImage,
          image: action.image,
          fieldIndex: action.fieldIndex,
          sectionIndex: action.sectionIndex,
          loading: false,
          loaded: true,
          error: ''
        },
      }
    case CONSTANTS.UPLOAD_TOKEN_FORM_IMAGE_ERROR:
      return {
        ...state,
        tokenFormImage: {
          ...state.tokenFormImage,
          loading: false,
          loaded: false,
          error: action.error
        },
      }
    case CONSTANTS.UPLOAD_TOKEN_FORM_DOCUMENT_REQUEST:
      return {
        ...state,
        tokenFormDocument: {
          ...state.tokenFormDocument,
          fieldIndex: action.fieldIndex,
          sectionIndex: action.sectionIndex,
          loading: true,
          loaded: false,
          error: ''
        },
      }
    case CONSTANTS.UPLOAD_TOKEN_FORM_DOCUMENT_SUCCESS:
      return {
        ...state,
        tokenFormDocument: {
          ...state.tokenFormDocument,
          file: action.file,
          fieldIndex: action.fieldIndex,
          sectionIndex: action.sectionIndex,
          loading: false,
          loaded: true,
          error: ''
        },
      }
    case CONSTANTS.UPLOAD_TOKEN_FORM_DOCUMENT_ERROR:
      return {
        ...state,
        tokenFormDocument: {
          ...state.tokenFormDocument,
          loading: false,
          loaded: false,
          error: action.error
        },
      }
    case CONSTANTS.EDIT_TOKEN_FORM_REQUEST:
      return {
        ...state,
        savingForm: {
          ...state.savingForm,
          loading: true
        }
      }
    case CONSTANTS.EDIT_TOKEN_FORM_SUCCESS:
      return {
        ...state,
        savingForm: {
          ...state.savingForm,
          loading: false
        }
      }
    case CONSTANTS.EDIT_TOKEN_FORM_ERROR:
      return {
        ...state,
        savingForm: {
          ...state.savingForm,
          loading: false,
          error: action.error
        }
      }
    case CONSTANTS.GET_TOKEN_PROFILES_LIST_REQUEST:
      return {
        ...state,
        tokenProfilesList: {
          ...state.tokenProfilesList,
          loading: true,
          loaded: false,
          error: ''
        }
      }
    case CONSTANTS.GET_TOKEN_PROFILES_LIST_SUCCESS:
      return {
        ...state,
        tokenProfilesList: {
          ...state.tokenProfilesList,
          profiles: action.data,
          loading: false,
          loaded: true,
          error: ''
        }
      }
    case CONSTANTS.GET_TOKEN_PROFILES_LIST_ERROR:
      return {
        ...state,
        tokenProfilesList: {
          ...state.tokenProfilesList,
          loading: false,
          loaded: false,
          error: action.error,
        }
      }
    case CONSTANTS.SELECT_ASSET_PROFILE_REQUEST:
      return {
        ...state,
        tokenForm: {
          ...state.tokenForm,
          loading: true,
          loaded: false
        }
      }
    case CONSTANTS.SELECT_ASSET_PROFILE_SUCCESS:
      return {
        ...state,
        tokenForm: {
          ...state.tokenForm,
          form: action.tokenForm,
          loading: false,
          loaded: true
        }
      }
    case CONSTANTS.SELECT_ASSET_PROFILE_ERROR:
      return {
        ...state,
        tokenForm: {
          ...state.tokenForm,
          loading: false,
          loaded: false,
          error: action.error
        }
      }
    case CONSTANTS.PUBLISH_ASSET_PROFILE_REQUEST:
      return {
        ...state,
        savingForm: {
          ...state.savingForm,
          loading: true
        }
      }
    case CONSTANTS.PUBLISH_ASSET_PROFILE_SUCCESS:
      return {
        ...state,
        savingForm: {
          ...state.savingForm,
          loading: false
        }
      }
    case CONSTANTS.PUBLISH_ASSET_PROFILE_ERROR:
      return {
        ...state,
        savingForm: {
          ...state.savingForm,
          loading: false,
          error: action.error,
        }
      }
    case CONSTANTS.SEARCH_ASSET_PROFILES_REQUEST:
      return {
        ...state,
        tokenProfilesList: {
          ...state.tokenProfilesList,
          loading: true,
          loaded: false,
          error: ''
        }
      }
    case CONSTANTS.SEARCH_ASSET_PROFILES_SUCCESS:
      return {
        ...state,
        tokenProfilesList: {
          ...state.tokenProfilesList,
          profiles: action.data,
          loading: false,
          loaded: true,
          error: ''
        }
      }
    case CONSTANTS.SEARCH_ASSET_PROFILES_ERROR:
      return {
        ...state,
        tokenProfilesList: {
          ...state.tokenProfilesList,
          loading: false,
          loaded: false,
          error: action.error,
        }
      }
    case CONSTANTS.UNPUBLISH_ASSET_PROFILE_REQUEST:
      return {
        ...state,
        unpublishAssetProfile: {
          ...state.unpublishAssetProfile,
          id: '',
          complete: false,
          performing: true,
          error: ''
        }
      }
    case CONSTANTS.UNPUBLISH_ASSET_PROFILE_SUCCESS:
      return {
        ...state,
        unpublishAssetProfile: {
          ...state.unpublishAssetProfile,
          id: action.profileId,
          complete: true,
          performing: false,
          error: ''
        }
      }
    case CONSTANTS.UNPUBLISH_ASSET_PROFILE_ERROR:
      return {
        ...state,
        unpublishAssetProfile: {
          ...state.unpublishAssetProfile,
          id: '',
          complete: false,
          performing: false,
          error: action.error
        }
      }
    // case CONSTANTS.UNPUBLISH_TOKEN_TEMPLATE_REQUEST:
    //   return {
    //     ...state,
    //     unpublishTokenTemplate: {
    //       ...state.unpublishTokenTemplate,
    //       id: '',
    //       complete: false,
    //       performing: true,
    //       error: ''
    //     }
    //   }
    // case CONSTANTS.UNPUBLISH_TOKEN_TEMPLATE_SUCCESS:
    //   return {
    //     ...state,
    //     unpublishTokenTemplate: {
    //       ...state.unpublishTokenTemplate,
    //       id: action.templateId,
    //       complete: true,
    //       performing: false,
    //       error: ''
    //     }
    //   }
    // case CONSTANTS.UNPUBLISH_TOKEN_TEMPLATE_ERROR:
    //   return {
    //     ...state,
    //     unpublishTokenTemplate: {
    //       ...state.unpublishTokenTemplate,
    //       id: '',
    //       complete: false,
    //       performing: false,
    //       error: action.error
    //     }
    //   }
    case CONSTANTS.RESET_UNPUBLISH_ASSET_PROFILE:
      return {
        ...state,
        unpublishAssetProfile: {
          id: '',
          complete: false,
          performing: false,
          error: ''
        },
      }
    case CONSTANTS.RESET_UNPUBLISH_TOKEN_TEMPLATE:
      return {
        ...state,
        unpublishTokenTemplate: {
          id: '',
          complete: false,
          performing: false,
          error: ''
        },
      }
    case CONSTANTS.GET_ASSET_PROFILE_EDITORS_REQUEST:
      return {
        ...state,
        assetProfileEditors: {
          ...state.assetProfileEditors,
          loading: true,
          loaded: false,
        }
      }
    case CONSTANTS.GET_ASSET_PROFILE_EDITORS_SUCCESS:
      return {
        ...state,
        assetProfileEditors: {
          ...state.assetProfileEditors,
          loading: false,
          loaded: true,
          editors: action.data,
        }
      }
    case CONSTANTS.GET_ASSET_PROFILE_EDITORS_ERROR:
      return {
        ...state,
        assetProfileEditors: {
          ...state.assetProfileEditors,
          loading: false,
          loaded: false,
          error: action.error
        }
      }
    case CONSTANTS.SEARCH_USERS_FOR_ASSET_PROFILES_REQUEST:
      return {
        ...state,
        searchEditors: {
          ...state.searchEditors,
          loading: true,
          // loaded: false,
        }
      }
    case CONSTANTS.SEARCH_USERS_FOR_ASSET_PROFILES_SUCCESS:
      return {
        ...state,
        searchEditors: {
          ...state.searchEditors,
          loading: false,
          loaded: true,
          editors: action.data
        }
      }
    case CONSTANTS.SEARCH_USERS_FOR_ASSET_PROFILES_ERROR:
      return {
        ...state,
        searchEditors: {
          ...state.searchEditors,
          loading: false,
          loaded: false,
          error: action.error
        }
      }
    case CONSTANTS.RESET_SEARCH_USERS_AP_PARAMS:
      return {
        ...state,
        searchEditors: {
          editors: [],
          loading: false,
          loaded: false,
          error: ''
        }
      }

    case CONSTANTS.USER_VIEW_ASSET_REQUEST:
      return {
        ...state,
        userAssetToView: {
          ...state.userAssetToView,
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.USER_VIEW_ASSET_SUCCESS:
      return {
        ...state,
        userAssetToView: {
          ...state.userAssetToView,
          loading: false,
          loaded: true,
          data: action.data
        }
      }

    case CONSTANTS.USER_VIEW_ASSET_ERROR:
      return {
        ...state,
        userAssetToView: {
          ...state.userAssetToView,
          loading: false,
          loaded: false,
          error: action.error
        }
      }

    case CONSTANTS.GET_LINKS_FOR_ADMIN_TOPBAR_REQUEST:
      return {
        ...state,
        adminTopbarLinks: {
          ...state.adminTopbarLinks,
          loading: true,
          loaded: false,
        }
      }

    case CONSTANTS.GET_LINKS_FOR_ADMIN_TOPBAR_SUCCESS:
      return {
        ...state,
        adminTopbarLinks: {
          ...state.adminTopbarLinks,
          loading: false,
          loaded: true,
          data: action.data
        }
      }

    case CONSTANTS.GET_LINKS_FOR_ADMIN_TOPBAR_ERROR:
      return {
        ...state,
        adminTopbarLinks: {
          ...state.adminTopbarLinks,
          loading: false,
          loaded: false,
          error: action.error
        }
      }
    default:
      return state;
  }
};
