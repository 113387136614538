import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { history } from "../../../configureStore";
import {
  submitContributorError,
  getContributorsSuccess,
  getContributorsError,
  getContributorSuccess,
  getContributorError,
  contributorApproveError,
  getContributorApplicationsSuccess,
  searchContributorApplicationsSuccess,
  getContributorAnalyticsSuccess,
  getFeaturedContributorsSuccess,
  getFeaturedContributorsError,
  getContributorsReportSuccess,
  getContributorsReportError,
  getContributorReportsSuccess,
  getContributorStatsSuccess,
  getLineGraphDataSuccess,
  getTokenGraphDataSuccess,
  sendContributorInvitesSuccess,
  getPieChartDataSuccess,
} from "../../actions/contributor";
import { searchUserRequest } from "../../actions/account";
import {
  POST_CONTRIBUTOR_REQUEST,
  GET_CONTRIBUTORS_REQUEST,
  GET_CONTRIBUTOR_REQUEST,
  POST_CONTRIBUTOR_APPROVE_REQUEST,
  GET_CONTRIBUTOR_APPLICATIONS_REQUEST,
  SEARCH_CONTRIBUTOR_APPLICATIONS_REQUEST,
  APPROVE_CONTRIBUTOR_APPLICATION_REQUEST,
  DENY_CONTRIBUTOR_APPLICATION_REQUEST,
  FOLLOW_CONTRIBUTOR_REQUEST,
  GET_CONTRIBUTOR_ANALYTICS_REQUEST,
  FEATURE_CONTRIBUTOR_REQUEST,
  GET_FEATURED_CONTRIBUTOR_REQUEST,
  REMOVE_FEATURED_CONTRIBUTOR_REQUEST,
  GET_CONTRIBUTORS_REPORT_REQUEST,
  CONTRIBUTOR_SEARCH_REQUEST,
  GET_CONTRIBUTOR_REPORTS_REQUEST,
  GET_CONTRIBUTOR_STATS_REQUEST,
  GET_LINE_GRAPH_DATA_REQUEST,
  GET_TOKEN_GRAPH_DATA_REQUEST,
  SEND_CONTRIBUTOR_INVITES_REQUEST,
  GET_CONTRIBUTOR_PIE_CHART_DATA_REQUEST,
} from "../../constants/contributor";
import request from "../../../utils/apiRequest";

function* postContributor(action) {
  try {
    const { data } = action;
    yield call(request, "research/partnership", "POST", data, true);
    toast.success("Success");
    history.push("/admin/contributors");
  } catch (err) {
    yield put(submitContributorError(err));
  }
}

function* getContributors(action) {
  try {
    const requestData = { offset: action.offset };
    const contributors = yield call(
      request,
      "admin/contributorList?",
      "GET",
      requestData,
      true
    );
    yield put(getContributorsSuccess(contributors));
  } catch (err) {
    yield put(getContributorsError(err));
  }
}

function* contributorSearchRequest(action) {
  try {
    const { q } = action;
    const contributors = yield call(
      request,
      `admin/contributorLookup?q=${q}`,
      "GET",
      null,
      true
    );
    yield put(getContributorsSuccess(contributors));
  } catch (err) {
    yield put(getContributorsError(err));
  }
}

function* getFeaturedContributor(action) {
  try {
    const contributors = yield call(
      request,
      "admin/featuredContributors",
      "GET",
      null,
      true
    );
    yield put(getFeaturedContributorsSuccess(contributors));
  } catch (err) {
    yield put(getFeaturedContributorsError(err));
  }
}

function* removeFeaturedContributor(action) {
  try {
    const requestData = { userId: action.userId };

    const responseData = yield call(
      request,
      "admin/removeFeatured",
      "POST",
      requestData,
      true
    );
    toast.success("removed successfully...");
  } catch (err) {
    toast.error(err.reason);
  }
}

function* getContributor(action) {
  try {
    const contributor = yield call(
      request,
      `account?userId=${action.userId}`,
      "GET",
      null,
      true
    );
    yield put(getContributorSuccess(contributor));
    yield put(searchUserRequest(contributor.data.username));
  } catch (err) {
    yield put(getContributorError(err));
  }
}

function* featureContributor(action) {
  try {
    const requestData = { userId: action.userId };

    const responseData = yield call(
      request,
      "admin/featureContributor",
      "POST",
      requestData,
      true
    );
    toast.success("featured successfully...");
  } catch (err) {
    toast.error("featured error");
  }
}

function* approveContributor(action) {
  try {
    const requestData = {
      approved: action.approved,
      userId: action.userId,
      date: action.date
    };

    yield call(
      request,
      "admin/contributor/approval",
      "POST",
      requestData,
      true
    );
    toast.success("Success");
    history.push("/admin/contributors");
  } catch (err) {
    yield put(contributorApproveError(err));
  }
}

function* getContributorApplications(action) {
  try {
    const responseData = yield call(
      request,
      "admin/contributorApplicationsList",
      "GET",
      null,
      true
    )
    yield put(getContributorApplicationsSuccess(responseData))
  } catch (err) {
    // toast.error(err)
  }
}

function* searchContributorApplications(action) {
  try {
    const { username } = action;
    const responseData = yield call(
      request,
      `admin/contributorApplicationsLookup?username=${username}`,
      "GET",
      null,
      true
    );

    yield put(searchContributorApplicationsSuccess(responseData));
  } catch (err) {
    // toast.error(err.reason)
  }
}

function* approveContributorApplication(action) {
  const { userInfo } = action;
  try {
    yield call(request, "admin/makeContributor", "POST", userInfo, true);
    toast.success(
      `${userInfo.username}'s application for Contributor status: approved`
    );
    const responseData = yield call(
      request,
      "/admin/contributorList",
      "GET",
      null,
      true
    );
    yield put(getContributorApplicationsSuccess(responseData));
  } catch (err) {
    // toast.error(err)
  }
}

function* denyContributorApplication(action) {
  try {
    const { username, date, userId, reason } = action.userInfo;
    const user = yield call(
      request,
      `admin/userLookup?username=${username}`,
      "GET",
      null,
      true
    );
    const { email } = user;
    const requestData = {
      userId,
      username,
      date,
      reason,
      email
    };
    yield call(request, "admin/rejectContributor", "POST", requestData, true);
    toast.success(`${username}'s application for Contributor status: denied`);
    const responseData = yield call(
      request,
      "/admin/contributorList",
      "GET",
      null,
      true
    );
    yield put(getContributorApplicationsSuccess(responseData));
  } catch (err) {
    // toast.error(err.reason)
  }
}

function* followContributor(action) {
  try {
    const { userId, contributorId, resourceType } = action;
    // userId - user doing the following
    // resourceId - either contributor or id of the topic
    // resourceType - either "contributor" or "topic"
    const requestData = {
      userId,
      resourceId: contributorId,
      resourceType
    };
    yield call(request, "analytics/event", "POST", requestData, true);
  } catch (err) {
    // toast.error(err)
  }
}

function* getContributorAnalytics(action) {
  try {
    const { userId } = action;
    const responseData = yield call(
      request,
      `analytics/user?userId=${userId}`,
      "GET",
      null,
      true
    );
    yield put(getContributorAnalyticsSuccess(responseData));
  } catch (err) {
    // toast.error(err)
  }
}

function* getContributorsReport(action) {
  try {
    const { userId } = action;
    const responseData = yield call(
      request,
      `admin/reports?userId=${userId}`,
      "GET",
      null,
      true
    );
    yield put(getContributorsReportSuccess(responseData));
  } catch (error) {
    yield put(getContributorsReportError(error));
  }
}

function* getContributorReports(action) {
  try {
    const { username, offset } = action
    const responseData = yield call(
      request,
      `research/userPublished?username=${username}&offset=${offset || 0}`,
      'GET',
      null,
      true,
    )
    yield put(getContributorReportsSuccess(responseData.reports))
  } catch (err) {}
}

function* getContributorStats(action) {
  try {
    const { username } = action
    const responseData = yield call(
      request,
      `research/userProfile?username=${username}`,
      'GET',
      null,
      true,
    )
    yield put(getContributorStatsSuccess(responseData))
  } catch (err) {}
}

function* getLineGraphData(action) {
  try {
    const { timeFrame, id, type, from, to } = action.params
    let responseData
    if (type === 'token') {
      responseData = yield call(
        request,
        `research/contributor/rangeAllocation`,
        'GET',
        null,
        true,
      )
    } else {
      let defaultTimeFrame = 'getDaily'
      let defaultFrom = new Date()
      defaultFrom.setMonth(defaultFrom.getMonth() - 1) // 1 months ago from today
      let defaultTo = new Date()
      let url = `research/contributor/${timeFrame || defaultTimeFrame}?type=${type}&from=${from || defaultFrom}&to=${to || defaultTo}`
      // if (type === 'follow') {
      //   url = `analytics/contributorStats/${timeFrame || defaultTimeFrame}?type=${type}&from=${from || defaultFrom}&to=${to || defaultTo}`
      // }
      if (id) { url += `&id=${id}` }
      responseData = yield call(
        request,
        url,
        'GET',
        null,
        true,
      )
    }
    if (type === 'token') {
      yield put(getPieChartDataSuccess(responseData))
    } else {
      yield put(getLineGraphDataSuccess(responseData))
    }
  } catch (err) {}
}


function* getPieChartData(action) {
  try {
    const responseData = yield call(
      request,
      `research/contributor/rangeAllocation`,
      'GET',
      null,
      true,
    )

    yield put(getPieChartDataSuccess(responseData))
  } catch (err) {}
}


function* getTokenGraphData(action) {
  try {
    let defaultFrom = new Date()
    defaultFrom.setMonth(defaultFrom.getMonth() - 8)
    const to = action.params.to || new Date()
    const from = action.params.from || defaultFrom
    const responseData = yield call(
      request,
      `research/contributor/rangeAllocation?to=${to}&from=${from}`,
      'GET',
      null,
      true,
    )
    yield put(getLineGraphDataSuccess(responseData))
  } catch (err) {}
}

function* sendContributorInvites(action) {
  try {
    const data = { emails: JSON.stringify(action.data) }
    const responseData = yield call(
      request,
      `account/inviteContributor`,
      'POST',
      data,
      true,
    )
    toast.info('Invitations Sent')
  } catch (err) {}
}

export default function* contributorSaga() {
  yield takeLatest(POST_CONTRIBUTOR_REQUEST, postContributor);
  yield takeLatest(GET_CONTRIBUTORS_REQUEST, getContributors);
  yield takeLatest(GET_CONTRIBUTOR_REQUEST, getContributor);
  yield takeLatest(POST_CONTRIBUTOR_APPROVE_REQUEST, approveContributor);
  yield takeLatest(GET_CONTRIBUTOR_APPLICATIONS_REQUEST, getContributorApplications);
  yield takeLatest(SEARCH_CONTRIBUTOR_APPLICATIONS_REQUEST,searchContributorApplications);
  yield takeLatest(APPROVE_CONTRIBUTOR_APPLICATION_REQUEST,approveContributorApplication);
  yield takeLatest(DENY_CONTRIBUTOR_APPLICATION_REQUEST,denyContributorApplication);
  yield takeLatest(FOLLOW_CONTRIBUTOR_REQUEST, followContributor);
  yield takeLatest(GET_CONTRIBUTOR_ANALYTICS_REQUEST, getContributorAnalytics);
  yield takeLatest(FEATURE_CONTRIBUTOR_REQUEST, featureContributor);
  yield takeLatest(GET_FEATURED_CONTRIBUTOR_REQUEST, getFeaturedContributor);
  yield takeLatest(REMOVE_FEATURED_CONTRIBUTOR_REQUEST,removeFeaturedContributor);
  yield takeLatest(GET_CONTRIBUTORS_REPORT_REQUEST, getContributorsReport);
  yield takeLatest(CONTRIBUTOR_SEARCH_REQUEST, contributorSearchRequest);
  yield takeLatest(GET_CONTRIBUTOR_REPORTS_REQUEST, getContributorReports)
  yield takeLatest(GET_CONTRIBUTOR_STATS_REQUEST, getContributorStats)
  yield takeLatest(GET_LINE_GRAPH_DATA_REQUEST, getLineGraphData)
  yield takeLatest(GET_TOKEN_GRAPH_DATA_REQUEST, getTokenGraphData)
  yield takeLatest(SEND_CONTRIBUTOR_INVITES_REQUEST, sendContributorInvites)
  yield takeLatest(GET_CONTRIBUTOR_PIE_CHART_DATA_REQUEST, getPieChartData)
}
