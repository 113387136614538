import { connect } from 'react-redux';
import SuggestionList from './SuggestionList';
import './SuggestionList.css';
import {
  getSuggestionListReqeust,
  removeSuggestionReqeust,
} from '../../../redux/actions/suggestion';

const mapStateToProps = state => ({
  suggestions: state.suggestions.suggestions.list.Items,
});
const mapDispatchToProps = {
  getSuggestionListReqeust,
  removeSuggestionReqeust,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SuggestionList);
