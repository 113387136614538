import { connect } from 'react-redux'
import Article from './Article'
import {
  getUpvotesRequest,
  getDownvotesRequest,
  readArticleRequest,
  getRelatedArticlesRequest,
  postCommentRequest,
  getCommentsRequest,
  submitReportRequest,
} from '../../../redux/actions/researches'
import {
  showLoginModal
} from '../../../redux/actions/login'
import {
  searchCollectionsRequest,
  addArticleToCollectionRequest,
  removeReportFromCollectionRequest,
} from '../../../redux/actions/collections'
import { upvoteRequest, postEventRequest, removeEventRequest,} from '../../../redux/actions/analytics'
import { followContributorRequest } from '../../../redux/actions/contributor'
import './Article.css'

const mapStateToProps = state => ({
  article: state.research.currentArticle,
  account: state.login.accountData,
})

const mapDispatchToProps = {
  readArticleRequest,
  getRelatedArticlesRequest,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Article)
