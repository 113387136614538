import React, { PureComponent } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
} from 'react-bootstrap'
import { MdArrowBack } from 'react-icons/md';
import Paginations from "../../Pagination";

class SpamReports extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      selectedReport: {},
      viewingReportDetails: false,
      offset: 0,
      currentPage: 1,
    }
  }

  componentDidMount() {
    this.props.getReportListRequest(this.state.offset)
  }

  componentDidUpdate() {
  }

  handleSelectReport = report => {
    this.setState({selectedReport: report, viewingReportDetails: true})
  }

  handleBack = () => {
    this.setState({selectedReport: {}, viewingReportDetails: false})
  }

  formatDate = dateInput => {
    let date = new Date(dateInput);
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    return month + " " + day + ", " + year;
  }

  renderReportDetails = report => {
    const date = report.date ? this.formatDate(report.date) : null
    const comment = report.comment || null
    const reason = report.reason || null
    const resourceId = report.resourceId || null
    const resourceCreatorId = report.resourceCreatorId || null
    const userId = report.userId || null
    return (
      <React.Fragment>
          <Col className='user-list-users-container'>
              <Row noGutters>
                  <div className='back-to-user-list-button'
                      onClick={this.handleBack}>
                      <MdArrowBack />
                      Back to the User List
                  </div>
              </Row>
              <Row>
                <Col className='user-list-selected-user-info-col'>
                  <Row className='user-list-selected-user-info-row'>
                    <Col><label>Date Submitted</label></Col>
                    <Col><p>{date}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                    <Col><label>Article</label></Col>
                    <Col><p>{resourceId}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                    <Col><label>Author</label></Col>
                    <Col><p>{resourceCreatorId}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                    <Col><label>User Making the Report</label></Col>
                    <Col><p>{userId}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                    <Col><label>Reason</label></Col>
                    <Col><p>{reason}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                    <Col><label>Comment</label></Col>
                    <Col><p>{comment}</p></Col>
                  </Row>
                  <Row className='user-list-selected-user-info-row'>
                    <Col>
                        <label>Status</label>
                    </Col>
                    <Col>
                      <Row className='user-list-button-row'>
                        <Button
                          id='application-approval'
                          className='user-list-toggle-button'>
                          {/* // onClick={() => this.handleApprove(userId, date, username)}> */}
                          Approve
                        </Button>
                        <Button
                          id='application-deny'
                          className='user-list-toggle-button'>
                          {/* // onClick={() => this.handleShowModal(application)}> */}
                          Deny
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
          </Col>
      </React.Fragment>
    )
  }

  renderReportsList = () => {
    const { reports } = this.props
    return (
      <Col classsName='user-list-users-container'>
        <Row noGutters>
          <Col className='user-list-items-col'>
            <Row className='user-list-users-container'>
              <Col>
                {reports.loaded ? reports.list.requests.map(report => this.renderReport(report)) : null}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='user-list-pagination-row'>
          <Col className='pagination-section'>
            {reports.list.total > 10 ? this.renderPagination() : null}
          </Col>
        </Row>
      </Col>
    )
  }

  renderPagination = () => {
    const { total } = this.props.reports.list
    const { currentPage } = this.state
    const pageCount = Math.ceil(total / 10) || 1
    let items = []
    for (let number = 1; number <= pageCount; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage}>
          {number}
        </Pagination.Item>
      )
    }

    return (
      <Pagination onClick={e => this.handlePageClick(e)}>{items}</Pagination>
    )
  }

  handlePageClick = e => {
    const pageNumber = parseInt(e.target.innerText) || 1
    this.setState({currentPage: pageNumber, offset: (pageNumber - 1) * 10})
    this.props.getReportListRequest((pageNumber - 1) * 10)
  }

  renderReport = report => {
    const date = this.formatDate(report.date) || null
    const article = report.resourceId || null
    const user = report.userId || null
    return (
      <Row className='user-list-user-row'>
          <Col>
            <div
              className='user-list-username'
              onClick={() => this.handleSelectReport(report)}
            >
                {article}
            </div>
          </Col>
          <Col className='user-list-user-col'>
            <div className='user-list-status'>
              Reported By: {user}
            </div>
          </Col>
          <Col className='user-list-user-col'>
            <div className='user-list-status'>
              Date Submitted: {date}
            </div>
          </Col>
      </Row>
    )
  }

  render() {
    const { selectedReport, viewingReportDetails } = this.state
    return (
      <Container className='user-lookup-container'>
        <Row noGutters className='user-list-top-row'>
          <Col>
            <h1>Spam Reports</h1>
          </Col>
        </Row>
        <Row className='user-list-section'>
          {viewingReportDetails ? this.renderReportDetails(selectedReport) : this.renderReportsList() }
        </Row>
      </Container>
    )
  }
}

export default SpamReports
