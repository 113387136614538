import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TokenDetailPage from './TokenDetailPage';
import './TokenDetailPage.css';
import {
  getTokenDetailRequest,
  getCommentsRequest,
  postCommentsRequest,
  getTokenNewsCommentRequest,
  getSeeMoreCommentsRequest,
} from '../../../redux/actions/tokenlist';
import { getNewsRequest } from '../../../redux/actions/news';
import 'react-quill/dist/quill.snow.css';

TokenDetailPage.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

TokenDetailPage.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

/*
 * PropType validation
 */
TokenDetailPage.propTypes = {
  placeholder: PropTypes.string,
};

const mapStateToProps = state => ({
  detail: state.tokens.token.detail,
  comments: state.tokens.comments.comments,
  commentType: state.tokens.commentType,
  replyComments: state.tokens.replyComments.replyComments,
  news: state.tokens.newsComments.newsComments,
  newsComments: state.tokens.news.comments,
  newsItem: state.news.newsItem.news,
});

const mapDispatchToProps = {
  getTokenDetailRequest,
  getCommentsRequest,
  postCommentsRequest,
  getTokenNewsCommentRequest,
  getNewsRequest,
  getSeeMoreCommentsRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TokenDetailPage);
