import { connect } from 'react-redux';
import SearchAssets from './SearchAssets';
import './SearchAssets.css';
import {
  getListOfFiltersForAssetTypeRequest,
  resetListOfFiltersForAssetType,
  getAssetsWithParamsRequest,
  getListOfAssetTypesRequest,
} from '../../../redux/actions/tokenlist';

const mapStateToProps = state => ({
  profileFilters: state.tokens.profileFilters,
  listOfFiltersForAssetType: state.tokens.listOfFiltersForAssetType,
  listOfAssetsForSearch: state.tokens.listOfAssetsForSearch,
  listOfAssetTypes: state.tokens.listOfAssetTypes,
  websiteConfig: state.admin.websiteConfig,
});

const mapDispatchToProps = {
  getListOfFiltersForAssetTypeRequest,
  resetListOfFiltersForAssetType,
  getAssetsWithParamsRequest,
  getListOfAssetTypesRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchAssets);
