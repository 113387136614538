import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { RESET_PASSWORD_REQUEST } from '../../constants/resetpassword';
import {
  resetPasswordSuccess,
  resetPasswordError,
} from '../../actions/resetpassword';
import request from '../../../utils/apiRequest';

export default function* resetPasswordSaga() {
  // yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
}
