import * as CONSTANTS from "../constants/researches";
import { CLEAR_USER_CACHE } from "../constants/account";

const initialState = {
  feeds: {
    list: [],
    loaded: false
  },
  feed: {
    loading: false,
    error: "",
    detail: []
  },
  searchKeys: {
    keyword: "",
    token: "",
    category: ""
  },
  researches: {
    list: [],
    error: "",
    loading: false
  },
  searchResults: {
    list: [],
    loading: true,
    loaded: false,
    error: ""
  },
  upVotes: {
    list: [],
    error: "",
    loading: false
  },
  downVotes: {
    list: [],
    error: "",
    loading: false
  },
  voteUsers: {
    list: [],
    error: "",
    loading: false
  },
  drafts: {
    loading: false,
    list: [],
    error: ""
  },
  newestDraft: {
    data: {}
  },
  saving: false,
  articles: {
    list: [],
    loading: false
  },
  bookmarks: {
    list: [],
    loading: false
  },
  trendingArticles: {
    list: [],
    loading: false,
    loaded: false
  },
  trendingTopics: {
    list: [],
    loading: false,
    loaded: false
  },
  userFeed: {
    list: [],
    loading: false,
    loaded: false
  },
  currentArticle: {
    data: {},
    loading: true,
    loaded: false,
    error: ''
  },
  relatedArticles: {
    articles: [],
    loaded: false
  },
  comments: {
    list: [],
    total: "",
    loaded: false
  },
  articleImage: {
    image: {},
    loaded: false
  },
  document: {
    data: "",
    loaded: false
  },
  topics: {
    list: [],
    loaded: false,
  },
  reports: {
    list: [],
    loaded: false,
  },
  publishingArticle: false,
  reportNotFound: false,
  assetProfiles: {
    profiles: {},
    loaded: false,
    loading: false,
    error: ''
  },
  currentDraft: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  allDrafts: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  assetResearch: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  assetPricingData: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case CLEAR_USER_CACHE:
    //   state = initialState;
    case CONSTANTS.GET_RESEARCH_FEED_REQUEST:
      return {
        ...state,
        feeds: {
          ...state.feeds,
          loading: true
        }
      };
    case CONSTANTS.GET_RESEARCH_FEED_SUCCESS:
      return {
        ...state,
        feeds: {
          ...state.feeds,
          list: action.feeds,
          loading: false,
          loaded: true
        }
      };
    case CONSTANTS.GET_RESEARCH_FEED_ERROR:
      return {
        ...state,
        feeds: {
          ...state.feeds,
          loading: false,
          error: action.error,
          loaded: false
        }
      };
    case CONSTANTS.REPORT_SEARCH_SUCCESS:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          list: action.searchResults,
          loading: false,
          loaded: true,
          error: ""
        }
      };
    case CONSTANTS.REPORT_SEARCH_ERROR:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case CONSTANTS.SET_FILTER_KEYS:
      return {
        ...state,
        searchKeys: {
          ...state.searchKeys,
          keyword: action.data.keyword,
          token: action.data.token,
          category: action.data.category
        }
      };
    case CONSTANTS.GET_FILTER_RESEARCH_REQEUST:
      return {
        ...state,
        researches: {
          ...state.researches,
          loading: true
        }
      };
    case CONSTANTS.GET_FILTER_RESEARCH_SUCCESS:
      return {
        ...state,
        researches: {
          ...state.researches,
          list: action.researches,
          loading: false
        }
      };
    case CONSTANTS.GET_FILTER_RESEARCH_ERROR:
      return {
        ...state,
        researches: {
          ...state.researches,
          loading: false,
          error: action.error
        }
      };
    case CONSTANTS.GET_UP_VOTES_REQUEST:
      return {
        ...state,
        upVotes: {
          ...state.upVotes,
          loading: true
        }
      };
    case CONSTANTS.GET_UP_VOTES_SUCCESS:
      return {
        ...state,
        upVotes: {
          ...state.upVotes,
          list: action.upVotes,
          loading: false
        }
      };
    case CONSTANTS.GET_UP_VOTES_ERROR:
      return {
        ...state,
        upVotes: {
          ...state.upVotes,
          loading: false
        }
      };
    case CONSTANTS.GET_DOWN_VOTES_REQUEST:
      return {
        ...state,
        downVotes: {
          ...state.downVotes,
          loading: true
        }
      };
    case CONSTANTS.GET_DOWN_VOTES_SUCCESS:
      return {
        ...state,
        downVotes: {
          ...state.downVotes,
          list: action.downVotes,
          loading: false
        }
      };
    case CONSTANTS.GET_DOWN_VOTES_ERROR:
      return {
        ...state,
        downVotes: {
          ...state.downVotes,
          loading: false
        }
      };
    case CONSTANTS.GET_REPORT_VOTES_USER_REQUEST:
      return {
        ...state,
        voteUsers: {
          ...state.voteUsers,
          loading: true
        }
      };
    case CONSTANTS.GET_REPORT_VOTES_USER_SUCCESS:
      return {
        ...state,
        voteUsers: {
          ...state.voteUsers,
          list: action.users,
          loading: false
        }
      };
    case CONSTANTS.GET_DRAFT_REQUEST:
      return {
        ...state,
        currentDraft: {
          ...state.currentDraft,
          loading: true,
          loaded: false
        }
      }
    case CONSTANTS.GET_DRAFT_SUCCESS:
      return {
        ...state,
        currentDraft: {
          ...state.currentDraft,
          data: action.data,
          loading: false,
          loaded: true
        }
      }
    case CONSTANTS.GET_DRAFT_ERROR:
      return {
        ...state,
        currentDraft: {
          ...state.currentDraft,
          loading: false,
          loaded: false,
          error: action.error
        }
      }
    case CONSTANTS.RESET_SEARCH_FEED:
      return {
        ...state,
        researches: {
          ...state.researches,
          list: [],
          loading: true
        }
      };
    case CONSTANTS.UPDATE_UPVOTE_COUNT: {
      const {
        feeds: {
          list: { hits }
        }
      } = state;
      const newHits = hits.map(item => {
        if (item._id !== action.id) return item;
        const newItem = { ...item };
        newItem._source.upvotes += action.count;
        return newItem;
      });
      return {
        ...state,
        feeds: {
          ...state.feeds,
          list: {
            ...state.feeds.list,
            hits: newHits
          }
        }
      };
    }
    case CONSTANTS.UPDATE_DOWNVOTE_COUNT: {
      const {
        feeds: {
          list: { hits }
        }
      } = state;
      const newHits = hits.map(item => {
        if (item._id !== action.id) return item;
        const newItem = { ...item };
        newItem._source.downvotes += action.count;
        return newItem;
      });
      return {
        ...state,
        feeds: {
          ...state.feeds,
          list: {
            ...state.feeds.list,
            hits: newHits
          }
        }
      };
    }
    case CONSTANTS.GET_USERS_DRAFTS_REQUEST: {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          loading: true
        }
      };
    }
    case CONSTANTS.GET_USERS_DRAFTS_SUCCESS: {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          list: action.data,
          loading: false
          // list:,
        }
      };
    }
    case CONSTANTS.GET_USERS_DRAFTS_ERROR: {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          loading: false
        }
      };
    }

    case CONSTANTS.CREATE_DRAFT_REQUEST: {
      return {
        ...state,
        currentDraft: {
          ...state.currentDraft,
          loading: true,
          loaded: false
        }
      }
    }

    case CONSTANTS.CREATE_DRAFT_SUCCESS: {
      return {
        ...state,
        currentDraft: {
          ...state.currentDraft,
          loading: false,
          loaded: true,
          data: action.data
        }
      }
    }

    case CONSTANTS.CREATE_DRAFT_ERROR: {
      return {
        ...state,
        currentDraft: {
          ...state.currentDraft,
          loading: false,
          loaded: false,
          error: action.error
        }
      }
    }

    case CONSTANTS.CREATE_DRAFT_CLEAR: {
      return {
        ...state,
        currentDraft: {
          data: {},
          loading: false,
          loaded: false,
          error: ''
        }
      }
    }

    case CONSTANTS.SAVE_DRAFT_REQUEST: {
      return {
        ...state,
        saving: true
      };
    }
    case CONSTANTS.SAVE_DRAFT_SUCCESS: {
      return {
        ...state,
        saving: false
      };
    }
    case CONSTANTS.SAVE_DRAFT_RESET: {
      return {
        ...state,
        saving: false
      };
    }
    case CONSTANTS.SAVE_DRAFT_ERROR: {
      return {
        ...state,
        saving: false
      };
    }
    case CONSTANTS.GET_BOOKMARKS_REQUEST: {
      return {
        ...state,
        bookmarks: {
          ...state.bookmarks,
          loading: true
        }
      };
    }
    case CONSTANTS.GET_BOOKMARKS_SUCCESS: {
      return {
        ...state,
        bookmarks: {
          ...state.bookmarks,
          list: action.bookmarks,
          loading: false
        }
      };
    }
    case CONSTANTS.GET_TRENDING_ARTICLES_REQUEST: {
      return {
        ...state,
        trendingArticles: {
          ...state.trendingArticles,
          loading: true
        }
      };
    }
    case CONSTANTS.GET_TRENDING_ARTICLES_SUCCESS: {
      return {
        ...state,
        trendingArticles: {
          ...state.trendingArticles,
          list: action.articles,
          loading: false,
          loaded: true
        }
      };
    }
    case CONSTANTS.GET_TRENDING_TOPICS_REQUEST: {
      return {
        ...state,
        trendingTopics: {
          ...state.trendingTopics,
          loading: true
        }
      };
    }
    case CONSTANTS.GET_TRENDING_TOPICS_SUCCESS: {
      return {
        ...state,
        trendingTopics: {
          ...state.trendingTopics,
          list: action.topics,
          loading: false,
          loaded: true
        }
      };
    }
    case CONSTANTS.GET_RESEARCH_USER_FEED_REQUEST: {
      return {
        ...state,
        userFeed: {
          ...state.userFeed,
          loading: true
        }
      };
    }
    case CONSTANTS.GET_RESEARCH_USER_FEED_SUCCESS: {
      return {
        ...state,
        userFeed: {
          ...state.userFeed,
          list: action.userFeed,
          loading: false,
          loaded: true
        }
      };
    }
    case CONSTANTS.READ_ARTICLE_REQUEST: {
      return {
        ...state,
        currentArticle: {
          ...state.currentArticle,
          data: {},
          loading: true,
          loaded: false,
        }
      }
    }
    case CONSTANTS.READ_ARTICLE_SUCCESS: {
      return {
        ...state,
        currentArticle: {
          ...state.currentArticle,
          data: action.data,
          loading: false,
          loaded: true
        }
      }
    }
    case CONSTANTS.READ_ARTICLE_ERROR: {
      return {
        ...state,
        currentArticle: {
          ...state.currentArticle,
          loading: false,
          loaded: false,
          error: action.error,
        }
      }
    }
    case CONSTANTS.GET_RELATED_ARTICLES_REQUEST: {
      return {
        ...state,
        relatedArticles: {
          ...state.relatedArticles,
          articles: [],
          loaded: false
        }
      };
    }
    case CONSTANTS.GET_RELATED_ARTICLES_SUCCESS: {
      return {
        ...state,
        relatedArticles: {
          ...state.relatedArticles,
          articles: action.articles,
          loaded: true
        }
      };
    }
    case CONSTANTS.GET_ARTICLE_COMMENTS_REQUEST: {
      return {
        ...state,
        comments: {
          ...state.comments,
          list: [],
          total: "",
          loaded: false
        }
      };
    }
    case CONSTANTS.GET_ARTICLE_COMMENTS_SUCCESS: {
      return {
        ...state,
        comments: {
          ...state.comments,
          list: action.comments.comments,
          total: action.comments.total,
          loaded: true
        }
      };
    }
    case CONSTANTS.ARTICLE_IMAGE_UPLOAD_REQUEST: {
      return {
        ...state,
        articleImage: {
          ...state.articleImage,
          image: {},
          loaded: false
        }
      };
    }
    case CONSTANTS.ARTICLE_IMAGE_UPLOAD_SUCCESS: {
      return {
        ...state,
        articleImage: {
          ...state.articleImage,
          image: action.image.s3Location,
          loaded: true
        }
      };
    }
    case CONSTANTS.CLEAR_ARTICLE_IMAGE: {
      return {
        ...state,
        articleImage: {
          image: {},
          loaded: false
        }
      };
    }
    case CONSTANTS.ARTICLE_UPLOAD_DOCUMENT_REQUEST: {
      return {
        ...state,
        document: {
          data: "",
          loaded: false
        }
      };
    }
    case CONSTANTS.ARTICLE_UPLOAD_DOCUMENT_SUCCESS: {
      return {
        ...state,
        document: {
          data: action.data,
          loaded: true
        }
      };
    }
    case CONSTANTS.CLEAR_DOCUMENT_DATA: {
      return {
        ...state,
        document: {
          data: "",
          loaded: false
        }
      };
    }
    case CONSTANTS.GET_TOPICS_LIST_REQUEST: {
      return {
        ...state,
        topics: {
          ...state.topics,
          list: [],
          loaded: false
        }
      };
    }
    case CONSTANTS.GET_TOPICS_LIST_SUCCESS: {
      return {
        ...state,
        topics: {
          ...state.topics,
          list: action.topics,
          loaded: true
        }
      };
    }
    case CONSTANTS.GET_ARTICLES_BY_CATEGORY_REQUEST: {
      return {
        ...state,
        feeds: {
          list: {},
          loaded: false
        }
      };
    }
    case CONSTANTS.GET_ARTICLES_BY_CATEGORY_SUCCESS: {
      return {
        ...state,
        feeds: {
          list: action.articles,
          loaded: true
        }
      };
    }
    case CONSTANTS.SEARCH_ARTICLES_REQUEST: {
      return {
        ...state,
        feeds: {
          list: {},
          loaded: false
        }
      };
    }
    case CONSTANTS.SEARCH_ARTICLES_SUCCESS: {
      return {
        ...state,
        feeds: {
          list: action.articles,
          loaded: true
        }
      };
    }
  case CONSTANTS.PUBLISH_ARTICLE_REQUEST: {
    return {
      ...state,
      publishingArticle: true,
    }
  }
  case CONSTANTS.PUBLISH_ARTICLE_SUCCESS: {
    return {
      ...state,
      publishingArticle: false,
    }
  }
  case CONSTANTS.GET_REPORT_LIST_REQUEST: {
    return {
      ...state,
      reports: {
        ...state.reports,
        list: [],
        loaded: false,
      }
    }
  }
  case CONSTANTS.GET_REPORT_LIST_SUCCESS: {
    return {
      ...state,
      reports: {
        ...state.reports,
        list: action.data,
        loaded: true,
      }
    }
  }
  case CONSTANTS.REPORT_NOT_FOUND: {
    return {
      ...state,
      reportNotFound: true,
    }
  }
  case CONSTANTS.GET_ASSET_PROFILES_REQUEST: {
    return {
      ...state,
      assetProfiles: {
        ...state.assetProfiles,
        loading: true,
        loaded: false,
      }
    }
  }
  case CONSTANTS.GET_ASSET_PROFILES_SUCCESS: {
    return {
      ...state,
      assetProfiles: {
        ...state.assetProfiles,
        profiles: action.data,
        loading: false,
        loaded: true,
      }
    }
  }
  case CONSTANTS.GET_ASSET_PROFILES_ERROR: {
    return {
      ...state,
      assetProfiles: {
        ...state.assetProfiles,
        loading: false,
        loaded: false,
        error: action.error,
      }
    }
  }

  case CONSTANTS.GET_ASSET_RESEARCH_REQUEST: {
    return {
      ...state,
      assetResearch: {
        ...state.assetResearch,
        loading: true,
        loaded: false,
      }
    }
  }

  case CONSTANTS.GET_ASSET_RESEARCH_REQUEST: {
    return {
      ...state,
      assetResearch: {
        ...state.assetResearch,
        loading: true,
        loaded: false,
      }
    }
  }

  case CONSTANTS.GET_ASSET_RESEARCH_SUCCESS: {
    return {
      ...state,
      assetResearch: {
        ...state.assetResearch,
        loaded: true,
        loading: false,
        data: action.data
      }
    }
  }

  case CONSTANTS.GET_ASSET_RESEARCH_ERROR: {
    return {
      ...state,
      assetResearch: {
        ...state.assetResearch,
        loaded: false,
        loading: false,
        error: action.error
      }
    }
  }

  case CONSTANTS.GET_ASSET_PRICING_DATA_REQUEST: {
    return {
      ...state,
      assetPricingData: {
        ...state.assetPricingData,
        loading: true,
        loaded: false,
      }
    }
  }

  case CONSTANTS.GET_ASSET_PRICING_DATA_SUCCESS: {
    return {
      ...state,
      assetPricingData: {
        ...state.assetPricingData,
        loaded: true,
        loading: false,
        data: action.data
      }
    }
  }

  case CONSTANTS.GET_ASSET_PRICING_DATA_ERROR: {
    return {
      ...state,
      assetPricingData: {
        ...state.assetPricingData,
        loaded: false,
        loading: false,
        error: action.error
      }
    }
  }
  default:
    return state;
  }
};
