import React, { PureComponent } from 'react'
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    Jumbotron,
} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Files from 'react-butterfiles';
import { Typeahead } from 'react-bootstrap-typeahead' // ES2015
import countryList from 'react-select-country-list'
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify'
import { initGA, logPageView, trackEvent } from "../../../utils/analytics";

class ResearchApplication extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      contributor_type: '',
      years_experience: '', //number
      description: '', //text entry
      linkedin: '', //text entry
      regions: '', //dropdown
      industry: [], //checkbox
      category: [], //checkbox
      resume: '', //document upload
      resumeTitle: '',
      sample: '', //document upload
      sampleTitle: '',
      conflicts: false, //dropdown
      name: '', //text
      website: '', //text
      loginUsername: '',
      loginPassword: '',
      loginCaptcha: false,
      registerUsername: '',
      registerPassword: '',
      registerEmail: '',
      registerCaptcha: false,
      userId: '',
      username: '',
      alreadyHaveAnAccount: false,
    }

    this.locationOptions = countryList().getLabels()
  } // end of constructor(props)

  componentDidMount() {
    this.setState({
      userId: this.props.accountInfo.userId,
      username: this.props.accountInfo.username,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        userId: nextProps.accountInfo.userId,
        username: nextProps.accountInfo.username,
      })
      if (nextProps.resume !== this.props.resume && nextProps.resume.loaded) {
        this.setState({resume: nextProps.resume.fileUrl})
      } else if (nextProps.sample !== this.props.sample && nextProps.sample.loaded) {
        this.setState({sample: nextProps.sample.fileUrl})
      }
    }
  }

  handleLogin = e => {
    e.preventDefault()
    const { loginUsername, loginPassword } = this.state
    const usernameContainsSpecialCharacters = this.handleUsernameContainsDisallowedCharacters(loginUsername)
    if (usernameContainsSpecialCharacters) {
      toast.info('Your username cannot have special characters. Please remove them and try again.')
    } else {
      this.props.loginRequest(loginUsername, loginPassword)
    }
  }

  handleRegister = e => {
    e.preventDefault()
    const { registerUsername, registerPassword, registerEmail } = this.state
    const usernameContainsSpecialCharacters = this.handleUsernameContainsDisallowedCharacters(registerUsername)
    if (usernameContainsSpecialCharacters) {
      toast.info('Your username cannot have special characters. Please remove them and try again.')
    } else {
      this.props.signupRequest(registerEmail, registerPassword, registerPassword, registerUsername)
    }
    trackEvent("User", "Registration", registerUsername)
  }

  onChangeLoginCaptcha = value => {
    if (value) {
      this.setState({loginCaptcha: true})
    } else {
      this.setState({loginCaptcha: false})
    }
  }

  onChangeRegisterCaptcha = value => {
    if (value) {
      this.setState({registerCaptcha: true})
    } else {
      this.setState({registerCaptcha: false})
    }
  }

  handleFormChange = e => {
      const { name, value } = e.target
      this.setState({ [name]:  value })
  } // end of handleFormChange(e)

  handleLocationSelect = e => {
      this.setState({ regions: e[0] })
  } // end of handleLocationSelect()

  handleSubmit = e => {
    const { contributorApplicationRequest } = this.props
    e.preventDefault();
    contributorApplicationRequest(this.state)
  } // end of handleSubmit()

  handleUsernameContainsDisallowedCharacters = username => {
    const disallowedCharacters = "!@#$%^&*(),.?:{}|<>"
    let validation = false
    for (let i = 0; i < username.length; i++) {
      if (disallowedCharacters.indexOf(username.charAt(i)) != -1) {
        validation = true
      }
    }
    return validation
  }

  uploadDocument = (file, fieldName) => {
    if (fieldName === 'resume') {
      this.setState({ resumeTitle: file[0].name })
    } else if (fieldName === 'sample') {
      this.setState({ sampleTitle: file[0].name })
    }
    const fileInfo = file[0].src.file;
    this.props.contributorApplicationDocumentUploadRequest(fileInfo, fieldName);
  } // end of uploadDocument(file, fieldName)

  handleClearSelectedFieldName = fieldName => {
    this.setState({ [fieldName]: '' })
  } // end of handleClearSelectedFieldName(fieldName)

  renderDocumentUploader = fieldName => {
    let doc = this.state[fieldName]
    if (doc) {
      return this.renderSelectedDocument(doc, fieldName)
    } else {
      return (
        <Files
          multiple={false}
          maxSize="2mb"
          multipleMaxSize="10mb"
          multipleMaxCount={1}
          accept={[
            'application/pdf',
            'application/text',
            'text/rtf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.document+json',
            'application/vnd.marlin.drm.mdcf',
            'application/vnd.sealed.doc',
          ]}
          onSuccess={(file) => this.uploadDocument(file, fieldName)}
          >
            {({ browseFiles, getDropZoneProps, getLabelProps }) => (
              <>
              <div {...getDropZoneProps({ className: 'myDropZone' })} />
              <div className="form-control" id="select-files" onClick={browseFiles}>
                Select file...
              </div>
            </>
          )}
        </Files>
      )
    }
  } // end of renderDocumentUploader()

  renderSelectedDocument = (doc, fieldName) => {
    let name = ''
    if (fieldName === 'resume') {
      name = this.state.resumeTitle
    } else if (fieldName === 'sample') {
      name = this.state.sampleTitle
    }

    return (
        <Row className='selected-document-row'>
          <Col>
            <p>{name}</p>
          </Col>
            <Button
              className='research-application-submit'
              id='clear-selected-field'
              onClick={() => this.handleClearSelectedFieldName(fieldName)}
              >
                X
              </Button>
        </Row>
    )
  } // end of renderSelectedDocument()

  renderStatus = isContributor => {
    if (isContributor === 'pending') {
      return (
        <Col className='research-status'>
          <p>
            Thank you for applying - you can check the status of your
            application from the
            {" "}<Link to='/research/management'>Account Menu</Link>
            , or
            {" "}<Link to='contact'>Contact Us</Link>{" "}
            if you have any questions.
          </p>
        </Col>
      )
    } else if (isContributor === true) {
      return (
        <Col className='research-status'>
          <p>
            You are already a contributor.
          </p>
        </Col>
      )
    }
  } // end of renderMessage()

  renderApplicationOrStatus = () => {
    const { accountInfo: { isContributor } } = this.props
    const refreshToken = localStorage.getItem('refreshToken')

    if (isContributor === 'pending' || isContributor === true && refreshToken) {
      return this.renderStatus(isContributor)
    } else if (refreshToken) {
      return this.renderApplication()
    }
  } // end of renderApplicationOrMessage()

  renderApplication = () => {
    const {
      contributor_type
    } = this.state
    const refreshToken = localStorage.getItem('refreshToken')

    return (
      <Form as={Col} className='research-application-form'>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Are you creating a profile on behalf of a company/research
            institution or some other organization?
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            <Form.Control
              className="research-application-form-control"
              name='contributor_type'
              as='select'
              onChange={this.handleFormChange}
              value={contributor_type}
              >
                <option value=''>Select...</option>
                <option>Individual</option>
                <option>Organization</option>
              </Form.Control>
            </Col>
          </Form.Group>
          {this.renderIndividualOrOrganizationQuestions(contributor_type)}
          <Row className='research-application-buttons-row'>
            {this.renderSubmitButton(contributor_type)}
          </Row>
        </Form>
      )
  } // end of renderApplication()

  renderLoginAndRegister = () => {
    const {
      loginUsername,
      loginPassword,
      loginCaptcha,
      registerUsername,
      registerPassword,
      registerEmail,
      registerCaptcha,
      alreadyHaveAnAccount,
    } = this.state
    const registerButtonDisabled = registerCaptcha ? false : true
    const loginButtonDisabled = loginCaptcha ? false : true

    const loginForm = (
      <Form as={Col} className='register-or-login-section' id='position-relative-container'>
        <Form.Group as={Row} className='no-padding' controlId="formBasicUsername">
          <Form.Label className='no-padding register-login-top-label' column>
            LOGIN
          </Form.Label>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Col md={3} />
          <Col>
            <Form.Control
              className="research-application-form-control"
              name='loginUsername'
              placeholder="Username..."
              onChange={this.handleFormChange}
              value={loginUsername}
              required
            />
          </Col>
          <Col md={3} />
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Col md={3} />
          <Col>
            <Form.Control
              className="research-application-form-control"
              name='loginPassword'
              placeholder="Password..."
              onChange={this.handleFormChange}
              value={loginPassword}
              required
            />
          </Col>
          <Col md={3} />
        </Form.Group>
        <Row className='rol-empty-row' />
        <Row className='no-padding recaptcha-row register-or-login-button-row'>
          <Col className='no-padding recaptcha-col' md={12}>
            <ReCAPTCHA
              className='recaptcha'
              sitekey={this.props.siteKey}
              onChange={this.onChangeLoginCaptcha}
            />
            <Button
              onClick={this.handleLogin}
              disabled={loginButtonDisabled}
              className='rol-submit-button'
              >
                Submit
            </Button>
            <div className='rol-already-have-an-account no-padding' onClick={this.handleAlreadyHaveAnAccount}>
              Don't have an account? Please register
            </div>
          </Col>
        </Row>
      </Form>
    )

    const registerForm = (
      <Form as={Col} className='register-or-login-section'>
        <Form.Group as={Row} className='no-padding' controlId="formBasicUsername">
          <Form.Label column className='no-padding register-login-top-label'>
            REGISTER
          </Form.Label>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Col md={3} />
          <Col>
            <Form.Control
              className="research-application-form-control"
              name='registerUsername'
              placeholder="Username..."
              onChange={this.handleFormChange}
              value={registerUsername}
              required
            />
          </Col>
          <Col md={3} />
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Col md={3} />
          <Col>
            <Form.Control
              className="research-application-form-control"
              name='registerPassword'
              placeholder="Password..."
              onChange={this.handleFormChange}
              value={registerPassword}
              required
            />
          </Col>
          <Col md={3} />
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Col md={3} />
          <Col>
            <Form.Control
              className="research-application-form-control"
              name='registerEmail'
              placeholder="Email..."
              onChange={this.handleFormChange}
              value={registerEmail}
              required
            />
          </Col>
          <Col md={3} />
        </Form.Group>
        <Row className='no-padding recaptcha-row'>
          <Col className='no-padding recaptcha-col' md={12}>
            <ReCAPTCHA
              className='recaptcha'
              sitekey={this.props.siteKey}
              onChange={this.onChangeRegisterCaptcha}
            />
            <Button
              onClick={this.handleRegister}
              disabled={registerButtonDisabled}
              className='rol-submit-button'
              >
              Submit
            </Button>
            <div className='rol-already-have-an-account no-padding' onClick={this.handleAlreadyHaveAnAccount}>
              Already have an account? Please log in
            </div>
          </Col>
        </Row>
      </Form>
    )

    return (
      <Row className='no-padding'>
        <div className='login-header-text'>
          You must create an account or log in before you can apply to become a contributor.
        </div>
        {alreadyHaveAnAccount ? loginForm : registerForm}
      </Row>
    )
  }

  handleAlreadyHaveAnAccount = () => {
    const { alreadyHaveAnAccount } = this.state
    this.setState({ alreadyHaveAnAccount: !alreadyHaveAnAccount })
  }

  renderSubmitButton = contributor_type => {
    if (contributor_type) {
      return (
        <Button
          onClick={this.handleSubmit}
          className='research-application-submit'
          variant="primary"
          type="submit">
          Submit
        </Button>
      )
    }
  } // end of renderSubmitButton()

  renderIndividualOrOrganizationQuestions = contributor_type => {
    if (contributor_type === 'Individual') {
      return this.renderIndividualQuestions()
    } else if (contributor_type === 'Organization') {
      return this.renderOrganizationQuestions()
    }
  } // end of renderIndividualOrOrganizationQuestions()

  renderIndividualQuestions = () => {
    const {
      years_experience,
      description,
      linkedin,
      regions,
      industry,
      category,
      resume,
      sample,
      conflicts,
      name,
    } = this.state
    return (
      <React.Fragment>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Please provide your name.
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            <Form.Control
                className="research-application-form-control"
                name='name'
                placeholder="Name..."
                onChange={this.handleFormChange}
                value={name}
                required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            How many years of experience do you have covering securities,
            digital assets or other financial topics?
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            <Form.Control
                className="research-application-form-control"
                name='years_experience'
                placeholder="Years of experience..."
                onChange={this.handleFormChange}
                value={years_experience}
                // required
                type="number"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Please briefly describe your experience in the Security Token
            Industry, as well as any other relevant information you would
            like our team to know as we review your application for
            publisher status.
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            <Form.Control
                className="research-application-form-control"
                as="textarea"
                name='description'
                placeholder="Description..."
                onChange={this.handleFormChange}
                value={description}
                // required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Please provide a link to your LinkedIn account if you have one.
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            <Form.Control
              className="research-application-form-control"
                name='linkedin'
                placeholder="LinkedIn Url..."
                onChange={this.handleFormChange}
                value={linkedin}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Which geographical region do you intend to focus on?
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            <Form.Control
              className="research-application-form-control"
              name='regions'
              as='select'
              className="research-application-form-control"
              onChange={this.handleFormChange}
              value={regions}
            >
              <option value=''>Region...</option>
              <option>North America</option>
              <option>South America</option>
              <option>Europe</option>
              <option>Africa</option>
              <option>Asia</option>
              <option>Australia/New Zealand</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Which industry do you intend to focus on?
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            <Form.Control
              className="research-application-form-control"
              name='industry'
              as="select"
              // multiple
              value={industry}
              onChange={this.handleFormChange}>
                <option value="">Industry...</option>
                <option>Art</option>
                <option>Banking</option>
                <option>Blockchain</option>
                <option>Cryptocurrency</option>
                <option>Custody</option>
                <option>Energy</option>
                <option>Entertainment</option>
                <option>Financial Tech</option>
                <option>Gaming</option>
                <option>Healthcare</option>
                <option>Investment Fund</option>
                <option>Legal</option>
                <option>Manufacturing</option>
                <option>Real Estate</option>
                <option>Retail</option>
                <option>Software/Technology</option>
                <option>Sports</option>
                <option>Supply Chain</option>
                <option>Travel</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Which category of research do you intend to focus on?
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            <Form.Control
              className="research-application-form-control"
              name='category'
              as='select'
              className="research-application-form-control"
              onChange={this.handleFormChange}
              value={category}
            >
              <option value=''>Category...</option>
              <option>STO Analysis</option>
              <option>Macro Trends</option>
              <option>Technology and Protocol Analysis</option>
              <option>Trading + TA</option>
              <option>Legal + Regulation</option>
              <option>Strategy + Advisory</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Do you currently own any of the securities listed on Velocity Channel?
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            <Form.Control
              className="research-application-form-control"
              name='conflicts'
              as='select'
              className="research-application-form-control"
              onChange={this.handleFormChange}
              value={conflicts}
            >
              <option value=''>Select...</option>
              <option>Yes</option>
              <option>No</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Please upload the most recent copy of your CV.
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            {this.renderDocumentUploader('resume')}
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Please upload a writing sample. (This sample will not be used in
              any capacity outside of the application review process and will
              be viewable after application decision)
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            {this.renderDocumentUploader('sample')}
          </Col>
        </Form.Group>
      </React.Fragment>
    )
  } // end of renderIndividualQuestions()

  renderOrganizationQuestions = () => {
    const {
      name,
      website,
      description,
      regions,
      industry,
      category,
    } = this.state
    return (
      <React.Fragment>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Please provide the name of your company.
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            <Form.Control
                className="research-application-form-control"
                name='name'
                placeholder="Company Name..."
                onChange={this.handleFormChange}
                value={name}
                // required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Please provide a link to your company website.
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            <Form.Control
                className="research-application-form-control"
                name='website'
                placeholder="Website..."
                onChange={this.handleFormChange}
                value={website}
                required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Please briefly describe your organization in terms of its size and
            primary areas of focus.
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            <Form.Control
                className="research-application-form-control"
                as="textarea"
                name='description'
                placeholder="Description..."
                onChange={this.handleFormChange}
                value={description}
                // required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Which geographical region do you intend to focus on?
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            <Form.Control
              className="research-application-form-control"
              name='regions'
              as='select'
              className="research-application-form-control"
              onChange={this.handleFormChange}
              value={regions}
            >
              <option value=''>Region...</option>
              <option>North America</option>
              <option>South America</option>
              <option>Europe</option>
              <option>Africa</option>
              <option>Asia</option>
              <option>Australia/New Zealand</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Which industry do you intend to focus on?
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            <Form.Control
              className="research-application-form-control"
              name='industry'
              as="select"
              // multiple
              value={industry}
              onChange={this.handleFormChange}>
                <option value="">Industry...</option>
                <option>Art</option>
                <option>Banking</option>
                <option>Blockchain</option>
                <option>Cryptocurrency</option>
                <option>Custody</option>
                <option>Energy</option>
                <option>Entertainment</option>
                <option>Financial Tech</option>
                <option>Gaming</option>
                <option>Healthcare</option>
                <option>Investment Fund</option>
                <option>Legal</option>
                <option>Manufacturing</option>
                <option>Real Estate</option>
                <option>Retail</option>
                <option>Software/Technology</option>
                <option>Sports</option>
                <option>Supply Chain</option>
                <option>Travel</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicUsername">
          <Form.Label column sm={8} md={8}>
            Which category of research do you intend to focus on?
          </Form.Label>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 3, offset: 1 }}>
            <Form.Control
              className="research-application-form-control"
              name='category'
              as='select'
              className="research-application-form-control"
              onChange={this.handleFormChange}
              value={category}
            >
              <option value=''>Category...</option>
              <option>STO Analysis</option>
              <option>Macro Trends</option>
              <option>Technology and Protocol Analysis</option>
              <option>Trading + TA</option>
              <option>Legal + Regulation</option>
              <option>Strategy + Advisory</option>
            </Form.Control>
          </Col>
        </Form.Group>
      </React.Fragment>
    )
  } // end of renderOrganizationQuestions()

  renderFormOrLogin = () => {
    const refreshToken = localStorage.getItem('refreshToken')
    if (refreshToken) {
      return (
        <Col className='research-application-section' md={{ span: 6, offset: 3 }}>
          {this.renderApplicationOrStatus()}
        </Col>
      )
    } else {
      return (
        <React.Fragment>
          <Col md={3} />
          <Col md={6}>
            {this.renderLoginAndRegister()}
          </Col>
          <Col md={3} />
        </React.Fragment>
      )
    }
  }

  render() {
    return (
        <Container className='research-application-container'>
            <Jumbotron className='research-application-background'>
                <Row className='research-application-header-row'>
                    <h3>CONTRIBUTOR APPLICATION</h3>
                </Row>
                <Row>
                  {this.renderFormOrLogin()}
                </Row>
            </Jumbotron>
        </Container>
    )
  } // end of render()
} // end of ResearchApplication

export default ResearchApplication
