import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  FormControl,
  Button,
  Form,
  Table,
  Spinner,
  Tooltip,
  OverlayTrigger,
  Modal,
} from 'react-bootstrap'
import { MdArrowBack } from 'react-icons/md'
import {
  AiOutlineEdit,
  AiOutlineCheckCircle
} from 'react-icons/ai'

class TradedSymbols extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {
        q: ''
      },
      tradedSymbols: this.props.tradedSymbols,
      assetTypes: this.props.assetTypes,
      currentModal: '',
      assetTypeForProfileCreation: ''
    }
  }

  componentDidMount() {
    this.handleSearch()
    this.props.getPublishedTemplatesRequest()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        tradedSymbols: nextProps.tradedSymbols,
        assetTypes: nextProps.assetTypes,
      })
    }
  }

  handleChangeParams = e => {
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]:  e.target.value
      }
    }, () => this.handleSearch())
  }

  handleSearch = () => {
    this.props.getTradedSymbolsRequest(this.state.params)
  }

  handleCreateAssetProfile = assetProfile => {
    this.handleClearModal()
    const body = {
      template_id: assetProfile,
      ticker: this.state.chosenSecurity
    }

    this.props.history.push('/admin/create-an-asset-profile')
    this.props.createTradedSymbolRequest(body)
  }

  handleClearModal = () => {
    this.setState({
      currentModal: '',
      chosenSecurity: ''
    })
  }

  handleShowModal = (modal, security) => {
    this.setState({
      currentModal: modal,
      chosenSecurity: security
    })
  }

  handleSelectAssetType = e => {
    this.setState({
      assetTypeForProfileCreation: e.target.value
    })
  }

  handleSelectAssetProfile = profile => {
    this.props.history.push('/admin/create-an-asset-profile')
    this.props.selectAssetProfileRequest(profile._id)
  }

  /****************************************************************************/

  renderSearchbar = () => {
    return (
      <Row noGutters className='submissions-search-bar'>
        <Col sm={6}>
          <label className='submission-form-control-label'>
            Search:
          </label>
          <FormControl
            size='sm'
            name='q'
            placeholder='Search...'
            value={this.state.params.q}
            onChange={this.handleChangeParams}
            className='submission-form-control' />
        </Col>
      </Row>
    )
  }

  renderTable = () => {
    const { data, loaded, loading } = this.state.tradedSymbols

    let content
    if (loading) {
      content = this.renderTableLoading()
    } else if (loaded) {
      if (data.length > 0) {
        content = data.map(item => this.renderTableItem(item))
      } else {
        content = (
          <tr>
            <td colSpan='1'>
              <Row noGutters className='table-loading-row'>
                No symbols found
              </Row>
            </td>
          </tr>
        )
      }
    }

    return (
      <Table bordered className='admin-table'>
        <thead>
          <tr>
            <th>Security</th>
          </tr>
        </thead>

        <tbody>
          <Row noGutters className='traded-symbols-table-row'>
            {content}
          </Row>
        </tbody>
      </Table>
    )
  }

  renderTableItem = item => {
    const { security, clear_inst, pair, profile } = item

    let displayPair
    if (pair) {
      displayPair = (
        <div id='mentionedIn-true'>True</div>
      )
    } else {
      displayPair = (
        <div id='mentionedIn-false'>False</div>
      )
    }

    let mentionedInIcon
    if (Object.keys(profile).length == 0) {
      const tooltip = (
        <Tooltip id='button-tooltip'>
          No profile found for this symbol
          <Row className='mentionedIn-header' noGutters>
            Click the icon to create a profile for this symbol
          </Row>
        </Tooltip>
      )
      mentionedInIcon = (
        <OverlayTrigger
          placement='left'
          delay={{ show: 250 }}
          overlay={tooltip}>
          <AiOutlineCheckCircle
            onClick={() => this.handleShowModal('showCreateAssetProfileModal', security)}
            className='mentionedIn-icon' />
        </OverlayTrigger>
      )
    } else {
      const { _id, name, verification } = profile
      const tooltip = (
        <Tooltip id='button-tooltip'>
          Profile Info
          <Row noGutters>
            Name: {name || 'No name given'}
          </Row>
          <Row noGutters>
            Id: {_id}
          </Row>
          <Row noGutters>
            Verification: {verification}
          </Row>
          <Row className='mentionedIn-header' noGutters>
            Click the icon to view the profile for this symbol
          </Row>
        </Tooltip>
      )
      mentionedInIcon = (
        <OverlayTrigger
          placement='left'
          delay={{ show: 250 }}
          overlay={tooltip}>
          <AiOutlineCheckCircle
            onClick={() => this.handleSelectAssetProfile(profile)}
            className='mentionedIn-icon'
            id='true' />
        </OverlayTrigger>
      )
    }

    return (
      <Col sm={6} md={4} lg={3} xl={2} className='traded-symbols-card'>
        <Row noGutters className='td-row'>
          {security || 'No Security'}
          {mentionedInIcon}
        </Row>
      </Col>
    )
  }

  renderTableLoading = () => (
    <tr>
      <td colSpan='1'>
        <Row noGutters className='table-loading-row'>
          <Spinner className='asset-profile-loading-spinner' animation='border' />
        </Row>
      </td>
    </tr>
  )

  renderCreateAssetProfileModal = () => {
    const { currentModal, assetTypes } = this.state
    const show = (currentModal == 'showCreateAssetProfileModal') ? true : false

    let options
    if (show) {
      if (assetTypes.loaded) {
        options = this.state.assetTypes.templates.docs.map(option => this.renderAssetTypeOptions(option))
      }
    }

    let disabled
    if (!this.state.assetTypeForProfileCreation) {
      disabled = true
    } else {
      disabled = false
    }

    return (
      <Modal
        centered
        className='admin-modal'
        show={show}
        onHide={this.handleClearModal}>

        <Modal.Header closeButton>
          Create Asset Profile
        </Modal.Header>

        <Modal.Body>
          <Col>
            <label className='submission-form-control-label'>
              Select an Asset Type for this Asset Profile
            </label>
            <FormControl
              size='sm'
              as='select'
              value={this.state.assetTypeForProfileCreation}
              onChange={this.handleSelectAssetType}
              className='submission-form-control'>
              <option value=''>Select an Asset Type...</option>
              {options}
            </FormControl>
          </Col>
        </Modal.Body>

        <Modal.Footer>
          <Row noGutters className='confirm-delete-modal-row'>
            <Button
              onClick={() => this.handleCreateAssetProfile(this.state.assetTypeForProfileCreation)}
              disabled={disabled}
              className='teal-button' size='sm'>
              Create Asset Profile
            </Button>

            <Button
              onClick={this.handleClearModal}
              className='delete-button' size='sm'>
              Cancel
            </Button>
          </Row>
        </Modal.Footer>

      </Modal>
    )
  }

  renderAssetTypeOptions = option => {
    const { name, _id } = option
    return (
      <option key={_id} value={_id}>{name || _id}</option>
    )
  }

  render() {
    return (
      <Container fluid className='admin-content-container'>
        <Row noGutters className='forms-header'>
          <h4>Market Symbols</h4>
        </Row>

        <div className='forms-table-box'>
          {this.renderCreateAssetProfileModal()}
          {this.renderSearchbar()}
          {this.renderTable()}
        </div>
      </Container>
    )
  }
}

export default TradedSymbols
