import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import moment from "moment";
import * as CONSTANTS from '../../constants/news'
import * as ACTIONS from '../../actions/news'
import request from "../../../utils/apiRequest";

function* newsFeedList(action) {
  try {
    const responseData = yield call(
      request,
      "/news/feedlist?",
      "GET",
    )

    yield put(ACTIONS.newsFeedListSuccess(responseData));
  } catch (err) {
    // toast.error(err.reason);
    yield put(ACTIONS.newsFeedListError(err));
  }
}

function* FeedItemList(action) {
  try {
    const responseData = yield call(
      request,
      `/news/publisher/${action.feedItem}`,
      "GET",
      null,
      true
    );
    yield put(ACTIONS.feedNewsItemSuccess(responseData));
  } catch (err) {
    // toast.error(err.reason);
    yield put(ACTIONS.feedNewsItemsError(err));
  }
}

function* getNews(action) {
  try {
    const responseData = yield call(
      request,
      "/news/article?",
      "GET",
      action,
      true
    )
    yield put(ACTIONS.getNewsSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.getNewsError(err))
  }
}

function* searchNews(action) {
  const { searchTitle, offset, publisher, toDate, fromDate, compact } = action;

  try {
    const requestData = {
      q: searchTitle,
      publisher: publisher,
      to: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
      from: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
      offset: offset,
      compact: compact
    };

    const responseData = yield call(
      request,
      "/news/search?",
      "GET",
      requestData,
      true
    );

    yield put(ACTIONS.searchNewsSuccess(responseData));
  } catch (err) {
    // toast.error(err.reason);
    yield put(ACTIONS.searchNewsError(err));
  }
}

function* getNewsFeed(action) {
  try {
    const body = {
      q: action.params.query,
      publisher: action.params.publisher,
      to: action.params.to ? moment(action.params.to).format("YYYY-MM-DD") : "",
      from: action.params.from ? moment(action.params.from).format("YYYY-MM-DD") : "",
      offset: action.params.offset,
      compact: action.params.compact
    }
    const responseData = yield call(
      request,
      '/news/search?',
      'GET',
      body
    )

    yield put(ACTIONS.getNewsFeedSuccess(responseData));
  } catch (err) {
    yield put(ACTIONS.getNewsFeedError(err));
  }
}

function* getCategoryNews(action) {
  const {category, offset, compact } = action;

  try {
    const requestData = {
      category,
      offset,
      compact
    };

    const responseData = yield call(
      request,
      "/news/category?",
      "GET",
      requestData,
      true
    );
    yield put(ACTIONS.getNewsFeedSuccess(responseData));
  } catch (err) {
    // toast.error(err.reason);
    yield put(ACTIONS.getNewsFeedError(err));
  }
}

function* loadMoreNewsList(action) {
  const { publisher, date } = action;

  try {
    const requestData = {
      date,
      publisher
    };

    const responseData = yield call(
      request,
      `/news/publisher/${publisher}`,
      "GET",
      requestData,
      true
    );
    yield put(ACTIONS.getSeeMoreNewsSuccess(responseData));
  } catch (err) {
    // toast.error(err.reason);
    yield put(ACTIONS.getSeeMoreNewsError(err));
  }
}

function* saveNews(action) {
  const { id, title, date, publisher } = action;
  try {
    const requestData = {
      id,
      title,
      publisher,
      date
    };
    const responseData = yield call(
      request,
      "/news/save",
      "POST",
      requestData,
      true
    );
    // toast.success("Saved Article");
    yield put(ACTIONS.saveNewsSuccess(responseData));
    yield put(ACTIONS.savedNewsIds(id));
  } catch (err) {
    if (err.reason === 'auth cookie not set') {
      toast.info('You must be logged in to save articles');
    }
  }
}

function* getSavedNews(action) {
  try {
    const responseData = yield call(
      request,
      '/news/saved',
      'GET',
    )
    yield put(ACTIONS.getSavedNewsSuccess(responseData))
  } catch (err) {
    yield put(ACTIONS.getSavedNewsError(err))
  }
}

function* getSeeMoreSavedNews(action) {
  try {
    const { dateFav, userId } = action;
    const requestData = {
      dateFav,
      userId
    };

    const responseData = yield call(
      request,
      "/news/saved",
      "GET",
      requestData,
      true
    );
    yield put(ACTIONS.getSavedNewsSeeMoreSuccess(responseData));
  } catch (err) {
    // toast.error(err.reason);
    yield put(ACTIONS.getSavedNewsSeeMoreError(err));
  }
}

function* getSavedNewsIds(action) {
  try {
    const responseData = yield call(
      request,
      '/news/saveid',
      'GET',
    )

    yield put(ACTIONS.getSavedNewsIdsSuccess(responseData))
  } catch (err) {
    yield put(ACTIONS.getSavedNewsIdsError(err))
  }
}

function* unSavedNews(action) {
  try {
    const { id } = action;
    const requestData = { id };

    const responseData = yield call(
      request,
      "/news/unsave",
      "POST",
      requestData,
      true
    );
    if (responseData.message === "success") {
      // toast.success("Removed Article");
      yield put(ACTIONS.getSavedNewsIdsRequest());
    }
  } catch (err) {
    // toast.error(err.reason);
    yield put(ACTIONS.unSavedNewIdError(err));
  }
}

function* categoryNews(action) {
  try {
    const { categoryType, offset, compact } = action;
    const requestData = {
      offset: offset,
      category: categoryType,
      compact: compact
    };
    const responseData = yield call(
      request,
      `/news/category?`,
      "GET",
      requestData,
      true
    );
    yield put(ACTIONS.getNewsFeedSuccess(responseData));
  } catch (err) {
    // toast.error(err.reason);
    yield put(ACTIONS.getCategoryError(err));
  }
}

function* newsCategoryList(action) {
  try {
    const responseData = yield call(
      request,
      "/news/categoryList?",
      "GET",
      null,
      true
    );
    yield put(ACTIONS.getCategoryListSuccess(responseData));
  } catch (err) {
    // toast.error(err.reason);
    yield put(ACTIONS.getCategoryListError(err));
  }
}

export default function* newsFeedListSaga() {
  yield takeLatest(CONSTANTS.NEWS_FEED_LIST_REQUEST, newsFeedList);
  yield takeLatest(CONSTANTS.FEED_NEWS_ITEMS_REQUEST, FeedItemList);
  yield takeLatest(CONSTANTS.GET_NEWS_REQUEST, getNews);
  yield takeLatest(CONSTANTS.SEARCH_NEWS_REQUEST, searchNews);
  yield takeLatest(CONSTANTS.GET_NEWS_FEED_REQUEST, getNewsFeed);
  yield takeLatest(CONSTANTS.GET_CATEGORY_REQUEST, getCategoryNews);
  yield takeLatest(CONSTANTS.GET_NEWS_SEE_MORE_REQUEST, loadMoreNewsList);
  yield takeLatest(CONSTANTS.SAVE_NEWS_REQUEST, saveNews);
  yield takeLatest(CONSTANTS.GET_USERS_SAVED_NEWS_REQUEST, getSavedNews);
  yield takeLatest(CONSTANTS.GET_USER_SAVED_NEWS_SEE_MORE_REQUEST, getSeeMoreSavedNews);
  yield takeLatest(CONSTANTS.GET_SAVED_NEWS_IDS_REQUEST, getSavedNewsIds);
  yield takeLatest(CONSTANTS.UNSAVED_NEWS_ID_REQUEST, unSavedNews);
  yield takeLatest(CONSTANTS.GET_CATEGORY_NEWS_REQUEST, categoryNews);
  yield takeLatest(CONSTANTS.GET_NEWS_CATEGORY_LIST_REQUEST, newsCategoryList);
}
