import React, { Component } from 'react';
import Avatar from 'react-avatar-edit';
import queryString from 'query-string';
import Files from 'react-butterfiles';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
  FormControl,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead'
import countryList from 'react-select-country-list'
import { IoIosAddCircle, IoMdRemoveCircle } from 'react-icons/io';
import ReactQuill from 'react-quill';
import DatePicker from 'react-date-picker';
import { v1, v4 } from 'uuid';

class FundSecurityForm extends Component {
  constructor(props) {
    super(props);

    // this is for the countryList module
    this.locationOptions = countryList().getLabels()

    this.state = {
      id: '',
      image: '',
      name: '',
      issuance_type: 'Equity', // must always be equity
      token_ticker: '',
      brief: '',
      description: '',
      start_date: '', // will be a date field w/ YYY-MM-DDTHH:MM:SS.MMMZ format
      end_date: '', // will be a date field w/ YYY-MM-DDTHH:MM:SS.MMMZ format
      src: '',
      industry: '',
      location: '',
      team: [{ name: '', link: '' }],
      team_keys: [v4('teams')],
      token_price: '', // corresponds to Price per token
      minimum_investment: '',
      deal_size: '',
      employees: '',
      website_link: '',
      twitter_link: '',
      linkedin_link: '',
      facebook_link: '',
      medium_link: '',
      // documents: [],
      is_edit: false,
      errors: '',
      locationOptions: this.locationOptions,
      video: '',
      protocol: '',
      purchase_link: '',
      status: '',
      purchase_requirements: '',
      incorporation_name: '',
      legal_form: '',
      regulation_type: '',
      documents: [],
      documents_keys: [v4('documents')],
      fund_focus: '',
      address: '',
      developer: '',

    };
  }

  componentDidMount() {
    const { search } = window.location;
    if (search) {
      const idObj = queryString.parse(search)
      this.props.getTokenEditRequest(idObj.name);
    } else {
      this.props.resetTokenForm();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { token, docURL, fieldType } = nextProps;

    if (!token && docURL && fieldType) {
      if (fieldType === 'fund_document_links') {
        this.setState({ document_links: docURL });
      }
    }

    if (token && !!Object.keys(token).length && !!token.Items.length) {
      const { Items } = token;
      const {
        name,
        brief,
        description,
        id,
        image,
        location,
      } = Items[0];

      let teamsKeys = [];

      if (Items[0].team.length > 0) {
        Items[0].team.forEach(team => {
          teamsKeys.push(v4(team));
        });
      }

      this.setState({
        id: id,
        name: Items[0].name ? Items[0].name : '',
        token_ticker: Items[0].token_ticker ? Items[0].token_ticker : '',
        brief: Items[0].brief ? Items[0].brief : '',
        description: Items[0].description ? Items[0].description : '',
        start_date: Items[0].start_date ? new Date(Items[0].start_date) : new Date(),
        end_date: Items[0].end_date ? new Date(Items[0].end_date) : new Date(),
        src: image,
        industry: Items[0].industry ? Items[0].industry : '',
        location: Items[0].location ? Items[0].location : '',
        team: Items[0].team ? Items[0].team : '',
        team_keys: Items[0].team.length > 0 ? teamsKeys : [v4('team')],
        token_price: Items[0].token_price ? Items[0].token_price : '',
        minimum_investment: Items[0].minimum_investment ? Items[0].minimum_investment : '',
        deal_size: Items[0].deal_size ? Items[0].deal_size : '',
        employees: Items[0].employees ? Items[0].employees : '',
        website_link: Items[0].website_link ? Items[0].website_link : '',
        twitter_link: Items[0].twitter_link ? Items[0].twitter_link : '',
        linkedin_link: Items[0].linkedin_link ? Items[0].linkedin_link : '',
        facebook_link: Items[0].facebook_link ? Items[0].facebook_link : '',
        medium_link: Items[0].medium_link ? Items[0].medium_link : '',
        documents: Items[0].documents ? Items[0].documents : '',
        video: Items[0].video ? Items[0].video : '',
        protocol: Items[0].protocol ? Items[0].protocol : '',
        purchase_link: Items[0].purchase_link ? Items[0].purchase_link : '',
        status: Items[0].status ? Items[0].status : '',
        purchase_requirements: Items[0].purchase_requirements ? Items[0].purchase_requirements : '',
        incorporation_name: Items[0].incorporation_name ? Items[0].incorporation_name : '',
        legal_form: Items[0].legal_form ? Items[0].legal_form : '',
        regulation_type: Items[0].regulation_form ? Items[0].regulation_form : '',
        is_edit: true,
      });
    }
  }

  handleNumericalFieldChange = e => {
      const { name, value } = e.target
      this.setState({ [name]:  value })
  } // end of handleFormChange(e)

  handleFormChange = fieldName => e => {
    this.setState({
      [fieldName]: e.target.value,
    });
  };

  onBeforeFileLoad = elem => {
    if (elem.target.files[0].size > 71680) {
      alert('File is too big!');
      elem.target.value = '';
    }
  };

  onFileLoad = file => {
    this.props.uploadImageRequest(file);
  };

  uploadDocument = file => {
    this.setState({ documents: file[0].src.file });
    const fileInfo = file[0].src.file;
    this.props.uploadDocumentRequest(fileInfo, 'fund_document_links');
  }

  saveEditedToken = () => {
    const { url, fieldType, docURL } = this.props;

    const {
        id,
        name,
        issuance_type,
        brief,
        description,
        start_date,
        end_date,
        industry,
        location,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        src,
        documents,
    } = this.state;

    let imageInfo = url ? url : src;
    let documentInfo =
      docURL && fieldType === 'equity_document_links' ? docURL : documents;
    // const convertedNewTeam = JSON.stringify(team);

    this.props.editFundTokenRequest(
        id,
        name,
        imageInfo,
        issuance_type,
        brief,
        description,
        start_date,
        end_date,
        industry,
        location,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        documentInfo,
    );
  };

  onSubmit = e => {
    e.preventDefault();

    const {
        name,
        issuance_type,
        brief,
        description,
        start_date,
        end_date,
        documents,
        industry,
        location,
        team,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
    } = this.state;

    const { url, fieldType, docURL } = this.props;
    let documentURL = fieldType === 'fund_document_links' ? docURL : '';

    const convertedNewTeam = JSON.stringify(team);

    this.props.getFundSecurityFormRequest(
        name,
        issuance_type,
        brief,
        description,
        start_date,
        end_date,
        documents,
        industry,
        location,
        convertedNewTeam,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        url,
        documentURL,
    );
  };

  renderPropertyImageUploader = () => {
    return (
      <React.Fragment>
          <Avatar
              width={100}
              height={100}
              imageWidth={100}
              imageHeight={100}
              label="Choose the Image"
              labelStyle={{ fontSize: 10 }}
              minCropRadius={100}
              src={this.state.src}
              onFileLoad={this.onFileLoad}
          />
      </React.Fragment>
    );
  };

  renderTeamInputForm = (team, index) => {
    const { team_keys } = this.state;

    return (
      <React.Fragment key={`team-${team_keys[index]}`}>
          <Form.Group
              as={Row}
              controlId="formHorizontalCountries"
              className="every-team-info"
          >
              <Form.Label column sm={2} style={{ textAlign: 'center' }}>
                  Name:
              </Form.Label>
              <Col sm={3}>
                  <Form.Control
                      placeholder="Name"
                      onChange={e => this.handleTeamNameChange(e, index)}
                      value={team.name}
                  />
              </Col>
              <Form.Label column sm={2} style={{ textAlign: 'center' }}>
                  LinkedIn:
              </Form.Label>
              <Col sm={3}>
                  <Form.Control
                      placeholder="Linkedin"
                      onChange={e => this.handleTeamLinkChange(e, index)}
                      value={team.link}
                  />
              </Col>
              <Col sm={2}>
                  <IoMdRemoveCircle
                      style={{ fontSize: 20 }}
                      onClick={() => {
                          this.removeTeam(index);
                      }}
                  />
              </Col>
          </Form.Group>
      </React.Fragment>
    );
  };

  handleTeamNameChange = (e, index) => {
    const { team } = this.state;
    const newTeamsObj = [...team];
    newTeamsObj[index] = Object.assign({}, newTeamsObj[index], {
      name: e.target.value,
    });
    this.setState({ team: newTeamsObj });
  };

  handleTeamLinkChange = (e, index) => {
    const { team } = this.state;
    const newTeamsObj = [...team];
    newTeamsObj[index] = Object.assign({}, newTeamsObj[index], {
      link: e.target.value,
    });
    this.setState({ team: newTeamsObj });
  };

  handleDocumentNameChange = (e, index) => {
    const { documents } = this.state
    const newDocsObj = [...documents]
    newDocsObj[index] = Object.assign({}, newDocsObj[index], {
      name: e.target.value,
    })
    this.setState({ documents: newDocsObj })
  }

  handleDocumentUrlChange = (e, index) => {
    const { documents } = this.state
    const newDocsObj = [...documents]
    newDocsObj[index] = Object.assign({}, newDocsObj[index], {
      docUrl: e.target.value,
    })
    this.setState({ documents: newDocsObj })
  }

  addTeams = () => {
    const { team, team_keys } = this.state;
    this.setState({
      team: [...team, { name: '', link: '' }],
      team_keys: [...team_keys, v4('team')],
    });
  };

  removeTeam = index => {
    this.setState({
      team: this.state.team.filter((_, i) => i !== index),
      team_keys: this.state.team_keys.filter((_, i) => i !== index),
    });
  };


  handleChange = fieldName => html => {
    this.setState({ [fieldName]: html });
  };

  handleNumericalChange = (event, fieldName) => {
      this.setState({ [fieldName]: event.target.value.replace(/\D/,'')})
  }

  handleIndustrySelect = (event) => {
    this.setState({ industry: event.target.value })
  }

  handleLocationSelect = (event) => {
    this.setState({ location: event[0] })
  }

  onChangeDate = fieldName => date => {
    this.setState({ [fieldName]: date });
  };

  renderDocumentUploader = () => (
    <Files
        multiple={false}
        maxSize="2mb"
        multipleMaxSize="10mb"
        multipleMaxCount={1}
        accept={[
            'application/pdf',
            'application/text',
            'text/rtf',
            'application/vnd.document+json',
            'application/vnd.marlin.drm.mdcf',
            'application/vnd.sealed.doc',
        ]}
        onSuccess={file => this.uploadDocument(file)}
        onError={errors => {
            this.setState({ errors });
        }}
    >
        {({ browseFiles, getDropZoneProps, getLabelProps }) => (
            <>
                <div {...getDropZoneProps({ className: 'myDropZone' })} />
                <div className="document-uploader" onClick={browseFiles}>
                    Select files...
                </div>
                {this.state.documents.name}
            </>
        )}
    </Files>
  );

  renderTokenForm = () => {
    const {
        name,
        image,
        token_ticker,
        brief,
        description,
        industry,
        location,
        team,
        employees,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        documents,
        token_price,
        issuance_type,
        minimum_investment,
        deal_size,
        start_date,
        end_date,
        is_edit,
        locationOptions,
        video,
        protocol,
        purchase_link,
        status,
        purchase_requirements,
        incorporation_name,
        legal_form,
        regulation_type,
        fund_focus,
        address,
        developer,
    } = this.state

    return (
      <Container className="form-content">
          <Form onSubmit={this.onSubmit}>

            <div className='token-form-sub-section'>
              <h2>VIDEO + BRIEF</h2>
              <Form.Group as={Row} controlId="formHorizontalLogoImage">
                <Form.Label column sm={2}>
                  Image
                </Form.Label>
                <Col md={7}>{this.renderPropertyImageUploader()}</Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalTokenName">
                <Form.Label column sm={2}>
                  Name
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    placeholder="Name"
                    onChange={this.handleFormChange('name')}
                    value={name}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalTokenName">
                <Form.Label column sm={2}>
                  Video Link
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    placeholder="Video Link"
                    onChange={this.handleFormChange('video')}
                    value={video}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalTokenName">
                <Form.Label column sm={2}>
                  Brief
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    placeholder="Brief"
                    onChange={this.handleFormChange('brief')}
                    value={brief}
                    required
                  />
                </Col>
              </Form.Group>
            </div>

            <div className='token-form-sub-section'>
              <h2>PROFILE</h2>
              <Form.Group as={Row} controlId="formHorizontalOfferType">
                  <Form.Label column sm={2}>
                      Token Type
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Issuance Type"
                          value={issuance_type}
                          disabled
                      />
                  </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalTokenName">
                <Form.Label column sm={2}>
                  Fund Focus
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    placeholder="Fund Focus"
                    onChange={this.handleFormChange('fund_focus')}
                    value={fund_focus}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalTokenDescription">
                  <Form.Label column sm={2}>
                      Description
                  </Form.Label>
                  <Col sm={8}>
                      <ReactQuill
                          theme={this.state.theme}
                          onChange={this.handleChange('description')}
                          value={description}
                          modules={FundSecurityForm.modules}
                          formats={FundSecurityForm.formats}
                          placeholder="Description"
                          required
                      />
                  </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalAddress">
                  <Form.Label column sm={2}>
                      Industry
                  </Form.Label>
                  <Col sm={8}>
                      <FormControl required key="industry" as="select" onChange={(event) => this.handleIndustrySelect(event)}>
                          <option value="">Industry</option>
                          <option>Art</option>
                          <option>Banking</option>
                          <option>Blockchain</option>
                          <option>Cryptocurrency</option>
                          <option>Custody</option>
                          <option>Energy</option>
                          <option>Entertainment</option>
                          <option>Financial Tech</option>
                          <option>Gaming</option>
                          <option>Healthcare</option>
                          <option>Investment Fund</option>
                          <option>Legal</option>
                          <option>Manufacturing</option>
                          <option>Real Estate</option>
                          <option>Retail</option>
                          <option>Software/Technology</option>
                          <option>Sports</option>
                          <option>Supply Chain</option>
                          <option>Travel</option>
                      </FormControl>
                  </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalDevelopmentTeam">
                  <Form.Label column sm={2}>
                      Number of Employees
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="0"
                          onChange={(event) => this.handleNumericalChange(event, 'employees')}
                          value={employees}
                          type="Number"
                      />
                  </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalDevelopmentTeam">
                  <Form.Label column sm={2}>
                      Management Team
                  </Form.Label>
                  <Col sm={8}>
                      {team.map((team, index) =>
                          this.renderTeamInputForm(team, index),
                      )}
                  </Col>
              </Form.Group>
              <Col
                  sm={{ span: 8, offset: 2 }}
                  style={{ marginBottom: 20, fontSize: 20 }}
              >
                  <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="tooltip-teams">Add Team</Tooltip>}
                  >
                      <IoIosAddCircle
                          onClick={() => {
                              this.addTeams();
                          }}
                      />
                  </OverlayTrigger>
              </Col>
            </div>

            <div className='token-form-sub-section'>
              <h2>STO DETAILS</h2>
              <Form.Group as={Row} controlId="formHorizontalTokenName">
                <Form.Label column sm={2}>
                  Tech Protocol
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    placeholder="Tech Protocol"
                    onChange={this.handleFormChange('protocol')}
                    value={protocol}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalTokenName">
                <Form.Label column sm={2}>
                  Purchase Link
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    placeholder="Purchase Link"
                    onChange={this.handleFormChange('purchase_link')}
                    value={purchase_link}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalTokenName">
                <Form.Label column sm={2}>
                  Token Ticker
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    placeholder="Token Ticker"
                    onChange={this.handleFormChange('token_ticker')}
                    value={token_ticker}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalTokenName">
                <Form.Label column sm={2}>
                  Current Status (upcoming, open, closed)
                </Form.Label>
                <Col sm={8}>
                    <FormControl required key="status" as="select" onChange={this.handleFormChange('status')}>
                        <option value="">Status</option>
                        <option>Open</option>
                        <option>Closed</option>
                        <option>Upcoming</option>
                    </FormControl>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalDealSize">
                  <Form.Label column sm={2}>
                      Deal Size
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Deal Size"
                          name='deal_size'
                          onChange={this.handleNumericalFieldChange}
                          value={deal_size}
                          type="number"
                          step='0.01'
                          min='0'
                          required
                      />
                  </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalTokenPrice">
                  <Form.Label column sm={2}>
                      Price per Token
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Price per Token"
                          name='token_price'
                          onChange={this.handleNumericalFieldChange}
                          value={token_price}
                          type="number"
                          step='0.01'
                          min='0'
                          required
                      />
                  </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalMinimumInvestment">
                  <Form.Label column sm={2}>
                      Minimum Investment
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Minimum Investment"
                          name='minimum_investment'
                          onChange={this.handleNumericalFieldChange}
                          value={minimum_investment}
                          type="number"
                          step='0.01'
                          min='0'
                      />
                  </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalPropertyDate">
                <Form.Label column sm={2}>
                  STO Start Date
                </Form.Label>
                <Col sm={8}>
                  <DatePicker required onChange={this.onChangeDate('start_date')} value={start_date} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalPropertyDate">
                <Form.Label column sm={2}>
                  STO End Date
                </Form.Label>
                <Col sm={8}>
                  <DatePicker required onChange={this.onChangeDate('end_date')} value={end_date} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalAddress">
                  <Form.Label column sm={2}>
                      Location
                  </Form.Label>
                  <Col sm={8}>
                      <Typeahead
                          labelKey="value"
                          required
                          options={locationOptions}
                          placeholder="Location"
                          onChange={(event) => this.handleLocationSelect(event)}
                      />
                  </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalTokenName">
                <Form.Label column sm={2}>
                  Purchase Requirements
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    placeholder="Purchase Requirements"
                    onChange={this.handleFormChange('purchase_requirements')}
                    value={purchase_requirements}
                    required
                  />
                </Col>
              </Form.Group>
            </div>

            <div className='token-form-sub-section'>
              <h2>SOCIAL</h2>
              <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
                  <Form.Label column sm={2}>
                      Website Link
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Website"
                          onChange={this.handleFormChange('website_link')}
                          value={website_link}
                      />
                  </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalTwitter">
                  <Form.Label column sm={2}>
                      Twitter Link
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Twitter Link"
                          onChange={this.handleFormChange('twitter_link')}
                          value={twitter_link}
                      />
                  </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalLinkedIn">
                  <Form.Label column sm={2}>
                      LinkedIn Link
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Linkedin Link"
                          onChange={this.handleFormChange('linkedin_link')}
                          value={linkedin_link}
                      />
                  </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalFacebook">
                  <Form.Label column sm={2}>
                      Facebook Link
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Facebook Link"
                          onChange={this.handleFormChange('facebook_link')}
                          value={facebook_link}
                      />
                  </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalMedium">
                  <Form.Label column sm={2}>
                      Medium Link
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Medium Link"
                          onChange={this.handleFormChange('medium_link')}
                          value={medium_link}
                      />
                  </Col>
              </Form.Group>
            </div>

            <div className='token-form-sub-section'>
              <h2>LEGAL</h2>
              <Form.Group as={Row} controlId="formHorizontalMedium">
                  <Form.Label column sm={2}>
                      Company Name
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Company Name"
                          onChange={this.handleFormChange('incorporation_name')}
                          value={incorporation_name}
                      />
                  </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalMedium">
                  <Form.Label column sm={2}>
                      Legal Form
                  </Form.Label>
                  <Col sm={8}>
                      <FormControl required key="legal_form" as="select" onChange={this.handleFormChange('legal_form')}>
                          <option value="">Legal Form</option>
                          <option>Holding Company</option>
                          <option>LLC</option>
                          <option>Corporation</option>
                      </FormControl>
                  </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalMedium">
                  <Form.Label column sm={2}>
                      Regulation Type
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Regulation Type"
                          onChange={this.handleFormChange('regulation_type')}
                          value={regulation_type}
                      />
                  </Col>
              </Form.Group>
            </div>

              <Form.Group as={Row}>
                  <Col sm={{ span: 10, offset: 2 }}>
                      {!is_edit ? (
                          <Button type="submit">Submit</Button>
                      ) : (
                          <Button
                              onClick={() => {
                                  this.saveEditedToken();
                              }}
                          >
                              Save
                          </Button>
                      )}
                  </Col>
              </Form.Group>
          </Form>
      </Container>
    );
  };

  render() {
    return (
      <div className="admin-portal-content">
          <h1 className="form-header">FUND SECURITY UPLOAD FORM</h1>
          {this.renderTokenForm()}
      </div>
    );
  }
}

export default FundSecurityForm;
