import { connect } from 'react-redux'
import LightWeightChart from './LightWeightChart'
import './LightWeightChart.css'


const mapStateToProps = state => ({
})

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LightWeightChart)
