import * as CONSTANTS from '../constants/suggestion';
import { CLEAR_USER_CACHE } from '../constants/account'

const initialState = {
  suggestions: {
    list: [],
    error: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case CLEAR_USER_CACHE:
    // state = initialState
    case CONSTANTS.GET_SUGGESTION_LIST_SUCCESS:
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          list: action.list,
        },
      };
    case CONSTANTS.GET_SUGGESTION_LIST_ERROR:
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          error: action.error,
        },
      };
    default:
      return state;
  }
};
