import React, { Component } from 'react'
import { ResponsiveBubble } from '@nivo/circle-packing'
import {
    Row,
    Col,
} from 'react-bootstrap'
import ReactLoading from 'react-loading'

class BubbleChart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: this.props.data,
        }
    } // end of constructor(props)


    render() {
        const {
            data,
        } = this.state
        const chartData = data.item

        return (
            <React.Fragment>
                <ResponsiveBubble
                    root={chartData}
                    identity='name'
                    // value='name'
                    // colors={{ scheme: 'spectral' }}
                    colors={{ scheme: 'category10' }}
                    colorBy='name'
                    labelTextColor='black'
                    labelSkipRadius={20}
                    padding={10}
                    enableLabel={true}
                    leavesOnly={true} // background circle


                />
            </React.Fragment>
        )
    }
} // end of BubbleChart extends Component

export default BubbleChart
