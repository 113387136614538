import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FundSecurityForm from './FundSecurityForm';
import './FundSecurityForm.css';
import {
  getFundSecurityFormRequest,
  getTokenRequest,
  getTokenEditRequest,
  resetTokenForm,
  editTokenRequest,
  editFundTokenRequest,
  uploadImageRequest,
  uploadDocumentRequest,
} from '../../../redux/actions/tokenform';
import 'react-quill/dist/quill.snow.css';

FundSecurityForm.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

FundSecurityForm.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

/*
 * PropType validation
 */
FundSecurityForm.propTypes = {
  placeholder: PropTypes.string,
};

const mapStateToProps = state => ({
  token: state.token.token.item,
  url: state.token.publicURL.url,
  docURL: state.token.publicURL.docURL,
  fieldType: state.token.publicURL.fieldType,
});

const mapDispatchToProps = {
  getFundSecurityFormRequest,
  getTokenRequest,
  getTokenEditRequest,
  resetTokenForm,
  editTokenRequest,
  editFundTokenRequest,
  uploadImageRequest,
  uploadDocumentRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FundSecurityForm);
