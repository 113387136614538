import { connect } from 'react-redux';
import Footer from './Footer';
import './Footer.css';

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Footer);
