import React, { PureComponent } from 'react'
import { history } from '../../configureStore'
import { NavLink } from 'react-router-dom'
import {
  Row,
  Col,
  Button,
  Container,
  Spinner,
  Image,
  Card,
  FormControl,
  Table,
} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { Typeahead } from 'react-bootstrap-typeahead' // ES2015
import countryList from 'react-select-country-list'
import { MdSearch, MdLocationOn } from 'react-icons/md'
import { ResponsiveBar } from '@nivo/bar'
import Choropleth from '../Choropleth'
import PieChart from '../PieChart'
import BubbleChart from '../BubbleChart'
import BarChart from '../BarChart'
import { initGA, logPageView } from "../../utils/analytics"
import MetaTags from 'react-meta-tags'
import LogoImg from '../../images/logoImage.js' // for metatags


class TokenProfile extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      listOfAssets: {
        assets: [],
        loading: false,
        loaded: false,
        error: ''
      },
      listOfAssetTypes: {
        assetTypes: [],
        loading: false,
        loaded: false,
        error: ''
      },
      listOfFiltersForAssetType: {
        filters: [],
        loading: false,
        loaded: false,
        error: ''
      },
      listOfAssetsForSearch: {
        data: [],
        loading: false,
        loaded: false,
        error: ''
      },
      chartData: {
        data: {},
        loading: false,
        loaded: false,
        error: ''
      },
      pressReleases: {
        pressReleasesList: [],
        loading: false,
        loaded: false,
        error: '',
      },
      pressReleaseOffset: 0,
      pressReleasesPageSize: 6,
      selectedAssetType: '',
      assetTypeSearchQuery: '',
      profileFilterValues: {},
    }

  }

  componentDidMount() {
    this.props.getListOfAssetsRequest()
    this.props.getListOfAssetTypesRequest()
    this.props.resetListOfFiltersForAssetType()
    this.props.getAssetsWithParamsRequest()
    this.props.getPressReleasesRequest()
    this.props.getChoroplethDataRequest() // name is a misnomer, this retrieves all chart data
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        listOfAssets: nextProps.listOfAssets,
        listOfAssetTypes: nextProps.listOfAssetTypes,
        listOfFiltersForAssetType: nextProps.listOfFiltersForAssetType,
        listOfAssetsForSearch: nextProps.listOfAssetsForSearch,
        chartData: nextProps.chartData,
        pressReleases: nextProps.pressReleases,
      })
    }

    if (this.props.listOfFiltersForAssetType !== nextProps.listOfFiltersForAssetType) {
      const { filters, loaded } = nextProps.listOfFiltersForAssetType
      if (loaded && filters[0]) {
        const newFilters = filters[0]._source.filters
        newFilters.map(filter => this.handleMapProfileFiltersToState(filter))
      }
    }
  }

  /************* HANDLERS *****************************************************/

  handleMapProfileFiltersToState = filter => {
    const { fieldName } = filter
    const profileFilterValues = this.state.profileFilterValues
    profileFilterValues[fieldName] = ''
  }

  handleChartData = (data, chartType) => {
    /*
    This filters through the data passed into it and selects for the
    data corresponding to a specific chartType.
        For Choropleth: chartType = 'map',
        For PieChart: chartType = 'industry',
        For BubbleChart: chartType = 'amount',
    */
    const filteredData = data.filter(datum => datum.type === chartType)

    const chartData = filteredData[0]
    return chartData
  }

  formatDate = dateInput => {
    let date = new Date(dateInput)
    const month = date.toLocaleString("en-us", { month: "short" })
    const day = date.getDate()
    const year = date.getFullYear()
    return month + " " + day + ", " + year
  }

  handlePressReleasesPageClick = data => {
    let selected = data.selected
    let offset = Math.ceil(selected * this.state.pressReleasesPageSize)
    // pressReleasesAnchor[0].scrollIntoView(true)
    this.props.getPressReleasesRequest(offset)
  }

  /************* END OF HANDLERS **********************************************/


  /************* RENDERS ******************************************************/

  renderAssets = () => {
    const { assets, loaded, loading } = this.state.listOfAssets

    let content
    if (loaded) {
      const tempAssets = assets.length > 4 ? assets.slice(0, 4) : assets
      content = tempAssets.map(asset => this.renderAsset(asset))
    } else if (loading) content = this.renderLoading()

    return (
      <Container fluid>
        <Row noGutters className='assets-header'>
          <h1>ASSETS</h1>
        </Row>
        <Row noGutters className='assets-row'>
          {content}
        </Row>
        <Row noGutters className='link-to-search-assets-row'>
          <NavLink className='link-to-search-assets' to='/assets/search-assets'>Click here to search assets</NavLink>
        </Row>
      </Container>
    )
  }

  renderAsset = asset => {
      const {
        image,
        name,
        brief,
        id,
        issuance_type,
        industry,
        location,
    } = asset._source

    return (
      <React.Fragment key={id}>
          <Col md='auto' className='token-column'>
              <Card className='token-card'>
                  <Card.Body>
                      <Row className='token-header-row'>
                          <Col md={{ span: 4 }}>
                              <NavLink to={`/token/${name}`}>
                                  <Image
                                      src={image}
                                      className='token-image'
                                      alt="token_image"
                                  />
                              </NavLink>
                          </Col>
                          <Col md={{ span: 8 }} className='token-header-text-column'>
                              <Row className='token-name'>
                                  <NavLink to={`/token/${name}`}>
                                      <h4>{name}</h4>
                                  </NavLink>
                              </Row>
                              <Row className='token-location'>
                                <MdLocationOn /> {location}
                              </Row>
                          </Col>
                      </Row>
                      <Row className='token-brief'>
                          <p>{brief}</p>
                      </Row>
                      <Row className='token-industry'>
                          <div className='token-industry-tag'>
                              {industry}
                          </div>
                      </Row>
                  </Card.Body>
              </Card>
          </Col>
      </React.Fragment>
    )
  } // end of renderToken()

  renderMarketOverview = () => {
    const { data, loaded, loading } = this.state.chartData
    let chart

    if (loaded) {
      const filteredData = this.handleChartData(data, 'map')
      chart = <Choropleth className='choropleth' data={filteredData} />
    } else if (loading) chart = this.renderLoading()

    return (
      <Container fluid>
        <Row className='market-overview-content' noGutters>
          <Col className='assets-by-jurisdiction-column'>
            <div className='assets-by-jurisdiction'>
              <div className='abj-header'>
                Assets by Jurisdiction
              </div>
              <div className='abj-body'>
                Visualize which jurisdictions are attracting digital asset issuances.
              </div>
            </div>
          </Col>
          <Col>
            <div className='choropleth-section'>
              {chart}
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  renderIndustriesChart = () => {
    const { data, loaded, loading } = this.state.chartData
    let chart

    if (loaded) {
      const filteredData = this.handleChartData(data, 'industry')
      chart = <PieChart className='pie-chart' data={filteredData.item} />
    } else if (loading) chart = this.renderLoading()

    return (
      <Container fluid>
        <Row className='market-overview-content' noGutters>
          <Col className='assets-by-jurisdiction-column'>
            <div className='assets-by-jurisdiction'>
              <div className='abj-header'>
                Industries
              </div>
              <div className='abj-body'>
                The biggest industries drawing investor capital in the form of digital asset offerings.
              </div>
            </div>
          </Col>
          <Col>
            <div className='choropleth-section'>
              {chart}
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  renderBarChart = () => {
    const { data, loaded, loading } = this.state.chartData

    let chart
    if (loaded) {
      const filteredData = this.handleChartData(data, 'type')
      chart = <BarChart className='pie-chart' data={filteredData.item} />
    } else if (loading) chart = this.renderLoading()

    return (
      <Container fluid>
        <Row className='market-overview-content' noGutters>
          <div className='bar-chart-section'>
            {chart}
          </div>
        </Row>
      </Container>
    )
  }

  renderPressReleases = () => {
    const { pressReleasesList, loading, loaded } = this.state.pressReleases
    let content
    if (loaded) {
      content = pressReleasesList.hits.map(pressRelease => this.renderPressRelease(pressRelease))
    } else if (loading) content = this.renderLoading()

    return (
      <Container fluid>
        <Row noGutters className='press-release-header'>
          <h1>
              PRESS RELEASES
          </h1>
        </Row>
        <Row noGutters>
          <Col>
            {content}
          </Col>
        </Row>
        <Row noGutters>
          {this.renderPagination('press')}
        </Row>
      </Container>
    )
  }

  renderPressRelease = pressRelease => {
    const {
      id,
      image,
      date,
      author,
      title,
      link,
    } = pressRelease._source
    const convertedDate = this.formatDate(date)

    let imageContent
    if (image) {
      imageContent = (
        <Col md={3}>
          <a href={link} target="_blank">
            <Image
              src={image || null}
              className='press-release-image'
              alt="token_image"
            />
          </a>
        </Col>
      )
    }

    return (
      <Row noGutters className='press-release' key={id}>
          {imageContent}
          <Col>
              <Row noGutters className='press-release-details'>
                  <p>
                      {convertedDate} {author || null}
                  </p>
              </Row>
              <Row noGutters className='press-release-title'>
                  <a href={link} target="_blank">
                      <h5>{title}</h5>
                  </a>
              </Row>
          </Col>
      </Row>
    )
  } // end of renderPressReleases()

  renderPagination = paginationType => {
    let pageCount
    let onPageChange
    if (paginationType === 'press') {
      const total = this.state.pressReleases.pressReleasesList.total
      const pageSize = this.state.pressReleasesPageSize
      pageCount = Math.ceil(total / pageSize) || 1

      onPageChange = this.handlePressReleasesPageClick
    }

    if (pageCount >= 1) {
        return (
            <Row className='pagination-row'>
                <Col md={{ span: 12 }} className='pagination-column'>
                    <ReactPaginate
                        className='paginate'
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={5}
                        pageRangeDisplayed={5}
                        onPageChange={onPageChange}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </Col>
            </Row>
        )
    }
  } // end of renderPagination

  renderMetaTags = () => {
    return (
      <MetaTags>
        <meta name='description' content='Digital asset and security token data and profiles'/>
        <meta name='title' property='og:title' content='Velocity Channel Digital Asset Database'/>
        <meta property='og:description' content='Digital asset and security token data and profiles'/>
        <meta property='og:image' content={LogoImg}/>
        <meta property='og:type' content='website'/>
        <meta property='og:url' content='https://velocitychannel.io/token'/>
        <meta property='twitter:title' content='Velocity Channel Digital Asset Database'/>
        <meta property='twitter:description' content='Digital asset and security token data and profiles'/>
        <meta property='twitter:image' content={LogoImg}/>
        <meta property='twitter:site' content='Velocity Channel'/>
        {/* <meta name='robots' content='nofollow'/> */}
      </MetaTags>
    )
  }

  renderLoading = () => (
    <Row noGutters className='loading-bar-row'>
      <Spinner animation='border' variant='light' />
    </Row>
  )

  render() {
    return (
      <Container fluid className='sto-insights-page-container'>
        {this.renderMetaTags()}

        <Row noGutters className='tp-cover-background assets-container'>
          {/* <Col lg={1} xl={2} /> */}
          <Col>
            {this.renderAssets()}
          </Col>
          {/* <Col lg={1} xl={2} /> */}
        </Row>

        <Row noGutters className='market-overview-container'>
          <Col lg={1} xl={2} />
          <Col>
            {this.renderMarketOverview()}
          </Col>
          <Col lg={1} xl={2} />
        </Row>

        <Row noGutters className='industries-chart-container'>
          <Col lg={1} xl={2} />
          <Col>
            {this.renderIndustriesChart()}
          </Col>
          <Col lg={1} xl={2} />
        </Row>

        <Row noGutters className='bar-chart-container'>
          <Col lg={1} />
          <Col>
            {this.renderBarChart()}
          </Col>
          <Col lg={1} />
        </Row>

        <Row noGutters className='press-releases-container'>
          <Col lg={1} xl={2} />
          <Col>
            {this.renderPressReleases()}
          </Col>
          <Col lg={1} xl={2} />
        </Row>

      </Container>
    )
  }
}

export default TokenProfile
