import * as CONSTANTS from "../constants/landingPage";
import { CLEAR_USER_CACHE } from '../constants/account'

const initialState = {
  tokenSearchList: {
    tokenList: [],
    error: ""
  },

  researchFeedList: {
    researchFeedList: [],
    error: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case CLEAR_USER_CACHE:
    // state = initialState
    case CONSTANTS.TOKEN_SEARCH_SUCCESS:
      return {
        ...state,
        tokenSearchList: {
          ...state.tokenSearchList,
          tokenList: action.tokenList
        }
      };
    case CONSTANTS.TOKEN_SEARCH_ERROR:
      return {
        ...state,
        tokenSearchList: {
          ...state.tokenSearchList,
          error: action.error
        }
      };
    case CONSTANTS.RESEARCH_FEED_SUCCESS:
      return {
        ...state,
        researchFeedList: {
          ...state.researchFeedList,
          researchFeedList: action.researchFeedList
        }
      };
    case CONSTANTS.RESEARCH_FEED_ERROR:
      return {
        ...state,
        researchFeedList: {
          ...state.researchFeedList,
          error: action.error
        }
      };
    default:
      return state;
  }
};
