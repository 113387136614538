import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { history } from '../../../configureStore';
import { CONTACT_REQUEST } from '../../constants/contact';
import { contactError, contactSuccess } from '../../actions/contact';
import request from '../../../utils/apiRequest';

function* sendContactRequest(action) {
  try {
    const requestData = {
      username: action.username,
      email: action.email,
      issue: action.issue,
    };

    const responseData = yield call(
      request,
      'account/contact',
      'POST',
      requestData,
    );
    // toast.success('Request sent successfully!');
    yield put(contactSuccess(responseData));
    // history.push('/login');
  } catch (err) {
    // toast.error('Request send error!');
    yield put(contactError(err));
  }
}

export default function* contactSaga() {
  yield takeLatest(CONTACT_REQUEST, sendContactRequest);
}
