import * as CONSTANTS from '../constants/forgotpassword'

export const forgotPasswordRequest = params => ({
  type: CONSTANTS.FORGOT_PASSWORD_REQUEST,
  params,
})

export const forgotPasswordSuccess = data => ({
  type: CONSTANTS.FORGOT_PASSWORD_SUCCESS,
  data,
})

export const forgotPasswordError = err => ({
  type: CONSTANTS.FORGOT_PASSWORD_ERROR,
  err,
})

export const forgotPasswordReset = () => ({
  type: CONSTANTS.FORGOT_PASSWORD_RESET,
})

export const resetPasswordRequest = params => ({
  type: CONSTANTS.RESET_PASSWORD_REQUEST,
  params,
})

export const resetPasswordSuccess = data => ({
  type: CONSTANTS.RESET_PASSWORD_SUCCESS,
  data,
})

export const resetPasswordError = err => ({
  type: CONSTANTS.RESET_PASSWORD_ERROR,
  err,
})

export const resetPasswordReset = () => ({
  type: CONSTANTS.RESET_PASSWORD_RESET,
})
