import { put, takeLatest, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as CONSTANTS from '../../constants/research';
import request from '../../../utils/apiRequest';
import {
  researchFormError,
  getReportsReqeust,
  getReportsSuccess,
  getReportsError,
  getReportSuccess,
  getReportError,
} from '../../actions/research';
import { history } from '../../../configureStore';

function* researchForm(action) {
  try {
    yield call(request, 'research/newReport', 'POST', action.data, true);
    // toast.success('Success');
  } catch (err) {
    // toast.error(err.reason);
    yield put(researchFormError(err));
  }
}

function* getReports(action) {
  try {
    const currentUserId = yield select(
      state => state.account.myAccount.info.userId,
    );

    const requestData = {
      userId: currentUserId,
    };
    const responseData = yield call(
      request,
      'research/user',
      'GET',
      requestData,
      true,
    );
    yield put(getReportsSuccess(responseData));
  } catch (err) {
    yield put(getReportsError(err));
    // toast.error(err.reason);
  }
}

function* getReport(action) {
  try {
    const requestData = {
      id: action.id,
    };
    const responseData = yield call(
      request,
      'research/report',
      'GET',
      requestData,
      true,
    );
    yield put(getReportSuccess(responseData));
  } catch (err) {
    // toast.error(err.reason);
    yield put(getReportError(err));
  }
}

function* deleteReport(action) {
  try {
    const requestData = {
      id: action.id,
    };
    yield call(request, 'research/deleteReport', 'POST', requestData, true);
    yield put(getReportsReqeust());
  } catch (err) {
    // toast.error(err.reason);
  }
}

function* editReport(action) {
  try {
    yield call(request, 'research/editReport', 'POST', action.data, true);
    // toast.success('Success');
    history.pushState('/admin/reserachlist');
  } catch (err) {
    // toast.error(err.reason);
  }
}

export default function* researchSaga() {
  yield takeLatest(CONSTANTS.RESEARCH_FORM_REQUEST, researchForm);
  yield takeLatest(CONSTANTS.GET_REPORTS_REQUEST, getReports);
  yield takeLatest(CONSTANTS.GET_REPORT_REQUEST, getReport);
  yield takeLatest(CONSTANTS.DELETE_REPORT_REQUEST, deleteReport);
  yield takeLatest(CONSTANTS.EDIT_REPORT_REQUEST, editReport);
}
