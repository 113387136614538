import * as CONSTANTS from "../constants/collections";
import { CLEAR_USER_CACHE } from "../constants/account";

const initialState = {
  discoverCollections: {
    collections: {},
    loaded: false,
    loading: false,
    error: ''
  },
  publicUserCollections: {
    collections: {},
    loaded: false,
    loading: false,
    error: ''
  },
  privateUserCollections: {
    collections: {},
    loaded: false,
    loading: false,
    error: ''
  },
  specificCollection: {
    collectionInfo: {},
    reports: {
      total: '',
      data: [],
    },
    loaded: false,
    loading: false,
    error: ''
  },
  collectionSearchResults: {
    results: {},
    loading: false,
    loaded: false,
    error: ''
  },
  collectionImage: {
    image: '',
    loading: false,
    loaded: false,
    error: '',
  },
  collectionBeingEdited: {
    collectionId: '',
    loading: false,
    complete: false,
    error: '',
  },
  removeReport: {
    loading: false,
    complete: false,
    error: '',
  },
  collectionPrivacyBeingToggled: {
    loading: false,
    error: '',
  },
  createNewCollectionRequest: {
    loading: false,
    newCollectionId: '',
    complete: false,
    error: ''
  },
  deleteCollection: {
    loading: false,
    complete: false,
    error: '',
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case CLEAR_USER_CACHE:
    //   state = initialState;
  case CONSTANTS.RESET_COLLECTION_INITIAL_STATE: {
    return state
  }
  case CONSTANTS.GET_DISCOVER_COLLECTIONS_REQUEST: {
    return {
      ...state,
      discoverCollections: {
        ...state.discoverCollections,
        loading: true,
        loaded: false,
        error: ''
      }
    }
  }
  case CONSTANTS.GET_DISCOVER_COLLECTIONS_SUCCESS: {
    return {
      ...state,
      discoverCollections: {
        ...state.discoverCollections,
        collections: action.data,
        loading: false,
        loaded: true,
        error: ''
      }
    }
  }
  case CONSTANTS.GET_DISCOVER_COLLECTIONS_ERROR: {
    return {
      ...state,
      discoverCollections: {
        ...state.discoverCollections,
        loaded: false,
        loading: false,
        error: action.error
      }
    }
  }
  case CONSTANTS.GET_PUBLIC_USER_COLLECTIONS_REQUEST: {
    return {
      ...state,
      publicUserCollections: {
        ...state.publicUserCollections,
        loading: true,
        loaded: false,
        error: ''
      }
    }
  }
  case CONSTANTS.GET_PUBLIC_USER_COLLECTIONS_SUCCESS: {
    return {
      ...state,
      publicUserCollections: {
        ...state.publicUserCollections,
        collections: action.data,
        loading: false,
        loaded: true,
        error: ''
      }
    }
  }
  case CONSTANTS.GET_PUBLIC_USER_COLLECTIONS_ERROR: {
    return {
      ...state,
      publicUserCollections: {
        ...state.publicUserCollections,
        loaded: false,
        loading: false,
        error: action.error
      }
    }
  }
  case CONSTANTS.GET_PRIVATE_USER_COLLECTIONS_REQUEST: {
    return {
      ...state,
      privateUserCollections: {
        ...state.privateUserCollections,
        loading: true,
        loaded: false,
        error: ''
      }
    }
  }
  case CONSTANTS.GET_PRIVATE_USER_COLLECTIONS_SUCCESS: {
    return {
      ...state,
      privateUserCollections: {
        ...state.privateUserCollections,
        collections: action.data,
        loading: false,
        loaded: true,
        error: ''
      }
    }
  }
  case CONSTANTS.GET_PRIVATE_USER_COLLECTIONS_ERROR: {
    return {
      ...state,
      privateUserCollections: {
        ...state.privateUserCollections,
        loaded: false,
        loading: false,
        error: action.error
      }
    }
  }
  case CONSTANTS.GET_SPECIFIC_COLLECTION_REQUEST: {
    return {
      ...state,
      specificCollection: {
        ...state.specificCollection,
        loading: true,
        loaded: false,
        error: ''
      }
    }
  }
  case CONSTANTS.GET_SPECIFIC_COLLECTION_SUCCESS: {
    return {
      ...state,
      specificCollection: {
        ...state.specificCollection,
        collectionInfo: action.collectionInfo,
        reports: action.reports,
        loading: false,
        loaded: true,
        error: ''
      }
    }
  }
  case CONSTANTS.GET_SPECIFIC_COLLECTION_ERROR: {
    return {
      ...state,
      specificCollection: {
        ...state.specificCollection,
        loading: false,
        loaded: false,
        error: action.error
      }
    }
  }
  case CONSTANTS.CREATE_NEW_PRIVATE_COLLECTION_REQUEST: {
    return {
      ...state,
      createNewCollectionRequest: {
        ...state.createNewCollectionRequest,
        loading: true,
        complete: false,
        error: ''
      }
    }
  }
  case CONSTANTS.CREATE_NEW_PRIVATE_COLLECTION_SUCCESS: {
    return {
      ...state,
      createNewCollectionRequest: {
        ...state.createNewCollectionRequest,
        loading: true,
        complete: true,
        newCollectionId: action.collectionId.id,
        error: ''
      }
    }
  }
  case CONSTANTS.CREATE_NEW_PRIVATE_COLLECTION_ERROR: {
    return {
      ...state,
      createNewCollectionRequest: {
        ...state.createNewCollectionRequest,
        loading: false,
        error: action.error
      }
    }
  }
  case CONSTANTS.CREATE_NEW_PRIVATE_COLLECTION_FINISH: {
    return {
      ...state,
      createNewCollectionRequest: {
        ...state.createNewCollectionRequest,
        loading: false,
        complete: false,
      }
    }
  }
  case CONSTANTS.RESET_CREATE_NEW_PRIVATE_COLLECTION: {
    return {
      ...state,
      createNewCollectionRequest: {
        loading: false,
        newCollectionId: '',
        complete: false,
        error: ''
      }
    }
  }
  case CONSTANTS.SEARCH_COLLECTIONS_REQUEST: {
    return {
      ...state,
      collectionSearchResults: {
        ...state.collectionSearchResults,
        loading: true,
        loaded: false,
        error: '',
      }
    }
  }
  case CONSTANTS.SEARCH_COLLECTIONS_SUCCESS: {
    return {
      ...state,
      collectionSearchResults: {
        ...state.collectionSearchResults,
        results: action.results,
        loading: false,
        loaded: true,
      }
    }
  }
  case CONSTANTS.SEARCH_COLLECTIONS_ERROR: {
    return {
      ...state,
      collectionSearchResults: {
        ...state.collectionSearchResults,
        loading: false,
        loaded: false,
        error: action.error,
      }
    }
  }
  case CONSTANTS.EDIT_COLLECTION_REQUEST: {
    return {
      ...state,
      collectionBeingEdited: {
        ...state.collectionBeingEdited,
        collectionId: action.collectionId,
        loading: true,
        complete: false,
        error: ''
      }
    }
  }
  case CONSTANTS.EDIT_COLLECTION_SUCCESS: {
    return {
      ...state,
      collectionBeingEdited: {
        ...state.collectionBeingEdited,
        loading: false,
        complete: true,
        error: ''
      }
    }
  }
  case CONSTANTS.EDIT_COLLECTION_ERROR: {
    return {
      ...state,
      collectionBeingEdited: {
        ...state.collectionBeingEdited,
        collectionBeingEdited: '',
        loading: false,
        loaded: false,
        error: action.error
      }
    }
  }
  case CONSTANTS.EDIT_COLLECTION_IMAGE_REQUEST: {
    return {
      ...state,
      collectionImage: {
        ...state.collectionImage,
        loaded: false,
        loading: true,
        error: '',
      }
    }
  }
  case CONSTANTS.EDIT_COLLECTION_IMAGE_SUCCESS: {
    return {
      ...state,
      collectionImage: {
        ...state.collectionImage,
        image: action.image.s3Location,
        loaded: true,
        loading: false,
        error: '',
      }
    }
  }
  case CONSTANTS.EDIT_COLLECTION_IMAGE_SUCCESS: {
    return {
      ...state,
      collectionImage: {
        ...state.collectionImage,
        image: '',
        loaded: false,
        loading: false,
        error: action.error,
      }
    }
  }
  case CONSTANTS.HANDLE_CLEAR_COLLECTION_IMAGE: {
    return {
      ...state,
      collectionImage: {
        image: '',
        loaded: false,
        loading: false,
        error: '',
      }
    }
  }
  case CONSTANTS.REMOVE_REPORT_FROM_COLLECTION_REQUEST: {
    return {
      ...state,
      removeReport: {
        ...state.removeReport,
        loading: true,
        complete: false,
        error: '',
      }
    }
  }
  case CONSTANTS.REMOVE_REPORT_FROM_COLLECTION_SUCCESS: {
    return {
      ...state,
      removeReport: {
        ...state.removeReport,
        loading: false,
        complete: true,
        error: '',
      }
    }
  }
  case CONSTANTS.REMOVE_REPORT_FROM_COLLECTION_SUCCESS: {
    return {
      ...state,
      removeReport: {
        ...state.removeReport,
        loading: false,
        complete: false,
        error: action.error,
      }
    }
  }
  case CONSTANTS.TOGGLE_PUBLIC_PRIVATE_COLLECTION_REQUEST: {
    return {
      ...state,
      collectionPrivacyBeingToggled: {
        ...state.collectionPrivacyBeingToggled,
        loading: true,
      }
    }
  }
  case CONSTANTS.TOGGLE_PUBLIC_PRIVATE_COLLECTION_SUCCESS: {
    return {
      ...state,
      collectionPrivacyBeingToggled: {
        ...state.collectionPrivacyBeingToggled,
        loading: false,
      }
    }
  }
  case CONSTANTS.TOGGLE_PUBLIC_PRIVATE_COLLECTION_ERROR: {
    return {
      ...state,
      collectionPrivacyBeingToggled: {
        ...state.collectionPrivacyBeingToggled,
        loading: false,
        error: action.error,
      }
    }
  }
  case CONSTANTS.DELETE_COLLECTION_REQUEST: {
    return {
      ...state,
      deleteCollection: {
        ...state.deleteCollection,
        loading: true,
      }
    }
  }
  case CONSTANTS.DELETE_COLLECTION_SUCCESS: {
    return {
      ...state,
      deleteCollection: {
        ...state.deleteCollection,
        loading: false,
        complete: true,
      }
    }
  }
  case CONSTANTS.DELETE_COLLECTION_ERROR: {
    return {
      ...state,
      deleteCollection: {
        ...state.deleteCollection,
        loading: false,
        complete: false,
        error: action.error,
      }
    }
  }
  default:
    return state;
  }
};
