import { connect } from 'react-redux';
import TokenProfile from './TokenProfile';
import '../Admin/AdminPage/AdminPage.css';
import '../Admin/TokenList/TokenList.css';
import './TokenProfile.css';
import {
  searchTokensRequest,
  getTokenListRequest,
  deleteTokenRequest,
  getTokenDetailsRequest,
  getMarketStatsRequest,
  getTokensByDateRequest,
  getPressReleasesRequest,
  getChoroplethDataRequest,
  getListOfAssetTypesRequest,
  getListOfAssetsRequest,
  getListOfFiltersForAssetTypeRequest,
  resetListOfFiltersForAssetType,
  getAssetsWithParamsRequest,
} from '../../redux/actions/tokenlist';

const mapStateToProps = state => ({
  searchTokens: state.tokens.searchTokens,
  tokenProfile: state.tokens.tokenProfile,
  stats: state.tokens.stats,
  error: state.tokens.searchTokens.error,
  tokensByDate: state.tokens.tokensByDate,
  pressReleases: state.tokens.pressReleases,
  chartData: state.tokens.chartData,
  assetTypes: state.tokens.assetTypes,
  listOfAssetTypes: state.tokens.listOfAssetTypes,
  listOfAssets: state.tokens.listOfAssets,
  profileFilters: state.tokens.profileFilters,
  listOfFiltersForAssetType: state.tokens.listOfFiltersForAssetType,
  listOfAssetsForSearch: state.tokens.listOfAssetsForSearch,
});

const mapDispatchToProps = {
  searchTokensRequest,
  getTokenListRequest,
  deleteTokenRequest,
  getTokenDetailsRequest,
  getMarketStatsRequest,
  getTokensByDateRequest,
  getPressReleasesRequest,
  getChoroplethDataRequest,
  getListOfAssetTypesRequest,
  getListOfAssetsRequest,
  getListOfFiltersForAssetTypeRequest,
  resetListOfFiltersForAssetType,
  getAssetsWithParamsRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TokenProfile);
