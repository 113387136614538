import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  POST_SUGGESTION_REQUEST,
  GET_SUGGESTION_LIST_REQUEST,
  REMOVE_SUGGESTION_REQUEST,
} from '../../constants/suggestion';
import {
  postSuggestionSuccess,
  postSuggestionError,
  getSuggestionListReqeust,
  getSuggestionListSuccess,
  getSuggestionListError,
  removeSuggestionError,
} from '../../actions/suggestion';
import request from '../../../utils/apiRequest';

function* postSuggestion(action) {
  try {
    const { token_name, description, issuance_stage, email } = action;
    const requestData = { token_name, description, issuance_stage, email };

    const responseData = yield call(
      request,
      'tokens/newSuggestion',
      'POST',
      requestData,
      true,
    );
    // toast.success('Success');
    yield put(postSuggestionSuccess(responseData));
  } catch (err) {
    // toast.error(err.reason);
    yield put(postSuggestionError(err));
  }
}

function* getSuggestionList(action) {
  try {
    const responseData = yield call(
      request,
      'admin/tokenSuggestions',
      'GET',
      null,
      true,
    );
    yield put(getSuggestionListSuccess(responseData));
  } catch (err) {
    // toast.error(err.reason);
    yield put(getSuggestionListError(err));
  }
}

function* removeSuggestion(action) {
  try {
    const requestData = {
      date: action.date,
      id: action.id,
    };

    yield call(
      request,
      'admin/removeTokenSuggestion',
      'POST',
      requestData,
      true,
    );
    // toast.success('Success');
    yield put(getSuggestionListReqeust());
  } catch (err) {
    // toast.error(err.reason);
    yield put(removeSuggestionError(err));
  }
}

export default function* suggestionFormSaga() {
  yield takeLatest(POST_SUGGESTION_REQUEST, postSuggestion);
  yield takeLatest(GET_SUGGESTION_LIST_REQUEST, getSuggestionList);
  yield takeLatest(REMOVE_SUGGESTION_REQUEST, removeSuggestion);
}
