import { connect } from 'react-redux';
import PrivacyPolicy from './PrivacyPolicy';
import './PrivacyPolicy.css';
const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivacyPolicy);