import { connect } from 'react-redux';
import Routes from './Routes'
import { withRouter } from 'react-router-dom';
import {
  getMyAccountRequest,
  wipeLocalStorage,
  checkUserStatus,
} from '../redux/actions/account';
import {
  enableWebWorker,
  showLoginModal,
  hideLoginModal,
  hideLoginModalAndWipeLocalStorage,
  autoLoginRequest
} from '../redux/actions/login';
import { getVersionRequest } from '../redux/actions/index'
import { getWebsiteConfigRequest } from '../redux/actions/admin'

const mapStateToProps = state => ({
  accountData: state.login.accountData,
  websiteConfig: state.admin.websiteConfig,
})

const mapDispatchToProps = {
  getMyAccountRequest,
  enableWebWorker,
  getVersionRequest,
  showLoginModal,
  hideLoginModal,
  wipeLocalStorage,
  checkUserStatus,
  hideLoginModalAndWipeLocalStorage,
  autoLoginRequest,
  getWebsiteConfigRequest,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes))
