import React from 'react';
import Terms from './Terms';
import { Container, Button, Row, Col, Image } from 'react-bootstrap';

export default () => (
  <div className='terms-of-service'>


    <Container className='terms-of-service-container'>
            <div className="termsb">
                <h1>
                    Terms and conditions
                </h1>
                <p>
                This website is operated by Velocity Ledger Technology. Throughout the site, the terms
                “we”, “us” and “our” refer to Velocity Ledger Technology and The Velocity Channel. Velocity
                Ledger Technology offers this website, including all information, tools and services available
                from this site to you, the user, conditioned upon your acceptance of all terms, conditions,
                policies and notices stated here.<br/><br/>

                By reading and proceeding to engage on our site, you accept the following terms & conditions.
                These terms & conditions apply to all users of the site, including, but not limited to users who
                are browsers, customers, marketers and/ or contributors of content.<br/><br/>

                Please read these terms & conditions carefully before accessing or using our website. If you do
                not agree to all the terms & conditions of this agreement, then you may not access the website.<br/><br/>

                Any new features or tools which are added to The Velocity Channel site will also be subject to the terms &
                conditions. You can review the most updated version of the terms & conditions at any time right here. We reserve
                the right to change or replace any part of these terms & conditions by posting new content, updates and/or
                changes to The Velocity Channel site. Your continued use of the website following the posting of any changes
                constitutes acceptance of changes.





                </p>
                <div  className="mainbody">
                    <div className="leftsite">
                        <h4>General conditions</h4>

                    </div>
                    <div className="rightsite">

                        <p>We reserve the right to refuse service to anyone for any reason at any time.<br/><br/>
                        By accessing this site, you understand that your content (not including credit card information),
                        may be transferred unencrypted and involve

                        <ol type="A">
                            <li>transmissions over various networks; and</li>
                            <li>changes to conform and adapt to technical requirements of connecting networks or devices. </li>

                        </ol>
                        Credit card information is always encrypted during transfer over networks.<br/><br/>
                        You agree not to reproduce, duplicate, copy, sell, resell or exploit any content that is featured on
                        The Velocity Channel. Furthermore, please note that all content displayed on, transmitted through, or
                        used in connection with The Velocity Channel website is under applicable copyrights, and/or other
                        propriety rights, and is the intellectual property of Velocity Ledger Technology and its partners.
                        Velocity Ledger Technology actively protects its rights to the content. Thus, by accessing The
                        Velocity Channel site, you agree not to use any content on the site, in any way other than for
                        personal, non-commercial use, without expressed written consent by us. You may never remove The
                        Velocity Channel logotypes, copyrights, trademarks or other annotations in such content.


                        </p>
                    </div>
                </div>
                <div  className="mainbody">
                    <hr/>
                    <div className="leftsite">
                        <h4>Disclaimer</h4>

                    </div>
                    <div className="rightsite">

                        <p> This website and the information provided on this website has been prepared solely for informational
                             purposes and should not be construed as an offer to buy or sell or a solicitation of an offer
                             to buy or sell any security or instrument or to participate in any transaction or trading activity. <br/><br/>
                             All thoughts and opinions expressed in the content on The Velocity Channel sites are the writers’
                             own and should not be viewed as financial or investment advice or indications whatsoever.<br/><br/>
                             The content on The Velocity Channel is based upon or derived from information generally believed
                             to be reliable although no representation is made that it is accurate or complete and The Velocity
                             Channel and Velocity Ledger Technology accept no liability with the regards to the readers’ reliance on it.<br/><br/>
                             We highly advise that you do your own research and consult with an authorised investment professional before making any monetary decisions.



                        </p>
                    </div>
                </div>

                <div  className="mainbody">
                    <hr/>
                    <div className="leftsite">
                        <h4>Limitations of information</h4>

                    </div>
                    <div className="rightsite">

                        <p>We are not responsible if information made available on this site is not accurate, complete or current.
                            The material on this site is provided as a general information service only and should not be relied
                            upon as the only source of information and the sole basis for making decisions. We clearly highlight
                            that you have the opportunity to consult primary, more accurate, more complete or more timely sources
                            of information. Any reliance on the material on this site is at your own risk, and Velocity Ledger
                            Technology, The Velocity Channel and its partners disclaim the responsibility of consequences in doing so.<br/><br/>
                            This site may contain certain historical information. Historical information, necessarily, is not current and is
                            provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have
                            no obligation to update any information on our site. You agree that it is your responsibility to inspect for changes to
                            The Velocity Channel and its content.


                        </p>
                    </div>
                </div>

                <div  className="mainbody">
                    <hr/>
                    <div className="leftsite">
                        <h4>Links to other websites</h4>

                    </div>
                    <div className="rightsite">

                        <p>Certain content made available on The Velocity Channel may include materials from third-parties.<br/><br/>
                        Links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible
                        for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or
                        responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.<br/><br/>
                        We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other
                        transactions made in connection with any third-party websites. Please review carefully the third-party’s policies and practices
                        and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding
                        third-party products should be directed to the third-party.


                        </p>
                    </div>
                </div>

                <div  className="mainbody">
                    <hr/>
                    <div className="leftsite">
                        <h4>Prohibited uses</h4>

                    </div>
                    <div className="rightsite">

                        <p> In addition to the beforementioned prohibitions that are set forward in the terms & conditions,
                            you are prohibited from using the site or its content:


                            <ol type="1">
                            <li>for any unlawful purpose;</li>
                            <li>to solicit others to perform or participate in any unlawful acts;</li>
                            <li>to violate any international, federal, provincial or state regulations,
                                rules, laws, or local ordinances; </li>
                            <li>to infringe upon or violate our intellectual
                                 property rights or the intellectual property rights of others;</li>
                            <li>to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender,
                                 sexual orientation, religion, ethnicity, race, age, national origin, or disability;</li>
                            <li>to submit false or misleading information; </li>
                            <li>to upload or transmit viruses or any other type of malicious code that will or may be used
                                in any way that will affect the functionality or operation of The Velocity Channel or of any
                                related website, other websites, or the Internet; </li>
                            <li>to collect or track the personal information of others;</li>
                            <li>to spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
                            <li>for any obscene or immoral purpose; or</li>
                            <li>to interfere with or circumvent the security features of The Velocity Channel or any related website,
                            other websites, or the Internet. </li>
                        </ol>
                        </p>
                        <h6>
                         We reserve the right to terminate your access to The Velocity Channel website for violating anything within the terms & conditions and of the prohibited uses.
                        </h6>
                    </div>
                </div>



            </div>
    </Container>
    </div>
);
