import queryString from 'query-string';
import { baseUrl } from '../config';

async function request(
  endpoint,
  method = 'GET',
  data = {},
  isToken = false,
  formData = false,
) {
  
  return new Promise((resolve, reject) => {
    let qs = '';
    let body;
    let headers;
    if (['GET', 'DELETE'].indexOf(method) > -1) {
      qs = `${queryString.stringify(data)}`;
    } else if (formData) {
      body = data;
    } else {
      body = queryString.stringify(data);
    }

    if (formData) {
      headers = {
        Accept: 'application/json',
      };
    } else {
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      };
    }

    const requestUrl = `${baseUrl}${endpoint}${qs}`;
    const options = {
      method,
      headers,
      credentials: 'include',
      body,
    };

    if (isToken) {
      options.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    }

    fetch(requestUrl, options) // eslint-disable-line
      .then(result => result.json())
      .then(json => {
        if (json.message === 'error') {
          reject(json);
        } else {
          resolve(json);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
}

export default request;
