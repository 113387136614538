import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
    GET_CHOROPLETH_DATA_REQUEST,
    GET_PIE_CHART_DATA_REQUEST,
} from '../../constants/charts';
import {
    getChoroplethDataRequest,
    getChoroplethDataSuccess,
    getChoroplethDataError,
    getPieChartDataRequest,
    getPieChartDataSuccess,
    getPieChartDataError,
} from '../../actions/charts';
import request from '../../../utils/apiRequest';

/**************************** CHOROPLETH WATCHERS *****************************/
function* getChoroplethData(action) {
    try {
        const responseData = yield call(
            request,
            'tokens/stats',
            'GET',
            null,
            true,
        )
        yield put(getChoroplethDataSuccess(responseData))
    } catch (err) {
        // toast.error(err.reason)
        yield put(getChoroplethDataError(err))
    }
} // end of getChoroplethData(action)
/************************ END OF CHOROPLETH WATCHERS **************************/


/**************************** PIE_CHART WATCHERS *****************************/
function* getPieChartData(action) {
    try {
        const responseData = yield call(
            request,
            'tokens/stats',
            'GET',
            null,
            true,
        )
        yield put(getPieChartDataSuccess(responseData))
    } catch (err) {
        // toast.error(err.reason)
        yield put(getPieChartDataError(err))
    }
} // end of getPieChartData(action)
/************************ END OF PIE_CHART WATCHERS ***************************/

export default function* chartSaga() {
    yield takeLatest(GET_CHOROPLETH_DATA_REQUEST, getChoroplethData)
    yield takeLatest(GET_PIE_CHART_DATA_REQUEST, getPieChartData)
} // end of export default function* chartSaga()
