export const POST_SUGGESTION_REQUEST = 'POST_SUGGESTION_REQUEST';
export const POST_SUGGESTION_SUCCESS = 'POST_SUGGESTION_SUCCESS';
export const POST_SUGGESTION_ERROR = 'POST_SUGGESTION_ERROR';

export const GET_SUGGESTION_LIST_REQUEST = 'GET_SUGGESTION_LIST_REQUEST';
export const GET_SUGGESTION_LIST_SUCCESS = 'GET_SUGGESTION_LIST_SUCCESS';
export const GET_SUGGESTION_LIST_ERROR = 'GET_SUGGESTION_LIST_ERROR';

export const REMOVE_SUGGESTION_REQUEST = 'REMOVE_SUGGESTION_REQUEST';
export const REMOVE_SUGGESTION_SUCCESS = 'REMOVE_SUGGESTION_SUCCESS';
export const REMOVE_SUGGESTION_ERROR = 'REMOVE_SUGGESTION_ERROR';
