import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'

import store, { history } from './configureStore';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';

import './styles/index.css';

ReactDOM.render(
  <Provider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
          <Router history={history}>
              <Routes />
          </Router>
      </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
