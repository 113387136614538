import { connect } from 'react-redux';
import TokenForm from './TokenForm';
import './TokenForm.css';
import {
  getTokenEditRequest,
  resetTokenForm,
  editTokenRequest,
  uploadImageRequest,
  createTokenRequest,
} from '../../../redux/actions/tokenform';
import 'react-quill/dist/quill.snow.css';

// EquityTokenForm.propTypes = {
//   placeholder: PropTypes.string,
// };

const mapStateToProps = state => ({
  imageUrl: state.token.publicURL.url,
  token: state.token.token.item,
});

const mapDispatchToProps = {
  getTokenEditRequest,
  resetTokenForm,
  editTokenRequest,
  uploadImageRequest,
  createTokenRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TokenForm);
