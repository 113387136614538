import { connect } from "react-redux"
import Collections from "./Collections"
import "./Collections.css"
import {
  getDiscoverCollectionsRequest,
  getPublicUserCollectionsRequest,
  getPrivateUserCollectionsRequest,
  getSpecificCollectionRequest,
  createNewPrivateCollectionRequest,
  editCollectionRequest,
  editCollectionImageRequest,
  handleClearCollectionImage,
  removeReportFromCollectionRequest,
  togglePublicPrivateCollectionRequest,
  resetCollectionInitialState,
  deleteCollectionRequest,
} from '../../redux/actions/collections'

const mapStateToProps = state => ({
  discoverCollections: state.collections.discoverCollections,
  publicUserCollections: state.collections.publicUserCollections,
  privateUserCollections: state.collections.privateUserCollections,
  specificCollection: state.collections.specificCollection,
  collectionImage: state.collections.collectionImage,
  collectionBeingEdited: state.collections.collectionBeingEdited,
  removeReport: state.collections.removeReport,
  userInfo: state.account.myAccount.info,
  collectionPrivacyBeingToggled: state.collections.collectionPrivacyBeingToggled,
  createNewCollectionRequest: state.collections.createNewCollectionRequest,
  deleteCollection: state.collections.deleteCollection,
})

const mapDispatchToProps = {
  getDiscoverCollectionsRequest,
  getPublicUserCollectionsRequest,
  getPrivateUserCollectionsRequest,
  getSpecificCollectionRequest,
  createNewPrivateCollectionRequest,
  editCollectionRequest,
  editCollectionImageRequest,
  handleClearCollectionImage,
  removeReportFromCollectionRequest,
  togglePublicPrivateCollectionRequest,
  resetCollectionInitialState,
  deleteCollectionRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Collections)
