import React, { Component } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { TiThList } from 'react-icons/ti';
import { history } from '../../../configureStore';
import ReactQuill from 'react-quill';

class SuggestionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tokenName: '',
      theme: 'snow',
      issuanceStage: '',
      tokenDescription: '',
      email: '',
      isFormSubmitted: false,
    };
  }

  handleFormChange = fieldName => e => {
    this.setState({
      [fieldName]: e.target.value,
    });
  };

  handleChange = fieldName => html => {
    this.setState({ [fieldName]: html });
  };

  onSubmit = e => {
    e.preventDefault();
    const { tokenName, issuanceStage, tokenDescription, email } = this.state;

    this.props.postSuggestionRequest(
      tokenName,
      issuanceStage,
      tokenDescription,
      email,
    );
    this.setState({ isFormSubmitted: true });
  };

  backToSuggestionLIst = () => {
    history.push('/admin/suggestionlist');
  };

  renderSuggestionForm = () => {
    const { isFormSubmitted } = this.state;

    return (
      <Container className="form-content">
        <Form onSubmit={this.onSubmit}>
          <Form.Group as={Row} controlId="formHorizontalTokenName">
            <Col sm={{ span: 8, offset: 2 }}>
              <p className="suggestion-form">
                {' '}
                Want to see a new token covered on Velocity Channel?{' '}
              </p>
              <p className="suggestion-form">
                Let us know and our staff will begin working to add the token to
                our system.
              </p>
              <Form.Control
                placeholder="Token Name"
                onChange={this.handleFormChange('tokenName')}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalIssuanceStage">
            <Col sm={{ span: 8, offset: 2 }}>
              <Form.Control
                as="select"
                onChange={this.handleFormChange('issuanceStage')}
                placeholder="Issuance stage"
                required
              >
                <option>Selet One of issuance stages</option>
                <option>Preissuance</option>
                <option>Token sale active</option>
                <option>Post issuance</option>
                <option>Post issuance with secondary trading</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalTokenDescription">
            <Col sm={{ span: 8, offset: 2 }}>
              <ReactQuill
                theme={this.state.theme}
                onChange={this.handleChange('tokenDescription')}
                value={this.state.tokenDescription}
                modules={SuggestionForm.modules}
                formats={SuggestionForm.formats}
                placeholder="Token Description"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Col sm={{ span: 8, offset: 2 }}>
              <Form.Control
                placeholder="Email"
                onChange={this.handleFormChange('email')}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={{ span: 4, offset: 4 }} className="form-submitt-button">
              <Button type="submit">Submit</Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={{ span: 8, offset: 2 }} className="form-submitt-button">
              {isFormSubmitted && (
                <p className="suggestion-form">
                  {' '}
                  Thank you for your suggestion, one of our staff will contact
                  you if more information is needed.
                </p>
              )}
            </Col>
          </Form.Group>
        </Form>
      </Container>
    );
  };
  render() {
    return (
      <div className="admin-portal-content">
        <h1 className="form-header">SUGGESTION FORM</h1>
        <Button
          variant="danger"
          className="suggestion-list"
          onClick={() => {
            this.backToSuggestionLIst();
          }}
        >
          <TiThList className="back-suggestion-list" />
          View Suggestions
        </Button>
        {this.renderSuggestionForm()}
      </div>
    );
  }
}

export default SuggestionForm;
