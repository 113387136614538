import * as CONSTANTS from '../constants/account';

export const getMyAccountRequest = () => ({
  type: CONSTANTS.MY_ACCOUNT_REQUEST,
});

export const getMyAccountSuccess = account => ({
  type: CONSTANTS.MY_ACCOUNT_SUCCESS,
  account,
});

export const getMyAccountError = error => ({
  type: CONSTANTS.MY_ACCOUNT_ERROR,
  error,
});

export const profileImageUploadRequest = file => ({
  type: CONSTANTS.PROFILE_IMAGE_UPLOAD_REQUEST,
  file,
});

export const profileImageUploadSuccess = profileImageUrl => ({
  type: CONSTANTS.PROFILE_IMAGE_UPLOAD_SUCCESS,
  profileImageUrl,
});

export const profileImageUploadError = error => ({
  type: CONSTANTS.PROFILE_IMAGE_UPLOAD_ERROR,
  error,
});

export const uploadAvatarImageRequest = file => ({
  type: CONSTANTS.UPLOAD_AVATAR_IMAGE_REQUEST,
  file
})

export const uploadAvatarImageSuccess = image => ({
  type: CONSTANTS.UPLOAD_AVATAR_IMAGE_SUCCESS,
  image
})

export const uploadAvatarImageError = error => ({
  type: CONSTANTS.UPLOAD_AVATAR_IMAGE_ERROR,
  error
})

export const uploadBannerImageRequest = file => ({
  type: CONSTANTS.UPLOAD_BANNER_IMAGE_REQUEST,
  file
})

export const uploadBannerImageSuccess = image => ({
  type: CONSTANTS.UPLOAD_BANNER_IMAGE_SUCCESS,
  image
})

export const uploadBannerImageError = error => ({
  type: CONSTANTS.UPLOAD_BANNER_IMAGE_ERROR,
  error
})

export const searchUserRequest = username => ({
  type: CONSTANTS.SEARCH_USER_REQUEST,
  username,
});

export const searchUserSuccess = user => ({
  type: CONSTANTS.SEARCH_USER_SUCCESS,
  user,
});

export const searchUserError = error => ({
  type: CONSTANTS.SEARCH_USER_ERROR,
  error,
});

export const resetSearchUser = () => ({
  type: CONSTANTS.RESET_SEARCH_USER,
});

export const changeAdminStatusRequest = (userId) => ({
  type: CONSTANTS.CHANGE_ADMIN_STATUS_REQUEST,
  userId,
});

export const changeAdminStatusError = error => ({
  type: CONSTANTS.CHANGE_ADMIN_STATUS_ERROR,
  error,
});

export const changeContributorStatusRequest = (userId, isContributor, username) => ({
  type: CONSTANTS.CHANGE_CONTRIBUTOR_STATUS_REQUEST,
  userId,
  isContributor,
  username,
});

export const changeContributorStatusError = error => ({
  type: CONSTANTS.CHANGE_CONTRIBUTOR_STATUS_ERROR,
  error,
});

export const editAccountRequest = (
    id,
    first,
    last,
    email,
    username,
    image,
) => ({
    type: CONSTANTS.EDIT_ACCOUNT_REQUEST,
    id,
    first,
    last,
    email,
    username,
    image,
})

export const editAccountSuccess = result => ({
  type: CONSTANTS.EDIT_ACCOUNT_SUCCESS,
  result,
});

export const editAccountError = error => ({
  type: CONSTANTS.EDIT_ACCOUNT_ERROR,
  error,
});

export const getUserListRequest = data => ({
    type: CONSTANTS.GET_USER_LIST_REQUEST,
    data,
})

export const getUserListSuccess = users => ({
    type: CONSTANTS.GET_USER_LIST_SUCCESS,
    users,
})


export const getUserListError = error => ({
    type: CONSTANTS.GET_USER_LIST_ERROR,
    error,
})

export const clearUserCache = () => ({
    type: CONSTANTS.CLEAR_USER_CACHE,
})

export const clearUserListCache = () => ({
  type: CONSTANTS.CLEAR_USER_LIST_CACHE,
})

export const contributorApplicationRequest = data => ({
  type: CONSTANTS.CONTRIBUTOR_APPLICATION_REQUEST,
  data,
})

export const contributorApplicationSuccess = data => ({
  type: CONSTANTS.CONTRIBUTOR_APPLICATION_SUCCESS,
  data,
})

export const contributorApplicationError = error => ({
  type: CONSTANTS.CONTRIBUTOR_APPLICATION_ERROR,
  error,
})

export const editContributorProfileRequest = userInfo => ({
  type: CONSTANTS.EDIT_CONTRIBUTOR_PROFILE_REQUEST,
  userInfo,
})

export const editContributorProfileSuccess = userInfo => ({
  type: CONSTANTS.EDIT_CONTRIBUTOR_PROFILE_SUCCESS,
  userInfo,
})

export const editContributorAccountRequest = userInfo => ({
  type: CONSTANTS.EDIT_CONTRIBUTOR_ACCOUNT_REQUEST,
  userInfo
})

export const editContributorAccountSuccess = userInfo => ({
  type: CONSTANTS.EDIT_CONTRIBUTOR_ACCOUNT_SUCCESS,
  userInfo
})

export const getPublishedReportsRequest = userId => ({
  type: CONSTANTS.GET_PUBLISHED_REPORTS_REQUEST,
  userId,
})

export const getPublishedReportsSuccess = reports => ({
  type: CONSTANTS.GET_PUBLISHED_REPORTS_SUCCESS,
  reports
})

export const getDraftsRequest = userId => ({
  type: CONSTANTS.GET_DRAFTS_REQUEST,
  userId,
})

export const getDraftsSuccess = drafts => ({
  type: CONSTANTS.GET_DRAFTS_SUCCESS,
  drafts
})

export const profileImageRequest = image => ({
  type: CONSTANTS.PROFILE_IMAGE_REQUEST,
  image,
})

export const contributorApplicationDocumentUploadRequest = (doc, fileType) => ({
  type: CONSTANTS.CONTRIBUTOR_APPLICATION_DOCUMENT_UPLOAD_REQUEST,
  doc,
  fileType,
})

export const contributorApplicationDocumentUploadSuccess = (data, fileType) => ({
  type: CONSTANTS.CONTRIBUTOR_APPLICATION_DOCUMENT_UPLOAD_SUCCESS,
  data,
  fileType,
})

export const checkUserStatus = () => ({
  type: CONSTANTS.CHECK_USER_STATUS,
})

export const setUserAuthenticatedStatusTrue = () => ({
  type: CONSTANTS.SET_USER_AUTHENTICATED_STATUS_TRUE,
})

export const setUserAuthenticatedStatusFalse = () => ({
  type: CONSTANTS.SET_USER_AUTHENTICATED_STATUS_FALSE,
})

export const wipeLocalStorage = () => ({
  type: CONSTANTS.WIPE_LOCAL_STORAGE,
})

export const sendInvitesRequest = array => ({
  type: CONSTANTS.SEND_INVITES_REQUEST,
  array,
})

export const sendInvitesSuccess = () => ({
  type: CONSTANTS.SEND_INVITES_SUCCESS,
})

export const landingPageUserSearchRequest = query => ({
  type: CONSTANTS.LANDING_PAGE_USER_SEARCH_REQUEST,
  query
})

export const landingPageUserSearchSuccess = data => ({
  type: CONSTANTS.LANDING_PAGE_USER_SEARCH_SUCCESS,
  data
})

export const landingPageUserSearchError = error => ({
  type: CONSTANTS.LANDING_PAGE_USER_SEARCH_ERROR,
  error
})

export const adminSearchUsersRequest = params => ({
  type: CONSTANTS.ADMIN_SEARCH_USERS_REQUEST,
  params
})

export const adminSearchUsersSuccess = data => ({
  type: CONSTANTS.ADMIN_SEARCH_USERS_SUCCESS,
  data
})

export const adminSearchUsersError = error => ({
  type: CONSTANTS.ADMIN_SEARCH_USERS_ERROR,
  error
})

export const adminToggleContributorRequest = id => ({
  type: CONSTANTS.ADMIN_TOGGLE_CONTRIBUTOR_REQUEST,
  id
})

export const adminToggleContributorSuccess = data => ({
  type: CONSTANTS.ADMIN_TOGGLE_CONTRIBUTOR_SUCCESS,
  data
})

export const adminToggleContributorError = error => ({
  type: CONSTANTS.ADMIN_TOGGLE_CONTRIBUTOR_ERROR,
  error
})
