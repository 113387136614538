import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import {
  Form,
  Row,
  Col,
  Container,
  FormControl,
  NavLink,
  Card,
  Image,
} from 'react-bootstrap'
import { history } from '../../configureStore';
import { Link } from "react-router-dom";
import "./Register.css";
import { toast } from 'react-toastify'
import { initGA, logPageView, trackEvent } from "../../utils/analytics";
import ReCAPTCHA from "react-google-recaptcha";
import logo from '../../images/HomePage/FI_LOGO.png'

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      password: "",
      email: "",
      firstName: "",
      lastName: "",
      countryOfOrigin: "",
      notARobot: false,
    };
  }

  componentDidMount() {
    initGA();
    logPageView();
  }


  componentWillUnmount() {
    //this.props.resetForgotPassword();
  }

  onChange = value => {
    // console.log(value)
    if (value) {
      this.setState({notARobot: true})
    } else {
      this.setState({notARobot: false})
    }
  }

  handleFormChange = fieldName => e => {
    this.setState({
      [fieldName]: e.target.value
    });
  };

  handleUsernameContainsDisallowedCharacters = username => {
    const disallowedCharacters = "!@#$%^&*(),.?:{}|<>"
    let validation = false
    for (let i = 0; i < username.length; i++) {
      if (disallowedCharacters.indexOf(username.charAt(i)) != -1) {
        validation = true
      }
    }
    return validation
  }

  handleSubmit = e => {
    e.preventDefault();

    const { email, password, userName, firstName, lastName } = this.state;
    const { signupRequest } = this.props;

    const usernameContainsSpecialCharacters = this.handleUsernameContainsDisallowedCharacters(userName)
    if (usernameContainsSpecialCharacters) {
      toast.info('Your username cannot contain special characters. Please remove them and try again.')
    } else {
      signupRequest(email, password, password, userName, firstName, lastName);
      trackEvent("User", "Registration", userName)
    }
  };

  handleCancel = e => {
    this.setState({
      userName: "",
      password: "",
      email: "",
      firstName: "",
      lastName: "",
      countryOfOrigin: ""
    });
  };

  handleOnChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    })
  }

  render() {
    const { userName, password, email, firstName, lastName } = this.state
    const registerButton = this.state.notARobot
      ? <Button onClick={this.handleSubmit} className='login-button' variant="primary">Register</Button>
      : <Button disabled className='login-button'>Register</Button>

    const disabled = (email && password) ? false : true

    return (
      <Container fluid className='login-container'>
        <Row noGutters className='login-row'>
          <Col sm={8} md={6} lg={4} xl={4} className='login-col'>
            <Card className='login-box'>
              <Row noGutters>
                <Col className='login-content-col'>

                  <Row noGutters className='login-header'>
                    <Link to={'/'}>
                      <Image fluid src={logo} className='login-logo' />
                    </Link>
                  </Row>

                  <Row noGutters className='login-box-padding login-formcontrol-row'>
                    <Col className='login-box-col'>
                      <Row noGutters className='login-welcome'>
                        <Col>
                          <h1>Welcome</h1>
                          <p>Register your account</p>
                        </Col>
                      </Row>

                      <Row noGutters className='login-formcontrol-container'>
                        <div className='login-formcontrol-box'>
                          <div className='login-formcontrol-text'>
                            Username
                          </div>
                          <FormControl
                            size='sm'
                            name='userName'
                            value={userName}
                            onChange={this.handleOnChange}
                            className='login-formcontrol' />
                        </div>
                      </Row>

                      <Row noGutters className='login-formcontrol-container'>
                        <div className='login-formcontrol-box'>
                          <div className='login-formcontrol-text'>
                            Password
                          </div>
                          <FormControl
                            size='sm'
                            name='password'
                            type='password'
                            value={password}
                            onChange={this.handleOnChange}
                            className='login-formcontrol' />
                        </div>
                      </Row>

                      <Row noGutters className='login-formcontrol-container'>
                        <div className='login-formcontrol-box'>
                          <div className='login-formcontrol-text'>
                            Email
                          </div>
                          <FormControl
                            size='sm'
                            name='email'
                            value={email}
                            onChange={this.handleOnChange}
                            className='login-formcontrol' />
                        </div>
                      </Row>

                      <Row noGutters className='login-formcontrol-container'>
                        <ReCAPTCHA
                          className='recaptcha'
                          sitekey={this.props.siteKey}
                          onChange={this.onChange}
                        />
                      </Row>

                      <Row noGutters className='login-button-row'>
                        <Button
                          size='sm'
                          disabled={disabled}
                          onClick={this.handleSubmit}
                          className='teal-button'>
                          REGISTER
                        </Button>
                      </Row>

                      <div className='login-navlinks'>
                        <Link to='/forgot'>Forgot Password</Link>
                        <Link to='/login'>Already have an account? Log in</Link>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Register;
