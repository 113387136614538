import * as CONSTANTS from "../constants/collections";

export const resetCollectionInitialState = () => ({
  type: CONSTANTS.RESET_COLLECTION_INITIAL_STATE
})

export const getDiscoverCollectionsRequest = () => ({
  type: CONSTANTS.GET_DISCOVER_COLLECTIONS_REQUEST,
})

export const getDiscoverCollectionsSuccess = data => ({
  type: CONSTANTS.GET_DISCOVER_COLLECTIONS_SUCCESS,
  data
})

export const getDiscoverCollectionsError = error => ({
  type: CONSTANTS.GET_DISCOVER_COLLECTIONS_ERROR,
  error
})

export const getPublicUserCollectionsRequest = () => ({
  type: CONSTANTS.GET_PUBLIC_USER_COLLECTIONS_REQUEST,
})

export const getPublicUserCollectionsSuccess = data => ({
  type: CONSTANTS.GET_PUBLIC_USER_COLLECTIONS_SUCCESS,
  data
})

export const getPublicUserCollectionsError = error => ({
  type: CONSTANTS.GET_PUBLIC_USER_COLLECTIONS_ERROR,
  error
})

export const getPrivateUserCollectionsRequest = () => ({
  type: CONSTANTS.GET_PRIVATE_USER_COLLECTIONS_REQUEST,
})

export const getPrivateUserCollectionsSuccess = data => ({
  type: CONSTANTS.GET_PRIVATE_USER_COLLECTIONS_SUCCESS,
  data
})

export const getPrivateUserCollectionsError = error => ({
  type: CONSTANTS.GET_PRIVATE_USER_COLLECTIONS_ERROR,
  error
})

export const getSpecificCollectionRequest = data => ({
  type: CONSTANTS.GET_SPECIFIC_COLLECTION_REQUEST,
  data
})

export const getSpecificCollectionSuccess = (collectionInfo, reports) => ({
  type: CONSTANTS.GET_SPECIFIC_COLLECTION_SUCCESS,
  collectionInfo,
  reports,
})

export const getSpecificCollectionError = error => ({
  type: CONSTANTS.GET_SPECIFIC_COLLECTION_ERROR,
  error
})

export const createNewPrivateCollectionRequest = () => ({
  type: CONSTANTS.CREATE_NEW_PRIVATE_COLLECTION_REQUEST
})

export const createNewPrivateCollectionSuccess = collectionId => ({
  type: CONSTANTS.CREATE_NEW_PRIVATE_COLLECTION_SUCCESS,
  collectionId
})

export const createNewPrivateCollectionError = error => ({
  type: CONSTANTS.CREATE_NEW_PRIVATE_COLLECTION_ERROR,
  error,
})

export const createNewPrivateCollectionFinish = () => ({
  type: CONSTANTS.CREATE_NEW_PRIVATE_COLLECTION_FINISH
})

export const resetCreateNewPrivateCollection = () => ({
  type: CONSTANTS.RESET_CREATE_NEW_PRIVATE_COLLECTION
})

export const searchCollectionsRequest = (query, reportId) => ({
  type: CONSTANTS.SEARCH_COLLECTIONS_REQUEST,
  query,
  reportId,
})

export const searchCollectionsSuccess = results => ({
  type: CONSTANTS.SEARCH_COLLECTIONS_SUCCESS,
  results,
})

export const searchCollectionsError = error => ({
  type: CONSTANTS.SEARCH_COLLECTIONS_ERROR,
  error,
})

export const addArticleToCollectionRequest = (collectionId, reportId) => ({
  type: CONSTANTS.ADD_ARTICLE_TO_COLLECTION_REQUEST,
  collectionId,
  reportId,
})

export const addArticleToCollectionSuccess = () => ({
  type: CONSTANTS.ADD_ARTICLE_TO_COLLECTION_SUCCESS,
})

export const addArticleToCollectionError = error => ({
  type: CONSTANTS.ADD_ARTICLE_TO_COLLECTION_ERROR,
  error
})

export const editCollectionRequest = (collectionId, name, description, image, isPublic) => ({
  type: CONSTANTS.EDIT_COLLECTION_REQUEST,
  collectionId,
  name,
  description,
  image,
  isPublic,
})

export const editCollectionSuccess = data => ({
  type: CONSTANTS.EDIT_COLLECTION_SUCCESS,
  data
})

export const editCollectionError = error => ({
  type: CONSTANTS.EDIT_COLLECTION_ERROR,
  error
})

export const editCollectionImageRequest = image => ({
  type: CONSTANTS.EDIT_COLLECTION_IMAGE_REQUEST,
  image,
})

export const editCollectionImageSuccess = image => ({
  type: CONSTANTS.EDIT_COLLECTION_IMAGE_SUCCESS,
  image,
})

export const editCollectionImageError = error => ({
  type: CONSTANTS.EDIT_COLLECTION_IMAGE_ERROR,
  error,
})

export const handleClearCollectionImage = () => ({
  type: CONSTANTS.HANDLE_CLEAR_COLLECTION_IMAGE
})

export const removeReportFromCollectionRequest = (collectionId, reportId) => ({
  type: CONSTANTS.REMOVE_REPORT_FROM_COLLECTION_REQUEST,
  collectionId,
  reportId
})

export const removeReportFromCollectionSuccess = () => ({
  type: CONSTANTS.REMOVE_REPORT_FROM_COLLECTION_SUCCESS,
})

export const removeReportFromCollectionError = error => ({
  type: CONSTANTS.REMOVE_REPORT_FROM_COLLECTION_ERROR,
  error
})

export const togglePublicPrivateCollectionRequest = (collectionId, reportId) => ({
  type: CONSTANTS.TOGGLE_PUBLIC_PRIVATE_COLLECTION_REQUEST,
  collectionId,
  reportId,
})

export const togglePublicPrivateCollectionSuccess = () => ({
  type: CONSTANTS.TOGGLE_PUBLIC_PRIVATE_COLLECTION_SUCCESS,
})

export const togglePublicPrivateCollectionError = () => ({
  type: CONSTANTS.TOGGLE_PUBLIC_PRIVATE_COLLECTION_ERROR,
})

export const deleteCollectionRequest = collectionId => ({
  type: CONSTANTS.DELETE_COLLECTION_REQUEST,
  collectionId
})

export const deleteCollectionSuccess = () => ({
  type: CONSTANTS.DELETE_COLLECTION_SUCCESS,
})

export const deleteCollectionError = error => ({
  type: CONSTANTS.DELETE_COLLECTION_ERROR,
  error
})
