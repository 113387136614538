import { connect } from 'react-redux'
import CreateReport from './CreateReport.js'
import Preview from '../../Research/Preview'
import {
  createDraftRequest,
  createDraftClear,
  saveDraftRequest,
  getDraftRequest,
  articleImageUploadRequest,
  uploadDocumentRequest,
  getTopicsListRequest,
  publishArticleRequest,
} from '../../../redux/actions/researches'

const mapStateToProps = state => ({
  currentDraft: state.research.currentDraft,
  allDrafts: state.research.allDrafts,
  savingDraft: state.research.saving,
  topics: state.research.topics,
  account: state.login.accountData,
})

const mapDispatchToProps = {
  createDraftRequest,
  createDraftClear,
  saveDraftRequest,
  getDraftRequest,
  articleImageUploadRequest,
  uploadDocumentRequest,
  getTopicsListRequest,
  publishArticleRequest,
}

export {
  Preview
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateReport)
