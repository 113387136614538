import React, { Component } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Image,
  Nav,
  Form,
  FormControl,
  InputGroup,
  Jumbotron,
  Spinner
} from "react-bootstrap";
import ReactLoading from "react-loading";
import { history } from "../../../configureStore";
import "./Contributor.css";
import FullReportPage from "../FullReportPage";
import Paginations from "../../Pagination";
import { MdArrowBack } from 'react-icons/md';

class Contributors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contributor: null,
      showContributor: false,
      seeReport: false,
      viewFullReport: false,
      viewReportId: "",
      query: "",
      newsFeedPageSize: 10,
      newsFeedPageOffset: 0
    };
  }

  componentDidMount() {
    const { newsFeedPageOffset } = this.state;
    this.props.getContributorsReqeust(newsFeedPageOffset);
  }

  formatDate = dateInput => {
    let date = new Date(dateInput);
    const month = date.toLocaleString("en-us", { month: "long" });
    const day = date.getDate();
    return month + " " + day;
  };

  handleTruncateTitle = (title, length) => {
    if (title.length > length) {
      return title.slice(0, length).concat("...");
    } else {
      return title;
    }
  }; // end of handleTruncateTitle(title, length)

  handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  onChangePaginationHandler = data => {
    const { newsFeedPageSize } = this.state;
    const offset = data.selected * newsFeedPageSize;
    this.props.getContributorsReqeust(offset);
    this.handleScrollToTop();
  };

  reviewContributor = item => {
    history.push({
      pathname: "/admin/contributorform",
      search: `?userid=${item.userId}`
    });
  };

  renderLoading = () => {
    return (
      <React.Fragment>
        <div className="loading-bar">
          <ReactLoading
            type={"spin"}
            color={"#5790c8"}
            height={100}
            width={100}
          />
        </div>
      </React.Fragment>
    );
  };

  renderToken = token => {
    return <p>{token}</p>;
  };

  onClickFeatureContributor = (e, userId) => {
    this.props.featureContributorReqeust(userId);
  };

  onClickBackToContributorList = (e, userId) => {
    this.setState({ seeReport: false });
  };

  onClickSeeReport = (e, userId) => {
    this.props.getContributorsReportRequest(userId);
    this.setState({ seeReport: true });
  };
  renderContributor = hit => {
    const { displayName, username, userId } = hit;
    return (
      <React.Fragment>
        <Row key={userId} className="research-list-research-row">
          <Col className="contributor-list-status" sm={{ span: 3, offset: 0 }}>
            {displayName}
          </Col>
          <Col className="contributor-list-status" sm={{ span: 3, offset: 0 }}>
            {username}
          </Col>
          <Col className="contributor-list-status" sm={{ span: 2, offset: 0 }}>
            {userId}
          </Col>
          <Col className="contributor-list-status" sm={{ span: 1, offset: 1 }}>
            <button onClick={e => this.onClickFeatureContributor(e, userId)}>
              Feature
            </button>
          </Col>
          <Col className="contributor-list-status" sm={{ span: 2, offset: 0 }}>
            <button onClick={e => this.onClickSeeReport(e, userId)}>
              See reports
            </button>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  renderContributors = () => {
    const { newsFeedPageSize } = this.state;
    const { contributors } = this.props;
    if (contributors) {
      const { hits, total } = contributors;
      let perPage = newsFeedPageSize;
      return (
        <React.Fragment>

          <Row className='user-list-items-row'>
            <Col className="contributor-list-items-col">
              {hits.map(hit => this.renderContributor(hit))}
            </Col>
          </Row>
          <Row noGutters className='user-lookup-pagination-row'>
            <Paginations
              handlePageClick={data => this.onChangePaginationHandler(data, "news_feed")}
              total={total}
              perPage={perPage} />
          </Row>
        </React.Fragment>
      );
    } else {
      return <React.Fragment>no contributors</React.Fragment>;
    }
  };

  renderReportList = () => {
    const {
      reports: { list, loading }
    } = this.props;

    if (loading) {
      this.renderLoading();
    }

    return (
      <Col className='user-list-users-container'>
        <Row noGutters>
          <div
              className='back-to-user-list-button'
              onClick={e => this.onClickBackToContributorList(e)}>
              <MdArrowBack />
              Back to the Contributor List
          </div>
        </Row>
        <Row className="report-list-items-row">
          <Col className="report-list-items-col">
            <Row>
              <Col>
                {list &&
                  Array.isArray(list.hits) &&
                  list.hits.map(hit => this.renderReport(hit))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  };

  onClickViewReport = (e, reportId) => {
    this.setState({
      viewReportId: reportId,
      viewFullReport: true
    });
  };

  renderReport = report => {
    const { title, category, id, datePublished } = report._source;

    return (
      <Row key={id} className="report-list-research-row">
        <Col>
          <div className="report-list-status">
            {this.handleTruncateTitle(title, 15)}
          </div>
        </Col>
        <Col>
          <div className="report-list-status">{category}</div>
        </Col>
        <Col>
          <div className="report-list-status">{id}</div>
        </Col>
        <Col>
          <div className="report-list-status">
            {this.formatDate(datePublished)}
          </div>
        </Col>
        <Col>
          <div className="report-list-status">
            <button onClick={e => this.onClickViewReport(e, id)}>
              <div className="view-full-report-button">Full report</div>
            </button>
          </div>
        </Col>
      </Row>
    );
  };

  onClickBackToReportList = e => {
    this.setState({
      viewReportId: "",
      viewFullReport: false
    });
  };

  renderFullReport() {
    return (
      <Col className='user-list-users-container'>
        <Row noGutters>
          <button onClick={e => this.onClickBackToReportList(e)}>
            back to report list
          </button>
        </Row>
        <FullReportPage reportId={this.state.viewReportId} />
      </Col>
    );
  }

  renderContributorsReport() {
    return (
      <React.Fragment>
        {this.state.viewFullReport
          ? this.renderFullReport()
          : this.renderReportList()}
      </React.Fragment>
    );
  }

  handleFormChange(e) {
    this.setState({
      query: e.target.value
    });
    this.props.contributorSearchRequest(e.target.value);
  }

  handleSearchContributor(query) {
    this.props.contributorSearchRequest(query);
  }

  renderReportSearch = () => {
    const { query } = this.state

    return (
      <Row noGutters className='user-lookup-form-row'>
        <label className='user-lookup-label'>
          Contributor Search:
        </label>
        <FormControl
          className='user-lookup-formcontrol'
          placeholder="Search for a report..."
          name="query"
          value={query}
          onChange={e => this.handleFormChange(e)}
          onKeyPress={e => this.handleSearchContributor(e.target.value)} />
      </Row>
    )
  }

  render() {
    const { contributors } = this.props;
    const { seeReport } = this.state;
    return (
      <Container className='user-lookup-container'>
        <Row className='user-list-top-row'>
          <Col>
            <h1>Contributor List</h1>
            {this.renderReportSearch()}
          </Col>
        </Row>
        <Row className='user-list-section'>
          {seeReport == false
            ? contributors && Object.keys(contributors).length
              ? this.renderContributors()
              : this.renderLoading()
            : this.renderContributorsReport()}
        </Row>
      </Container>
    );
  }
}

export default Contributors;
