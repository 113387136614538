import React, { PureComponent } from "react";
import ImageUploader from 'react-images-upload';
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-date-picker";
import ReactTooltip from 'react-tooltip'
import MetaTags from 'react-meta-tags'
import {
  MdPublic,
  MdLock,
  MdClose,
  MdAdd,
  MdEdit,
  MdKeyboardArrowRight,
  MdThumbUp,
  MdThumbDown,
  MdBookmark,
  MdDelete,
} from "react-icons/md";
import { FaBars } from "react-icons/fa";
import {
  Row,
  Col,
  Button,
  Form,
  Image,
  Card,
  Container,
  ListGroup,
  Spinner,
  CardColumns,
  Tooltip,
  OverlayTrigger,
  FormControl,
  Modal,
  Popover,
} from "react-bootstrap";
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'
import LogoImg from '../../images/logoImage.js'
import { NavLink } from 'react-router-dom'
import { history } from "../../configureStore";
import defaultNewsImage from '../../images/default-news-image-1.png'

class Collections extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      sidenavOpen: true,
      currentSection: 'Discover',
      privateCollectionsOpen: false,
      publicCollectionsOpen: false,
      discoverCollections: {
        collections: {},
        loaded: false,
        loading: false,
        error: ''
      },
      publicUserCollections: {
        collections: {},
        loaded: false,
        loading: false,
        error: ''
      },
      privateUserCollections: {
        collections: {},
        loaded: false,
        loading: false,
        error: ''
      },
      specificCollection: {
        collectionInfo: {},
        reports: {
          total: '',
          data: [],
        },
        loaded: false,
        loading: false,
        error: ''
      },
      editSpecificCollection: false,
      editCollectionParams: {
        image: {
          image: '',
          loading: false,
          loaded: false,
          error: '',
        },
        name: '',
        description: '',
        isPublic: ''
      },
      collectionImage: {
        image: '',
        loading: false,
        loaded: false,
        error: '',
      },
      collectionBeingEdited: {
        collectionId: '',
        loading: false,
        complete: false,
        error: ''
      },
      editModal: false,
      removeReport: {
        loading: false,
        finished: false,
        error: '',
      },
      collectionPrivacyBeingToggled: {
        loading: false,
        error: '',
      },
      createNewCollectionRequest: {
        loading: false,
        newCollectionId: '',
        complete: false,
        error: ''
      },
      deleteCollection: {
        loading: false,
        complete: false,
        error: '',
      },
      urlParams: {
        active: '',
        currentCategory: '',
      },
      doYouWantToDeleteThisCollectionModal: false,
      collectionToDelete: '',
    }
  }

  componentDidMount() {
    this.props.resetCollectionInitialState()
    this.props.getDiscoverCollectionsRequest()
    this.props.getPublicUserCollectionsRequest()
    this.props.getPrivateUserCollectionsRequest()
    this.handleClearUploadedImage()

    const { collectionId } = this.handleGetCollectionIdFromURL()
    if (collectionId) {
      this.handleParseUrlAndSelectCategory(collectionId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        discoverCollections: nextProps.discoverCollections,
        publicUserCollections: nextProps.publicUserCollections,
        privateUserCollections: nextProps.privateUserCollections,
        specificCollection: nextProps.specificCollection,
        collectionImage: nextProps.collectionImage,
        editCollectionParams: {
          ...this.state.editCollectionParams,
          image: nextProps.collectionImage,
        },
        collectionBeingEdited: nextProps.collectionBeingEdited,
        removeReport: nextProps.removeReport,
        collectionPrivacyBeingToggled: nextProps.collectionPrivacyBeingToggled,
        createNewCollectionRequest: nextProps.createNewCollectionRequest,
        deleteCollection: nextProps.deleteCollection,
      })

      if (nextProps.collectionBeingEdited !== this.props.collectionBeingEdited) {
        this.handlePessimisticallyChangeCollectionData(nextProps.collectionBeingEdited)
      }
      if (nextProps.createNewCollectionRequest.complete) {
        this.handlePessimisticallyAddNewCollection(nextProps.createNewCollectionRequest)
      }
    }
  }

  componentWillUpdate(nextState) {
    if (this.props.history.location !== this.props.location) {
      /* make it so this happens every time the url changes to reflect back and forth  */
      // const { collectionId } = this.handleGetCollectionIdFromURL()
    }
  }

  handleCollectionNameContainsDisallowedCharacters = username => {
    const disallowedCharacters = "!@#$%^&*(),.?:{}|<>"
    let validation = false
    for (let i = 0; i < username.length; i++) {
      if (disallowedCharacters.indexOf(username.charAt(i)) != -1) {
        validation = true
      }
    }
    return validation
  }

  handleGetCollectionIdFromURL = () => {
    const params = window.location.href
    const splitAtCollections = params.split('/collections')
    let collectionId
    if (splitAtCollections[1].length > 0) {
      const splitTitleAndId = splitAtCollections[1].split('-');
      collectionId = splitTitleAndId[1];
    }
    return { collectionId: collectionId }
  }

  handleParseUrlAndSelectCategory = id => {
    this.props.getSpecificCollectionRequest({id})
    if (id) {
      this.setState({ currentSection: id })
    } else {
      this.setState({ currentSection: 'Discover' })
    }
    this.handleTurnOffEdit()
    this.handleClearUploadedImage()
  }

  handleInPageNavigation = (collection, collectionId) => {
    const collectionName = collection ? collection.replace(/\s+/g, '-').toLowerCase() + '-' : ''
    const collectionPart = collectionName + collectionId
    let url
    if (collection === 'Discover') {
      url = '/research/collections'
    } else if (collection === 'Upvotes' || collection === 'Downvotes' || collection === 'Bookmarks') {
      url = `/research/collections/${collectionPart}`
    } else {
      url = `/research/collections/${collectionPart}`
    }
    this.props.history.push(url)
    this.handleParseUrlAndSelectCategory(collectionId)
  }

  handlePessimisticallyChangeCollectionData = editData => {
    const { collectionId } = editData
    const { publicUserCollections, privateUserCollections, editCollectionParams, specificCollection } = this.state
    const publicCollectionData = publicUserCollections.collections.data
    const privateCollectionData = privateUserCollections.collections.data
    const specificCollectionData = specificCollection.collectionInfo.data

    if (specificCollectionData.isPublic) {
      for (let i = 0; i < publicCollectionData.length; i++) {
        if (publicCollectionData[i].id === collectionId) {
          publicCollectionData[i].name = editCollectionParams.name
          publicCollectionData[i].isPublic = editCollectionParams.isPublic
        }
      }
    } else {
      for (let i = 0; i < privateCollectionData.length; i++) {
        if (privateCollectionData[i].id === collectionId) {
          privateCollectionData[i].name = editCollectionParams.name
          privateCollectionData[i].isPublic = editCollectionParams.isPublic
        }
      }
    }
    specificCollectionData.name = editCollectionParams.name
    specificCollectionData.description = editCollectionParams.description
    specificCollectionData.image = editCollectionParams.image.image || specificCollectionData.image
    specificCollectionData.isPublic = editCollectionParams.isPublic
  }

  handlePessimisticallyRemoveReportFromCollection = reportId => {
    const { specificCollection } = this.state
    const specificCollectionReports = specificCollection.reports.data

    for (let i = 0; i < specificCollectionReports.length; i++) {
      if (specificCollectionReports[i].report.id === reportId) {
        specificCollectionReports.splice(i, 1)
      }
    }

    this.setState({
      specificCollection: {
        ...this.state.specificCollection,
        reports: {
          ...this.state.specificCollection.reports,
          data: specificCollectionReports,
        }
      }
    })
  }

  handlePessimisticallyTransferCollectionToDifferentFolder = collectionId => {
    const { specificCollection, publicUserCollections, privateUserCollections, discoverCollections } = this.state
    let specificCollectionData = specificCollection.collectionInfo.data
    let publicUserCollectionsData = publicUserCollections.collections
    let privateUserCollectionsData = privateUserCollections.collections
    let discoverCollectionsData = discoverCollections.collections

    if (specificCollectionData.isPublic) {
      // if the collection isPublic, make it private
      specificCollectionData.isPublic = false
      for (let i = 0; i < publicUserCollectionsData.data.length; i++) {
        if (publicUserCollectionsData.data[i].id === collectionId) {
          privateUserCollectionsData.data.unshift(publicUserCollectionsData.data[i])
          publicUserCollectionsData.data.splice(i, 1)
          publicUserCollections.collections.total -= 1
          privateUserCollections.collections.total += 1
          i--
        }
      }
      for (let i = 0; i < discoverCollectionsData.data.length; i++) {
        if (discoverCollectionsData.data[i].collection.id === collectionId) {
          discoverCollectionsData.data.splice(i, 1)
          discoverCollections.collections.total -= 1
          i--
        }
      }
    } else {
      //make it public
      specificCollectionData.isPublic = true
      for (let i = 0; i < privateUserCollectionsData.data.length; i++) {
        if (privateUserCollectionsData.data[i].id === collectionId) {
          publicUserCollectionsData.data.unshift(privateUserCollectionsData.data[i])
          privateUserCollectionsData.data.splice(i, 1)
          privateUserCollections.collections.total -= 1
          publicUserCollections.collections.total += 1
          i--
        }
      }
    }

    this.setState({
      specificCollection: {
        ...this.state.specificCollection,
        collectionInfo: {
          ...this.state.specificCollectionInfo,
          data: specificCollectionData
        }
      },
      publicUserCollections: {
        ...this.state.publicUserCollections,
        collections: publicUserCollectionsData
      },
      privateUserCollections: {
        ...this.state.privateUserCollections,
        collections: privateUserCollectionsData
      },
    })
  }

  handlePessimisticallyAddNewCollection = createNewCollectionRequest => {
    const { privateUserCollections } = this.state
    const privateUserCollectionsData = privateUserCollections.collections.data
    const newCollectionId = createNewCollectionRequest.newCollectionId
    const newCollectionData = {
      id: newCollectionId,
      name: '',
      description: '',
      image: '',
      isPublic: '',
      canEdit: true,
    }
    privateUserCollectionsData.push(newCollectionData)
  }

  handlePessimisticallyDeleteCollection = collectionId => {
    const { publicUserCollections, privateUserCollections } = this.state
    const publicUserCollectionsData = publicUserCollections.collections.data
    const privateUserCollectionsData = privateUserCollections.collections.data

    for (let i = 0; i < publicUserCollectionsData.length; i++) {
      if (publicUserCollectionsData[i].id === collectionId) {
        publicUserCollectionsData.splice(i, 1)
        i--
      }
    }

    for (let i = 0; i < privateUserCollectionsData.length; i++) {
      if (privateUserCollectionsData[i].id === collectionId) {
        privateUserCollectionsData.splice(i, 1)
        i--
      }
    }

    this.setState({
      publicUserCollections: {
        ...this.state.publicUserCollections,
        collections: {
          ...this.state.publicUserCollections.collections,
          data: publicUserCollectionsData
        }
      },
      privateUserCollections: {
        ...this.state.privateUserCollections,
        collections: {
          ...this.state.privateUserCollections.collections,
          data: privateUserCollectionsData
        }
      }
    })
  }

  handleEditFormChange = e => {
    const { name, value } = e.target
    this.setState({
      editCollectionParams: {
        ...this.state.editCollectionParams,
        [name]:  value
      }
    })
  } // end of handleEditFormChange(e)

  handleNavigationTitle = (title, id) => {
    if (title && id) {
      return title.toLowerCase().replace(/%/g, '').replace(/\s+/g, '-').replace(/\?/g,'') + '-' + id
    }
  } // end of handleNavigationTitle(title)

  handleToggleSidenav = () => {
    this.setState({ sidenavOpen: !this.state.sidenavOpen })
  }

  handleTogglePrivate = () => {
    this.setState({ privateCollectionsOpen: !this.state.privateCollectionsOpen })
  }

  handleTogglePublic = () => {
    this.setState({ publicCollectionsOpen: !this.state.publicCollectionsOpen })
  }

  handleMapOverCollections = collections => {
    if (collections.total > 0) {
      return collections.data.map(collection => this.renderSidebarCollectionItem(collection))
    } else {
      return this.renderNoCollections()
    }
  }

  handleSelectSection = section => {
    this.setState({ currentSection: section })
    this.handleInPageNavigation(section)
  }

  handleSelectCollection = collection => {
    const { id, name } = collection
    this.setState({ currentSection: id })
    this.handleTurnOffEdit()
    this.handleClearUploadedImage()
    this.handleInPageNavigation(name, id)
    // this.props.getSpecificCollectionRequest(collection)
  }

  handleCreateNewPrivateCollection = e => {
    e.stopPropagation()
    this.props.createNewPrivateCollectionRequest()
  }

  handleClickEditButton = () => {
    this.setState({editSpecificCollection: !this.state.editSpecificCollection})
    this.handleLoadCollectionDataIntoEditCollectionParams()
  }

  handleLoadCollectionDataIntoEditCollectionParams = () => {
    const { specificCollection } = this.state
    const collectionData = specificCollection.loaded ? specificCollection.collectionInfo.data : {}
    this.setState({
      editCollectionParams: {
        name: collectionData.name,
        description: collectionData.description,
        image: {
          ...this.state.editCollectionParams.image,
          image: collectionData.image,
        },
        isPublic: collectionData.isPublic,
      }
    })
  }

  handleTurnOffEdit = () => {
    this.setState({
      editSpecificCollection: false,
      editCollectionParams: {
        image: {
          image: '',
          loading: false,
          loaded: false,
          error: '',
        },
        name: '',
        description: '',
        isPublic: ''
      },
    })
  }

  handleUploadCollectionImage = image => {
    this.props.editCollectionImageRequest(image[0])
  }

  handleClearUploadedImage = () => {
    this.props.handleClearCollectionImage()
  }

  handleSubmitCollectionEdit = () => {
    const { editCollectionParams, specificCollection } = this.state
    const collectionId = specificCollection.collectionInfo.data.id
    const name = editCollectionParams.name
    const description = editCollectionParams.description
    const image = editCollectionParams.image.image

    const collectionNameContainsSpecialCharacters = this.handleCollectionNameContainsDisallowedCharacters(name)
    if (collectionNameContainsSpecialCharacters) {
      toast.info("Your collection's name cannot contain special characters. Please remove them and try again.")
    } else {
      this.props.editCollectionRequest(collectionId, name, description, image)
      this.handleTurnOffEdit()
    }
  }

  handleTogglePublicPrivateCollection = (collectionId) => {
    this.props.togglePublicPrivateCollectionRequest(collectionId)
    this.handlePessimisticallyTransferCollectionToDifferentFolder(collectionId)
  }

  handleDeleteCollectionRequest = collectionId => {
    const { specificCollection } = this.state
    if (specificCollection.collectionInfo.data.id === collectionId) {
      this.handleSelectSection('Discover')
    }
    this.handleCloseDeleteCollectionsModal()
    this.handlePessimisticallyDeleteCollection(collectionId)
    this.props.deleteCollectionRequest(collectionId)
  }

  handleClickDeleteButton = () => {
    const { specificCollection } = this.state
    const specificCollectionData = specificCollection.collectionInfo.data
    this.props.deleteCollectionRequest(specificCollectionData.id)
    // FINISH WRITING THE HANDLEPESSIMISTICALLYDELETECOLLECTION
  }

  handleClickDeleteButtonSidebar = (e, collectionId) => {
    e.stopPropagation()
    this.setState({
      doYouWantToDeleteThisCollectionModal: true,
      collectionToDelete: collectionId,
    })
  }

  handleCloseDeleteCollectionsModal = () => {
    this.setState({
      doYouWantToDeleteThisCollectionModal: false,
      collectionToDelete: '',
    })
  }

  handleRemoveReportFromCollection = (collectionId, reportId) => {
    this.setState({
      specificCollection: {
        ...this.state.specificCollection,
        loading: true,
        loaded: false,
      }
    })
    this.props.removeReportFromCollectionRequest(collectionId, reportId)
    setTimeout(() => this.handlePessimisticallyRemoveReportFromCollection(reportId), 2000)
  }

  renderSidebar = () => {
    const {
      sidenavOpen,
      currentSection,
      privateCollectionsOpen,
      publicCollectionsOpen,
      privateUserCollections,
      publicUserCollections,
    } = this.state

    const columnSizeMd = sidenavOpen ? 2 : 'auto'

    const closeButton = (
      <MdClose
        className='collections-sidebar-button'
        onClick={this.handleToggleSidenav}
      />
    )

    const openButton = (
      <FaBars
        className='collections-sidebar-button'
        onClick={this.handleToggleSidenav}
      />
    )

    const addCollectionIcon = (
      <OverlayTrigger
        placement='auto'
        overlay={
          <Tooltip className='collections-tooltip'>
            Create a new private collection
          </Tooltip>
        }
      >
        <MdAdd
          className='collections-sidebar-add'
          onClick={e => this.handleCreateNewPrivateCollection(e)}
        />
      </OverlayTrigger>
    )

    const infoIcon = (
      <OverlayTrigger
        placement='auto'
        overlay={
          <Tooltip className='collections-tooltip'>
            Create and use collections to save and organize related research reports into logical groups. Once you've collected some research, you can describe and name your collection, make it public, share it with the world, and engage in discussion with the VelocityChannel community.
          </Tooltip>
        }
      >
        <AiOutlineQuestionCircle className='collections-sidebar-add' />
      </OverlayTrigger>
    )

    const sidebarOpenContent = (
      <ListGroup variant='flush' className='collections-sidebar' id={sidenavOpen ? 'open-sidenav' : 'closed-sidenav'}>
        <ListGroup.Item className='collections-sidebar-button-row'>
          { sidenavOpen ? closeButton : openButton }
        </ListGroup.Item>
        <ListGroup.Item
          onClick={() => this.handleSelectSection('Discover')}
          className='collections-sidebar-item-my-collections'
          active={currentSection === 'Discover'}
          id={sidenavOpen ? 'open-sidenav' : 'closed-sidenav'}
        >
          <Row className='no-padding collections-sidebar-add-column'>
            <Col className='no-padding'>
              My Collections
            </Col>
            <Col className='no-padding right-justified-column'>
              {infoIcon}
              {addCollectionIcon}
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item
          id={sidenavOpen ? 'open-sidenav' : 'closed-sidenav'}
          onClick={this.handleTogglePublic}
          active={currentSection === 'profile-info'}
        >
          <Row className='no-padding collections-title-row'>
            <Col className='no-padding'>
              <MdPublic className='collections-sidebar-icon' />
              Public
            </Col>
            <Col className='no-padding right-justified-column'>
              <MdKeyboardArrowRight className='collections-sidebar-arrow' id={publicCollectionsOpen ? 'open-collection' : ''} />
            </Col>
          </Row>
        </ListGroup.Item>
        <div className='collections-sidebar-folder' id={publicCollectionsOpen ? 'open-collection' : ''}>
          {this.handleMapOverCollections(publicUserCollections.collections)}
        </div>
        <ListGroup.Item
          id={sidenavOpen ? 'open-sidenav' : 'closed-sidenav'}
          onClick={this.handleTogglePrivate}
          active={currentSection === 'profile-info'}
        >
          <Row className='no-padding collections-title-row'>
            <Col className='no-padding'>
              <MdLock className='collections-sidebar-icon' />
              Private
            </Col>
            <Col className='no-padding right-justified-column'>
              <MdKeyboardArrowRight className='collections-sidebar-arrow' id={privateCollectionsOpen ? 'open-collection' : ''} />
            </Col>
          </Row>
        </ListGroup.Item>
        <div className='collections-sidebar-folder' id={privateCollectionsOpen ? 'open-collection' : ''}>
          {this.handleMapOverCollections(privateUserCollections.collections)}
        </div>
      </ListGroup>
    )

    const sidebarClosedContent = (
      <ListGroup variant='flush' className='collections-sidebar' id='closed-sidenav'>
        <ListGroup.Item className='collections-sidebar-button-row'>
          { sidenavOpen ? closeButton : openButton }
        </ListGroup.Item>
      </ListGroup>
    )

    return (
      <Col className='collections-sidebar-column' id={sidenavOpen ? 'open-sidenav' : 'closed-sidenav'}>
        {sidebarOpenContent}
      </Col>
    )
  }

  renderNoCollections = () => (
    <ListGroup.Item className='collections-sidebar-no-collections'>
      No collections
    </ListGroup.Item>
  )

  renderSidebarCollectionItem = collection => {
    const { currentSection } = this.state

    const deleteButton = collection.canEdit ? <MdDelete className='collection-card-delete-icon' onClick={e => this.handleClickDeleteButtonSidebar(e, collection.id)} /> : null

    return (
      <ListGroup.Item
        key={collection.id}
        className='collections-sidebar-collection-item'
        active={currentSection === collection.id}
        onClick={() => this.handleSelectCollection(collection)}
      >
        <Row className='no-padding'>
          <Col className='no-padding'>
            {collection.name || 'Unnamed Collection'}
          </Col>
          <Col className='no-padding right-justified-column' md={1}>
            {deleteButton}
          </Col>
        </Row>
      </ListGroup.Item>
    )
  }

  renderUploadedImage = () => {
    const { editCollectionParams } = this.state
    return (
      <React.Fragment>
        <MdClose className='edit-collection-image-clear-button' onClick={this.handleClearUploadedImage} />
        <Image src={editCollectionParams.image.image || null} className='collections-info-image' />
      </React.Fragment>
    )
  }

  renderImageUploader = () => {
    const { editCollectionParams } = this.state
    if (editCollectionParams.image.loading) {
      return (
        <div className='collections-info-image'>
          {this.renderSpinner()}
        </div>
      )
    } else {
      return (
        <ImageUploader
          className='edit-collections-image-uploader'
          withIcon={false}
          buttonText='Choose Image'
          label='Choose an image for your collection'
          singleImage
          onChange={this.handleUploadCollectionImage}
        />
      )
    }
  }

  renderCollectionsInfo = () => {
    const {
      specificCollection,
      discoverCollections,
      currentSection,
      sidenavOpen,
      editSpecificCollection,
      editCollectionParams,
    } = this.state
    const { userInfo } = this.props
    const columnWidthMd = sidenavOpen ? 1 : 2
    const collectionData = specificCollection.loaded ? specificCollection.collectionInfo.data : {}

    const publicIcon = (
      <MdPublic
        className='collections-privacy-icon'
        id={!collectionData.canEdit ? 'no-functionality' : ''}
        onClick={() => this.handleTogglePublicPrivateCollection(collectionData.id)}
        data-tip
        data-for='publicAndPrivateTooltip'
      />
    )

    const privateIcon = (
      <MdLock
        className='collections-privacy-icon'
        id={!collectionData.canEdit ? 'no-functionality' : ''}
        onClick={() => this.handleTogglePublicPrivateCollection(collectionData.id)}
        data-tip
        data-for='publicAndPrivateTooltip'
      />
    )

    const editButton = (
      <OverlayTrigger
        placement='auto'
        overlay={
          <Tooltip className='collections-tooltip'>
            Edit
          </Tooltip>
        }
      >
        <MdEdit className='collections-edit-icon' onClick={this.handleClickEditButton}/>
      </OverlayTrigger>
    )

    const deleteButton = (
      <OverlayTrigger
        placement='auto'
        overlay={
          <Tooltip className='collections-tooltip'>
            Delete
          </Tooltip>
        }
      >
        <MdDelete className='collection-card-delete-icon' onClick={e => this.handleClickDeleteButtonSidebar(e, specificCollection.collectionInfo.data.id)} />
      </OverlayTrigger>
    )

    const publicIconText = !collectionData.canEdit ? 'Marked as public' : 'Marked as public. Click to make this collection private'
    const privateIconText = !collectionData.canEdit ? 'Marked as private' : 'Marked as private. Click to make this collection public'

    const tooltips = (
      <ReactTooltip className='collections-react-tooltip' id='publicAndPrivateTooltip' effect='solid' place='left'>
        {collectionData.isPublic ? publicIconText : privateIconText}
      </ReactTooltip>
    )

    let collectionImageIcon
    if (collectionData.name === 'Upvotes' && !collectionData.canEdit) {
      collectionImageIcon = <MdThumbUp className='collections-info-image-icon' />
    } else if (collectionData.name === 'Downvotes' && !collectionData.canEdit) {
      collectionImageIcon = <MdThumbDown className='collections-info-image-icon' />
    } else if (collectionData.name === 'Bookmarks' && !collectionData.canEdit) {
      collectionImageIcon = <MdBookmark className='collections-info-image-icon' />
    }

    let collectionImage
    if (collectionData.name === 'Upvotes' || collectionData.name === 'Downvotes' || collectionData.name === 'Bookmarks') {
      collectionImage = <div className='collections-info-image-static'>{collectionImageIcon}</div>
    } else if (editSpecificCollection) {
      collectionImage = editCollectionParams.image.image ? this.renderUploadedImage() : this.renderImageUploader()
    } else {
      collectionImage = collectionData.image
      ? <Image className='collections-info-image' src={collectionData.image} />
      : <div className='collection-no-data-image' onClick={this.handleClickEditButton}><p>Click here to change this collection's name</p></div>
    }

    const disableSubmitButton = (!editCollectionParams.name && !editCollectionParams.description && !editCollectionParams.image.loaded) ? true : false

    if (editSpecificCollection) {
      return (
        <React.Fragment>
          <Row className='collections-info'>
            <Col md={columnWidthMd} />
            <Col className='no-padding collections-info-image-column' md={2}>
              {collectionImage}
            </Col>
            <Col md={1} />
            <Col className='no-padding collections-info-data-column'>
              <Row className='no-padding collections-info-data-row'>
                <Col className='no-padding'>
                  <Row className='no-padding collections-info-name-row'>
                    <FormControl
                      className='edit-collection-form-control'
                      placeholder='Name this collection...'
                      name='name'
                      value={editCollectionParams.name}
                      onChange={this.handleEditFormChange}
                    />
                  </Row>
                  <Row className='no-padding collections-info-description-row'>
                    <FormControl
                      className='edit-collection-form-control-textarea'
                      placeholder='Describe this collection...'
                      as='textarea'
                      name='description'
                      value={editCollectionParams.description}
                      onChange={this.handleEditFormChange}
                    />
                  </Row>
                </Col>
                <Col className='no-padding edit-collection-submit-button-column'>
                  <Button className='edit-collection-submit-button' disabled={disableSubmitButton} onClick={this.handleSubmitCollectionEdit}>
                    Submit Changes
                  </Button>
                </Col>
                <Col className='no-padding collections-info-icons-column'>
                  {collectionData.isPublic ? publicIcon : privateIcon}
                  {(collectionData.userId === userInfo.userId && collectionData.canEdit) ? editButton : null}
                  {(collectionData.userId === userInfo.userId && collectionData.canEdit) ? deleteButton : null}
                </Col>
              </Row>
              <Row className='collections-details'>
                {this.renderTopicsDetails(collectionData.topics)}
                {this.renderCategoriesDetails(collectionData.categories)}
                {this.renderIndustriesDetails(collectionData.industries)}
              </Row>
            </Col>
            <Col md={columnWidthMd} />
          </Row>
          {tooltips}
        </React.Fragment>
      )
    } else if (currentSection === collectionData.id) {
      return (
        <React.Fragment>
          <Row className='collections-info'>
            <Col md={columnWidthMd} />
            <Col className='no-padding collections-info-image-column' md={2}>
              {collectionImage}
            </Col>
            <Col md={1} />
            <Col className='no-padding collections-info-data-column'>
              <Row className='no-padding collections-info-data-row'>
                <Col className='no-padding'>
                  <Row className='no-padding collections-info-name-row'>
                    {collectionData.name || <div className='collection-no-data-text' onClick={this.handleClickEditButton}>Click here to change this collection's name</div>}
                  </Row>
                  <Row className='no-padding collections-info-description-row'>
                    {collectionData.description || <div className='collection-no-data-text' onClick={this.handleClickEditButton}>Click here to change this collection's description</div>}
                  </Row>
                </Col>
                <Col className='no-padding collections-info-icons-column'>
                  {collectionData.isPublic ? publicIcon : privateIcon}
                  {(collectionData.userId === userInfo.userId && collectionData.canEdit) ? editButton : null}
                  {(collectionData.userId === userInfo.userId && collectionData.canEdit) ? deleteButton : null}
                </Col>
              </Row>
              <Row className='collections-details'>
                {this.renderTopicsDetails(collectionData.topics)}
                {this.renderCategoriesDetails(collectionData.categories)}
                {this.renderIndustriesDetails(collectionData.industries)}
              </Row>
            </Col>
            <Col md={columnWidthMd} />
          </Row>
          {tooltips}
        </React.Fragment>
      )
    }
  }

  renderTopicsDetails = topics => {
    if (topics) {
      const topicsData = Object.keys(topics)
      return (
        <Col className='no-padding collections-details-data-column'>
          <Row className='no-padding collections-details-title'>
            Topics
          </Row>
          <Row className='no-padding collections-details-items'>
            {topicsData.map(topic => this.renderIndividualDetailItem(topic))}
          </Row>
        </Col>
      )
    }
  }

  renderCategoriesDetails = categories => {
    if (categories) {
      const categoriesData = Object.keys(categories)
      return (
        <Col className='no-padding collections-details-data-column'>
          <Row className='no-padding collections-details-title'>
            Categories
          </Row>
          <Row className='no-padding collections-details-items'>
            {categoriesData.map(category => this.renderIndividualDetailItem(category))}
          </Row>
        </Col>
      )
    }
  }

  renderIndustriesDetails = industries => {
    if (industries) {
      const industriesData = Object.keys(industries)
      return (
        <Col className='no-padding collections-details-data-column'>
          <Row className='no-padding collections-details-title'>
            Industries
          </Row>
          <Row className='no-padding collections-details-items'>
            {industriesData.map(industry => this.renderIndividualDetailItem(industry))}
          </Row>
        </Col>
      )
    }
  }

  renderIndividualDetailItem = item => {
    return (
      <div className='collections-detail-individual-item' key={item}>
        {item}
      </div>
    )
  }

  renderCollections = () => {
    const {
      discoverCollections,
      specificCollection,
      sidenavOpen,
      currentSection,
    } = this.state
    let collectionReports = []
    const columnWidthMd = sidenavOpen ? 1 : 2

    if (currentSection === 'Discover' && discoverCollections.loaded) {
      collectionReports = discoverCollections.collections.data
    } else if (specificCollection.loaded) {
      collectionReports = specificCollection.reports.data || []
    }

    const noReports = (
      <div className='no-reports-in-collection'>There are no reports in this collection</div>
    )

    const reportsCardColumn = (
      <Row className='no-padding collections-card-row'>
        <CardColumns className='collections-card-columns'>
          {collectionReports.length > 0 ? collectionReports.map(report => this.renderIndividualCard(report)) : null}
        </CardColumns>
      </Row>
    )

    const renderReports = collectionReports.length > 0 ? reportsCardColumn : noReports

    return (
      <Row className='collections'>
        <Col md={columnWidthMd} />
        <Col className='no-padding'>
          {renderReports}
        </Col>
        <Col md={columnWidthMd} />
      </Row>
    )
  }

  renderRemoveReportModal = () => {
    const { removeReport } = this.state
    return (
      <Modal centered className='collections-please-wait-modal' show={removeReport.loading}>
        <Row className='no-padding collections-please-wait-modal-header'>
          Please wait...
        </Row>
        {this.renderSpinner()}
      </Modal>
    )
  }

  renderTogglePrivacyModal = () => {
    const { collectionPrivacyBeingToggled } = this.state
    return (
      <Modal centered className='collections-please-wait-modal' show={collectionPrivacyBeingToggled.loading}>
        <Row className='no-padding collections-please-wait-modal-header'>
          Please wait while we make those changes...
        </Row>
        {this.renderSpinner()}
      </Modal>
    )
  }

  renderCreateCollectionModal = () => {
    const { createNewCollectionRequest } = this.state
    return (
      <Modal centered className='collections-please-wait-modal' show={createNewCollectionRequest.loading}>
        <Row className='no-padding collections-please-wait-modal-header'>
          Please wait while we create your new collection...
        </Row>
        {this.renderSpinner()}
      </Modal>
    )
  }

  renderDoYouWantToDeleteThisCollectionModal = () => {
    const { doYouWantToDeleteThisCollectionModal, collectionToDelete } = this.state
    return (
      <Modal centered className='collections-please-wait-modal' onHide={this.handleCloseDeleteCollectionsModal} show={doYouWantToDeleteThisCollectionModal}>
        <Row className='no-padding collections-please-wait-modal-header'>
          Do you want to delete this collection?
        </Row>
        <Row className='no-padding collections-please-wait-modal-button-row'>
          <Button
            className='collections-delete-collection-popover-confirm-button'
            onClick={() => this.handleDeleteCollectionRequest(collectionToDelete)}
          >
            Delete
          </Button>
        </Row>
      </Modal>
    )
  }

  renderDeleteCollectionModal = () => {
    const { deleteCollection } = this.state
    return (
      <Modal centered className='collections-please-wait-modal' show={deleteCollection.loading}>
        <Row className='no-padding collections-please-wait-modal-header'>
          Please wait while we delete your collection...
        </Row>
        {this.renderSpinner()}
      </Modal>
    )
  }

  renderIndividualCard = data => {
    const { currentSection, specificCollection } = this.state
    const contributor = currentSection === 'Discover' ? data.contributor : data.contributor
    const collection = currentSection === 'Discover' ? data.collection : data.report
    const collectionTitle = currentSection === 'Discover' ? (collection.name || 'Untitled Collection') : collection.title
    const collectionDescription = currentSection === 'Discover' ? collection.description : collection.summary
    const collectionImage = collection.image
    const contributorName = contributor.displayName || contributor.username
    const contributorImage = contributor.image || defaultNewsImage
    const navigationTitle = this.handleNavigationTitle(collection.title, collection.id)
    const collectionId = specificCollection.loaded ? specificCollection.collectionInfo.data.id : null
    const reportId = collection.id

    let titleComponent
    let collectionImageComponent
    if (currentSection === 'Discover') {
      titleComponent = collectionTitle
      collectionImageComponent = (
        <Card.Img variant='top' src={collectionImage} className='collection-card-image' />
      )
    } else {
      titleComponent = (
        <NavLink to={`/research/${navigationTitle}`} target='_blank'>
          {collectionTitle}
        </NavLink>
      )
      collectionImageComponent = (
        <NavLink to={`/research/${navigationTitle}`} target='_blank'>
          <Card.Img variant='top' src={collectionImage} className='collection-card-image' />
        </NavLink>
      )
    }

    if (collection.name === 'Upvotes' && !collection.canEdit) {
      collectionImageComponent = <div className='collections-info-image-static'><MdThumbUp className='collections-info-image-icon' /></div>
    } else if (collection.name === 'Downvotes' && !collection.canEdit) {
      collectionImageComponent = <div className='collections-info-image-static'><MdThumbDown className='collections-info-image-icon' /></div>
    } else if (collection.name === 'Bookmarks' && !collection.canEdit) {
      collectionImageComponent = <div className='collections-info-image-static'><MdBookmark className='collections-info-image-icon' /></div>
    }

    const removeReportIcon = (
      <OverlayTrigger
        trigger="click"
        placement="auto"
        overlay={
          <div className='collections-delete-report-popover'>
            <Row className='no-padding collections-delete-report-header'>Are you sure you want to remove this article from your collection?</Row>
            <Row className='no-padding collections-delete-report-popover-button-row'>
              <Button
                className='collections-delete-report-popover-confirm-button'
                onClick={() => this.handleRemoveReportFromCollection(collectionId, reportId)}
              >
                Remove
              </Button>
            </Row>
          </div>
        }
      >
        <MdDelete
          className='collection-card-delete-icon'
        />
      </OverlayTrigger>
    )

    return (
      <Card className='collection-card' key={collection.id}>
        {collectionImageComponent}
        <Card.Body>
          <Card.Title className='collection-card-name'>
            {titleComponent}
          </Card.Title>
          <Card.Text className='collection-card-description'>{collectionDescription}</Card.Text>
          <Row className='no-padding collection-card-contributor-info-row'>
            <Col className='no-padding' md={2}>
              <NavLink to={`/contributor/${contributor.username}`} target='_blank'>
                <Card.Img src={contributorImage} className='collection-card-contributor-image' />
              </NavLink>
            </Col>
            <Col className='no-padding'>
              <NavLink to={`/contributor/${contributor.username}`} target='_blank'>
                <Card.Text className='collection-card-contributor-name'>{contributorName}</Card.Text>
              </NavLink>
            </Col>
            <Col className='no-padding right-justified-column' md={1}>
              {/* {removeReportIcon} */}
              {currentSection === 'Discover' ? null : removeReportIcon}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }

  renderSpinner = () => (
    <Row className='no-padding collections-spinner-row'>
      <Spinner className='collections-spinner' animation='border'/>
    </Row>
  )

  renderCollectionsContent = () => {
    const {
      discoverCollections,
      publicUserCollections,
      privateUserCollections,
      specificCollection,
      currentSection,
    } = this.state

    if (discoverCollections.loading || publicUserCollections.loading || privateUserCollections.loading || specificCollection.loading) {
      return (
        <Col className='collections-content-column'>
          {this.renderSpinner()}
        </Col>
      )
    } else {
      return (
        <Col className='collections-content-column'>
          {currentSection !== 'Discover' ? this.renderCollectionsInfo() : null}
          {this.renderCollections()}
        </Col>
      )
    }
  }

  renderMetaTags = () => {
    return (
      <MetaTags>
        <meta name='description' content='My Research Collections on VelocityChannel'/>
        <meta name='title' property='og:title' content='View My Collections on VelocityChannel'/>
        <meta property='og:description' content='My Research Collections on VelocityChannel'/>
        <meta property='og:image' content={LogoImg}/>
        <meta property='og:type' content='website'/>
        <meta property='og:url' content={`https://velocitychannel.io/research/collections`}/>
        <meta property='twitter:title' content='View My Collections on VelocityChannel'/>
        <meta property='twitter:description' content='My Research Collections on VelocityChannel'/>
        <meta property='twitter:image' content={LogoImg}/>
        <meta property='twitter:site' content='Velocity Channel'/>
        {/* <meta name='robots' content='nofollow' /> */}
      </MetaTags>
    )
  }

  renderSpecificCollectionMetaTags = () => {
    const data = this.state.specificCollection.collectionInfo.data
    const location = this.props.history.location.search
    return (
      <MetaTags>
        <meta name='description' content={data.description || null}/>
        <meta name='title' property='og:title' content={`${data.name || 'Unnamed Collection'} - a Research Collection on VelocityChannel`}/>
        <meta property='og:description' content={data.description || null}/>
        <meta property='og:image' content={data.image || null}/>
        <meta property='og:type' content='website'/>
        <meta property='og:url' content={`https://velocitychannel.io/research/collections${location}`}/>
        <meta property='twitter:title' content={`${data.name || 'Unnamed Collection'} - a Research Collection on VelocityChannel`}/>
        <meta property='twitter:description' content={data.description || null}/>
        <meta property='twitter:image' content={data.image || null}/>
        <meta property='twitter:site' content='Velocity Channel'/>
        <meta name='robots' content='index,follow' />
      </MetaTags>
    )
  }

  render() {
    const {
      discoverCollections,
      publicUserCollections,
      privateUserCollections,
      specificCollection,
      sidenavOpen,
    } = this.state

    if (discoverCollections.loaded && publicUserCollections.loaded && privateUserCollections.loaded) {
      return (
        <Container className='collections-container'>
          {specificCollection.loaded ? this.renderSpecificCollectionMetaTags() : this.renderMetaTags()}
          {this.renderRemoveReportModal()}
          {this.renderTogglePrivacyModal()}
          {this.renderCreateCollectionModal()}
          {this.renderDeleteCollectionModal()}
          {this.renderDoYouWantToDeleteThisCollectionModal()}
          <Row className='no-padding collections-container-top-row'>
            {this.renderSidebar()}
            {this.renderCollectionsContent()}
          </Row>
        </Container>
      )
    } else {
      return (
        <Container className='collections-container'>
          {this.renderSpinner()}
        </Container>
      )
    }
  }
}

export default Collections;
