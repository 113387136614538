import { connect } from 'react-redux';

import Contact from './Contact';
import { contactRequest, resetContact } from '../../redux/actions/contact'

const mapStateToProps = state => ({
  contactRequest: state.contact,
});

const mapDispatchToProps = {
    contactRequest,
    resetContact,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Contact);
