import { connect } from "react-redux"
import LandingPage2 from "./LandingPage2"
import "./LandingPage2.css"
import {
  landingPageUserSearchRequest,
  sendInvitesRequest,
} from '../../redux/actions/account'

const mapStateToProps = state => ({
  userSearch: state.account.landingPageUserSearch,
  user: state.account.myAccount.info,
  authenticated: state.login.authenticated,
})

const mapDispatchToProps = {
  landingPageUserSearchRequest,
  sendInvitesRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPage2)
