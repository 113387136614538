import { connect } from 'react-redux'
import AssetProfiles from './AssetProfiles'
import './AssetProfiles.css'
import {
  getPublishedTemplatesRequest,
  createTokenFormFromTemplateRequest,
  resetTokenFormParams,
  uploadTokenFormImageRequest,
  uploadTokenFormDocumentRequest,
  editTokenFormRequest,
  getTokenProfilesListRequest,
  selectAssetProfileRequest,
  publishAssetProfileRequest,
  searchAssetProfilesRequest,
  deleteAssetProfileRequest,
  unpublishAssetProfileRequest,
  createAssetProfileRequest,
  assetProfileUploadFileRequest,
  assetProfileDeleteFileRequest,
  uploadAssetProfileImageRequest,
  deleteAssetProfileImageRequest,
  updateAssetProfileOfferingRequest,
  uploadOfferingDocumentRequest,
  clearOfferingDocument,
} from '../../../redux/actions/tokenform';
import {
  getTradedSymbolsRequest,
  createOfferingRequest,
  getOfferRequest,
  updateOfferRequest,
} from '../../../redux/actions/admin';

const mapStateToProps = state => ({
  assetTypes: state.token.publishedTemplates,
  assetProfileToEdit: state.token.tokenForm,
  tokenFormImage: state.token.tokenFormImage,
  tokenFormDocument: state.token.tokenFormDocument,
  tokenProfilesList: state.token.tokenProfilesList,
  savingForm: state.token.savingForm,
  unpublishAssetProfile: state.token.unpublishAssetProfile,
  tradedSymbols: state.admin.tradedSymbols,
  offer: state.admin.offer,
})

const mapDispatchToProps = {
  getPublishedTemplatesRequest,
  createTokenFormFromTemplateRequest,
  resetTokenFormParams,
  uploadTokenFormImageRequest,
  uploadTokenFormDocumentRequest,
  editTokenFormRequest,
  getTokenProfilesListRequest,
  selectAssetProfileRequest,
  publishAssetProfileRequest,
  searchAssetProfilesRequest,
  deleteAssetProfileRequest,
  unpublishAssetProfileRequest,
  createAssetProfileRequest,
  assetProfileUploadFileRequest,
  assetProfileDeleteFileRequest,
  uploadAssetProfileImageRequest,
  deleteAssetProfileImageRequest,
  updateAssetProfileOfferingRequest,
  uploadOfferingDocumentRequest,
  clearOfferingDocument,
  getTradedSymbolsRequest,
  createOfferingRequest,
  getOfferRequest,
  updateOfferRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssetProfiles)
