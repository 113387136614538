import { connect } from 'react-redux';
import AccountForm from './AccountForm.js';
import './AccountForm.css';
import {
  getMyAccountRequest,
  profileImageUploadRequest,
  profileImageRequest,
  editAccountRequest
} from '../../redux/actions/account';

const mapStateToProps = state => ({
  accountInfo: state.account.myAccount.info,
  test: state.account,
});
const mapDispatchToProps = {
  getMyAccountRequest,
  profileImageUploadRequest,
  editAccountRequest,
  profileImageRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountForm);
