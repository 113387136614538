import { connect } from 'react-redux';

import HomePage from './HomePage';
import './HomePage.css';
import { signupRequest } from '../../redux/actions/signup';

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  signupRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomePage);
