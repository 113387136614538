import * as CONSTANTS from "../constants/researches";

export const getResearchFeedRequest = offset => ({
  type: CONSTANTS.GET_RESEARCH_FEED_REQUEST,
  offset
});

export const getResearchFeedSuccess = feeds => ({
  type: CONSTANTS.GET_RESEARCH_FEED_SUCCESS,
  feeds
});

export const getResearchFeedError = error => ({
  type: CONSTANTS.GET_RESEARCH_FEED_ERROR,
  error
});

export const setFilterKey = data => ({
  type: CONSTANTS.SET_FILTER_KEYS,
  data
});

export const filterResearchRequest = (searchKeys, offset) => ({
  type: CONSTANTS.GET_FILTER_RESEARCH_REQEUST,
  searchKeys,
  offset
});

export const filterResearchSuccess = researches => ({
  type: CONSTANTS.GET_FILTER_RESEARCH_SUCCESS,
  researches
});

export const filterResearchError = err => ({
  type: CONSTANTS.GET_FILTER_RESEARCH_ERROR,
  err
});

export const getDownvotesRequest = () => ({
  type: CONSTANTS.GET_DOWN_VOTES_REQUEST
});

export const getDownvotesSuccess = downVotes => ({
  type: CONSTANTS.GET_DOWN_VOTES_SUCCESS,
  downVotes
});

export const getDownvotesError = () => ({
  type: CONSTANTS.GET_DOWN_VOTES_ERROR
});

export const getUpvotesRequest = () => ({
  type: CONSTANTS.GET_UP_VOTES_REQUEST
});

export const getUpvotesSuccess = upVotes => ({
  type: CONSTANTS.GET_UP_VOTES_SUCCESS,
  upVotes
});

export const getUpvotesError = () => ({
  type: CONSTANTS.GET_UP_VOTES_ERROR
});

export const postUpVoteReqeust = (userId, resourceId, resourceCreatorId) => ({
  type: CONSTANTS.POST_UP_VOTE_REQUEST,
  userId,
  resourceId,
  resourceCreatorId
});

export const postDownVoteRequest = (userId, resourceId, resourceCreatorId) => ({
  type: CONSTANTS.POST_DOWN_VOTE_REQUEST,
  userId,
  resourceId,
  resourceCreatorId
});

export const removeUpVoteRequest = resourceId => ({
  type: CONSTANTS.REMOVE_UP_VOTE_REQUEST,
  resourceId
});

export const removeDownVoteRequest = resourceId => ({
  type: CONSTANTS.REMOVE_DOWN_VOTE_REQUEST,
  resourceId
});

export const getReportVoteUsersRequest = (type, id) => ({
  type: CONSTANTS.GET_REPORT_VOTES_USER_REQUEST,
  voteType: type,
  id
});

export const getReportVoteUsersSuccess = users => ({
  type: CONSTANTS.GET_REPORT_VOTES_USER_SUCCESS,
  users
});

export const getReportVoteUsersError = err => ({
  type: CONSTANTS.GET_REPORT_VOTES_USER_ERROR,
  err
});

export const getReportRequest = id => ({
  type: CONSTANTS.GET_FEED_REPORT_REQUEST,
  id
});

export const getReportSuccess = feed => ({
  type: CONSTANTS.GET_FEED_REPORT_SUCCESS,
  feed
});

export const getReportError = err => ({
  type: CONSTANTS.GET_FEED_REPORT_ERROR,
  err
});

export const updateUpvoteCount = (id, count) => ({
  type: CONSTANTS.UPDATE_UPVOTE_COUNT,
  id,
  count
});

export const updateDownvoteCount = (id, count) => ({
  type: CONSTANTS.UPDATE_DOWNVOTE_COUNT,
  id,
  count
});

export const resetSearchFeed = () => ({
  type: CONSTANTS.RESET_SEARCH_FEED
});

export const createDraftRequest = () => ({
  type: CONSTANTS.CREATE_DRAFT_REQUEST
});

export const createDraftSuccess = data => ({
  type: CONSTANTS.CREATE_DRAFT_SUCCESS,
  data
});

export const createDraftError = error => ({
  type: CONSTANTS.CREATE_DRAFT_ERROR,
  error
})

export const createDraftClear = () => ({
  type: CONSTANTS.CREATE_DRAFT_CLEAR
})

export const saveDraftRequest = data => ({
  type: CONSTANTS.SAVE_DRAFT_REQUEST,
  data
})

export const saveDraftSuccess = data => ({
  type: CONSTANTS.SAVE_DRAFT_SUCCESS,
  data
})

export const saveDraftError = err => ({
  type: CONSTANTS.SAVE_DRAFT_ERROR,
  err
})

export const saveDraftReset = () => ({
  type: CONSTANTS.SAVE_DRAFT_RESET,
})

export const deleteDraftRequest = (id, userId, params) => ({
  type: CONSTANTS.DELETE_DRAFT_REQUEST,
  id,
  userId,
  params
})

export const deleteDraftSuccess = data => ({
  type: CONSTANTS.DELETE_DRAFT_SUCCESS,
  data
})

export const deleteDraftError = error => ({
  type: CONSTANTS.DELETE_DRAFT_ERROR,
  error
})

export const getUsersDraftsRequest = id => ({
  type: CONSTANTS.GET_USERS_DRAFTS_REQUEST,
  id
});

export const getUsersDraftsSuccess = data => ({
  type: CONSTANTS.GET_USERS_DRAFTS_SUCCESS,
  data
});

export const publishArticleRequest = data => ({
  type: CONSTANTS.PUBLISH_ARTICLE_REQUEST,
  data
});

export const publishArticleSuccess = data => ({
  type: CONSTANTS.PUBLISH_ARTICLE_SUCCESS,
  data
});

export const getBookmarksRequest = () => ({
  type: CONSTANTS.GET_BOOKMARKS_REQUEST
});

export const getBookmarksSuccess = bookmarks => ({
  type: CONSTANTS.GET_BOOKMARKS_SUCCESS,
  bookmarks
});

export const getTrendingArticlesRequest = () => ({
  type: CONSTANTS.GET_TRENDING_ARTICLES_REQUEST
});

export const getTrendingArticlesSuccess = articles => ({
  type: CONSTANTS.GET_TRENDING_ARTICLES_SUCCESS,
  articles
});

export const getTrendingTopicsRequest = timeFrame => ({
  type: CONSTANTS.GET_TRENDING_TOPICS_REQUEST,
  timeFrame,
})

export const getTrendingTopicsSuccess = topics => ({
  type: CONSTANTS.GET_TRENDING_TOPICS_SUCCESS,
  topics
});

export const getResearchUserFeedRequest = offset => ({
  type: CONSTANTS.GET_RESEARCH_USER_FEED_REQUEST,
  offset
});

export const getResearchUserFeedSuccess = userFeed => ({
  type: CONSTANTS.GET_RESEARCH_USER_FEED_SUCCESS,
  userFeed
});

export const readArticleRequest = (articleId, parameters) => ({
  type: CONSTANTS.READ_ARTICLE_REQUEST,
  articleId,
  parameters,
})

export const readArticleSuccess = data => ({
  type: CONSTANTS.READ_ARTICLE_SUCCESS,
  data
})

export const readArticleError = error => ({
  type: CONSTANTS.READ_ARTICLE_ERROR,
  error
})

export const getRelatedArticlesRequest = id => ({
  type: CONSTANTS.GET_RELATED_ARTICLES_REQUEST,
  id
});

export const getRelatedArticlesSuccess = articles => ({
  type: CONSTANTS.GET_RELATED_ARTICLES_SUCCESS,
  articles
});

export const postCommentRequest = (
  userId,
  resourceId,
  resourceCreatorId,
  comment
) => ({
  type: CONSTANTS.POST_COMMENT_REQUEST,
  userId,
  resourceId,
  resourceCreatorId,
  comment
});

export const getCommentsRequest = (resourceId, offset) => ({
  type: CONSTANTS.GET_ARTICLE_COMMENTS_REQUEST,
  resourceId,
  offset,
})

export const getCommentsSuccess = comments => ({
  type: CONSTANTS.GET_ARTICLE_COMMENTS_SUCCESS,
  comments
});

export const articleImageUploadRequest = (image, formData) => ({
  type: CONSTANTS.ARTICLE_IMAGE_UPLOAD_REQUEST,
  image,
  formData
})

export const articleImageUploadSuccess = image => ({
  type: CONSTANTS.ARTICLE_IMAGE_UPLOAD_SUCCESS,
  image
})

export const articleImageUploadError = error => ({
  type: CONSTANTS.ARTICLE_IMAGE_UPLOAD_ERROR,
  error
})

export const clearArticleImage = () => ({
  type: CONSTANTS.CLEAR_ARTICLE_IMAGE
})

export const uploadDocumentRequest = (doc, formData) => ({
  type: CONSTANTS.ARTICLE_UPLOAD_DOCUMENT_REQUEST,
  doc,
  formData
})

export const uploadDocumentSuccess = data => ({
  type: CONSTANTS.ARTICLE_UPLOAD_DOCUMENT_SUCCESS,
  data
})

export const uploadDocumentError = error => ({
  type: CONSTANTS.ARTICLE_UPLOAD_DOCUMENT_ERROR,
  error
})

export const clearDocumentData = () => ({
  type: CONSTANTS.CLEAR_DOCUMENT_DATA
});

export const getTopicsListRequest = () => ({
  type: CONSTANTS.GET_TOPICS_LIST_REQUEST
});

export const getTopicsListSuccess = topics => ({
  type: CONSTANTS.GET_TOPICS_LIST_SUCCESS,
  topics,
})

export const getArticlesByCategoryRequest = (category, offset) => ({
  type: CONSTANTS.GET_ARTICLES_BY_CATEGORY_REQUEST,
  category,
  offset,
})

export const getArticlesByCategorySuccess = articles => ({
  type: CONSTANTS.GET_ARTICLES_BY_CATEGORY_SUCCESS,
  articles,
})

export const searchArticlesRequest = parameters => ({
  type: CONSTANTS.SEARCH_ARTICLES_REQUEST,
  parameters,
})

export const searchArticlesSuccess = articles => ({
  type: CONSTANTS.SEARCH_ARTICLES_SUCCESS,
  articles,
})

export const unpublishReportRequest = (id, userId, params) => ({
  type: CONSTANTS.UNPUBLISH_REPORT_REQUEST,
  id,
  userId,
  params
});

export const unpublishReportSuccess = article => ({
  type: CONSTANTS.READ_ARTICLE_SUCCESS,
  article
});

export const unpublishReportError = error => ({
  type: CONSTANTS.UNPUBLISH_REPORT_ERROR,
  error
});

export const reportSearchRequest = (q, offset) => ({
  type: CONSTANTS.REPORT_SEARCH_REQUEST,
  q,
  offset,
});

export const reportSearchSuccess = searchResults => ({
  type: CONSTANTS.REPORT_SEARCH_SUCCESS,
  searchResults,
});

export const reportSearchError = error => ({
  type: CONSTANTS.REPORT_SEARCH_ERROR,
  error,
});

export const submitReportRequest = data => ({
  type: CONSTANTS.SUBMIT_REPORT_REQUEST,
  data,
})

export const submitReportSuccess = () => ({
  type: CONSTANTS.SUBMIT_REPORT_SUCCESS,
})

export const getReportListRequest = offset => ({
  type: CONSTANTS.GET_REPORT_LIST_REQUEST,
  offset,
})

export const getReportListSuccess = data => ({
  type: CONSTANTS.GET_REPORT_LIST_SUCCESS,
  data,
})

export const reportNotFound = () => ({
  type: CONSTANTS.REPORT_NOT_FOUND,
})

export const getAssetProfilesRequest = userId => ({
  type: CONSTANTS.GET_ASSET_PROFILES_REQUEST,
  userId
})

export const getAssetProfilesSuccess = data => ({
  type: CONSTANTS.GET_ASSET_PROFILES_SUCCESS,
  data
})

export const getAssetProfilesError = error => ({
  type: CONSTANTS.GET_ASSET_PROFILES_ERROR,
  error
})

export const getDraftRequest = id => ({
  type: CONSTANTS.GET_DRAFT_REQUEST,
  id
})

export const getDraftSuccess = data => ({
  type: CONSTANTS.GET_DRAFT_SUCCESS,
  data
})

export const getDraftError = error => ({
  type: CONSTANTS.GET_DRAFT_ERROR,
  error
})

export const getAssetResearchRequest = params => ({
  type: CONSTANTS.GET_ASSET_RESEARCH_REQUEST,
  params
})

export const getAssetResearchSuccess = data => ({
  type: CONSTANTS.GET_ASSET_RESEARCH_SUCCESS,
  data
})

export const getAssetResearchError = error => ({
  type: CONSTANTS.GET_ASSET_RESEARCH_ERROR,
  error
})

export const getAssetPricingDataRequest = params => ({
  type: CONSTANTS.GET_ASSET_PRICING_DATA_REQUEST,
  params
})

export const getAssetPricingDataSuccess = data => ({
  type: CONSTANTS.GET_ASSET_PRICING_DATA_SUCCESS,
  data
})

export const getAssetPricingDataError = error => ({
  type: CONSTANTS.GET_ASSET_PRICING_DATA_ERROR,
  error
})
