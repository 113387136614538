import { put, takeLatest, call, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { history } from '../../../configureStore';
import * as CONSTANTS from '../../constants/tokenform';
import * as ACTIONS from '../../actions/tokenform';
import * as RESEARCH_ACTIONS from '../../actions/researches'
import * as TOKENLIST_ACTIONS from '../../actions/tokenlist'
import * as ADMIN_ACTIONS from '../../actions/admin'
import request from '../../../utils/apiRequest';

function* equityToken(action) {
  try {
    const {
      name,
      issuance_type,
      token_ticker,
      brief,
      description,
      start_date,
      end_date,
      industry,
      location,
      team,
      employees,
      token_price,
      minimum_investment,
      deal_size,
      website_link,
      twitter_link,
      linkedin_link,
      facebook_link,
      medium_link,
      file,
      document_links,
      incorporation_name,
      legal_form,
      protocol,
      purchase_link,
      purchase_requirements,
      regulation_type,
      status,
      video,
    } = action.tokenData;

    const requestData = {
        name,
        issuance_type,
        token_ticker,
        brief,
        description,
        start_date,
        end_date,
        industry,
        location,
        team,
        employees,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        image: file,
        document_links,
        incorporation_name,
        legal_form,
        protocol,
        purchase_link,
        purchase_requirements,
        regulation_type,
        status,
        video,
    };

    const responseData = yield call(
      request,
      'admin/addToken',
      'POST',
      requestData,
      true,
    );
    // toast.success('Success');
    yield put(ACTIONS.getEquityTokenFormSuccess(responseData));
    history.push('/admin');
  } catch (error) {
    // toast.error(error.reason);
    yield put(ACTIONS.getEquityTokenFormError(error));
  }
}

function* debtSecurityToken(action) {
  try {
    const {
        name,
        issuance_type,
        issuance_stage,
        interest_type,
        brief,
        description,
        principal,
        issuer,
        maturation_date,
        start_date,
        end_date,
        industry,
        location,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        file,
        document_links,
    } = action;

    const requestData = {
        name,
        issuance_type,
        issuance_stage,
        interest_type,
        brief,
        description,
        principal,
        issuer,
        maturation_date,
        start_date,
        end_date,
        industry,
        location,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        image: file,
        document_links,
    };

    const responseData = yield call(
      request,
      'admin/addToken',
      'POST',
      requestData,
      true,
    );
    // toast.success('Success');
    yield put(ACTIONS.getDebtSecurityTokenFormSuccess(responseData));
    history.push('/admin');
  } catch (err) {
    // toast.error(err.reason);
    yield put(ACTIONS.getDebtSecurityTokenFormError(err));
  }
}

function* realEstateToken(action) {
  try {
    const {
        name,
        issuance_type,
        token_ticker,
        brief,
        description,
        start_date,
        end_date,
        industry,
        address,
        location,
        team,
        development_company,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        file,
        document_links,
    } = action;

    const requestData = {
        name,
        issuance_type,
        token_ticker,
        brief,
        description,
        start_date,
        end_date,
        industry,
        address,
        location,
        team,
        development_company,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        image: file,
        document_links,
    };

    const responseData = yield call(
      request,
      'admin/addToken',
      'POST',
      requestData,
      true,
    );
    // toast.success('Success');
    yield put(ACTIONS.getRealEstateTokenFormSuccess(responseData));
    history.push('/admin');
  } catch (err) {
    // toast.error(err.reason);
    yield put(ACTIONS.getRealEstateTokenFormError(err));
  }
}

function* fundSecurityToken(action) {
  try {
    const {
        name,
        issuance_type,
        brief,
        description,
        start_date,
        end_date,
        documents,
        industry,
        location,
        team,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        file,
        document_links,
    } = action;

    const requestData = {
        name,
        issuance_type,
        brief,
        description,
        start_date,
        end_date,
        documents,
        industry,
        location,
        team,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        image: file,
        document_links,
    };

    const responseData = yield call(
      request,
      'admin/addToken',
      'POST',
      requestData,
      true,
    );
    // toast.success('Success');
    yield put(ACTIONS.getFundSecurityFormSuccess(responseData));
    history.push('/admin');
  } catch (error) {
    // toast.error(error.reason);
    yield put(ACTIONS.getFundSecurityFormError(error));
  }
}

function* getToken(action) {
  try {
    const { name } = action.name
    const responseData = yield call(
        request,
        `tokens/${name}`,
        'GET',
        null,
        true,
    );
    yield put(ACTIONS.getTokenSuccess(responseData));
  } catch (err) {
    yield put(ACTIONS.getTokenError(err));
  }
}

function* getTokenEdit(action) {
  try {
    const { name } = action;
    const responseData = yield call(
      request,
      `tokens/single/${name}`,
      'GET',
      null,
      true,
    );
    yield put(ACTIONS.getTokenSuccess(responseData));
  } catch (err) {
    yield put(ACTIONS.getTokenError(err));
  }
}

function* editEquityToken(action) {
  let requestData = {};
  const {
      id,
      name,
      image,
      issuance_type,
      token_ticker,
      brief,
      description,
      start_date,
      end_date,
      industry,
      location,
      team,
      employees,
      token_price,
      minimum_investment,
      deal_size,
      website_link,
      twitter_link,
      linkedin_link,
      facebook_link,
      medium_link,
      documents,
  } = action.data;

  requestData = {
      id,
      name,
      image,
      issuance_type,
      token_ticker,
      brief,
      description,
      start_date,
      end_date,
      industry,
      location,
      team,
      employees,
      token_price,
      minimum_investment,
      deal_size,
      website_link,
      twitter_link,
      linkedin_link,
      facebook_link,
      medium_link,
      documents,
  }

  try {
    const responseData = yield call(
      request,
      'admin/editToken',
      'POST',
      requestData,
      true,
    );
    // toast.success('success');
    yield put(ACTIONS.editTokenSuccess(responseData));
    history.push('/admin');
  } catch (err) {
    // toast.error(err)
  }
}

function* editDebtToken(action) {
    let requestData = {}
    const {
        id,
        name,
        image,
        issuance_type,
        issuance_stage,
        interest_type,
        brief,
        description,
        principal,
        issuer,
        maturation_date,
        start_date,
        end_date,
        industry,
        location,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        documents,
    } = action

    requestData = {
        id,
        name,
        image,
        issuance_type,
        issuance_stage,
        interest_type,
        brief,
        description,
        principal,
        issuer,
        maturation_date,
        start_date,
        end_date,
        industry,
        location,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        documents,
    }

    try {
      const responseData = yield call(
        request,
        'admin/editToken',
        'POST',
        requestData,
        true,
      );
      // toast.success('success');
      yield put(ACTIONS.editTokenSuccess(responseData));
      history.push('/admin');
    } catch (err) {
      yield put(ACTIONS.editTokenError(err));
    }
}

function* editRealEstateToken(action) {
    let requestData = {}
    const {
        id,
        name,
        image,
        issuance_type,
        token_ticker,
        brief,
        description,
        start_date,
        end_date,
        industry,
        address,
        location,
        team,
        development_company,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        development_plan,
        documents,
    } = action

    requestData = {
        id,
        name,
        image,
        issuance_type,
        token_ticker,
        brief,
        description,
        start_date,
        end_date,
        industry,
        address,
        location,
        team,
        development_company,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        development_plan,
        documents,
    }

    try {
      const responseData = yield call(
        request,
        'admin/editToken',
        'POST',
        requestData,
        true,
      );
      // toast.success('success');
      yield put(ACTIONS.editTokenSuccess(responseData));
      history.push('/admin');
    } catch (err) {
      yield put(ACTIONS.editTokenError(err));
    }
}

function* editFundToken(action) {
  let requestData = {};
  const {
      id,
      name,
      image,
      issuance_type,
      token_ticker,
      brief,
      description,
      start_date,
      end_date,
      industry,
      location,
      team,
      employees,
      token_price,
      minimum_investment,
      deal_size,
      website_link,
      twitter_link,
      linkedin_link,
      facebook_link,
      medium_link,
      documents,
  } = action;

  requestData = {
      id,
      name,
      image,
      issuance_type,
      token_ticker,
      brief,
      description,
      start_date,
      end_date,
      industry,
      location,
      team,
      employees,
      token_price,
      minimum_investment,
      deal_size,
      website_link,
      twitter_link,
      linkedin_link,
      facebook_link,
      medium_link,
      documents,
  }

  try {
    const responseData = yield call(
      request,
      'admin/editToken',
      'POST',
      requestData,
      true,
    );
    // toast.success('success');
    yield put(ACTIONS.editTokenSuccess(responseData));
    history.push('/admin');
  } catch (err) {
    yield put(ACTIONS.editTokenError(err));
  }
}

function* uploadImage(action) {
  try {
    const { image } = action;
    let formData = new FormData();
    formData.append('file', image);

    const responseData = yield call(
      request,
      'file/tokenImage',
      'POST',
      formData,
      true,
      true,
    );
    yield put(ACTIONS.uploadImageSuccess(responseData));
  } catch (err) {
    yield put(ACTIONS.uploadImageError(err));
  }
}

function* uploadDocument(action) {
  try {
    const { file, fieldType } = action;
    const fileData = file.src.file
    let formData = new FormData();
    formData.append('file', fileData);
    const responseData = yield call(
      request,
      'file/document',
      'POST',
      formData,
      true,
      true,
    )
    yield put(ACTIONS.uploadDocumentSuccess(responseData, fieldType, file.name));
  } catch (err) {
    // toast.error(err)
  }
}

function* createToken(action) {
  try {
    const tokenData = {
      ...action.data,
      team: JSON.stringify(action.data.team)
    }
    const responseData = yield call(
      request,
      'admin/addToken',
      'POST',
      tokenData,
      true,
    )
    history.push('/admin');
  } catch (err) {
    // toast.error(err)
  }
} // end of createToken(action)

function* editToken(action) {
  try {
    const tokenData = {
      ...action.data,
      team: JSON.stringify(action.data.team)
    }
    const responseData = yield call(
      request,
      'admin/editToken',
      'POST',
      tokenData,
      true,
    );
    history.push('/admin');
  } catch (err) {
    // toast.error(err)
  }
}

function* submitListing(action) {
  try {
    const { data } = action
    const responseData = yield call(
      request,
      'tokens/listingRequest',
      'POST',
      data,
      true,
    )
    toast.info('Your application to have your token listed was successfully sent, and is currently under review.')
    yield delay(3000)
    history.push('/')
  } catch (err) {}
}

function* getListingRequests(action) {
  try {
    const { offset } = action
    const responseData = yield call(
      request,
      'admin/listingRequests',
      'GET',
      offset,
      true,
    )
    yield put(ACTIONS.getListingRequestsSuccess(responseData))
  } catch (err) {
  }
}

function* getTokenTemplates(action) {
  try {
    let url = '/admin/templates/search?'
    if (action.params.q) url += `q=${action.params.q}&`
    if (action.params.isPublished || !action.params.isPublished) url += `isPublished=${action.params.isPublished}&`
    if (action.params.offset) url += `offset=${action.params.offset}`

    const responseData = yield call(
      request,
      url,
      'GET',
      action.params.offset,
    )

    yield put(ACTIONS.getTokenTemplatesSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.getTokenTemplatesError(err))
  }
}

function* getTokenTemplateToEdit(action) {
  try {
    const responseData = yield call(
      request,
      `/admin/templates/search?_id=${action.id}`,
      'GET',
    )

    yield put(ACTIONS.getTokenTemplateToEditSuccess(responseData.docs[0]))
  } catch (err) {
    yield put(ACTIONS.getTokenTemplateToEditError(err))
  }
}

function* editTokenTemplate(action) {
  try {
    const body = {
      template: JSON.stringify(action.template)
    }

    console.log(action.template)

    const responseData = yield call(
      request,
      '/admin/templates/edit',
      'POST',
      body,
    )

    yield put(ACTIONS.editTokenTemplateSuccess())
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.editTokenTemplateError(err))
  }
}

function* createTokenTemplate(action) {
  try {
    const responseData = yield call(
      request,
      '/admin/templates/create',
      'POST',
    )

    yield put(ACTIONS.getTokenTemplateToEditRequest(responseData.data._id))
    yield put(ACTIONS.getTokenTemplatesRequest({}))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.createTokenTemplateError(err))
  }
}

function* deleteTokenTemplate(action) {
  try {
    const body = {
      _id: action.id
    }
    const responseData = yield call(
      request,
      '/admin/templates/delete',
      'POST',
      body,
    )

    yield put(ACTIONS.getTokenTemplatesRequest(action.params))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.deleteTokenTemplateError(err))
  }
}

function* getPublishedTemplates(action) {
  try {
    const responseData = yield call(
      request,
      '/admin/templates/search?isPublished=true',
      'GET',
    )

    yield put(ACTIONS.getPublishedTemplatesSuccess(responseData))
  } catch (err) {
    yield put(ACTIONS.getPublishedTemplatesError(err))
  }
}

function* createTokenFormFromTemplate(action) {
  try {
    const { assetType } = action
    const body = {
      assetType: assetType
    }
    const responseData = yield call(
      request,
      'admin/profile/createProfile',
      'POST',
      body,
      true,
    )
    toast.info('Creating asset profile...')
    yield delay(1000)
    yield put(ACTIONS.selectAssetProfileRequest(responseData.profile.id))
    yield put(ACTIONS.getTokenProfilesListRequest())
    toast.info('Asset profile created')
  } catch (err) {
    yield put(ACTIONS.createTokenFormFromTemplateError(err))
  }
}

function* uploadTokenFormImage(action) {
  try {
    const { image, fieldIndex, sectionIndex } = action
    let formData = new FormData()
    formData.append('file', image)

    const responseData = yield call(
      request,
      'file/tokenImage',
      'POST',
      formData,
      true,
      true,
    )
    yield put(ACTIONS.uploadTokenFormImageSuccess(responseData.s3Location, fieldIndex, sectionIndex))
  } catch (err) {
    yield put(ACTIONS.uploadTokenFormImageError(err))
  }
}

function* uploadTokenFormDocument(action) {
  try {
    const { file, fieldIndex, sectionIndex } = action
    let formData = new FormData()
    formData.append('file', file)

    const responseData = yield call(
      request,
      'file/document',
      'POST',
      formData,
      true,
      true,
    )

    yield put(ACTIONS.uploadTokenFormDocumentSuccess(responseData.s3Location, fieldIndex, sectionIndex))
  } catch (err) {
    yield put(ACTIONS.uploadTokenFormDocumentError(err))
  }
}

function* editTokenForm(action) {
  try {
    const body = {
      profile: JSON.stringify(action.form)
    }

    const responseData = yield call(
      request,
      '/admin/profile/editProfile',
      'POST',
      body,
    )

    yield put(ACTIONS.editTokenFormSuccess())
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.editTokenFormError(err))
  }
}



function* getTokenProfilesList(action) {
  try {
    let url = '/admin/profile/search?'
    if (action.params.assetType) url += `template_id=${action.params.assetType}&`
    if (action.params.verification) url += `verification=${action.params.verification}&`
    if (action.params.q) url += `q=${action.params.q}&`
    if (action.params.offset) url += `offset=${action.params.offset}`

    const responseData = yield call(
      request,
      url,
      'GET'
    )

    yield put(ACTIONS.getTokenProfilesListSuccess(responseData))
  } catch (err) {
    yield put(ACTIONS.getTokenProfilesListError(err))
  }
}

function* selectAssetProfile(action) {
  try {
    const responseData = yield call(
      request,
      `/admin/profile/search?_id=${action.profileId}`,
      'GET',
    )

    console.log(responseData)

    yield put(ACTIONS.selectAssetProfileSuccess(responseData))

    if (responseData.profile.offer_id) {
      yield put(ADMIN_ACTIONS.getOfferRequest(responseData.profile.offer_id))
    }
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.selectAssetProfileError(err))
  }
}

function* deleteAssetProfile(action) {
  try {
    const body = {
      _id: action.profileId
    }
    const responseData = yield call(
      request,
      '/admin/profile/delete',
      'POST',
      body,
    )

    yield put(ACTIONS.getTokenProfilesListRequest(action.params))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.deleteAssetProfileError(err))
  }
}

function* publishAssetProfile(action) {
  try {
    const body = {
      _id: action.profileId
    }
    const responseData = yield call(
      request,
      '/admin/profile/publish',
      'POST',
      body,
    )

    yield put(ACTIONS.selectAssetProfileRequest(action.profileId))
    yield put(ACTIONS.getTokenProfilesListRequest())
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.publishAssetProfileError(err))
  }
}

function* searchAssetProfiles(action) {
  try {
    const { filterPublished, filterAssetType, filterVerification, query } = action.params
    let url = 'admin/profile/search?'
    url = filterPublished ? url + `published=${filterPublished}&` : url
    url = filterAssetType ? url + `assetType=${filterAssetType}&` : url
    url = filterVerification ? url + `verification=${filterVerification}&` : url
    url = query ? url + `q=${query}` : url
    const responseData = yield call(
      request,
      url,
      'GET',
      true,
      true,
    )

    yield put(ACTIONS.searchAssetProfilesSuccess(responseData))
  } catch (err) {
    yield put(ACTIONS.searchAssetProfilesError(err))
  }
}

function* unpublishAssetProfile(action) {
  try {
    const body = {
      _id: action.profileId
    }
    const responseData = yield call(
      request,
      '/admin/profile/unpublish',
      'POST',
      body,
    )

    yield put(ACTIONS.selectAssetProfileRequest(action.profileId))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.unpublishAssetProfileError(err))
  }
}

function* unpublishTokenTemplate(action) {
  try {
    const body = {
      _id: action.id
    }
    const responseData = yield call(
      request,
      '/admin/templates/unpublish',
      'POST',
      body,
    )

    yield put(ACTIONS.getTokenTemplateToEditRequest(action.id))
    yield put(ACTIONS.unpublishTokenTemplateSuccess())
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.unpublishTokenTemplateError(err))
  }
}

function* createAssetProfile(action) {
  try {
    const body = {
      template_id: action.id
    }
    const responseData = yield call(
      request,
      '/admin/profile/create',
      'POST',
      body
    )

    yield put(ACTIONS.selectAssetProfileRequest(responseData.profile._id))
    yield put(ACTIONS.getTokenProfilesListRequest())
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.createAssetProfileError(err))
  }
}

function* submitAssetProfileForVerification(action) {
  try {
    const { profileId, userId } = action
    const body = {
      id: profileId
    }
    const responseData = yield call(
      request,
      'profiles/verification',
      'POST',
      body,
      true,
    )
    toast.info('Your asset profile was submitted for verification.')
    yield put(RESEARCH_ACTIONS.getAssetProfilesRequest(userId))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.submitAssetProfileForVerificationError(err))
  }
}

function* getAssetProfileEditors(action) {
  try {
    const { profileId } = action
    const responseData = yield call(
      request,
      `profiles/getEditors?id=${profileId}`,
      'GET',
      true,
      true
    )
    yield put(ACTIONS.getAssetProfileEditorsSuccess(responseData))
  } catch (err) {
    yield put(ACTIONS.getAssetProfileEditorsError(err))
  }
}

function* searchUsersForAssetProfiles(action) {
  try {
    const { query } = action
    const responseData = yield call(
      request,
      `profiles/searchUsers?q=${query}`,
      'GET',
      true,
      true,
    )
    yield put(ACTIONS.searchUsersForAssetProfilesSuccess(responseData))
  } catch (err) {
    yield put(ACTIONS.searchUsersForAssetProfilesError(err))
  }
}

function* addEditorToAssetProfile(action) {
  try {
    const { userId, profileId } = action
    const body = {
      userId: userId,
      profileId: profileId
    }
    const responseData = yield call(
      request,
      'profiles/addEditor',
      'POST',
      body,
      true
    )
    console.log(responseData) // can responseData return the user's profile? image, username, id, etc
  } catch (err) {
    yield put(ACTIONS.addEditorToAssetProfileError(err))
  }
}

function* publishTokenTemplate(action) {
  try {
    const body = {
      _id: action.id
    }
    const responseData = yield call(
      request,
      '/admin/templates/publish',
      'POST',
      body
    )

    yield put(ACTIONS.getTokenTemplateToEditRequest(action.id))
    yield put(ACTIONS.publishTokenTemplateSuccess())
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.publishTokenTemplateError(err))
  }
}

function* assetProfileUploadFile(action) {
  try {
    const { fileData, formData, fieldIndex, sectionIndex, subfieldIndex, objectSubfieldIndex } = action
    let newFileData = new FormData()
    newFileData.append('file', fileData)

    const responseData = yield call(
      request,
      '/file/uploadFile',
      'POST',
      newFileData,
      false,
      true
    )

    const fileUrl = responseData.url

    if (objectSubfieldIndex >= 0) {
      formData.sections[sectionIndex].fields[fieldIndex].subfields[subfieldIndex].subfields[objectSubfieldIndex].value = fileUrl
    } else if (subfieldIndex >= 0) {
      formData.sections[sectionIndex].fields[fieldIndex].subfields[subfieldIndex].value = fileUrl
    } else {
      formData.sections[sectionIndex].fields[fieldIndex].value = fileUrl
    }

    yield put(ACTIONS.editTokenFormRequest(formData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.assetProfileUploadFileError(err))
  }
}

function* assetProfileDeleteFile(action) {
  try {
    const { url, fieldIndex, formData, sectionIndex, subfieldIndex, objectSubfieldIndex } = action
    const body = {
      url: url
    }
    const responseData = yield call(
      request,
      '/file/deleteFile',
      'POST',
      body
    )

    if (objectSubfieldIndex >= 0) {
      formData.sections[sectionIndex].fields[fieldIndex].subfields[subfieldIndex].subfields[objectSubfieldIndex].value = ''
    } else if (subfieldIndex >= 0) {
      formData.sections[sectionIndex].fields[fieldIndex].subfields[subfieldIndex].value = ''
    } else {
      formData.sections[sectionIndex].fields[fieldIndex].value = ''
    }

    yield put(ACTIONS.editTokenFormRequest(formData))
  } catch (err) {
    toast.error(err.reason)
    if (err.reason == 'File not found') {
      const { url, fieldIndex, formData, sectionIndex, subfieldIndex, objectSubfieldIndex } = action

      if (objectSubfieldIndex >= 0) {
        formData.sections[sectionIndex].fields[fieldIndex].subfields[subfieldIndex].subfields[objectSubfieldIndex].value = ''
      } else if (subfieldIndex >= 0) {
        formData.sections[sectionIndex].fields[fieldIndex].subfields[subfieldIndex].value = ''
      } else {
        formData.sections[sectionIndex].fields[fieldIndex].value = ''
      }

      yield put(ACTIONS.editTokenFormRequest(formData))
    }

    yield put(ACTIONS.assetProfileDeleteFileError(err))
  }
}

function* uploadAssetProfileImage(action) {
  try {
    let newFileData = new FormData()
    newFileData.append('file', action.imageData)

    const responseData = yield call(
      request,
      '/file/uploadFile',
      'POST',
      newFileData,
      false,
      true
    )

    const fileUrl = responseData.url
    let newFormData = action.formData
    newFormData.image = fileUrl
    yield put(ACTIONS.editTokenFormRequest(newFormData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.uploadAssetProfileImageError(err))
  }
}

function* deleteAssetProfileImage(action) {
  try {
    const body = {
      url: action.url
    }
    const responseData = yield call(
      request,
      '/file/deleteFile',
      'POST',
      body
    )

    let newFormData = action.formData
    newFormData.image = ''
    yield put(ACTIONS.editTokenFormRequest(newFormData))
  } catch (err) {
    toast.error(err.reason)
    if (err.reason == 'File not found') {
      const { url, fieldIndex, formData, sectionIndex } = action
      formData.sections[sectionIndex].fields[fieldIndex].value = ''
      yield put(ACTIONS.editTokenFormRequest(formData))
    }

    yield put(ACTIONS.assetProfileDeleteFileError(err))
  }
}

function* userViewAsset(action) {
  try {
    const responseData = yield call(
      request,
      `/profiles/single?profileName=${action.id}`,
      'GET'
    )

    yield put(TOKENLIST_ACTIONS.getTokenSpecificNewsRequest(responseData.name))
    yield put(RESEARCH_ACTIONS.getAssetResearchRequest(responseData.name))
    yield put(RESEARCH_ACTIONS.getAssetPricingDataRequest(responseData.symbol))
    yield put(ACTIONS.userViewAssetSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.userViewAssetError(err))
  }
}

function* getLinksForAdminTopbar(action) {
  try {
    const responseData = yield call(
      request,
      '/links',
      'GET'
    )

    yield put(ACTIONS.getLinksForAdminTopbarSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.getLinksForAdminTopbarError(err))
  }
}

function* updateAssetProfileOffering(action) {
  try {
    const body = {
      _id: action.id,
      offering: JSON.stringify(action.offering)
    }

    const responseData = yield call(
      request,
      '/admin/profile/updateOffering',
      'POST',
      body
    )

    yield put(ACTIONS.updateAssetProfileOfferingSuccess(responseData))
  } catch (err) {
    toast.error(err.reason)
    yield put(ACTIONS.updateAssetProfileOfferingError(err))
  }
}

function* uploadOfferingDocument(action) {
  try {
    let newFileData = new FormData()
    newFileData.append('file', action.file)

    const responseData = yield call(
      request,
      '/file/uploadFile',
      'POST',
      newFileData,
      false,
      true
    )

    const fileUrl = responseData.url
    let newFormData = action.formData

    let newOffering = action.formData.offering
    newOffering[action.location] = fileUrl
    yield put(ACTIONS.updateAssetProfileOfferingRequest(newFormData._id, newOffering))

    newFormData.offering = newOffering
    yield put(ACTIONS.editTokenFormRequest(newFormData))
  } catch (err) {
    toast.error(err.reason)
    // yield put(ACTIONS.uploadAssetProfileImageError(err))
  }
}

function* clearOfferingDocument(action) {
  try {
    const body = {
      url: action.url
    }
    const responseData = yield call(
      request,
      '/file/deleteFile',
      'POST',
      body
    )

    let newFormData = action.formData
    newFormData.offering[action.location] = ''
    yield put(ACTIONS.updateAssetProfileOfferingRequest(newFormData._id, newFormData.offering))
    yield put(ACTIONS.editTokenFormRequest(newFormData))
  } catch (err) {
    toast.error(err.reason)
    if (err.reason == 'File not found') {
      let newFormData = action.formData
      newFormData.offering[action.location] = ''
      yield put(ACTIONS.updateAssetProfileOfferingRequest(newFormData._id, newFormData.offering))
      yield put(ACTIONS.editTokenFormRequest(newFormData))
    }
  }
}

export default function* tokenFormSaga() {
  yield takeLatest(CONSTANTS.EQUITY_TOKEN_FORM_REQUEST, equityToken)
  yield takeLatest(CONSTANTS.DEBT_SECURITY_TOKEN_FORM_REQUEST, debtSecurityToken);
  yield takeLatest(CONSTANTS.REAL_ESTATE_TOKEN_FORM_REQUEST, realEstateToken);
  yield takeLatest(CONSTANTS.FUND_SECURITY_FORM_REQUEST, fundSecurityToken);
  yield takeLatest(CONSTANTS.GET_TOKEN_REQUEST, getToken);
  yield takeLatest(CONSTANTS.GET_TOKEN_EDIT_REQUEST, getTokenEdit);
  yield takeLatest(CONSTANTS.EDIT_TOKEN_REQUEST, editToken);
  yield takeLatest(CONSTANTS.EDIT_DEBT_TOKEN_REQUEST, editDebtToken)
  yield takeLatest(CONSTANTS.EDIT_REAL_ESTATE_TOKEN_REQUEST, editRealEstateToken);
  yield takeLatest(CONSTANTS.EDIT_FUND_SECURITY_REQUEST, editFundToken);
  yield takeLatest(CONSTANTS.UPLOAD_IMAGE_REQUEST, uploadImage);
  yield takeLatest(CONSTANTS.UPLOAD_DOCUMENT_REQUEST, uploadDocument);
  yield takeLatest(CONSTANTS.CREATE_TOKEN_REQUEST, createToken)
  yield takeLatest(CONSTANTS.SUBMIT_LISTING_REQUEST, submitListing)
  yield takeLatest(CONSTANTS.GET_LISTING_REQUESTS_REQUEST, getListingRequests)
  yield takeLatest(CONSTANTS.GET_TOKEN_TEMPLATES_REQUEST, getTokenTemplates)
  yield takeLatest(CONSTANTS.GET_TOKEN_TEMPLATE_TO_EDIT_REQUEST, getTokenTemplateToEdit)
  yield takeLatest(CONSTANTS.CREATE_TOKEN_TEMPLATE_REQUEST, createTokenTemplate)
  yield takeLatest(CONSTANTS.EDIT_TOKEN_TEMPLATE_REQUEST, editTokenTemplate)
  yield takeLatest(CONSTANTS.DELETE_TOKEN_TEMPLATE_REQUEST, deleteTokenTemplate)
  yield takeLatest(CONSTANTS.GET_PUBLISHED_TEMPLATES_REQUEST, getPublishedTemplates)
  yield takeLatest(CONSTANTS.CREATE_TOKEN_FORM_FROM_TEMPLATE_REQUEST, createTokenFormFromTemplate)
  yield takeLatest(CONSTANTS.UPLOAD_TOKEN_FORM_IMAGE_REQUEST, uploadTokenFormImage)
  yield takeLatest(CONSTANTS.UPLOAD_TOKEN_FORM_DOCUMENT_REQUEST, uploadTokenFormDocument)
  yield takeLatest(CONSTANTS.EDIT_TOKEN_FORM_REQUEST, editTokenForm)
  yield takeLatest(CONSTANTS.GET_TOKEN_PROFILES_LIST_REQUEST, getTokenProfilesList)
  yield takeLatest(CONSTANTS.SELECT_ASSET_PROFILE_REQUEST, selectAssetProfile)
  yield takeLatest(CONSTANTS.PUBLISH_ASSET_PROFILE_REQUEST, publishAssetProfile)
  yield takeLatest(CONSTANTS.SEARCH_ASSET_PROFILES_REQUEST, searchAssetProfiles)
  yield takeLatest(CONSTANTS.DELETE_ASSET_PROFILE_REQUEST, deleteAssetProfile)
  yield takeLatest(CONSTANTS.UNPUBLISH_TOKEN_TEMPLATE_REQUEST, unpublishTokenTemplate)
  yield takeLatest(CONSTANTS.UNPUBLISH_ASSET_PROFILE_REQUEST, unpublishAssetProfile)
  yield takeLatest(CONSTANTS.CREATE_ASSET_PROFILE_REQUEST, createAssetProfile)
  yield takeLatest(CONSTANTS.SUBMIT_ASSET_PROFILE_FOR_VERIFICATION_REQUEST, submitAssetProfileForVerification)
  yield takeLatest(CONSTANTS.GET_ASSET_PROFILE_EDITORS_REQUEST, getAssetProfileEditors)
  yield takeLatest(CONSTANTS.SEARCH_USERS_FOR_ASSET_PROFILES_REQUEST, searchUsersForAssetProfiles)
  yield takeLatest(CONSTANTS.ADD_EDITOR_TO_ASSET_PROFILE_REQUEST, addEditorToAssetProfile)
  yield takeLatest(CONSTANTS.PUBLISH_TOKEN_TEMPLATE_REQUEST, publishTokenTemplate)
  yield takeLatest(CONSTANTS.ASSET_PROFILE_UPLOAD_FILE_REQUEST, assetProfileUploadFile)
  yield takeLatest(CONSTANTS.ASSET_PROFILE_DELETE_FILE_REQUEST, assetProfileDeleteFile)
  yield takeLatest(CONSTANTS.UPLOAD_ASSET_PROFILE_IMAGE_REQUEST, uploadAssetProfileImage)
  yield takeLatest(CONSTANTS.DELETE_ASSET_PROFILE_IMAGE_REQUEST, deleteAssetProfileImage)
  yield takeLatest(CONSTANTS.USER_VIEW_ASSET_REQUEST, userViewAsset)
  yield takeLatest(CONSTANTS.GET_LINKS_FOR_ADMIN_TOPBAR_REQUEST, getLinksForAdminTopbar)
  yield takeLatest(CONSTANTS.UPDATE_ASSET_PROFILE_OFFERING_REQUEST, updateAssetProfileOffering)
  yield takeLatest(CONSTANTS.UPLOAD_OFFERING_DOCUMENT_REQUEST, uploadOfferingDocument)
  yield takeLatest(CONSTANTS.CLEAR_OFFERING_DOCUMENT, clearOfferingDocument)
}
