import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { GoPencil } from 'react-icons/go';
import { MdRemoveShoppingCart } from 'react-icons/md';
import ReactHtmlParser from 'react-html-parser';
import he from 'he';
import { history } from '../../../configureStore';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom'

class TokenList extends Component {
  componentDidMount() {
    this.props.getTokenListRequest();
  }

  removeToken = id => {
    this.props.deleteTokenRequest(id);
  };

  editToken = (name) => {
    this.props.history.push({
      pathname: '/admin/tokenform',
      search: `?name=${name}`,
    })
  };

  tokenDetailPage = (name, id) => {
    history.push({
      pathname: `/admin/${name}`,
      search: `?id=${id}`,
    });
  };

  renderTokenItem = token => {
    const {
      id,
      image,
      name,
      description,
      industry,
      issuance_type,
    } = token;

    return (
      <Row key={id} className="token-item">
          <Col sm={3} className="logo-image">
              <Image src={image} className="token-image" />
          </Col>
          <Col sm={7} className="token-info">
              <h5
                  className="token-name-link"
                  onClick={() => {
                      this.tokenDetailPage(name, id);
                  }}
              >
                  {name}
              </h5>
              {/* <p>{ReactHtmlParser(he.decode(description))}</p> */}
              <span>
                  {industry} | {issuance_type}
              </span>
          </Col>
          <Col sm={2} className="token-info">
              <GoPencil
                  className="edit-token"
                  onClick={() => {
                      this.editToken(name);
                  }}
              />
              &nbsp; &nbsp;
              <MdRemoveShoppingCart
                  className="remove-token"
                  onClick={() => {
                      this.removeToken(id);
                  }}
              />
          </Col>
      </Row>
    );
  };

  render() {
    const { tokenList } = this.props;
    if (!tokenList) {
      return (
        <React.Fragment>
            <div className="loading-bar">
                <ReactLoading
                    type={'spin'}
                    color={'#5790c8'}
                    height={100}
                    width={100}
                />
            </div>
        </React.Fragment>
      );
    }

    return (
      <div className="admin-portal-content">
          <h1 className="form-header">TOKEN LIST</h1>
          <div className="form-content">
              {tokenList.Items.map(token => this.renderTokenItem(token))}
          </div>
      </div>
    );
  }
}

export default withRouter(TokenList);
