import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
} from '../constants/signup';

export const signupRequest = (email, password, cpassword, username) => {
  return {
    type: SIGNUP_REQUEST,
    email,
    password,
    cpassword,
    username,
  };
};

export const signupSuccess = accessData => ({
  type: SIGNUP_SUCCESS,
  payload: accessData.data,
});

export const signupError = err => ({
  type: SIGNUP_ERROR,
  payload: err,
});
