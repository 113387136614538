import { connect } from "react-redux";
import FeaturedContributors from "./FeaturedContributors";
import {
  getFeaturedContributorsReqeust,
  removeFeaturedContributorsReqeust
} from "../../../redux/actions/contributor";
const mapStateToProps = state => ({
  featuredContributors: state.contributors.featuredContributors
});
const mapDispatchToProps = {
  getFeaturedContributorsReqeust,
  removeFeaturedContributorsReqeust
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeaturedContributors);
