import { connect } from 'react-redux';
import Choropleth from './Choropleth';
import './Choropleth.css';
import {
    getChoroplethDataRequest,
} from '../../redux/actions/charts';

const mapStateToProps = state => ({
    // choroplethData: state.charts.choroplethData,
})

const mapDispatchToProps = {
    getChoroplethDataRequest,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Choropleth)
