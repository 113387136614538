export const RESEARCH_FORM_REQUEST = 'RESEARCH_FORM_REQUEST';
export const RESEARCH_FORM_SUCCESS = 'RESEARCH_FORM_SUCCESS';
export const RESEARCH_FORM_ERROR = 'RESEARCH_FORM_ERROR';

export const GET_REPORTS_REQUEST = 'GET_REPORTS_REQUEST';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_ERROR = 'GET_REPORTS_ERROR';

export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR';

export const DELETE_REPORT_REQUEST = 'DELETE_REPORT_REQUEST';
export const SET_RESET_REPORT = 'SET_RESET_REPORT';

export const EDIT_REPORT_REQUEST = 'EDIT_REPORT_REQUEST';
