import { connect } from "react-redux"
import ForgotPassword from './ForgotPassword'
import { forgotPasswordRequest, forgotPasswordReset } from '../../redux/actions/forgotpassword'

const mapStateToProps = state => ({
  requestSent: state.forgot.requestSent,
})

const mapDispatchToProps = {
  forgotPasswordRequest,
  forgotPasswordReset,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
