import { connect } from 'react-redux';
import BubbleChart from './BubbleChart';
import './BubbleChart.css';
// import {
//     getBubbleChartDataRequest,
// } from '../../redux/actions/charts';

const mapStateToProps = state => ({
    // choroplethData: state.charts.choroplethData,
})

export default connect(
    mapStateToProps,
)(BubbleChart)
