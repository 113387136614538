import ReactGA from 'react-ga'

export const initGA = () => {

  if(process.env.NODE_ENV === "production"){
    console.log("init GA")
    ReactGA.initialize('UA-145152432-1')
  }
}
export const logPageView = () => {
  if(process.env.NODE_ENV === "production"){
    console.log("in logPageView : "+window.location.pathname)
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
  }
}

export const trackEvent = (eventCategory, eventAction, eventLabel) => {
  if(process.env.NODE_ENV === "production"){
    console.log("in track event ")
    // ReactGA.ga('send', hitType, eventCategory, eventAction, eventLabel, eventValue, nonInteraction)
    ReactGA.event({category: eventCategory, action: eventAction, label: eventLabel})
  }
}
