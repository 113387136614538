import { connect } from 'react-redux';
import Contributor from './Contributor';
import { getMyAccountRequest } from '../../../redux/actions/account';
import {
  submitContributorRequest,
  getContributorRequest,
  contributorApproveReqeust,
  resetContributorForm,
} from '../../../redux/actions/contributor';

const mapStateToProps = state => ({
  accountInfo: state.account.myAccount.info,
  contributor: state.contributors.contributor,
});

const mapDispatchToProps = {
  getMyAccountRequest,
  resetContributorForm,
  getContributorRequest,
  contributorApproveReqeust,
  submitContributorRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Contributor);
