import React, { PureComponent } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  FormControl
} from 'react-bootstrap'

class WebsiteConfig extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      websiteConfig: this.props.websiteConfig,
    }
  }

  componentDidMount() {
  }

    componentWillReceiveProps(nextProps) {
      if (nextProps !== this.props) {
        this.setState({
          websiteConfig: nextProps.websiteConfig
        })
      }
    }

  onChangeWebsiteConfig = e => {
    const body = { [e.target.name]: e.target.value }
    this.setState({
      websiteConfig: {
        ...this.state.websiteConfig,
        data: {
          ...this.state.websiteConfig.data,
          [e.target.name]: e.target.value
        }
      }
    }, e => this.props.updateWebsiteConfigRequest(body))
  }


  render() {
    return (
      <Container fluid className='admin-content-container'>
        <Row noGutters className='forms-header'>
          <h4>Website Configuration</h4>
        </Row>

        <div className='forms-table-box'>
          <Row noGutters className='center-aligned'>
            <label className='website-config-label'>
              Profile Preview:
            </label>
            <FormControl
              size='sm'
              name='profile_preview'
              as='select'
              value={this.props.websiteConfig.data.profile_preview}
              onChange={this.onChangeWebsiteConfig}
              className='submission-form-control contained-width'>
              <option value='screener'>Screener</option>
              <option value='tile'>Tile</option>
            </FormControl>
          </Row>
        </div>

      </Container>
    )
  }
}

export default WebsiteConfig
