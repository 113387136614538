import { connect } from 'react-redux';
import './ListingRequests.css'
import ListingRequests from './ListingRequests'
import { getListingRequestsRequest } from '../../../redux/actions/tokenform.js'

const mapStateToProps = state => ({
  listingRequests: state.token.listingRequests,
})

const mapDispatchToProps = {
  getListingRequestsRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ListingRequests)
