import React, { PureComponent } from 'react'
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  Card,
  Image
} from 'react-bootstrap'
import { NavLink } from "react-router-dom"
import logo from '../../images/HomePage/FI_LOGO.png'

class Reset extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      params: {
        password: '',
        confirmPassword: '',
        token: '',
      },
      email: '',
      noTokenRecognized: false,
      passwordSuccessfullyReset: this.props.passwordSuccessfullyReset,
    }
  }

  componentDidMount() {
    this.handleGetTokenAndEmailFromUrl()
    // this.props.resetReset()
    this.props.resetPasswordReset()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        passwordSuccessfullyReset: nextProps.passwordSuccessfullyReset
      })
    }
  }

  handleGetTokenAndEmailFromUrl = () => {
    const { search } = this.props.history.location

    if (search) {
      const splitUrl = search.split('&')
      const token = splitUrl[0].split('=')[1]
      const email = splitUrl[1].split('=')[1]

      this.setState({
        params: {
          ...this.state.params,
          token: token,
          email: email
        }
      })

    } else {
      this.props.history.push('/login')
    }
  }

  handleOnChange = e => {
    const { name, value } = e.target
    this.setState({
      params: {
        ...this.state.params,
        [name]: value
      }
    })
  }

  handleReset = () => {
    this.props.resetPasswordRequest(this.state.params)
  }

  renderReset = () => {
    const { password, confirmPassword } = this.state.params
    const disabled = (password && confirmPassword && (password === confirmPassword)) ? false : true

    return (
      <Row noGutters className='login-row'>
        <Col sm={8} md={6} lg={4} xl={4}>
          <Card className='login-box'>
            <Row noGutters>
              <Col className='login-content-col'>
                <Row noGutters className='login-header'>
                  <Image fluid src={logo} className='login-logo' />
                </Row>

                <Row noGutters className='login-box-padding login-formcontrol-row'>
                  <Col className='login-box-col'>
                    <Row noGutters className='login-welcome'>
                      <h5>Please enter a new password</h5>
                    </Row>

                    <Row noGutters className='login-formcontrol-container'>
                      <div className='login-formcontrol-box'>
                        <div className='login-formcontrol-text'>
                          Password
                        </div>
                        <FormControl
                          size='sm'
                          name='password'
                          type='password'
                          value={password}
                          onChange={this.handleOnChange}
                          className='login-formcontrol' />
                      </div>
                    </Row>

                    <Row noGutters className='login-formcontrol-container'>
                      <div className='login-formcontrol-box'>
                        <div className='login-formcontrol-text'>
                          Confirm Password
                        </div>
                        <FormControl
                          size='sm'
                          name='confirmPassword'
                          type='password'
                          value={confirmPassword}
                          onChange={this.handleOnChange}
                          className='login-formcontrol' />
                      </div>
                    </Row>

                    <Row noGutters className='login-button-row'>
                      <Button
                        size='sm'
                        disabled={disabled}
                        onClick={this.handleReset}
                        className='teal-button'>
                        RESET
                      </Button>
                    </Row>

                    <div className='login-navlinks'>
                      <NavLink to='/login'>Log in</NavLink>
                      <NavLink to='/register'>Create an Account</NavLink>
                    </div>

                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }

  renderPasswordSuccessfullyReset = () => {
    return (
      <Row noGutters className='login-row'>
        <Col sm={8} md={6} lg={4} xl={4}>
          <Card className='login-box'>
            <Row noGutters>
              <Col className='login-content-col'>
                <Row noGutters className='login-header'>
                  <Image fluid src={logo} className='login-logo' />
                </Row>

                <Row noGutters className='login-box-padding login-formcontrol-row'>
                  <Col className='login-box-col'>
                    <Row noGutters className='login-welcome'>
                      <h5>Your password has been successfully reset.</h5>
                    </Row>

                    <div className='login-navlinks'>
                      <NavLink to='/login'>Log in</NavLink>
                    </div>

                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }

  render() {
    let content
    if (this.state.passwordSuccessfullyReset) {
      content = this.renderPasswordSuccessfullyReset()
    } else {
      content = this.renderReset()
    }
    return (
      <Container fluid className='login-container'>
        {content}
      </Container>
    )
  }
}

export default Reset
