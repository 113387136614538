import React, { Component } from 'react';
import queryString from 'query-string';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ReactQuill from 'react-quill';
import { IoIosAddCircle, IoMdRemoveCircle } from 'react-icons/io';
import { v4, v1 } from 'uuid';

class ResearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      description: '',
      content: '',
      theme: 'snow',
      tag_keys: [v1('tags')],
      tags: [''],
      category_keys: [v4('category')],
      categories: [''],
      is_edit: false,
    };
  }

  componentDidMount() {
    const { search } = window.location;
    if (search) {
      const idObj = queryString.parse(search);
      this.props.getReportRequest(idObj.id);
    } else {
      this.props.setResetReport();
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      report: { item },
    } = nextProps;

    if (item && item.length) {
      const { id, categories, content, description, tags, title } = item[0];
      let categoryKeys = [];
      let tagKeys = [];

      if (categories.length > 0) {
        categories.forEach(category => {
          categoryKeys.push(v4(category));
        });
      }

      if (tags.length > 0) {
        tags.forEach(tag => {
          tagKeys.push(v1(tag));
        });
      }

      this.setState({
        id,
        title,
        description,
        content,
        tags: tags,
        tag_keys: tagKeys,
        categories: categories,
        category_keys: categoryKeys,
        is_edit: true,
      });
    }
  }

  handleFormChange = fieldName => e => {
    this.setState({
      [fieldName]: e.target.value,
    });
  };

  handleChange = fieldName => html => {
    this.setState({ [fieldName]: html });
  };

  saveReport = () => () => {
    const { id, title, description, content, tags, categories } = this.state;
    const formData = {
      id,
      title,
      description,
      content,
      tags,
      categories,
    };

    this.props.editReportRequest(formData);
  };

  addCategory = () => {
    const { categories, category_keys } = this.state;
    this.setState({
      categories: [...categories, ''],
      category_keys: [...category_keys, v4('category')],
    });
  };

  removeCategory = index => {
    this.setState({
      categories: this.state.categories.filter((_, i) => i !== index),
      category_keys: this.state.category_keys.filter((_, i) => i !== index),
    });
  };

  addTag = () => {
    const { tags, tag_keys } = this.state;
    this.setState({
      tags: [...tags, ''],
      category_keys: [...tag_keys, v1('tag')],
    });
  };

  handleCategoryChange = (e, index) => {
    const { categories } = this.state;
    const newCategoryArray = [...categories];

    newCategoryArray[index] = e.target.value;
    this.setState({ categories: newCategoryArray });
  };

  removeTag = index => {
    this.setState({
      tags: this.state.tags.filter((_, i) => i !== index),
      tag_keys: this.state.tag_keys.filter((_, i) => i !== index),
    });
  };

  handleTagChange = (e, index) => {
    const { tags } = this.state;
    const newTags = [...tags];
    newTags[index] = e.target.value;
    this.setState({ tags: newTags });
  };

  onSubmit = e => {
    e.preventDefault();

    const { title, description, content, tags, categories } = this.state;
    const formData = {
      title,
      description,
      content,
      tags,
      categories,
    };
    this.props.researchFormRequest(formData);
  };

  renderTokens = (tag, index) => {
    const { tag_keys } = this.state;

    return (
      <React.Fragment key={`tag-${tag_keys[index]}`}>
        <Form.Group as={Row} controlId="form-tag" className="every-team-info">
          <Form.Label column sm={2}>
            Token {index + 1}
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              placeholder="Token"
              value={tag}
              onChange={e => this.handleTagChange(e, index)}
            />
          </Col>
          <Col sm={1} className="text-center">
            <IoMdRemoveCircle
              style={{ fontSize: 20 }}
              onClick={() => {
                this.removeTag(index);
              }}
            />
          </Col>
        </Form.Group>
      </React.Fragment>
    );
  };

  renderCategories = (category, index) => {
    const { category_keys } = this.state;

    return (
      <React.Fragment key={`category-${category_keys[index]}`}>
        <Form.Group
          as={Row}
          controlId="form-category"
          className="every-team-info"
        >
          <Form.Label column sm={2}>
            Category {index + 1}
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              placeholder="Category"
              value={category}
              onChange={e => this.handleCategoryChange(e, index)}
            />
          </Col>
          <Col sm={1} className="text-center">
            <IoMdRemoveCircle
              style={{ fontSize: 20 }}
              onClick={() => {
                this.removeCategory(index);
              }}
            />
          </Col>
        </Form.Group>
      </React.Fragment>
    );
  };

  renderResearchTokenForm = () => {
    return (
      <Container>
        <Form className="pt-5" onSubmit={this.onSubmit}>
          <Form.Group as={Row} controlId="article-title">
            <Form.Label column sm={2} className="text-primary">
              Title your report:
            </Form.Label>
            <Col sm={12}>
              <Form.Control
                placeholder="Title"
                value={this.state.title}
                onChange={this.handleFormChange('title')}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="description">
            <Form.Label column className="text-primary">
              Briefly describe your research:
            </Form.Label>
            <Col sm={12}>
              <ReactQuill
                theme={this.state.theme}
                onChange={this.handleChange('description')}
                value={this.state.description}
                modules={ResearchForm.descriptionModule}
                formats={ResearchForm.descriptionFormat}
                placeholder="Write Description"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="categories">
            <Form.Label column className="text-primary">
              Label your research with categories so users can easily find your
              work:
            </Form.Label>
            <Col sm={12}>
              {this.state.categories.map((category, index) =>
                this.renderCategories(category, index),
              )}
            </Col>
          </Form.Group>
          <Col
            sm={{ span: 8, offset: 2 }}
            style={{ marginBottom: 20, fontSize: 20 }}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="tooltip-country">Add Categories</Tooltip>}
            >
              <IoIosAddCircle
                onClick={() => {
                  this.addCategory();
                }}
              />
            </OverlayTrigger>
          </Col>

          <Form.Group as={Row} controlId="tokentags">
            <Form.Label column className="text-primary">
              If you will be researching a particular token or tokens please
              list them here:
            </Form.Label>
            <Col sm={12}>{this.state.tags.map(this.renderTokens)}</Col>
          </Form.Group>
          <Col
            sm={{ span: 8, offset: 2 }}
            style={{ marginBottom: 20, fontSize: 20 }}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="tooltip-country">Add New Token</Tooltip>}
            >
              <IoIosAddCircle
                onClick={() => {
                  this.addTag();
                }}
              />
            </OverlayTrigger>
          </Col>

          <Form.Group as={Row} controlId="content">
            <Form.Label column className="text-primary">
              Write your article:
            </Form.Label>
            <Col sm={12}>
              <ReactQuill
                theme={this.state.theme}
                onChange={this.handleChange('content')}
                value={this.state.content}
                modules={ResearchForm.modules}
                formats={ResearchForm.formats}
                placeholder="Write Content"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={{ span: 6, offset: 4 }}>
              {!this.state.is_edit ? (
                <Button type="submit">Save</Button>
              ) : (
                <Button onClick={this.saveReport()}>Save</Button>
              )}
            </Col>
          </Form.Group>
        </Form>
      </Container>
    );
  };

  render() {
    return (
      <div className="admin-portal-content">
        <h1 className="form-header">Research Form</h1>
        {this.renderResearchTokenForm()}
      </div>
    );
  }
}

export default ResearchForm;
