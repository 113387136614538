import {
  TOKEN_SEARCH_REQUEST,
  TOKEN_SEARCH_SUCCESS,
  TOKEN_SEARCH_ERROR,
  RESEARCH_FEED_REQUEST,
  RESEARCH_FEED_SUCCESS,
  RESEARCH_FEED_ERROR
} from "../constants/landingPage";

export const tokenSearchRequest = q => ({
  type: TOKEN_SEARCH_REQUEST,
  q
});

export const tokenSearchSuccess = responseData => ({
  type: TOKEN_SEARCH_SUCCESS,
  tokenList: responseData
});

export const tokenSearchError = error => ({
  type: TOKEN_SEARCH_ERROR,
  error
});

export const researchFeedRequest = () => ({
  type: RESEARCH_FEED_REQUEST
});

export const researchFeedSuccess = responseData => ({
  type: RESEARCH_FEED_SUCCESS,
  researchFeedList: responseData
});

export const researchFeedError = error => ({
  type: RESEARCH_FEED_ERROR,
  error
});
