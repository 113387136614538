import React, { Component } from 'react'
import {
  Container,
  Button,
  Row,
  Col,
  Image,
  FormControl,
  Spinner,
  Modal
} from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import ReactLoading from 'react-loading'
import ReactHtmlParser from 'react-html-parser'
import he from 'he'
import { history } from '../../../configureStore'
import './CreateReport.css'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'
import { ImageDrop } from 'quill-image-drop-module'
import TagsInput from 'react-tagsinput'
import Autosuggest from 'react-autosuggest';
import { IoIosAdd } from 'react-icons/io'
import { MdCancel } from 'react-icons/md'
import defaultAvatar from '../../../images/default-avatar-image.png'

let timeout

class CreateReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentDraft: this.props.currentDraft,
      allDrafts: this.props.allDrafts,
      currentModal: 'false',
    }

    Quill.register('modules/imageDrop', ImageDrop)
    var bold = Quill.import('formats/bold')
    bold.tagName = 'b'
    Quill.register(bold, true)

    this.modules = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        ['link', 'image'],
        ['clean'],
      ],
      imageDrop: {},
      clipboard: {
        matchVisual: true,
      },
    }

    this.formats = [
        'font',
        'size',
        'bold', 'italic', 'underline',
        'blockquote', 'code-block',
        'indent',
        'link', 'image',
        'list', 'bullet',
        'align',
        'color', 'background'
    ]

    this.myImageInput = React.createRef()
    this.myDocumentInput = React.createRef()
  }

  componentDidMount() {
    this.props.getTopicsListRequest()
    this.props.createDraftClear()
    this.handleParseUrl()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        currentDraft: nextProps.currentDraft,
        allDrafts: nextProps.allDrafts,
      })
    }
  }

  handleParseUrl = () => {
    if (this.props.history.location.search) {
      const search = this.props.history.location.search
      let newSearch = search.split('?id=')
      this.props.getDraftRequest(newSearch[1])
    }
  }

  handleEditDraft = e => {
    const draft = this.state.currentDraft.data.docs[0]
    draft[e.target.name] = e.target.value

    this.handleSetStateAndSave()
  }

  handleSetStateAndSave = () => {
    this.setState({
      currentDraft: {
        ...this.state.currentDraft
      }
    }, () => this.props.saveDraftRequest(this.state.currentDraft.data))
  }

  handleRteChange = (content, delta, source, editor) => {
    const draft = this.state.currentDraft.data.docs[0]
    draft.body = editor.getHTML()

    this.handleSetStateAndSave()
  }

  handleChangeCategories = categories => {
    const draft = this.state.currentDraft.data.docs[0]
    draft.categories = categories

    this.handleSetStateAndSave()
  }

  handleAutoSuggestRenderInput = ({addTag, ...props}) => {
    if (this.props.topics) {
      if (this.props.topics.loaded) {
        const { list } = this.props.topics
        const handleOnChange = (e, {newValue, method}) => {
          if (method === 'enter') {
            e.preventDefault()
          } else {
            props.onChange(e)
          }
        }

        const inputValue = (props.value && props.value.trim().toLowerCase()) || ''
        const inputLength = inputValue.length

        let suggestions = list.filter((topic) => {
          return topic.topic.toLowerCase().slice(0, inputLength) === inputValue
        })

        return (
          <Autosuggest
            ref={props.ref}
            suggestions={suggestions}
            shouldRenderSuggestions={(value) => value && value.trim().length > 0}
            getSuggestionValue={(suggestion) => suggestion.topic}
            renderSuggestion={(suggestion) => <span>{suggestion.topic}</span>}
            inputProps={{...props, onChange: handleOnChange}}
            onSuggestionSelected={(e, {suggestion}) => {addTag(suggestion.topic)}}
            onSuggestionsClearRequested={() => {}}
            onSuggestionsFetchRequested={() => {}} />
        )
      }
    }
  }

  formatDate = dateInput => {
    let date = new Date(dateInput)
    const month = date.toLocaleString("en-us", { month: "short" })
    const day = date.getDate()
    // const year = date.getFullYear()
    return month + " " + day
  }

  handlePublish = () => {
    this.props.publishArticleRequest(this.state.currentDraft.data.docs[0])
  }

  handleImageSelect = e => {
    this.props.articleImageUploadRequest(e.target.files[0], this.state.currentDraft.data)
  }

  handleDocumentSelect = e => {
    this.props.uploadDocumentRequest(e.target.files[0], this.state.currentDraft.data)
  }

  handleHidePreview = () => {
    this.setState({
      currentModal: ''
    })
  }

  handleShowPreview = () => {
    this.setState({
      currentModal: 'preview'
    })
  }

  /****************************************************************************/

  renderForm = () => {
    const { data, loading, loaded } = this.state.currentDraft

    let content
    if (loaded) {
      const { title, summary, body, image } = data.docs[0]

      content = (
        <Col className='create-report-form-col'>
          {this.renderTopbar()}
          <Row className='rte-title-row'>
            <h1>
              <FormControl
                className='rte-content-title'
                name='title'
                value={title}
                onChange={this.handleEditDraft}
                placeholder='Title...'
                autoComplete='off' />
            </h1>
          </Row>
          <Row className='rte-summary-row'>
            <h2>
              <FormControl
                className='rte-content-summary'
                name='summary'
                value={summary}
                onChange={this.handleEditDraft}
                placeholder='Summary...'
                autoComplete='off' />
            </h2>
          </Row>
          <Row className='rte-quill-row'>
            <ReactQuill
              className='rte-quill'
              value={body || ''}
              onChange={this.handleRteChange}
              modules={this.modules}
              formats={this.formats}
              placeholder='Start writing your report...' />
          </Row>
        </Col>
      )
    } else if (loading) {

    }

    return content
  }

  renderImageUploader = () => {
    let imageUploader
    if (this.state.currentDraft.loaded) {
      const image = this.state.currentDraft.data.docs[0].image

      if (image) {
        imageUploader = (
          <div className='uploaded-image-container'>
            <Image className='uploaded-report-image' src={image} />
            <MdCancel className='md-cancel' onClick={this.handleClearImage}/>
          </div>
        )
      } else {
        imageUploader = (
          <Row noGutters className='create-report-sidebar-row'>
            <Col>
              <label className='create-report-sidebar-label'>
                Upload a cover image
              </label>
              <div onClick={(e) => this.myImageInput.click()}
                className='no-article-image'>
                Select an image
              </div>
              <input ref={(ref) => this.myImageInput = ref}
                style={{ display: 'none' }} type='file'
                onChange={this.handleImageSelect} />
            </Col>
          </Row>
        )
      }
    }

    return imageUploader
  }

  renderDocumentUploader = () => {
    let documentUploader

    if (this.state.currentDraft.loaded) {
      documentUploader = (
        <Row noGutters className='create-report-sidebar-row'>
          <Col>
            <label className='create-report-sidebar-label'>
              Import text from .doc or .docx file
            </label>
            <div onClick={(e) => this.myDocumentInput.click()}
              className='no-article-image'>
              Browse files
            </div>
            <input ref={(ref) => this.myDocumentInput = ref}
              style={{ display: 'none' }} type='file'
              onChange={this.handleDocumentSelect} />
          </Col>
        </Row>
      )
    }

    return documentUploader
  }

  renderSidebar = () => {
    return (
      <Col sm={2} className='create-report-sidebar-col'>
        {this.renderAddCategories()}
        {this.renderImageUploader()}
        {this.renderDocumentUploader()}
      </Col>
    )
  }

  renderAddCategories = () => {
    let content
    if (this.state.currentDraft.loaded) {
      const categories = this.state.currentDraft.data.docs[0].categories
      content = (
        <Row noGutters className='create-report-sidebar-row'>
          <Col>
            <label className='create-report-sidebar-label'>
              Add/change topics (up to 5)
            </label>
            <TagsInput
              value={categories || []}
              onChange={this.handleChangeCategories} />
          </Col>
        </Row>
      )
    }

    return content
  }

  renderTopbar = () => {
    return (
      <div className='create-report-topbar-container'>
        <Button
          onClick={this.handleSetStateAndSave}
          className='teal-button create-report-button' size='sm'>
          Save
        </Button>
        <Button
          onClick={this.handleShowPreview}
          className='teal-button create-report-button' size='sm'>
          Preview
        </Button>
      </div>
    )
  }

  renderCurrentDraft = () => {
    return (
      <Container fluid className='admin-create-report-container'>
        {this.renderPreview()}
        <Row noGutters>
          {this.renderForm()}
          {this.renderSidebar()}
        </Row>
      </Container>
    )
  }

  renderCreateDraft = () => {
    return (
      <Container className='admin-create-draft-container'>
        <Button className='rte-no-drafts-button'
          onClick={this.props.createDraftRequest}>
          <IoIosAdd className='rte-no-drafts-add-icon' />
          Create a Draft
        </Button>
      </Container>
    )
  }

  renderPreview = () => {
    if (this.state.currentDraft.loaded) {
      const show = this.state.currentModal == 'preview' ? true : false
      const { title, summary, body } = this.state.currentDraft.data.docs[0]
      const { first_name, last_name, image } = this.props.account.data
      const displayName = `${first_name} ${last_name}`
      let datePublished = this.formatDate(new Date())
      return (
        <Modal
          centered
          size='lg'
          onHide={this.handleHidePreview}
          show={show}
          className='admin-modal preview-article-modal'>
          <Modal.Header closeButton>
            <Row noGutters className='preview-article-modal-header'>
              Preview
              <Button
                onClick={this.handlePublish}
                className='teal-button' size='sm'>
                Publish
              </Button>
            </Row>
          </Modal.Header>
          <Modal.Body>
            <div className='show-article-container'>
              <Row className='show-article-top-row'>
                <Col className='show-article-info-col'>
                  <Row className='show-article-title-row'>
                    <h1>{title || 'No Title'}</h1>
                  </Row>
                  <Row className='show-article-summary-row'>
                    <h2>{summary || 'No Summary'}</h2>
                  </Row>
                  <Row className='show-article-user-row'>
                    <Image className='show-article-user-image'
                      src={image || defaultAvatar} roundedCircle fluid />
                    <Col className='show-article-user-info-col'>
                      <Row className='show-article-name-row'>
                        {displayName}
                      </Row>
                      <Row className='show-article-date-row'>
                        {this.formatDate(datePublished)}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className='show-article-body-row'>
                {body ? ReactHtmlParser(he.decode(body)) : ''}
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      )
    }
  }

  renderLoading = () => {
    return (
      <Row noGutters className='create-report-loading-row'>
        <Spinner animation='border' variant='light' className='create-report-spinner' />
      </Row>
    )
  }

  render() {
    const { currentDraft, allDrafts } = this.state
    let content
    if (currentDraft.loading) {
      content = this.renderLoading()
    } else if (currentDraft.loaded) {
      content = this.renderCurrentDraft()
    } else {
      content = this.renderCreateDraft()
    }

    return content
  }
}

export default CreateReport
