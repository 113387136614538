import * as CONSTANTS from "../constants/discover";

export const getResearchDiscoverRequest = params => ({
  type: CONSTANTS.GET_RESEARCH_DISCOVER_REQUEST,
  params
})

export const getResearchDiscoverSuccess = data => ({
  type: CONSTANTS.GET_RESEARCH_DISCOVER_SUCCESS,
  data
})

export const getResearchDiscoverError = error => ({
  type: CONSTANTS.GET_RESEARCH_DISCOVER_ERROR,
  error
})
