import React, { Component } from "react"
import Avatar from "react-avatar-edit"
import jwt from "jsonwebtoken"
import { GoPencil } from "react-icons/go"
import { Button, Row, Col, Jumbotron, Form, Nav, Modal, FormControl, Container } from "react-bootstrap"
import { MdArrowForward } from "react-icons/md"
import { history } from "../../configureStore"
import AccountForm from "../AccountForm/AccountForm.js"
import { Link } from 'react-router-dom'
import defaultAvatarImage from '../../images/logo-hi-res.png'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FiClipboard, FiMinus, FiPlus } from 'react-icons/fi'
import { toast } from 'react-toastify';

class AccountSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userArray: [
        {name: '', email: ''},
      ],
      showReferAFriendModal: false,
      copyLink: '',
      preview: null,
      src: "",
      savedImage: false,
      showButton: false,
      file: {},
      edit: false,
      changeImage: false,
      accountInfo: {
        id: '',
        first: '',
        last: '',
        email: '',
        username: '',
        address: '',
        image: '',
      }
    }
  }

  componentDidMount() {
    const { id, first, last, email, username, address, userId } = this.props.accountInfo
    const { accountImage } = this.props
    this.setState({
      copyLink: userId ? `https://velocitychannel.io/register?source=${userId}` : '',
      accountInfo: {
        id: id ? id : '',
        first: first ? first : '',
        last: last ? last : '',
        email: email ? email : '',
        username: username ? username : '',
        address: address ? address : '',
        image: accountImage ? accountImage : '',
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      if (nextProps.accountImage !== this.state.accountImage) {
        const { id, first, last, email, username, address, userId } = nextProps.accountInfo
        const { accountImage } = nextProps
        this.setState({
          ...this.state,
          copyLink: userId ? `https://velocitychannel.io/register?source=${userId}` : '',
          accountInfo: {
            ...this.state.accountInfo,
            id: id ? id : '',
            first: first ? first : '',
            last: last ? last : '',
            email: email ? email : '',
            username: username ? username : '',
            address: address ? address : '',
            image: accountImage ? accountImage : '',
          }
        })
      }
    }
  }

  onBeforeFileLoad(e) {
    if(e.target.files[0].size > 1000000){
      alert("File is too big!")
      e.target.value = ""
    }
  }

  onClose = () => {
    this.setState({
      preview: null,
      savedImage: false,
      showButton: false,
      file: {},
    })
  }

  onCrop = preview => {
    this.setState({ showButton: true })
  }

  onFileLoad = file => {
    this.setState({ file: file })
  }

  onSaveImage = () => {
    const { file } = this.state
    if (file.name) {
      this.setState({
        ...this.state,
        savedImage: true,
        showButton: false,
        changeImage: false,
      })
      this.props.profileImageUploadRequest(file)
    } else {
      this.setState({
        ...this.state,
        showButton: false,
        changeImage: false,
      })
    }
  }

  handlePreviewImage = (previewImg) => {
     return previewImg ? this.state.preview : this.state.image
  }

  handleClickChangeImage = () => {
      this.setState({changeImage: !this.state.changeImage})
  } // end of handleClickChangeImage()

  handleOnImageError = e => {
      e.target.src = defaultAvatarImage
  } // end of handleOnImageError()

  handleFormChange = e => {
    const { name, value } = e.target
    this.setState({
      accountInfo: {
        ...this.state.accountInfo,
        [name]:  value,
      }
    })
  } // end of handleFormChange(e)

  saveEditedAccount = () => {
    const { accountInfo } = this.state
    this.props.editAccountRequest(accountInfo)
    this.props.getMyAccountRequest()
    this.handleEditForm()
  }

  goToAdminPage = () => {
    history.push("/admin")
  }

  handleEditForm = () => {
    this.props.getMyAccountRequest()
    this.setState({ edit: !this.state.edit })
  }

  handleHideModal = () => {
    this.setState({
      showReferAFriendModal: false,
    })
  }

  handleShowModal = () => {
    this.setState({
      showReferAFriendModal: true
    })
  }

  handleModifyName = (index, name) => {
    const newArray = this.state.userArray.slice()
    newArray[index].name = name.target.value
    this.setState({
      userArray: newArray
    })
  }

  handleModifyEmail = (index, email) => {
    const newArray = this.state.userArray.slice()
    newArray[index].email = email.target.value
    this.setState({
      userArray: newArray
    })
  }

  handleAddUser = () => {
    let { userArray } = this.state
    const userObject = {
      name: '',
      email: '',
    }
    userArray.push(userObject)
    this.setState({
      userArray: userArray
    })
  }

  handleRemoveUser = index => {
    let { userArray } = this.state
    userArray.splice(index, 1)
    this.setState({
      userArray: userArray
    })
  }

  handleSendInvites = () => {
    const { userArray } = this.state
    this.props.sendInvitesRequest(userArray)
    this.handleHideModal()
    this.handleClearUserArray()
  }

  handleClearUserArray = () => {
    this.setState({
      userArray: [
        {name: '', email: ''},
      ],
    })
  }

  renderAccountImage = () => {
    const { image } = this.props.accountInfo
    let testImage = image ? image : defaultAvatarImage

      return (
        <React.Fragment>
          <Row className='image-uploader-row'>
            <img className='uploaded-image' src={testImage} />
          </Row>
        </React.Fragment>
      )
  }

  renderAccountInfo = () => {
      const {
          first,
          last,
          username,
          email
      } = this.state.accountInfo
      let name
      if (first && last) {
        name = first + ' ' + last
      } else if (first && !last) {
        name = first
      } else if (!first && last) {
        name = last
      } else {
        name = 'No name listed'
      }


      return (
        <Row className='account-info'>
            <Col md={{ span: 4 }} className='image-uploader' id='account-image'>
                {this.renderAccountImage()}
            </Col>
            <Col sm={{ span: 8 }} className='my-account-info-column'>
                <Row className="my-account-info-top">
                    <Col sm={3}>Name</Col>
                    <Col sm={9} className='my-account-edit-form-control-col'>
                        {name}
                    </Col>
                </Row>
                <Row className="my-account-info-inner">
                    <Col sm={3}>User Name</Col>
                    <Col sm={9}>
                        {username}
                    </Col>
                </Row>
                <Row className="my-account-info-inner">
                    <Col sm={3}>Email</Col>
                    <Col sm={9}>
                        {email}
                    </Col>
                </Row>
                {this.renderContributorStatus()}
            </Col>
        </Row>
      )
  }

  renderContributorStatus = () => {
    const {
      accountInfo: {
        isContributor,
      }
    } = this.props

    if (isContributor === 'pending') {
      return (
        <Row className="my-account-info-inner">
            <Col sm={3}>Contributor Application Status</Col>
            <Col sm={9}>
                {isContributor}
            </Col>
        </Row>
      )
    } else if (isContributor === true) {
      return (
        <Row className="my-account-info-inner">
          <Col sm={3}>Contributor Application Status</Col>
          <Col sm={9}>
            You are currently a contributor
          </Col>
        </Row>
      )
    }
  } // end of renderContributorStatus()

  renderAdminLink = () => {
      return (
          <Link to='/admin' className='admin-link'>
              Click here to go to the Admin Page
              <MdArrowForward />
          </Link>
      )
  } // end of renderAdminlink()

  renderResearchManagerLink = () => {
      return (
          <Link to='/research/management' className='admin-link'>
              Click here to go to the Research Management Portal
              <MdArrowForward />
          </Link>
      )
  } // end of renderAdminlink()

  renderImageUploader = () => {
    const { changeImage } = this.state

    if (changeImage) {
        return (
            <React.Fragment>
                <Row className='image-uploader-row' id='account-image'>
                    <div className='avatar-container'>
                        <Avatar
                          className='avatar'
                          width={300}
                          height={300}
                          imageWidth={300}
                          onCrop={this.onCrop}
                          onClose={this.onClose}
                          src={this.state.accountInfo.image}
                          onFileLoad={this.onFileLoad}
                          onBeforeFileLoad={this.onBeforeFileLoad}
                        />
                    </div>
                </Row>
                <Row className='save-image-button-row'>
                    <Button className="save-image" onClick={this.onSaveImage}>
                        Confirm
                    </Button>
                </Row>
            </React.Fragment>
        )
    } else if (!changeImage) {
        return (
            <React.Fragment>
                <Row className='image-uploader-row' id='account-image'>
                    <div className='image-uploader-container'>
                        <img
                            className="uploaded-image"
                            src={this.state.accountInfo.image}
                            alt="Preview"
                            onError={this.handleOnImageError}
                        />
                        <div
                            className="image-overlay"
                            onClick={this.handleClickChangeImage}
                        >
                            <div className='image-overlay-text'>
                                Change Image?
                            </div>
                        </div>
                    </div>
                </Row>
            </React.Fragment>
        )
    }
  } // end of renderImageUploader()

  renderAccountForm = () => {
    const { first, last, email, username, address, image } = this.state.accountInfo
    return (
        <Form>
            <Row className='account-info'>
                <Col md={{ span: 4 }} className='image-uploader' id='account-image'>
                    {this.renderImageUploader()}
                </Col>
                <Col sm={{ span: 8 }} className='my-account-info-column'>
                    <Row className="my-account-info-top">
                        <Col sm={3}>Name</Col>
                        <Col sm={4} className='my-account-edit-form-control-col'>
                            <Form.Control
                                name="first"
                                onChange={this.handleFormChange}
                                value={first}
                                placeholder='First Name...'
                            />
                        </Col>
                        <Col sm={5}>
                            <Form.Control
                                name="last"
                                onChange={this.handleFormChange}
                                value={last}
                                placeholder='Last Name...'
                            />
                        </Col>
                    </Row>
                    <Row className="my-account-info-inner">
                        <Col sm={3}>User Name</Col>
                        <Col sm={9}>
                            <Form.Control
                                name="username"
                                onChange={this.handleFormChange}
                                value={username}
                            />
                        </Col>
                    </Row>
                    <Row className="my-account-info-inner">
                        <Col sm={3}>Email</Col>
                        <Col sm={9}>
                            <Form.Control
                                name="email"
                                onChange={this.handleFormChange}
                                value={email}
                            />
                        </Col>
                    </Row>
                    <Row className='account-form-button-row'>
                        <Button className="save-image" onClick={() => this.saveEditedAccount(this.state.accountInfo)}>
                            Save
                        </Button>{" "}
                    </Row>
                </Col>
            </Row>
        </Form>
    )
  }

  renderReferAFriendFormControl = index => {
    const { userArray } = this.state
    const name = userArray[index].name
    const email = userArray[index].email
    return (
      <Row className='no-padding' key={index}>
        <Col className='no-padding'>
          <Row className='no-padding refer-a-friend-control-row'>
            <Col className='no-padding' md={3}>
              <FormControl className='refer-a-friend-form-control' placeholder='Name...' value={name} onChange={e => this.handleModifyName(index, e)} />
            </Col>
            <Col md={1} />
            <Col className='no-padding' md={7}>
              <FormControl className='refer-a-friend-form-control' placeholder='Email Address...' value={email} onChange={e => this.handleModifyEmail(index, e)}  />
            </Col>
            <Col md={1} className='no-padding clipboard-col'>
              <FiMinus className='report-info-button refer-a-friend-remove-button' onClick={() => this.handleRemoveUser(index)}/>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  renderReferAFriendModal = () => {
    const { showReferAFriendModal, copyLink, userArray } = this.state
    return (
      <Modal className='refer-a-friend-modal' show={showReferAFriendModal} onHide={this.handleHideModal}>
        <Modal.Body>
          <Row className='no-padding'>
            Use this unique link to refer a friend to Velocity Channel.
          </Row>
          <Row className='no-padding copy-link-control-row'>
            <Col md={11} className='no-padding'>
              <FormControl className='copy-link-form-control' value={copyLink} />
            </Col>
            <Col md={1} className='no-padding clipboard-col'>
              <CopyToClipboard text={copyLink} onCopy={() => toast.info('Copied link to clipboard')}>
                <FiClipboard className='report-info-button' />
              </CopyToClipboard>
            </Col>
          </Row>
          <Row className='no-padding copy-link-control-row'>
            <p className='refer-a-friend-text-p'>Know anyone that would be interested in our content? Send them an invite to get them started:</p>
            <Col className='no-padding'>
              {userArray.map((user, index) => this.renderReferAFriendFormControl(index))}
              <Row className='no-padding refer-a-friend-modal-bottom-row'>
                <Col md={11} />
                <Col className='no-padding clipboard-col'>
                  <FiPlus className='report-info-button' onClick={this.handleAddUser}/>
                </Col>
              </Row>
              <Row className='no-padding center-justified-row'>
                <Button className='refer-a-friend-button' onClick={this.handleSendInvites}>
                  Send Invites
                </Button>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }

  render() {
      let decodedToken = jwt.decode(localStorage.token)
      const { savedImage, showButton } = this.state
      const { accountInfo } = this.props
      const { isAdmin } = accountInfo
      const { edit } = this.state

      return (
          <Container className='no-padding account-settings-container'>
            {this.renderReferAFriendModal()}
              <Row className="mt-5 pt-4 settings-body-section-bottom">
                  <Jumbotron className="p-0 py-4 search-space-section-news " />
                  <Col sm={{ span: 10, offset: 1 }} className="edit-profile-area">
                      {
                        !edit
                        ? this.renderAccountInfo()
                        : this.renderAccountForm()
                      }
                  </Col>
                  <Col style={{marginTop:"100px"}} >
                      <GoPencil
                          className="edit-account-button"
                          onClick={() => this.handleEditForm()}
                      />
                  </Col>
                  <Row className='refer-a-friend-row'>
                    <Button className='refer-a-friend-button' onClick={this.handleShowModal}>
                      Refer A Friend
                    </Button>
                  </Row>
                  <Row className='admin-link-row'>
                    { isAdmin ? this.renderResearchManagerLink() : null }
                    { isAdmin ? this.renderAdminLink() : null }
                  </Row>
              </Row>
          </Container>
    )
  }
}

export default AccountSettings
