import React, { Component } from 'react';
import queryString from 'query-string';
import Avatar from 'react-avatar-edit';
import Files from 'react-butterfiles';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead'
import countryList from 'react-select-country-list'
import { IoIosAddCircle, IoMdRemoveCircle } from 'react-icons/io';
import ReactQuill from 'react-quill';
import DatePicker from 'react-date-picker';
import { v4 } from 'uuid';

class RealEstateTokenForm extends Component {
  constructor(props) {
    super(props);

    // this is for the countryList module
    this.locationOptions = countryList().getLabels()

    this.state = {
        id: '',
        image: '',
        name: '',
        issuance_type: 'Real Estate', // must always be Real Estate
        token_ticker: '',
        brief: '',
        description: '',
        address: '',
        start_date: '', // will be a date field w/ YYY-MM-DDTHH:MM:SS.MMMZ format
        end_date: '', // will be a date field w/ YYY-MM-DDTHH:MM:SS.MMMZ format
        src: '',
        industry: 'Real Estate', // must always be Real Estate
        location: '',
        team: [{ name: '', link: '' }],
        team_keys: [v4('teams')],
        development_company: '',
        token_price: '', // corresponds to Price per token
        minimum_investment: '',
        deal_size: '',
        website_link: '',
        linkedin_link: '',
        facebook_link: '',
        medium_link: '',
        documents: [],
        development_plan: [],
        is_edit: false,
        errors: '',
        locationOptions: this.locationOptions,
    };
  }

  componentDidMount() {
    const { search } = window.location;
    if (search) {
      const idObj = queryString.parse(search);
      this.props.getTokenEditRequest(idObj.name);
    } else {
      this.props.resetTokenForm();
    }
    this.props.resetDocUrl();
  }

  componentWillReceiveProps(nextProps) {
    const { token, fieldType, docURL } = nextProps;

    if (!token && docURL && fieldType) {
      if (fieldType === 'real_estate_development_plan') {
        this.setState({ document_links: docURL });
      } else if (fieldType === 'real_estate_document_links') {
        this.setState({ document_links: docURL });
      }
    }

    if (token && Object.keys(token).length) {
          const { Items } = token;
          const {
            id,
            image
          } = Items[0];

          let teamsKeys = [];

          if (Items[0].team.length > 0) {
            Items[0].team.forEach(team => {
              teamsKeys.push(v4(team));
            });
          }

      this.setState({
        id: id,
        name: Items[0].name ? Items[0].name : '',
        token_ticker: Items[0].token_ticker ? Items[0].token_ticker : '',
        brief: Items[0].brief ? Items[0].brief : '',
        description: Items[0].description ? Items[0].description : '',
        address: Items[0].address ? Items[0].address : '',
        start_date: Items[0].start_date ? new Date(Items[0].start_date) : new Date(),
        end_date: Items[0].end_date ? new Date(Items[0].end_date) : new Date(),
        src: image,
        location: Items[0].location ? Items[0].location : '',
        team: Items[0].team ? Items[0].team : '',
        team_keys: Items[0].team.length > 0 ? teamsKeys : [v4('team')],
        development_company: Items[0].development_company ? Items[0].development_company : '',
        token_price: Items[0].token_price ? Items[0].token_price : '',
        minimum_investment: Items[0].minimum_investment ? Items[0].minimum_investment : '',
        deal_size: Items[0].deal_size ? Items[0].deal_size : '',
        website_link: Items[0].website_link ? Items[0].website_link : '',
        twitter_link: Items[0].twitter_link ? Items[0].twitter_link : '',
        linkedin_link: Items[0].linkedin_link ? Items[0].linkedin_link : '',
        facebook_link: Items[0].facebook_link ? Items[0].facebook_link : '',
        medium_link: Items[0].medium_link ? Items[0].medium_link : '',
        documents: Items[0].documents ? Items[0].documents : '',
        development_plan: Items[0].development_plan ? Items[0].development_plan : '',
        is_edit: true,
      });
    }
  }

  handleRteChange = (content, delta, source, editor) => {
      this.setState({
          description: editor.getHTML()
      })
  } // end of handleRteChange()

  handleNumericalFieldChange = e => {
      const { name, value } = e.target
      this.setState({ [name]:  value })
  } // end of handleFormChange(e)

  onChangeDate = fieldName => date => {
    this.setState({ [fieldName]: date });
  };

  handleFormChange = fieldName => e => {
    this.setState({
      [fieldName]: e.target.value,
    });
  };

  onFileLoad = file => {
    this.props.uploadImageRequest(file);
  };

  renderPropertyImageUploader = () => {
    return (
      <React.Fragment>
        <Avatar
          width={100}
          height={100}
          imageWidth={100}
          imageHeight={100}
          label="Choose the Image"
          labelStyle={{ fontSize: 10 }}
          minCropRadius={100}
          src={this.state.src}
          onFileLoad={this.onFileLoad}
        />
      </React.Fragment>
    );
  };

  saveEditedToken = () => {
    const { url, fieldType, docURL } = this.props;
    const {
      id,
      name,
      issuance_type,
      token_ticker,
      brief,
      description,
      start_date,
      end_date,
      industry,
      address,
      location,
      team,
      development_company,
      token_price,
      minimum_investment,
      deal_size,
      website_link,
      twitter_link,
      linkedin_link,
      facebook_link,
      medium_link,
      src,
      development_plan,
      documents,
    } = this.state;

    let imageInfo = url ? url : src
    let documentInfo =
      docURL && fieldType === 'real_estate_document_links' ? docURL : documents;
    const convertedNewTeam = JSON.stringify(team);

    this.props.editRealEstateTokenRequest(
        id,
        name,
        imageInfo,
        issuance_type,
        token_ticker,
        brief,
        description,
        start_date,
        end_date,
        industry,
        address,
        location,
        convertedNewTeam,
        development_company,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        development_plan,
        documentInfo,
    );
  };

  handleChange = fieldName => html => {
    this.setState({ [fieldName]: html });
  };

  handleNumericalChange = (event, fieldName) => {
      this.setState({ [fieldName]: event.target.value.replace(/\D/,'')})
  }

  handleIndustrySelect = (event) => {
    this.setState({ industry: event.target.value })
  }

  handleLocationSelect = (event) => {
    this.setState({ location: event[0] })
  }

  onChangeDate = fieldName => date => {
    this.setState({ [fieldName]: date });
  };

  onSubmit = e => {
    e.preventDefault();

    const {
        name,
        issuance_type,
        token_ticker,
        brief,
        description,
        address,
        start_date,
        end_date,
        industry,
        location,
        team,
        development_company,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
    } = this.state;

    const { url, fieldType, docURL } = this.props;
    let documentURL = fieldType === 'real_estate_document_links' ? docURL : '';

    const convertedNewTeam = JSON.stringify(team);

    this.props.getRealEstateTokenFormRequest(
        name,
        issuance_type,
        token_ticker,
        brief,
        description,
        address,
        start_date,
        end_date,
        industry,
        location,
        convertedNewTeam,
        development_company,
        token_price,
        minimum_investment,
        deal_size,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        url,
        documentURL
    );
  };

  uploadDocument = (file, field) => {
    if (field === 'development_plan') {
      this.setState({ development_plan: file[0].src.file });
      const fileInfo = file[0].src.file;
      this.props.uploadDocumentRequest(
        fileInfo,
        'real_estate_development_plan',
      );
    } else {
      this.setState({ documents: file[0].src.file });
      const fileInfo = file[0].src.file;
      this.props.uploadDocumentRequest(fileInfo, 'real_estate_document_links');
    }
  };

  renderDocumentUploader = field => (
    <Files
        multiple={false}
        maxSize="2mb"
        multipleMaxSize="10mb"
        multipleMaxCount={1}
        accept={[
            'application/pdf',
            'application/text',
            'text/rtf',
            'application/vnd.document+json',
            'application/vnd.marlin.drm.mdcf',
            'application/vnd.sealed.doc',
        ]}
        onSuccess={file => this.uploadDocument(file, field)}
        onError={errors => {
            this.setState({ errors });
        }}
    >
        {({ browseFiles, getDropZoneProps, getLabelProps }) => (
            <>
                <div {...getDropZoneProps({ className: 'myDropZone' })} />
                <div className="document-uploader" onClick={browseFiles}>
                    Select files...
                </div>
                {this.state.documents.name}
        </>
      )}
    </Files>
  );

  renderDevelopmentPlanUploader = field => (
    <Files
        multiple={false}
        maxSize="2mb"
        multipleMaxSize="10mb"
        multipleMaxCount={1}
        accept={[
            'application/pdf',
            'application/text',
            'text/rtf',
            'application/vnd.document+json',
            'application/vnd.marlin.drm.mdcf',
            'application/vnd.sealed.doc',
        ]}
        onSuccess={file => this.uploadDocument(file, field)}
        onError={errors => {
            this.setState({ errors });
        }}
    >
        {({ browseFiles, getDropZoneProps, getLabelProps }) => (
            <>
                <div {...getDropZoneProps({ className: 'myDropZone' })} />
                <div className="document-uploader" onClick={browseFiles}>
                    Select files...
                </div>
                {this.state.development_plan.name}
        </>
      )}
    </Files>
  );

  renderTokenForm = () => {
    const {
        name,
        token_ticker,
        brief,
        description,
        address,
        team,
        development_company,
        website_link,
        twitter_link,
        linkedin_link,
        facebook_link,
        medium_link,
        token_price,
        issuance_type,
        minimum_investment,
        deal_size,
        start_date,
        end_date,
        is_edit,
        locationOptions
    } = this.state;

    return (
      <Container className="form-content">
          {/* {console.log(this.props)} */}
          <Form onSubmit={this.onSubmit}>
              <Form.Group as={Row} controlId="formHorizontalTokenImage">
                  <Col md={2} className="form-item-title">
                      <p>Property Image:</p>
                  </Col>
                  <Col md={7}>{this.renderPropertyImageUploader()}</Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalTokenName">
                  <Form.Label column sm={2}>
                      Name
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Name"
                          onChange={this.handleFormChange('name')}
                          value={name}
                          required
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalOfferType">
                  <Form.Label column sm={2}>
                      Issuance Type
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Issuance Type"
                          value={issuance_type}
                          disabled
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalTokenName">
                  <Form.Label column sm={2}>
                      Token Ticker
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Token Ticker"
                          onChange={this.handleFormChange('token_ticker')}
                          value={token_ticker}
                          // required
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalTokenName">
                  <Form.Label column sm={2}>
                      Brief
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Brief"
                          onChange={this.handleFormChange('brief')}
                          value={brief}
                          required
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalDescription">
                  <Form.Label column sm={2}>
                      Description
                  </Form.Label>
                  <Col sm={8}>
                      <ReactQuill
                          theme={this.state.theme}
                          onChange={this.handleChange('description')}
                          value={description}
                          modules={RealEstateTokenForm.modules}
                          formats={RealEstateTokenForm.formats}
                          placeholder="Description"
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalTokenAddress">
                  <Form.Label column sm={2}>
                      Address
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Address"
                          onChange={this.handleFormChange('address')}
                          value={address}
                          required
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalAddress">
                  <Form.Label column sm={2}>
                      Location
                  </Form.Label>
                  <Col sm={8}>
                      <Typeahead
                          labelKey="value"
                          // multiple
                          options={locationOptions}
                          placeholder="Location"
                          onChange={(event) => this.handleLocationSelect(event)}
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalPropertyDate">
                  <Form.Label column sm={2}>
                      STO Start Date
                  </Form.Label>
                  <Col sm={8}>
                      <DatePicker onChange={this.onChangeDate('start_date')} value={start_date} />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalPropertyDate">
                  <Form.Label column sm={2}>
                      STO End Date
                  </Form.Label>
                  <Col sm={8}>
                      <DatePicker onChange={this.onChangeDate('end_date')} value={end_date} />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalDevelopmentCompany">
                  <Form.Label column sm={2}>
                      Development Company
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Development Company"
                          onChange={this.handleFormChange('development_company')}
                          value={development_company}
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalDevelopmentTeam">
                  <Form.Label column sm={2}>
                      Development Team
                  </Form.Label>
                  <Col sm={8}>
                      {team.map((team, index) =>
                          this.renderTeamInputForm(team, index),
                      )}
                  </Col>
              </Form.Group>
              <Col
                  sm={{ span: 8, offset: 2 }}
                  style={{ marginBottom: 20, fontSize: 20 }}
              >
                  <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="tooltip-teams">Add Team</Tooltip>}
                  >
                      <IoIosAddCircle
                          onClick={() => {
                              this.addTeams();
                          }}
                      />
                  </OverlayTrigger>
              </Col>

              <Form.Group as={Row} controlId="formHorizontalTokenPrice">
                  <Form.Label column sm={2}>
                      Price per Token
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Price per Token"
                          name='token_price'
                          onChange={this.handleNumericalFieldChange}
                          value={token_price}
                          type="number"
                          step='0.01'
                          min='0'
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalMinimumInvestment">
                  <Form.Label column sm={2}>
                      Minimum Investment
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Minimum Investment"
                          name='minimum_investment'
                          onChange={this.handleNumericalFieldChange}
                          value={minimum_investment}
                          type="number"
                          step='0.01'
                          min='0'
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalDealSize">
                  <Form.Label column sm={2}>
                      Deal Size
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Deal Size"
                          name='deal_size'
                          onChange={this.handleNumericalFieldChange}
                          value={deal_size}
                          type="number"
                          step='0.01'
                          min='0'
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalRelevantlinks">
                  <Form.Label column sm={2}>
                      Website Link
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Website"
                          onChange={this.handleFormChange('website_link')}
                          value={website_link}
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalTwitter">
                  <Form.Label column sm={2}>
                      Twitter Link
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Twitter Link"
                          onChange={this.handleFormChange('twitter_link')}
                          value={twitter_link}
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalLinkedIn">
                  <Form.Label column sm={2}>
                      Linkedin Link
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Linkedin Link"
                          onChange={this.handleFormChange('linkedin_link')}
                          value={linkedin_link}
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalFacebook">
                  <Form.Label column sm={2}>
                      Facebook Link
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Facebook Link"
                          onChange={this.handleFormChange('facebook_link')}
                          value={facebook_link}
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalMedium">
                  <Form.Label column sm={2}>
                      Medium Link
                  </Form.Label>
                  <Col sm={8}>
                      <Form.Control
                          placeholder="Medium Link"
                          onChange={this.handleFormChange('medium_link')}
                          value={medium_link}
                      />
                  </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalDocument">
                  <Form.Label column sm={2}>
                      Development Plan
                  </Form.Label>
                  <Col sm={8}>{this.renderDevelopmentPlanUploader('development_plan')}</Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalDocument">
                  <Form.Label column sm={2}>
                      Document Links
                  </Form.Label>
                  <Col sm={8}>{this.renderDocumentUploader('documents')}</Col>
              </Form.Group>

              <Form.Group as={Row}>
                  <Col sm={{ span: 10, offset: 2 }}>
                      {!is_edit ? (
                          <Button type="submit">Submit</Button>
                      ) : (
                          <Button
                              onClick={() => {
                                  this.saveEditedToken();
                              }}
                          >
                              Save
                          </Button>
                      )}
                  </Col>
              </Form.Group>
          </Form>
      </Container>
    );
  };

  renderTeamInputForm = (team, index) => {
    const { team_keys } = this.state;

    return (
      <React.Fragment key={`team-${team_keys[index]}`}>
          <Form.Group
              as={Row}
              controlId="formHorizontalDevelopmentTeams"
              className="every-team-info"
          >
              <Form.Label column sm={2} style={{ textAlign: 'center' }}>
                  Name:
              </Form.Label>
              <Col sm={3}>
                  <Form.Control
                      placeholder="Name"
                      onChange={this.handleTeamNameChange(index)}
                      value={team.name}
                  />
              </Col>
              <Form.Label column sm={2} style={{ textAlign: 'center' }}>
                  LinkedIn:
              </Form.Label>
              <Col sm={3}>
                  <Form.Control
                      placeholder="Link"
                      onChange={this.handleTeamLinkChange(index)}
                      value={team.link}
                  />
              </Col>
              <Col sm={2}>
                  <IoMdRemoveCircle
                      style={{ fontSize: 20 }}
                      onClick={() => {
                          this.removeTeam(index);
                      }}
                  />
              </Col>
          </Form.Group>
      </React.Fragment>
    );
  };

  renderTeamInputForm = (team, index) => {
    const { team_keys } = this.state;

    return (
      <React.Fragment key={`team-${team_keys[index]}`}>
          <Form.Group
              as={Row}
              controlId="formHorizontalCountries"
              className="every-team-info"
          >
              <Form.Label column sm={2} style={{ textAlign: 'center' }}>
                  Name:
              </Form.Label>
              <Col sm={3}>
                  <Form.Control
                      placeholder="Name"
                      onChange={e => this.handleTeamNameChange(e, index)}
                      value={team.name}
                  />
              </Col>
              <Form.Label column sm={2} style={{ textAlign: 'center' }}>
                  LinkedIn:
              </Form.Label>
              <Col sm={3}>
                  <Form.Control
                      placeholder="Linkedin"
                      onChange={e => this.handleTeamLinkChange(e, index)}
                      value={team.link}
                  />
              </Col>
              <Col sm={2}>
                  <IoMdRemoveCircle
                      style={{ fontSize: 20 }}
                      onClick={() => {
                          this.removeTeam(index);
                      }}
                  />
              </Col>
          </Form.Group>
      </React.Fragment>
    );
  };

  handleTeamNameChange = (e, index) => {
    const { team } = this.state;
    const newTeamsObj = [...team];
    newTeamsObj[index] = Object.assign({}, newTeamsObj[index], {
      name: e.target.value,
    });
    this.setState({ team: newTeamsObj });
  };

  handleTeamLinkChange = (e, index) => {
    const { team } = this.state;
    const newTeamsObj = [...team];
    newTeamsObj[index] = Object.assign({}, newTeamsObj[index], {
      link: e.target.value,
    });
    this.setState({ team: newTeamsObj });
  };

  addTeams = () => {
    const { team, team_keys } = this.state;
    this.setState({
      team: [...team, { name: '', link: '' }],
      team_keys: [...team_keys, v4('team')],
    });
  };

  removeTeam = index => {
    this.setState({
      team: this.state.team.filter((_, i) => i !== index),
      team_keys: this.state.team_keys.filter((_, i) => i !== index),
    });
  };

  render() {
    return (
      <div className="admin-portal-content">
        <h1 className="form-header">REAL ESTATE OFFERING TOKEN UPLOAD FORM</h1>
        {this.renderTokenForm()}
      </div>
    );
  }
}

export default RealEstateTokenForm;
