import React, { Component } from 'react'
import { ResponsiveChoropleth } from '@nivo/geo'
import {
    Row,
    Col,
} from 'react-bootstrap'
import ReactLoading from 'react-loading'
import countries from "../Choropleth/world_countries.json";

class Choropleth extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: this.props.data,
        }
    } // end of constructor(props)

    renderTooltip = e => {
      const name = e.feature.properties.name
      const value = e.feature.value

      let tooltip
      if (value) {
        tooltip = (
          <div className='choropleth-tooltip'>
             {name + ' - ' + value}
          </div>
        )
      } else {
        tooltip = (
          <div className='choropleth-tooltip'>
             No data available for {name}
          </div>
        )
      }

      return tooltip
    }

    render() {
        const {
            data,
        } = this.state
        const chartData = data.item
        const chartDomain = data.count

        return (
            <React.Fragment>
                <ResponsiveChoropleth
                    className='choropleth'
                    data={chartData}
                    features={countries.features}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    colors='blues'
                    domain={[ 0, chartDomain ]} // needs to be passed in
                    unknownColor="rgba(0, 0, 0, .0)"
                    label="properties.name"
                    valueFormat=".2s"
                    projectionTranslation={[ 0.5, 0.5 ]}
                    projectionRotation={[ 0, 0, 0 ]}
                    enableGraticule={false} // gridlines
                    graticuleLineColor="white"
                    graticuleLineWidth={0.5}
                    borderWidth={0.5}
                    borderColor="white"
                    tooltip={(chartData) => this.renderTooltip(chartData)}
                />
            </React.Fragment>
        )
    } // end of render()
} // end of class Choropleth

export default Choropleth
