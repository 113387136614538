let captchaKeys = {}

if (process.env.NODE_ENV == "development") {
  // DEVELOPMENT KEYS
  captchaKeys = {
    siteKey: '6Lfbg8YUAAAAADDS46bwRr0Aq8GJW2xhlEsWQ4ZM',
    secretKey: '6Lfbg8YUAAAAALBSd3Fso2wQCR1_OX7ImYLMSy9i'
  }
}
else if(process.env.REACT_APP_ENV == "staging"){
  //STAGING KEYS
  captchaKeys = {
    siteKey: '6LcBOugUAAAAAKvwl3GJXtk38TuSrqNHDuQlwSFB',
    secretKey: '6LcBOugUAAAAALnbzKTF4ovicWHDov5ecD8gn0-V'
  }
} else {
  // PRODUCTION KEYS - www.velocitychannel.io
  captchaKeys = {
    siteKey: '6LdygsYUAAAAAEFjPJE4-PXhs0u28oUvSop8vZcE',
    secretKey: '6LdygsYUAAAAAAwzm9gcOg7ua-IqknReBO7QmzSZ'
  }
}

export default captchaKeys
