import React, { PureComponent } from 'react'
import {
  Row,
  Col,
  Button,
  Image,
  Container,
  Jumbotron,
  Form,
  Link,
} from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import MetaTags from 'react-meta-tags';
import DatePicker from "react-date-picker";
import tokenListingImage1 from '../../images/TokenListed/token-listing-image-1.png'
import tokenListingImage2 from '../../images/TokenListed/token-listing-image-2.png'
import LogoImg from '../../images/logoImage.js'

const geographicOptions = [
  "North America",
  "South America",
  "Europe",
  "Middle East",
  "Africa",
  "Asia",
  "Australia/New Zealand",
  "General Focus",
]

class TokenListed extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      name: '',
      description: '',
      location: 'North America',
      link: '',
      stoDate: '',
    }
  }

  componentDidUpdate() {
  }

  handleFormChange = e => {
      const { name, value } = e.target
      this.setState({ [name]:  value })
  } // end of handleFormChange(e)

  onChangeDate = date => {
    const { stoDate } = this.state;
    if (date === null) {
      this.setState({ stoDate: '' });
    } else {
      this.setState({ stoDate: date });
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.submitListingRequest(this.state)
  }

  renderMetaTags = () => {
    return (
      <MetaTags>
        <meta name='description' content='Feature your digital asset on VelocityChannel and gain access to a highly engaged audience of researchers, investors and operators'/>
        <meta name='title' property='og:title' content='Get Profiled on Velocity Channel'/>
        <meta property='og:description' content='Feature your digital asset on VelocityChannel and gain access to a highly engaged audience of researchers, investors and operators'/>
        <meta property='og:image' content={LogoImg}/>
        <meta property='og:type' content='website'/>
        <meta property='og:url' content={`https://velocitychannel.io/get-your-token-listed`}/>
        <meta property='twitter:title' content='Get Profiled on Velocity Channel'/>
        <meta property='twitter:description' content='Feature your digital asset on VelocityChannel and gain access to a highly engaged audience of researchers, investors and operators'/>
        <meta property='twitter:image' content={LogoImg}/>
        <meta property='twitter:site' content='Velocity Channel'/>
        {/* <meta name='robots' content='nofollow' /> */}
      </MetaTags>
    )
  }

  render() {
    const { email, name, description, location, link, stoDate } = this.state
    return (
      <Container className='token-listed-container-2'>
        {this.renderMetaTags()}
        <Row className='no-padding token-listed-header-row'>
          <Col md={1} />
          <Col md={5} className='no-padding header-text'>
            <h3>Profile your digital asset on Velocity Channel</h3>
            <p>Velocity Channel’s information hub will help investors and enthusiasts navigate the rapidly evolving digital assets ecosystem, and help token issuers disseminate information directly to an active community.</p>
            <Row className='no-padding token-listed-bottom-row'>
              <Col>
                <Image src={tokenListingImage1} className='token-listing-image' />
                <h4>Investors can:</h4>
                <ul>
                  <li>Utilize an advanced search and indexing system to discover new digital assets.</li>
                  <li>Discover vetted investment opportunities based on their personalized investor profile.</li>
                  <li>Get informed on where and how to participate in a wide selection of digital asset offerings.</li>
                </ul>
              </Col>
              {/* <Col md={1} /> */}
              <Col>
                <Image src={tokenListingImage2} className='token-listing-image' />
                <h4>Issuing teams can:</h4>
                <ul>
                  <li>Invite particular groups of investors to participate.</li>
                  <li>Gain exposure for their product/digital asset.</li>
                  <li>Request sponsored research pieces from our community of insight providers.</li>
                </ul>
              </Col>

            </Row>
          </Col>
          <Col md={1} />
          <Col md={4} className='no-padding'>
            <div className='token-listed-form-box'>
              <Form validated={false} onSubmit={this.handleSubmit}>
                <Form.Group>
                  Please provide a primary email for further inquiries and updates from our listing team.*
                  <Form.Control
                    required
                    name='email'
                    value={email}
                    onChange={this.handleFormChange}
                    onChange={this.handleFormChange}
                  />
                </Form.Group>
                <Form.Group>
                  What will your Digital Asset be called?*
                  <Form.Control
                    required
                    name='name'
                    value={name}
                    onChange={this.handleFormChange}
                  />
                </Form.Group>
                <Form.Group>
                  Briefly describe your business model.*
                  <Form.Control
                    required
                    name='description'
                    value={description}
                    onChange={this.handleFormChange}
                  />
                </Form.Group>
                <Form.Group>
                  Where are you based?*
                  <Form.Control
                    required
                    name='location'
                    as='select'
                    value={location}
                    onChange={this.handleFormChange}
                  >
                    <option>North America</option>
                    <option>South America</option>
                    <option>Europe</option>
                    <option>Africa</option>
                    <option>Asia</option>
                    <option>Australia/New Zealand</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  Please provide a link to your website. (Optional)
                  <Form.Control name='link' value={link}  onChange={this.handleFormChange} />
                </Form.Group>
                <Form.Group>
                  Please approximate when you expect to make your STO available to investors. (Optional)
                  <DatePicker
                    onChange={this.onChangeDate}
                    value={stoDate}
                    calendarType="US"
                  />
                </Form.Group>
                <Row className='no-padding'>
                  <Button type='submit' className='submit-button'>Submit</Button>
                </Row>
              </Form>
            </div>
          </Col>
          <Col md={1} />
        </Row>
      </Container>
    )
  }
}

export default TokenListed
