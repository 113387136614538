import * as CONSTANTS from '../constants';

// Sagas
export const getData = () => ({
  type: CONSTANTS.GET_DATA,
});

export const setData = data => ({
  type: CONSTANTS.SET_DATA,
  payload: data,
});

export const getVersionRequest = () => ({
  type: CONSTANTS.GET_VERSION_REQUEST,
})

export const getVersionSuccess = data => ({
  type: CONSTANTS.GET_VERSION_SUCCESS,
  data,
})
