import { put, takeLatest, call } from "redux-saga/effects"
import { toast } from "react-toastify"
import moment from "moment"
import {
  TOKEN_SEARCH_REQUEST,
  TOKEN_SEARCH_SUCCESS,
  TOKEN_SEARCH_ERROR,
  RESEARCH_FEED_REQUEST,
  RESEARCH_FEED_SUCCESS,
  RESEARCH_FEED_ERROR
} from "../../constants/landingPage"
import {
  tokenSearchSuccess,
  tokenSearchError,
  researchFeedSuccess,
  researchFeedError
} from "../../actions/landingPage"
import request from "../../../utils/apiRequest"

function* getTokenSearchList(action) {
  try {
    const { q } = action
    const requestData = {q}
    let path = `tokens/search?`
    if (q && q.length > 0) {
      path = `tokens/search?q=${q}`
    }
    const responseData = yield call(
      request,
      path,
      "GET",
      null,
      true
    )
    if (responseData.hits.length !== 0) {
      yield put(tokenSearchSuccess(responseData))
    }
  } catch (error) {
    // toast.error(error.reason)
  }
}

function* getResearchFeedList(action) {
  try {
    
  const responseData = yield call(
    request,
    `research/feed`,
    "GET",
    null,
    true
  )
  
  yield put(researchFeedSuccess(responseData))
} catch (error) {
  // toast.error(error.reason)
  yield put(researchFeedError(error))
}
}

export default function* landingPageSaga() {
  yield takeLatest(TOKEN_SEARCH_REQUEST, getTokenSearchList)
  yield takeLatest(RESEARCH_FEED_REQUEST, getResearchFeedList)
}
