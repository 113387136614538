import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Form,
  Image,
  Card,
  Container,
  ListGroup,
  Spinner,
  CardColumns,
  Tooltip,
  OverlayTrigger,
  FormControl,
  Modal,
  Popover,
  Jumbotron,
  Dropdown,
} from "react-bootstrap";
import MetaTags from 'react-meta-tags'
import { AiOutlineUser } from 'react-icons/ai'
import { MdThumbUp, MdComment, MdPeople, MdFeedback, MdLocationOn, MdViewModule, MdViewColumn } from 'react-icons/md'
import { FaBookmark, FaRegEye, FaRegNewspaper, FaIndustry } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import { history } from "../../configureStore";
import LogoImg from '../../images/logoImage.js'

class Discover extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      researchDiscover: this.props.researchDiscover,
      params: {
        q: ''
      },
    }
  }

  componentDidMount() {
    this.handleParseUrl()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        researchDiscover: nextProps.researchDiscover,
      })
    }
  }

  handleSearchResearch = () => {
    this.props.getResearchDiscoverRequest(this.state.params)
  }

  handleChangeParams = e => {
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: e.target.value
      }
    }, () => this.handleSearchResearch())
  }

  handleParseUrl = () => {
    const search = this.props.history.location.search
    const splitSearch = search.split('?name=')

    this.setState({
      params: {
        ...this.state.params,
        q: splitSearch[1]
      }
    }, () => this.handleSearchResearch())
  }

  formatDate = dateInput => {
    let date = new Date(dateInput)
    const month = date.toLocaleString("en-us", { month: "long" })
    const day = date.getDate()
    return month + " " + day
  }

  renderContent = () => {
    const { data, loaded, loading } = this.state.researchDiscover

    let content
    if (loaded) {
      if (data.total > 0) {
        content = data.docs.map(research => this.renderResearch(research))
      } else {
        content = (
          <div className='nothing-found'>
            No content found
          </div>
        )
      }
    } else if (loading) {
      content = this.renderSpinner()
    }

    return (
      <Col className='discover-content-col'>
        {content}
      </Col>
    )
  }

  renderResearch = research => {
    const { _id, image, title, user_id, datePublished, summary, categories } = research

    let displaySummary
    if (summary && summary.length > 220) {
      displaySummary = summary.slice(0, 220) + '...'
    } else {
      displaySummary = summary
    }

    let userImage
    if (user_id.image) {
      userImage = <Image src={user_id.image} className='discover-article-user-image' />
    } else {
      userImage = <AiOutlineUser className='discover-article-user-image-icon' />
    }

    const navName = title.replace(/\s+/g, '-').toLowerCase() + '-' + _id

    const url = `/research/${navName}`

    return (
      <div className='discover-individual-research' key={_id}>
        <div className='discover-broad-column'>
          <div className='discover-article-image-container'>
            {userImage}
          </div>
          <div className='discover-article-column user-info'>
            <div className='discover-article-username'>
              {user_id.name || user_id._id}
            </div>
            <div className='discover-article-date'>
              {this.formatDate(datePublished)}
            </div>
          </div>
          <Col className='discover-article-column'>
            <Row noGutters className='discover-article-title'>
              <NavLink to={url}>
                {title}
              </NavLink>

              <div className='discover-article-categories-container'>
                {categories.map(category => this.renderCategory(category))}
              </div>

            </Row>
            <div className='discover-article-summary'>
              {displaySummary || ''}
            </div>
          </Col>
        </div>

        <div className='discover-article-image-container'>
          <Image src={image} className='discover-article-image' />
        </div>
      </div>
    )
  }

  renderCategory = category => {
    return (
      <div className='article-category' key={category}>
        {category}
      </div>
    )
  }

  renderBanner = () => {
    return (
      <Row noGutters className='discover-banner'>
        <Col>
          <Row noGutters className='discover-banner-header'>
            Explore our content library
          </Row>
          <Row noGutters className='discover-banner-filter-row'>
            <FormControl
              name='q'
              value={this.state.params.q}
              onChange={this.handleChangeParams}
              placeholder='Search...'
              size='sm' className='discover-search-bar' />
            <Button
              onClick={this.handleSearchResearch}
              className='teal-button' size='sm'>
              Search
            </Button>
          </Row>
        </Col>
      </Row>
    )
  }

  renderSpinner = () => (
    <Container fluid className='discover-spinner-container'>
      <Row noGutters>
        <Spinner className='discover-spinner' animation='border'/>
      </Row>
    </Container>
  )

  renderMetaTags = () => {
    return (
      <MetaTags>
        <title>Asset Builder</title>
        <meta name='description' content='Discover research and publishers'/>
        <meta name='title' property='og:title' content='Explore Research'/>
        <meta property='og:description' content='Discover research and publishers'/>
        <meta property='og:image' content={LogoImg}/>
        <meta property='og:type' content='website'/>
        <meta property='og:url' content={window.location.href}/>
        <meta property='twitter:title' content='Explore VelocityChannel Research'/>
        <meta property='twitter:description' content='Discover research and publishers'/>
        <meta property='twitter:image' content={LogoImg}/>
        <meta property='twitter:site' content='Velocity Channel'/>
        <meta name='robots' content='nofollow' />
      </MetaTags>
    )
  }

  render() {
    return (
      <Container fluid className='feed-container'>
        {this.renderMetaTags()}
        {this.renderBanner()}
        {this.renderContent()}
      </Container>
    )
  }
}

export default Discover
