import * as CONSTANTS from '../constants/analytics';

export const upvoteRequest = data => ({
  type: CONSTANTS.POST_UPVOTE_REQUEST,
  data,
})

export const upvoteSuccess = data => ({
  type: CONSTANTS.POST_UPVOTE_SUCCESS,
  data,
})

export const postEventRequest = data => ({
  type: CONSTANTS.POST_EVENT_REQUEST,
  data,
})

export const postEventSuccess = data => ({
  type: CONSTANTS.POST_EVENT_REQUEST,
  data,
})

export const removeCommentRequest = data => ({
  type: CONSTANTS.REMOVE_COMMENT_REQUEST,
  data,
})

export const removeCommentSuccess = data => ({
  type: CONSTANTS.REMOVE_COMMENT_SUCCESS,
  data,
})

export const getContributorStatsRequest = (from, to, reportId) => ({
  type: CONSTANTS.GET_CONTRIBUTOR_STATS_REQUEST_ANALYTICS,
  from,
  to,
  reportId,
})

export const getContributorStatsSuccess = data => ({
  type: CONSTANTS.GET_CONTRIBUTOR_STATS_SUCCESS_ANALYTICS,
  data,
})

export const getReportStatsRequest = data => ({
  type: CONSTANTS.GET_REPORT_STATS_REQUEST,
  data,
})

export const getReportStatsSuccess = data => ({
  type: CONSTANTS.GET_REPORT_STATS_SUCCESS,
  data,
})

export const refreshReportComments = resourceId => ({
  type: CONSTANTS.REFRESH_REPORT_COMMENTS,
  resourceId,
})

export const removeEventRequest = data => ({
  type: CONSTANTS.REMOVE_EVENT_REQUEST,
  data,
})

export const removeEventSuccess = data => ({
  type: CONSTANTS.REMOVE_EVENT_SUCCESS,
  data,
})

export const unpublishArticleRequest = data => ({
  type: CONSTANTS.UNPUBLISH_ARTICLE_REQUEST,
  data,
})

export const unpublishArticleSuccess = () => ({
  type: CONSTANTS.UNPUBLISH_ARTICLE_SUCCESS,
})

export const checkViewedStatus = () => ({
  type: CONSTANTS.CHECK_VIEWED_STATUS,
})
