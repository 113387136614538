import { connect } from 'react-redux';
import PieChart from './PieChart';
import './PieChart.css';
import {
    getPieChartDataRequest,
} from '../../redux/actions/charts';

const mapStateToProps = state => ({
    // choroplethData: state.charts.choroplethData,
})

const mapDispatchToProps = {
    getPieChartDataRequest,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PieChart)
