import * as CONSTANTS from "../constants/contributor";

export const submitContributorRequest = data => ({
  type: CONSTANTS.POST_CONTRIBUTOR_REQUEST,
  data
});

export const submitContributorSuccess = responseData => ({
  type: CONSTANTS.POST_CONTRIBUTOR_SUCCESS,
  responseData
});

export const submitContributorError = error => ({
  type: CONSTANTS.POST_CONTRIBUTOR_ERROR,
  error
});

export const getContributorsReqeust = (offset) => ({
  type: CONSTANTS.GET_CONTRIBUTORS_REQUEST,
  offset,
});

export const getContributorsSuccess = list => ({
  type: CONSTANTS.GET_CONTRIBUTORS_SUCCESS,
  list
});

export const getContributorsError = error => ({
  type: CONSTANTS.GET_CONTRIBUTORS_ERROR,
  error
});

export const getFeaturedContributorsReqeust = () => ({
  type: CONSTANTS.GET_FEATURED_CONTRIBUTOR_REQUEST
});

export const getFeaturedContributorsSuccess = list => ({
  type: CONSTANTS.GET_FEATURED_CONTRIBUTOR_SUCCESS,
  list
});

export const getFeaturedContributorsError = error => ({
  type: CONSTANTS.GET_FEATURED_CONTRIBUTOR_ERROR,
  error
});

export const removeFeaturedContributorsReqeust = userId => ({
  type: CONSTANTS.REMOVE_FEATURED_CONTRIBUTOR_REQUEST,
  userId
});

export const getContributorRequest = userId => ({
  type: CONSTANTS.GET_CONTRIBUTOR_REQUEST,
  userId
});

export const getContributorSuccess = contributor => ({
  type: CONSTANTS.GET_CONTRIBUTOR_SUCCESS,
  contributor
});

export const getContributorError = error => ({
  type: CONSTANTS.GET_CONTRIBUTOR_ERROR,
  error
});

export const resetContributorForm = () => ({
  type: CONSTANTS.RESET_CONTRIBUTOR_FORM_ITEMS
});

export const contributorApproveReqeust = (userId, date, approved) => ({
  type: CONSTANTS.POST_CONTRIBUTOR_APPROVE_REQUEST,
  userId,
  date,
  approved
});

export const contributorApproveError = error => ({
  type: CONSTANTS.POST_CONTRIBUTOR_APPROVE_ERROR,
  error
});

export const getContributorApplicationsRequest = () => ({
  type: CONSTANTS.GET_CONTRIBUTOR_APPLICATIONS_REQUEST
});

export const getContributorApplicationsSuccess = contributorApplications => ({
  type: CONSTANTS.GET_CONTRIBUTOR_APPLICATIONS_SUCCESS,
  contributorApplications
});

export const searchContributorApplicationsRequest = username => ({
  type: CONSTANTS.SEARCH_CONTRIBUTOR_APPLICATIONS_REQUEST,
  username
});

export const searchContributorApplicationsSuccess = application => ({
  type: CONSTANTS.SEARCH_CONTRIBUTOR_APPLICATIONS_SUCCESS,
  application
});

export const clearContributorApplicationsCache = () => ({
  type: CONSTANTS.CLEAR_CONTRIBUTOR_APPLICATIONS_CACHE
});

export const approveContributorApplicationRequest = userInfo => ({
  type: CONSTANTS.APPROVE_CONTRIBUTOR_APPLICATION_REQUEST,
  userInfo
});

export const denyContributorApplicationRequest = userInfo => ({
  type: CONSTANTS.DENY_CONTRIBUTOR_APPLICATION_REQUEST,
  userInfo
});

export const followContributorRequest = (
  userId,
  contributorId,
  resourceType
) => ({
  type: CONSTANTS.FOLLOW_CONTRIBUTOR_REQUEST,
  userId,
  contributorId,
  resourceType
});

export const followContributorSuccess = followedContributors => ({
  type: CONSTANTS.FOLLOW_CONTRIBUTOR_REQUEST,
  followedContributors
});

export const getContributorAnalyticsRequest = userId => ({
  type: CONSTANTS.GET_CONTRIBUTOR_ANALYTICS_REQUEST,
  userId
});

export const getContributorAnalyticsSuccess = data => ({
  type: CONSTANTS.GET_CONTRIBUTOR_ANALYTICS_SUCCESS,
  data
});

export const featureContributorReqeust = userId => ({
  type: CONSTANTS.FEATURE_CONTRIBUTOR_REQUEST,
  userId
});

export const featureContributorSuccess = list => ({
  type: CONSTANTS.FEATURE_CONTRIBUTOR_SUCCESS,
  list
});

export const featureContributorError = error => ({
  type: CONSTANTS.FEATURE_CONTRIBUTOR_ERROR,
  error
});

export const getContributorsReportRequest = userId => ({
  type: CONSTANTS.GET_CONTRIBUTORS_REPORT_REQUEST,
  userId
});

export const getContributorsReportSuccess = list => ({
  type: CONSTANTS.GET_CONTRIBUTORS_REPORT_SUCCESS,
  list
});

export const getContributorsReportError = error => ({
  type: CONSTANTS.GET_CONTRIBUTORS_REPORT_ERROR,
  error
});

export const contributorSearchRequest = q => ({
  type: CONSTANTS.CONTRIBUTOR_SEARCH_REQUEST,
  q,
});

export const getContributorReportsRequest = (username, offset) => ({
  type: CONSTANTS.GET_CONTRIBUTOR_REPORTS_REQUEST,
  username,
  offset,
})

export const getContributorReportsSuccess = reports => ({
  type: CONSTANTS.GET_CONTRIBUTOR_REPORTS_SUCCESS,
  reports,
})

export const getContributorStatsRequest = username => ({
  type: CONSTANTS.GET_CONTRIBUTOR_STATS_REQUEST,
  username,
})

export const getContributorStatsSuccess = userData => ({
  type: CONSTANTS.GET_CONTRIBUTOR_STATS_SUCCESS,
  userData,
})
// export const editContributorProfileRequest = userInfo => ({
//   type: CONSTANTS.EDIT_CONTRIBUTOR_PROFILE_REQUEST,
//   userInfo,
// })
//
// export const editContributorProfileSuccess = userInfo => ({
//   type: CONSTANTS.EDIT_CONTRIBUTOR_PROFILE_SUCCESS,
//   userInfo,
// })

export const getLineGraphDataRequest = params => ({
  type: CONSTANTS.GET_LINE_GRAPH_DATA_REQUEST,
  params
})

export const getLineGraphDataSuccess = data => ({
  type: CONSTANTS.GET_LINE_GRAPH_DATA_SUCCESS,
  data
})

export const getPieChartDataRequest = params => ({
  type: CONSTANTS.GET_CONTRIBUTOR_PIE_CHART_DATA_REQUEST,
  params
})

export const getPieChartDataSuccess = data => ({
  type: CONSTANTS.GET_CONTRIBUTOR_PIE_CHART_DATA_SUCCESS,
  data
})

export const getTokenGraphDataRequest = params => ({
  type: CONSTANTS.GET_TOKEN_GRAPH_DATA_REQUEST,
  params
})

export const getTokenGraphDataSuccess = data => ({
  type: CONSTANTS.GET_TOKEN_GRAPH_DATA_SUCCESS,
  data
})

export const sendContributorInvitesRequest = data => ({
  type: CONSTANTS.SEND_CONTRIBUTOR_INVITES_REQUEST,
  data,
})

export const sendContributorInvitesSuccess = () => ({
  type: CONSTANTS.SEND_CONTRIBUTOR_INVITES_SUCCESS,
})
