import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { v1 } from 'uuid';
import { IoIosAddCircle, IoMdRemoveCircle } from 'react-icons/io';
import queryString from 'query-string';

class Contributor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      name: '',
      email: '',
      dob: '',
      residence: '',
      occupation: '',
      has_tokens: false,
      tokens: [''],
      token_keys: [v1()],
      linkedin: '',
      experience: '',
      show_token_list: false,
      is_approve: false,
    };
  }

  componentDidMount() {
    const { search } = window.location;
    const idObj = queryString.parse(search);
    if (search) {
      this.props.getContributorReqeust(idObj.userid);
    } else {
      this.props.resetContributorForm();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { contributor } = nextProps;

    let tokens_list_keys = [];
    let tokens = [];
    if (contributor && Object.keys(contributor).length) {
      let has_token = false;
      const { Items } = contributor;
      const {
        date,
        dob,
        email,
        experience,
        has_tokens,
        linkedin,
        name,
        occupation,
        residence,
        tokens_list,
      } = Items[0];
      if (Array.isArray(tokens_list)) {
        tokens_list.forEach(token => {
          tokens_list_keys.push(v1(token));
        });
        tokens = tokens_list;
      } else {
        tokens_list_keys.push(v1(tokens_list));
        tokens.push(tokens_list);
      }

      if (has_tokens === 'on') {
        has_token = true;
      }

      this.setState({
        name,
        email,
        dob,
        residence,
        occupation,
        has_tokens,
        tokens,
        date,
        token_keys: tokens_list_keys,
        linkedin,
        experience,
        is_approve: true,
      });
    }
  }

  handleFormChange = fieldName => e => {
    if (fieldName === 'has_tokens') {
      let isTrue = e.target.checked ? true : false;
      this.setState({ has_tokens: e.target.checked, show_token_list: isTrue });
    }
    this.setState({
      [fieldName]: e.target.value,
    });
  };

  removeToken = index => {
    this.setState({
      tokens: this.state.tokens.filter((_, i) => i !== index),
      token_keys: this.state.token_keys.filter((_, i) => i !== index),
    });
  };

  addToken = () => {
    const { tokens, token_keys } = this.state;
    this.setState({
      tokens: [...tokens, ''],
      token_keys: [...token_keys, v1()],
    });
  };

  handleTokenChange = index => e => {
    const { tokens } = this.state;
    const newTokenArray = [...tokens];

    newTokenArray[index] = e.target.value;
    this.setState({ tokens: newTokenArray });
  };

  onSubmit = e => {
    e.preventDefault();

    const {
      name,
      email,
      dob,
      residence,
      occupation,
      has_tokens,
      tokens,
      linkedin,
      experience,
    } = this.state;
    const { accountInfo, submitContributorRequest } = this.props;
    const { userId, username } = accountInfo;
    const data = {
      userId,
      username,
      name,
      email,
      dob,
      residence,
      occupation,
      has_tokens,
      tokens_list: tokens,
      linkedin,
      experience,
    };

    submitContributorRequest(data);
  };

  onApprove = () => {
    const { date } = this.state;
    const { search } = window.location;
    const idObj = queryString.parse(search);
    this.props.contributorApproveReqeust(idObj.userid, date, true);
  };

  onDeny = () => {
    const { date } = this.state;
    const { search } = window.location;
    const idObj = queryString.parse(search);
    this.props.contributorApproveReqeust(idObj.userid, date, false);
  };

  renderContributorForm = () => {
    const {
      name,
      email,
      dob,
      residence,
      occupation,
      tokens,
      linkedin,
      has_tokens,
      experience,
      is_approve,
      show_token_list,
    } = this.state;
    return (
      <Container className="form-content">
        <Form onSubmit={this.onSubmit}>
          <Form.Group as={Row} controlId="formHorizontalContributorName">
            <Form.Label column sm={4}>
              Contributor Name
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                placeholder="Contributor Name"
                onChange={this.handleFormChange('name')}
                value={name}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formHorizontalContributorEmail">
            <Form.Label column sm={4}>
              Email
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                placeholder="Contributor Email"
                onChange={this.handleFormChange('email')}
                value={email}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formHorizontalContributorDOB">
            <Form.Label column sm={4}>
              DOB
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                placeholder="Contributor DOB"
                onChange={this.handleFormChange('dob')}
                value={dob}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formHorizontalContributorResidence">
            <Form.Label column sm={4}>
              Country of Residence
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                placeholder="Country of Residence"
                onChange={this.handleFormChange('residence')}
                value={residence}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formHorizontalContributorOccupation">
            <Form.Label column sm={4}>
              Current occupation
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                placeholder="Current occupation"
                onChange={this.handleFormChange('occupation')}
                value={occupation}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formHorizontalContributorEmail">
            <Form.Label column sm={4}>
              Do you have holdings of tokens listed in VC?
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                aria-label="radio 1"
                onChange={this.handleFormChange('has_tokens')}
                value={has_tokens}
                required
              />
            </Col>
          </Form.Group>
          {show_token_list && (
            <React.Fragment>
              <Form.Group as={Row} controlId="formHorizontalContributorTokens">
                <Form.Label column sm={4}>
                  What tokens?
                </Form.Label>
                <Col sm={8}>
                  {tokens.map((token, index) =>
                    this.renderTokenInputForm(token, index),
                  )}
                </Col>
              </Form.Group>
              <Col
                sm={{ span: 8, offset: 4 }}
                style={{ marginBottom: 20, fontSize: 20 }}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="tooltip-teams">Add Token</Tooltip>}
                >
                  <IoIosAddCircle
                    onClick={() => {
                      this.addToken();
                    }}
                  />
                </OverlayTrigger>
              </Col>
            </React.Fragment>
          )}

          <Form.Group as={Row} controlId="formHorizontalContributorOccupation">
            <Form.Label column sm={4}>
              Linkedin
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                placeholder="Contributor Linkedin"
                onChange={this.handleFormChange('linkedin')}
                value={linkedin}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formHorizontalContirubutorExperience">
            <Form.Label column sm={4}>
              Briefly describe in a few sentences your exposure and experience
              with the Security token industry
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                placeholder="Experience"
                onChange={this.handleFormChange('experience')}
                value={experience}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Col sm={{ span: 8, offset: 4 }}>
              {is_approve ? (
                <React.Fragment>
                  <Button
                    variant="success"
                    onClick={() => {
                      this.onApprove();
                    }}
                  >
                    Approve
                  </Button>{' '}
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <Button
                    variant="danger"
                    onClick={() => {
                      this.onDeny();
                    }}
                  >
                    Deny
                  </Button>
                </React.Fragment>
              ) : (
                <Button type="submit">Submit</Button>
              )}
            </Col>
          </Form.Group>
        </Form>
      </Container>
    );
  };

  renderTokenInputForm = (token, index) => {
    const { token_keys } = this.state;

    return (
      <React.Fragment key={`offering-${token_keys[index]}`}>
        <Form.Group
          as={Row}
          controlId="formHorizontalOfferToken"
          className="every-team-info"
        >
          <Form.Label column sm={2} style={{ textAlign: 'center' }}>
            Field {index + 1}
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="Contributor Token"
              onChange={this.handleTokenChange(index)}
              value={token}
            />
          </Col>
          <Col sm={2}>
            <IoMdRemoveCircle
              style={{ fontSize: 20 }}
              onClick={() => {
                this.removeToken(index);
              }}
            />
          </Col>
        </Form.Group>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="admin-portal-content">
        <h1 className="form-header">CONTRIBUTOR FORM</h1>
        {this.renderContributorForm()}
      </div>
    );
  }
}

export default Contributor;
