import { connect } from "react-redux"
import TwoFactorAuth from './TwoFactorAuth'
import { loginWithToken } from '../../redux/actions/login'

const mapStateToProps = state => ({
  loginData: state.login.loginData,
})

const mapDispatchToProps = {
  loginWithToken
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuth)
