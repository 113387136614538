import { connect } from 'react-redux'
import TokenTemplates from './TokenTemplates'
import './TokenTemplates.css'
import {
  getTokenTemplatesRequest,
  getTokenTemplateToEditRequest,
  handleClearTokenTemplateToEdit,
  createTokenTemplateRequest,
  editTokenTemplateRequest,
  deleteTokenTemplateRequest,
  publishTokenTemplateRequest,
  unpublishTokenTemplateRequest,
} from '../../../redux/actions/tokenform';

const mapStateToProps = state => ({
  tokenTemplates: state.token.tokenTemplates,
  tokenTemplateToEdit: state.token.tokenTemplateToEdit,
  savingTemplate: state.token.savingTemplate,
  allProps: state,
})

const mapDispatchToProps = {
  getTokenTemplatesRequest,
  getTokenTemplateToEditRequest,
  handleClearTokenTemplateToEdit,
  createTokenTemplateRequest,
  editTokenTemplateRequest,
  deleteTokenTemplateRequest,
  publishTokenTemplateRequest,
  unpublishTokenTemplateRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TokenTemplates)
