import { connect } from "react-redux"
import Discover from "./Discover"
import "./Discover.css"
import {
  getResearchDiscoverRequest,
} from '../../redux/actions/discover'

const mapStateToProps = state => ({
  researchDiscover: state.discover.researchDiscover,
})

const mapDispatchToProps = {
  getResearchDiscoverRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Discover)
