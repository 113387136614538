/* eslint-disable */
import { put, takeLatest, call } from 'redux-saga/effects'
import * as CONSTANTS from '../../constants/index'
import * as ACTIONS from '../../actions/index'
import request from '../../../utils/apiRequest'

function* getVersion(action) {
  try {
    const responseData = yield call(
      request,
      'version',
      'GET',
      null,
      true,
    )
    const currentVersion = responseData.version
    const storedVersion = localStorage.version

    if (!storedVersion) {
      localStorage.setItem('version', currentVersion)
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
      localStorage.removeItem('persist:root')
      window.location.reload(true);
    } else {
      const splitStoredVersion = storedVersion.split(/\./g);
      const splitCurrentVersion = currentVersion.split(/\./g);

      while (splitStoredVersion.length || splitCurrentVersion.length) {
        const a = Number(splitStoredVersion.shift());
        const b = Number(splitCurrentVersion.shift());
        // eslint-disable-next-line no-continue
        if (a === b) {
          continue
        } else if (a < b) {
          localStorage.setItem('version', currentVersion)
          caches.keys().then(function(names) {
            for (let name of names) caches.delete(name);
          });
          localStorage.removeItem('persist:root')
          window.location.reload(true);
        }
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
      }
      return false;
    }
  } catch (err) {
    // toast.error(err.reason)
  }
} // end of logout(action)

export default function* loginSaga() {
  yield takeLatest(CONSTANTS.GET_VERSION_REQUEST, getVersion)
}
