import { connect } from 'react-redux';
import ResearchHomepage from './ResearchHomepage';
import './ResearchHomepage.css';
import {
  getUpvotesRequest,
  getDownvotesRequest,
  getBookmarksRequest,
  getTrendingArticlesRequest,
  getTrendingTopicsRequest,
  getResearchFeedRequest,
  getResearchUserFeedRequest,
  readArticleRequest,
  getArticlesByCategoryRequest,
  searchArticlesRequest,
} from '../../../redux/actions/researches'
import { postEventRequest, removeCommentRequest, removeEventRequest } from '../../../redux/actions/analytics'
import { followContributorRequest } from '../../../redux/actions/contributor'
const mapStateToProps = state => ({
  upVotes: state.research.upVotes,
  downVotes: state.research.downVotes,
  bookmarks: state.research.bookmarks,
  trendingArticles: state.research.trendingArticles,
  trendingTopics: state.research.trendingTopics,
  feeds: state.research.feeds,
  userFeed: state.research.userFeed,
  user: state.account.myAccount.info,
  currentArticle: state.research.currentArticle,
});

const mapDispatchToProps = {
  getUpvotesRequest,
  getDownvotesRequest,
  getBookmarksRequest,
  getTrendingArticlesRequest,
  getTrendingTopicsRequest,
  getResearchFeedRequest,
  getResearchUserFeedRequest,
  followContributorRequest,
  readArticleRequest,
  postEventRequest,
  removeCommentRequest,
  getArticlesByCategoryRequest,
  searchArticlesRequest,
  removeEventRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResearchHomepage);
