import React, { PureComponent } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Jumbotron,
  Form,
  FormControl,
  InputGroup,
  Tabs,
  Tab,
  Modal,
  Spinner,
  ListGroup,
  Tooltip,
  OverlayTrigger,
  Image,
  NavDropdown,
} from 'react-bootstrap'
import { NavLink } from "react-router-dom"
import {
  AiOutlineFileSearch,
  AiOutlineFundView,
  AiOutlineTeam,
  AiOutlineWarning,
  AiOutlineReconciliation,
  AiOutlineSolution,
  AiOutlineUser,
  AiOutlineSearch,
  AiOutlineLogout,
  AiOutlineSetting,
  AiOutlineUnorderedList
} from 'react-icons/ai'
import { FiSearch, FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import LogoImg from '../../../images/HomePage/FI_LOGO.png'
import defaultBannerImage from '../../../images/topbar-background-image.png'
import defaultProfilePicture from '../../../images/default-avatar-image.png'

class ResearchManagementPortal extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      account: this.props.account,
      currentSection: 'dashboard',
      editProfilePicture: false,
      editBannerImage: false,
    }
  } // end of constructor(props)

  componentDidMount() {
  } // end of componentDidMount()

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        account: nextProps.account
      })
    }
  }

  handleChangeSection = e => {
    this.setState({
      currentSection: e.target.value
    })
  }

  renderSidebar = () => {
    const { collapseSidebar, currentSection, account } = this.state

    let sidebarToggle
    if (collapseSidebar) {
      sidebarToggle = 'collapsed-sidebar'
    }

    let options
    if (account.loaded) {
      // if the account is an issuer or an admin, render these options
      if (account.data.isIssuer || account.data.isAdmin) {
        options = (
          <React.Fragment>
            <ListGroup.Item
              className='admin-list-item'
              active={currentSection === 'dashboard'}>
              <NavLink to='/portal' className='admin-list-item-title'>
                <Row noGutters>
                  Dashboard
                </Row>
              </NavLink>
            </ListGroup.Item>
            <ListGroup.Item
              className='admin-list-item'
              active={currentSection === 'my-profile'}>
              <NavLink to={this.props.history.location.pathname} className='admin-list-item-title'>
                <Row noGutters>
                  My Profile
                </Row>
              </NavLink>
            </ListGroup.Item>
            <ListGroup.Item
              className='admin-list-item'
              active={currentSection === 'manage-assets'}>
              <NavLink to={this.props.history.location.pathname} className='admin-list-item-title'>
                <Row noGutters>
                  Manage Assets
                </Row>
              </NavLink>
            </ListGroup.Item>
          </React.Fragment>
        )
      }
    }

    return (
      <div className='admin-sidebar' id={sidebarToggle}>
        <Col>
          <Row noGutters className='admin-logo-row'>
            <NavLink to='/'>
              <Image className='admin-logo' src={LogoImg} />
            </NavLink>
          </Row>

          <Row noGutters className='admin-system-row'>
            <div className='system-header'>SYSTEM</div>
          </Row>

          <ListGroup variant='flush' className='admin-list-group'>
            {options}
          </ListGroup>

          {this.renderSidebarToggleButton()}
        </Col>
      </div>
    )
  }

  renderTopbar = () => {
    const { data, loaded } = this.state.account

    let displayName
    if (loaded) {
      displayName = data.first_name + ' ' + data.last_name
    }

    const title = (
      <React.Fragment>
        {displayName}
        <AiOutlineUser />
      </React.Fragment>
    )

    return (
      <div className='admin-topbar'>
        <Row noGutters className='admin-topbar-row'>

          <NavDropdown className='admin-topbar-nav' title={title}>
            <NavDropdown.Item
              href='/'
              className='admin-topbar-login'>
              <AiOutlineUnorderedList />
              Portal
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              className='admin-topbar-login'
              onClick={this.props.logout}>
              <AiOutlineLogout />
              Log out
            </NavDropdown.Item>
          </NavDropdown>
        </Row>
      </div>
    )
  }

  renderSidebarToggleButton = () => {
    const { collapseSidebar } = this.state

    let icon
    if (collapseSidebar) {
      icon = <FiChevronRight className='admin-sidebar-collapse-toggle-button' />
    } else {
      icon = <FiChevronLeft className='admin-sidebar-collapse-toggle-button' />
    }

    return (
      <Row noGutters className='admin-sidebar-collapse-toggle-row'>
        <div
          onClick={this.handleToggleCollapseSidebar}
          className='admin-sidebar-collapse-toggle-aura'>
          {icon}
        </div>
      </Row>
    )
  }

  renderContent = () => {
    const { currentSection } = this.state

    let header
    let content
    if (currentSection == 'dashboard') {
      header = 'Dashboard'
      content = this.renderDashboard()
    }
    return (
      <Container fluid className='admin-content-container'>
        <div className='forms-table-box'>
          {content}
        </div>
      </Container>
    )
  }

  renderDashboard = () => {
    return (
      <React.Fragment>
        <Row noGutters>
          <Col>
            <Row noGutters className='rmp-banner-row'>
              {this.renderBannerUploader()}
            </Row>
            <Row noGutters className='rmp-dashboard-row'>
              <Col>
                {this.renderProfilePicture()}
              </Col>
              <Col md={6} className='no-padding engagement-column'>
                {this.renderDashboardAccountInfo()}
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  renderDashboardAccountInfo = () => {
    console.log(this.state)
    const { data, loaded, loading } = this.state.account

    let content
    if (loaded) {
      const { email, firm, first_name, last_name, userId } = data
      let name = `${first_name} ${last_name}`
      content = (
        <div className='dashboard-account-info-card'>
          <Row noGutters className='dashboard-header-row'>
            User Profile
          </Row>
          <Row noGutters className='dashboard-contents-row'>
            Username
            <div>
              {userId}
            </div>
          </Row>
          <Row noGutters className='dashboard-contents-row'>
            Name
            <div>
              {name}
            </div>
          </Row>
          <Row noGutters className='dashboard-contents-row'>
            Email
            <div>
              {email}
            </div>
          </Row>
        </div>
      )
    } else if (loading) {

    }

    return content
  }

  renderProfilePicture = () => {
    const { editProfilePicture, account } = this.state

    let profilePicture = defaultProfilePicture

    let content
    if (editProfilePicture) {
      // show editProfilePicture
    } else {
      content = (
        <Image className='rmp-uploaded-image' src={profilePicture} />
      )
    }

    return content
  }

  renderBannerUploader = () => {
    const { editBannerImage, bannerImage } = this.state

    let banner = defaultBannerImage

    let content
    if (editBannerImage) {
    } else {
      content = (
        <Image className='rmp-banner-image' src={banner} />
      )
    }

    return content
  }

  render() {
    return (
      <Container fluid className='admin-container'>
        <Row noGutters>

          {this.renderSidebar()}

          <Col>
            {this.renderTopbar()}

            <div className='admin-content-pane'>
              {this.renderContent()}
            </div>

          </Col>

        </Row>
      </Container>
    )
  } // end of render()
} // end of ResearchManagementPortal class

// <Container className='research-management-portal'>
//   <div className='profile-form-modal'>
//     {this.renderReferAFriendModal()}
//     {this.renderProfileFormModal()}
//     {this.renderUnpublishModal()}
//     {this.renderCopyLinkModal()}
//     {this.renderCreateAssetProfileModal()}
//   </div>
//   <Container className='research-management-portal-container'>
//     <Tab.Container defaultActiveKey='#profile-info'>
//       <Row className='no-padding rmp-container-row'>
//         <Col md={2} className='no-padding rmp-sidebar-column'>
//           {accountInfo.isContributor ? this.renderSidebar() : this.renderNonContributorSidebar()}
//         </Col>
//         <Col md={1} />
//         <Col className='no-padding'>
//           <Row className='header-row'>
//             <h3>{headerText}</h3>
//           </Row>
//           <Row className='content-row'>
//             {this.renderContentPane()}
//           </Row>
//         </Col>
//         <Col md={1} />
//       </Row>
//     </Tab.Container>
//   </Container>
// </Container>


export default ResearchManagementPortal
