export const POST_CONTRIBUTOR_REQUEST = 'POST_CONTRIBUTOR_REQUEST';
export const POST_CONTRIBUTOR_SUCCESS = 'POST_CONTRIBUTOR_SUCCESS';
export const POST_CONTRIBUTOR_ERROR = 'POST_CONTRIBUTOR_ERROR';

export const GET_CONTRIBUTORS_REQUEST = 'GET_CONTRIBUTORS_REQUEST';
export const GET_CONTRIBUTORS_SUCCESS = 'GET_CONTRIBUTORS_SUCCESS';
export const GET_CONTRIBUTORS_ERROR = 'GET_CONTRIBUTORS_ERROR';

export const GET_CONTRIBUTOR_APPLICATIONS_REQUEST = 'GET_CONTRIBUTOR_APPLICATIONS_REQUEST'
export const GET_CONTRIBUTOR_APPLICATIONS_SUCCESS = 'GET_CONTRIBUTOR_APPLICATIONS_SUCCESS'
export const GET_CONTRIBUTOR_APPLICATIONS_ERROR = 'GET_CONTRIBUTOR_APPLICATIONS_ERROR'

export const GET_CONTRIBUTOR_REQUEST = 'GET_CONTRIBUTOR_REQUEST';
export const GET_CONTRIBUTOR_SUCCESS = 'GET_CONTRIBUTOR_SUCCESS';
export const GET_CONTRIBUTOR_ERROR = 'GET_CONTRIBUTOR_ERROR';

export const SEARCH_CONTRIBUTOR_APPLICATIONS_REQUEST = 'SEARCH_CONTRIBUTOR_APPLICATIONS_REQUEST'
export const SEARCH_CONTRIBUTOR_APPLICATIONS_SUCCESS = 'SEARCH_CONTRIBUTOR_APPLICATIONS_SUCCESS'
export const SEARCH_CONTRIBUTOR_APPLICATIONS_ERROR = 'SEARCH_CONTRIBUTOR_APPLICATIONS_ERROR'

export const APPROVE_CONTRIBUTOR_APPLICATION_REQUEST = 'APPROVE_CONTRIBUTOR_APPLICATION_REQUEST'
export const APPROVE_CONTRIBUTOR_APPLICATION_SUCCESS = 'APPROVE_CONTRIBUTOR_APPLICATION_SUCCESS'
export const APPROVE_CONTRIBUTOR_APPLICATION_ERROR = 'APPROVE_CONTRIBUTOR_APPLICATION_ERROR'

export const DENY_CONTRIBUTOR_APPLICATION_REQUEST = 'DENY_CONTRIBUTOR_APPLICATION_REQUEST'
export const DENY_CONTRIBUTOR_APPLICATION_SUCCESS = 'DENY_CONTRIBUTOR_APPLICATION_SUCCESS'
export const DENY_CONTRIBUTOR_APPLICATION_ERROR = 'DENY_CONTRIBUTOR_APPLICATION_ERROR'

export const CLEAR_CONTRIBUTOR_APPLICATIONS_CACHE = 'CLEAR_CONTRIBUTOR_APPLICATIONS_CACHE'

export const RESET_CONTRIBUTOR_FORM_ITEMS = 'RESET_CONTRIBUTOR_FORM_ITEMS';

export const POST_CONTRIBUTOR_APPROVE_REQUEST =
  'POST_CONTRIBUTOR_APPROVE_REQUEST';
export const POST_CONTRIBUTOR_APPROVE_SUCCESS =
  'POST_CONTRIBUTOR_APPROVE_SUCCESS';
export const POST_CONTRIBUTOR_APPROVE_ERROR = 'POST_CONTRIBUTOR_APPROVE_ERROR';

export const FOLLOW_CONTRIBUTOR_REQUEST = 'FOLLOW_CONTRIBUTOR_REQUEST'
export const FOLLOW_CONTRIBUTOR_SUCCESS = 'FOLLOW_CONTRIBUTOR_SUCCESS'

export const GET_CONTRIBUTOR_ANALYTICS_REQUEST = 'GET_CONTRIBUTOR_ANALYTICS_REQUEST'
export const GET_CONTRIBUTOR_ANALYTICS_SUCCESS = 'GET_CONTRIBUTOR_ANALYTICS_SUCCESS'

export const GET_FEATURED_CONTRIBUTOR_REQUEST = 'GET_FEATURED_CONTRIBUTOR_REQUEST';
export const GET_FEATURED_CONTRIBUTOR_SUCCESS = 'GET_FEATURED_CONTRIBUTOR_SUCCESS';
export const GET_FEATURED_CONTRIBUTOR_ERROR = 'GET_FEATURED_CONTRIBUTOR_ERROR';

export const FEATURE_CONTRIBUTOR_REQUEST = 'FEATURE_CONTRIBUTOR_REQUEST';
export const FEATURE_CONTRIBUTOR_SUCCESS = 'FEATURE_CONTRIBUTOR_SUCCESS';
export const FEATURE_CONTRIBUTOR_ERROR = 'FEATURE_CONTRIBUTOR_ERROR';

export const GET_CONTRIBUTORS_REPORT_REQUEST = 'GET_CONTRIBUTORS_REPORT_REQUEST';
export const GET_CONTRIBUTORS_REPORT_SUCCESS = 'GET_CONTRIBUTORS_REPORT_SUCCESS';
export const GET_CONTRIBUTORS_REPORT_ERROR = 'GET_CONTRIBUTORS_REPORT_ERROR';

export const REMOVE_FEATURED_CONTRIBUTOR_REQUEST = 'REMOVE_FEATURED_CONTRIBUTOR_REQUEST';

export const CONTRIBUTOR_SEARCH_REQUEST = 'CONTRIBUTOR_SEARCH_REQUEST';

export const GET_CONTRIBUTOR_REPORTS_REQUEST = 'GET_CONTRIBUTOR_REPORTS_REQUEST'
export const GET_CONTRIBUTOR_REPORTS_SUCCESS = 'GET_CONTRIBUTOR_REPORTS_SUCCESS'

export const GET_CONTRIBUTOR_STATS_REQUEST = 'GET_CONTRIBUTOR_STATS_REQUEST'
export const GET_CONTRIBUTOR_STATS_SUCCESS = 'GET_CONTRIBUTOR_STATS_SUCCESS'

export const GET_LINE_GRAPH_DATA_REQUEST = 'GET_LINE_GRAPH_DATA_REQUEST'
export const GET_LINE_GRAPH_DATA_SUCCESS = 'GET_LINE_GRAPH_DATA_SUCCESS'

export const GET_CONTRIBUTOR_PIE_CHART_DATA_REQUEST = 'GET_CONTRIBUTOR_PIE_CHART_DATA_REQUEST'
export const GET_CONTRIBUTOR_PIE_CHART_DATA_SUCCESS = 'GET_CONTRIBUTOR_PIE_CHART_DATA_SUCCESS'

export const GET_TOKEN_GRAPH_DATA_REQUEST = 'GET_TOKEN_GRAPH_DATA_REQUEST'
export const GET_TOKEN_GRAPH_DATA_SUCCESS = 'GET_TOKEN_GRAPH_DATA_SUCCESS'

export const SEND_CONTRIBUTOR_INVITES_REQUEST = 'SEND_CONTRIBUTOR_INVITES_REQUEST'
export const SEND_CONTRIBUTOR_INVITES_SUCCESS = 'SEND_CONTRIBUTOR_INVITES_SUCCESS'
