import * as CONSTANTS from '../constants/account';

const initialState = {
  myAccount: {
    info: [],
    error: '',
    image: '',
    loading: false,
  },
  user: {
      selectedUser: {},
      found: false,
  },
  error: '',
  userList: {
      Items: [],
      count: '',
      error: '',
      loading: false,
  },
  publishedReports: {
    hits: [],
    max_score: null,
    total: 0,
  },
  contributorResume: {
    fileUrl: '',
    loaded: false,
  },
  contributorSample: {
    fileUrl: '',
    loaded: false,
  },
  authenticated: false,
  // drafts: {
  //   hits: [],
  //   max_score: null,
  //   total: 0,
  // },
  drafts: [],
  editAccountError: false,
  uploadAvatarImage: {
    image: '',
    loading: false,
    loaded: false,
    error: '',
  },
  uploadBannerImage: {
    image: '',
    loading: false,
    loaded: false,
    error: '',
  },
  landingPageUserSearch: {
    data: {},
    loading: false,
    loaded: false,
    error: '',
  },
  userSearch: {
    data: {},
    loading: false,
    loaded: false,
    error: '',
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.MY_ACCOUNT_SUCCESS:
    // localStorage.setItem('loggedIn', true)
      return {
        ...state,
        myAccount: {
          ...state.myAccount,
          info: action.account,
        },
      };
    case CONSTANTS.MY_ACCOUNT_ERROR:
    // localStorage.setItem('loggedIn', false)
      return {
        ...state,
        myAccount: {
          ...state.myAccount,
          error: action.error,
        },
      };
    case CONSTANTS.PROFILE_IMAGE_UPLOAD_REQUEST:
      return {
        ...state,
        myAccount: {
          ...state.myAccount,
          loading: true,
        }
      }
    case CONSTANTS.PROFILE_IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        myAccount: {
          ...state.myAccount,
          image: action.profileImageUrl.data.Attributes.image,
          loading: false,
        },
      };
    case CONSTANTS.SEARCH_USER_SUCCESS:
      return {
          ...state,
          user: {
              selectedUser: action.user,
              found: true,
          },
      }
    case CONSTANTS.SEARCH_USER_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case CONSTANTS.RESET_SEARCH_USER:
      return {
        ...state,
        user: {},
      };
     case CONSTANTS.GET_USER_LIST_SUCCESS:
     return {
         ...state,
         userList: {
             ...state.userList,
             Items: action.users.Items,
             count: action.users.count,
             loading: false,
         }
     }
    // case CONSTANTS.CLEAR_USER_CACHE:
    //   // state = initialState
    //   console.log(initialState)
    case CONSTANTS.CLEAR_USER_LIST_CACHE:
      return {
          ...state,
          user: {
              selectedUser: {},
              found: false,
          },
      }
    case CONSTANTS.GET_PUBLISHED_REPORTS_SUCCESS:
      return {
        ...state,
        publishedReports: action.reports
      }
    case CONSTANTS.GET_DRAFTS_SUCCESS:
      return {
        ...state,
        drafts: action.drafts
      }
    case CONSTANTS.PROFILE_IMAGE_REQUEST:
      return {
        ...state,
        myAccount: {
          ...state.myAccount,
          loading: true,
        }
      }
    case CONSTANTS.PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        myAccount: {
          ...state.myAccount,
          image: action.profileImageUrl.data.Attributes.image,
          loading: false,
        },
      };
    case CONSTANTS.CONTRIBUTOR_APPLICATION_DOCUMENT_UPLOAD_REQUEST:
      if (action.fileType === 'resume') {
        return {
          ...state,
          contributorResume: {
            fileUrl: '',
            loaded: false,
          },
        }
      } else if (action.fileType === 'sample') {
        return {
          ...state,
          contributorSample: {
            fileUrl: '',
            loaded: false,
          },
        }
      }
    case CONSTANTS.CONTRIBUTOR_APPLICATION_DOCUMENT_UPLOAD_SUCCESS:
      if (action.fileType === 'resume') {
        return {
          ...state,
          contributorResume: {
            fileUrl: action.data,
            loaded: true,
          },
        }
      } else if (action.fileType === 'sample') {
        return {
          ...state,
          contributorSample: {
            fileUrl: action.data,
            loaded: true,
          },
        }
      }
    case CONSTANTS.SET_USER_AUTHENTICATED_STATUS_TRUE:
      return {
        ...state,
        authenticated: true,
      }
    case CONSTANTS.SET_USER_AUTHENTICATED_STATUS_FALSE:
      return {
        ...state,
        authenticated: false,
      }
    case CONSTANTS.WIPE_LOCAL_STORAGE:
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      return {
        ...state,
        authenticated: false,
      }
    case CONSTANTS.EDIT_ACCOUNT_REQUEST:
      return {
        ...state,
        editAccountError: false,
      }
    case CONSTANTS.EDIT_ACCOUNT_SUCCESS:
      return {
        ...state,
        editAccountError: false,
      }
    case CONSTANTS.EDIT_ACCOUNT_ERROR:
      return {
        ...state,
        editAccountError: true,
      }
    case CONSTANTS.UPLOAD_AVATAR_IMAGE_REQUEST:
      return {
        ...state,
        uploadAvatarImage: {
          ...state.uploadAvatarImage,
          loading: true,
          loaded: false,
          error: '',
        }
      }
    case CONSTANTS.UPLOAD_AVATAR_IMAGE_SUCCESS:
    //console.log(action)
      return {
        ...state,
        uploadAvatarImage: {
          ...state.uploadAvatarImage,
          image: action.image,
          loading: false,
          loaded: true,
        }
      }
      case CONSTANTS.UPLOAD_AVATAR_IMAGE_ERROR:
        return {
          ...state,
          uploadAvatarImage: {
            ...state.uploadAvatarImage,
            loading: false,
            loaded: false,
            error: action.error,
          }
        }
    case CONSTANTS.UPLOAD_BANNER_IMAGE_REQUEST:
      return {
        ...state,
        uploadBannerImage: {
          ...state.uploadBannerImage,
          loading: true,
          loaded: false,
          error: '',
        }
      }
    case CONSTANTS.UPLOAD_BANNER_IMAGE_SUCCESS:
      return {
        ...state,
        uploadBannerImage: {
          ...state.uploadBannerImage,
          image: action.image,
          loading: false,
          loaded: true,
        }
      }
    case CONSTANTS.UPLOAD_BANNER_IMAGE_ERROR:
      return {
        ...state,
        uploadBannerImage: {
          ...state.uploadBannerImage,
          loading: false,
          loaded: false,
          error: action.error,
        }
      }
    case CONSTANTS.LANDING_PAGE_USER_SEARCH_REQUEST:
      return {
        ...state,
        landingPageUserSearch: {
          ...state.landingPageUserSearch,
          loading: true,
          loaded: false,
          error: '',
        }
      }
    case CONSTANTS.LANDING_PAGE_USER_SEARCH_SUCCESS:
      return {
        ...state,
        landingPageUserSearch: {
          ...state.landingPageUserSearch,
          data: action.data,
          loading: false,
          loaded: true,
          error: '',
        }
      }
    case CONSTANTS.LANDING_PAGE_USER_SEARCH_ERROR:
      return {
        ...state,
        landingPageUserSearch: {
          ...state.landingPageUserSearch,
          loading: false,
          loaded: false,
          error: action.error,
        }
      }

    case CONSTANTS.ADMIN_SEARCH_USERS_REQUEST:
      return {
        ...state,
        userSearch: {
          ...state.userSearch,
          loading: true,
          loaded: false,
          error: ''
        }
      }

    case CONSTANTS.ADMIN_SEARCH_USERS_SUCCESS:
      return {
        ...state,
        userSearch: {
          ...state.userSearch,
          loading: false,
          loaded: true,
          data: action.data,
        }
      }

    case CONSTANTS.ADMIN_SEARCH_USERS_ERROR:
      return {
        ...state,
        userSearch: {
          ...state.userSearch,
          loading: false,
          loaded: false,
          error: action.error
        }
      }
    default:
      return state;
  }
};
