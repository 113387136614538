import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import {
  Form,
  Row,
  Col,
  Container,
  FormControl,
  NavLink,
  Card,
  Image,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "./Login.css"
import { initGA, logPageView } from "../../utils/analytics"
import logo from '../../images/HomePage/FI_LOGO.png'

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loginParams: {
        email: '',
        password: '',
      }
    }
  }

  componentDidMount() {
    initGA()
    logPageView()
  }

  handleOnChange = e => {
    this.setState({
      loginParams: {
        ...this.state.loginParams,
        [e.target.name]: e.target.value,
      }
    })
  }

  handleLogin = () => {
    this.props.loginRequest(this.state.loginParams)
  }

  render() {
    const { email, password } = this.state.loginParams
    const disabled = (email && password) ? false : true

    return (
      <Container fluid className='login-container'>
        <Row noGutters className='login-row'>
          <Col sm={8} md={6} lg={4} xl={4} className='login-col'>
            <Card className='login-box'>
              <Row noGutters>
                <Col className='login-content-col'>

                  <Row noGutters className='login-header'>
                    <Link to={'/'}>
                      <Image fluid src={logo} className='login-logo' />
                    </Link>
                  </Row>

                  <Row noGutters className='login-box-padding login-formcontrol-row'>
                    <Col className='login-box-col'>
                      <Row noGutters className='login-welcome'>
                        <Col>
                          <h1>Welcome</h1>
                          <p>Log in to continue</p>
                        </Col>
                      </Row>

                      <Row noGutters className='login-formcontrol-container'>
                        <div className='login-formcontrol-box'>
                          <div className='login-formcontrol-text'>
                            Email
                          </div>
                          <FormControl
                            size='sm'
                            name='email'
                            value={email}
                            onChange={this.handleOnChange}
                            className='login-formcontrol' />
                        </div>
                      </Row>

                      <Row noGutters className='login-formcontrol-container'>
                        <div className='login-formcontrol-box'>
                          <div className='login-formcontrol-text'>
                            Password
                          </div>
                          <FormControl
                            size='sm'
                            name='password'
                            type='password'
                            value={password}
                            onChange={this.handleOnChange}
                            className='login-formcontrol' />
                        </div>
                      </Row>

                      <Row noGutters className='login-button-row'>
                        <Button
                          size='sm'
                          disabled={disabled}
                          onClick={this.handleLogin}
                          className='teal-button'>
                          LOGIN
                        </Button>
                      </Row>

                      <div className='login-navlinks'>
                        <Link to='/forgot'>Forgot Password</Link>
                        <Link to='/register'>Create an Account</Link>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Login
