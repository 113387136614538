import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import {
  Container,
  Button,
  Row,
  Col,
  Image,
  Nav,
  FormControl,
  Jumbotron,
  Modal,
} from "react-bootstrap"
import { MdSearch } from "react-icons/md"
import l2pImage1 from '../../images/LandingPage/lp2-image-1.png'
import l2pImage2 from '../../images/LandingPage/lp2-image-2.png'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { FiClipboard, FiMinus, FiPlus } from 'react-icons/fi'
import MetaTags from 'react-meta-tags'
import LogoImg from '../../images/logoImage.js'

class LandingPage2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      showInviteModal: false,
      userArray: [
        {name: '', email: ''},
      ],
      referAFriendCopyLink: '',
    }
  }

  componentDidMount() {
    const { user, accountImage } = this.props

    this.setState({
      referAFriendCopyLink: user.userId ? `https://velocitychannel.io/register?source=${user.userId}` : '',
    })
  } // end of componentDidMount()

  handleChangeQuery = e => {
    const { value } = e.target
      this.setState({
          query:  value,
      }, () => this.handleSearch())
  }

  handleSearch = () => {
    const { query } = this.state
    this.props.landingPageUserSearchRequest(query)
  }

  handleToggleInviteModal = () => {
    this.setState({
      showInviteModal: !this.state.showInviteModal
    })
  }

  handleClearUserArray = () => {
    this.setState({
      userArray: [
        {name: '', email: ''},
      ],
    })
  }

  handleAddUser = () => {
    let { userArray } = this.state
    const userObject = {
      name: '',
      email: '',
    }
    userArray.push(userObject)
    this.setState({
      userArray: userArray
    }, () => this.forceUpdate())
  }

  handleRemoveUser = index => {
    let { userArray } = this.state
    userArray.splice(index, 1)
    this.setState({
      userArray: userArray
    }, () => this.forceUpdate())
  }

  handleModifyName = (index, name) => {
    const newArray = this.state.userArray.slice()
    newArray[index].name = name.target.value
    this.setState({
      userArray: newArray
    })
  }

  handleModifyEmail = (index, email) => {
    const newArray = this.state.userArray.slice()
    newArray[index].email = email.target.value
    this.setState({
      userArray: newArray
    })
  }

  handleSendInvites = () => {
    const { userArray } = this.state
    this.props.sendInvitesRequest(userArray)
    this.handleClearUserArray()
    this.handleToggleInviteModal()
  }

  /********************************************************/

  renderSignupButton = () => {
    return (
      <Row className='no-padding lp2-button-row'>
        <Col className='no-padding'>
          <Row className='no-padding'>
            <NavLink to='/register'>
            <Button className='lp2-button'>SIGN UP</Button>
          </NavLink>
          </Row>
          <Row className='no-padding lp2-button-text'>
            Already have an account? <NavLink to='/login'>Log in.</NavLink>
          </Row>
        </Col>
      </Row>
    )
  }

  renderInviteAFriendButton = () => {
    return (
      <Row className='no-padding lp2-button-row'>
        <Col className='no-padding'>
          <Row className='no-padding'>
            <Button className='lp2-button' onClick={this.handleToggleInviteModal}>INVITE A FRIEND</Button>
          </Row>
        </Col>
      </Row>
    )
  }

  renderInviteAFriendText = () => (
    <p>Discover news, asset profiles, research and more from a diverse and distributed community of insight providers.</p>
  )

  renderSignInText = () => (
    <p>Discover news, asset profiles, research and more from a diverse and distributed community of insight providers. Sign up now to get started!</p>
  )

  renderInviteAFriendModal = () => {
    const { showInviteModal, userArray, referAFriendCopyLink } = this.state
    return (
      <Modal centered className='lp2-invite-modal' show={showInviteModal} onHide={this.handleToggleInviteModal}>
        <Modal.Body>
          <Row className='no-padding lp2-invite-header-row'>
            Invite a Friend
          </Row>
          <Row className='no-padding lp2-copy-link-control-row'>
            <p className='lp2-refer-a-friend-text'>Know anyone that would be interested in our content? Send them an invite to get them started and earn tokens when they sign up:</p>
            <Col className='no-padding'>
              {userArray.map((user, index) => this.renderReferAFriendFormControl(index))}
              <Row className='no-padding lp2-invite-modal-row'>
                <Col md={11} />
                <Col className='no-padding lp2-clipboard-col'>
                  <FiPlus className='lp2-report-info-button' onClick={this.handleAddUser}/>
                </Col>
              </Row>
              <Row className='no-padding center-justified-row'>
                <Button className='lp2-refer-a-friend-button' onClick={this.handleSendInvites}>
                  Send Invites
                </Button>
              </Row>
            </Col>
          </Row>
          <Row className='no-padding lp2-refer-a-friend-link-text'>
            Or, just share this unique link with them directly:
          </Row>
          <Row className='no-padding lp2-copy-link-control-row'>
            <Col md={11} className='no-padding'>
              <FormControl className='lp2-refer-a-friend-form-control' value={referAFriendCopyLink} />
            </Col>
            <Col md={1} className='no-padding lp2-clipboard-col'>
              <CopyToClipboard text={referAFriendCopyLink} onCopy={() => toast.info('Copied link to clipboard')}>
                <FiClipboard className='lp2-report-info-button' />
              </CopyToClipboard>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }

  renderReferAFriendFormControl = index => {
    const { userArray } = this.state
    const name = userArray[index].name
    const email = userArray[index].email
    return (
      <Row className='no-padding lp2-invite-form-control' key={index}>
        <Col className='no-padding'>
          <Row className='no-padding lp2-refer-a-friend-control-row'>
            <Col className='no-padding' md={3}>
              <FormControl className='lp2-refer-a-friend-form-control' placeholder='Name...' value={name} onChange={e => this.handleModifyName(index, e)} />
            </Col>
            <Col md={1} />
            <Col className='no-padding' md={7}>
              <FormControl className='lp2-refer-a-friend-form-control' placeholder='Email Address...' value={email} onChange={e => this.handleModifyEmail(index, e)}  />
            </Col>
            <Col md={1} className='no-padding lp2-clipboard-col'>
              <FiMinus className='lp2-report-info-button' onClick={() => this.handleRemoveUser(index)}/>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  renderMetaTags = () => {
    return (
      <MetaTags>
        <meta name='description' content='The next generation research platform for digital assets'/>
        <meta name='title' property='og:title' content='Velocity Channel'/>
        <meta property='og:description' content='The next generation research platform for digital assets'/>
        <meta property='og:image' content={LogoImg}/>
        <meta property='og:type' content='website'/>
        <meta property='og:url' content='https://velocitychannel.io'/>
        <meta property='twitter:title' content='Velocity Channel'/>
        <meta property='twitter:description' content='The next generation research platform for digital assets'/>
        <meta property='twitter:image' content={LogoImg}/>
        <meta property='twitter:site' content='Velocity Channel'/>
      </MetaTags>
    )
  }

  render() {
    const { authenticated } = this.props
    const link = 'https://velocitychannel.io/research/a-token-driven-financial-information-ecosystem-7mpmt54ok66o8mok'
    return (
      <Container fluid className='content-pane-container'>
        <div className='landing-page-background'>
          {this.renderInviteAFriendModal()}
          {this.renderMetaTags()}

          <div className='landing-page-intro'>
            <div className='landing-page-content-container'>
              <h4 className='landing-page-header'>
                The Next Generation Research Platform for Digital Assets.
              </h4>
              <p>
                Velocity Channel is a
                <a href={link} target='_blank'>token-driven</a>
                financial research network providing information on
                blockchain, digital assets and private securities.
              </p>
                {authenticated ? this.renderInviteAFriendText() : this.renderSignInText()}
                {authenticated ? this.renderInviteAFriendButton() : this.renderSignupButton()}
            </div>
          </div>

          <div className='landing-page-second'>
            <div className='landing-page-second-container'>
              <Image src={l2pImage2} className='landing-page-image' />
              <div className='lp2-border-box-2'>
                <h4>Want Access to a Highly Engaged Audience and a Way to Earn Velocity Ledger Tokens?</h4>
                <p>Join our research community as an insight provider.</p>
                <Row className='no-padding lp2-button-row'>
                  <Col className='no-padding'>
                    <Row className='no-padding'>
                      <NavLink to='/become-a-contributor'>
                        <Button className='lp2-button'>LEARN MORE</Button>
                      </NavLink>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

        </div>
      </Container>
    )
  }
}

// <div className='lp2-second-jumbotron'>
//
// </div>


export default LandingPage2
