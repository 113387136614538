import * as CONSTANTS from '../constants/analytics'
import { CLEAR_USER_CACHE } from '../constants/account'

const initialState = {
  upvotes: {
    list: [],
    loaded: false,
  },
  contributorStats : {
    stats: {},
    loaded: false,
  },
  reportStats: {
    stats: {},
    loaded: false,
  },
  viewed: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    // case CLEAR_USER_CACHE:
    //   state = initialState
    case CONSTANTS.POST_UPVOTE_REQUEST:
      return {
        ...state,
        upvotes: {
          ...state.upvotes,
          list: [],
          loaded: false,
        },
      }
    case CONSTANTS.POST_UPVOTE_SUCCESS:
      return {
        ...state,
        upvotes: {
          ...state.upvotes,
          list: action.upvotes,
          loaded: true,
        },
      }
  case CONSTANTS.GET_CONTRIBUTOR_STATS_REQUEST_ANALYTICS:
    return {
      ...state,
      contributorStats: {
        stats: {},
        loaded: false,
      },
    }
  case CONSTANTS.GET_CONTRIBUTOR_STATS_SUCCESS_ANALYTICS:
    return {
      ...state,
      contributorStats: {
        stats: action.data,
        loaded: true,
      },
    }
  case CONSTANTS.GET_REPORT_STATS_REQUEST:
    return {
      ...state,
      reportStats: {
        stats: {},
        loaded: false,
      },
    }
  case CONSTANTS.GET_REPORT_STATS_SUCCESS:
    return {
      ...state,
      reportStats: {
        stats: action.data,
        loaded: true,
      },
    }
  case CONSTANTS.CHECK_VIEWED_STATUS:
    return {
      ...state,
      viewed: true,
    }
  default:
    return state
  }
}
